import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { AuthContext } from "../../../providers/Auth/AuthProvider";
import CustomInput from "../../../components/input/CustomInput";
import { SnackBarContext } from "../../../providers/SnackBar/SnackBarProvider";
import { useNavigate } from "@reach/router";
import ROUTES from "../../../components/constants/routes";
import CustomButton from "../../../components/button/CustomButton";
import LOCAL_STORAGE_KEYS from "../../../constants/localStorage";
import { MAKE_SIGNED_SALE } from "../../../graphql/sale/mutation";
import {
    getIpId,
    fillSaleOrOffer,
    partialDomain,
    IDEAL_MARKETPLACE_TYPES,
} from "common";
import { ethers } from "ethers";
import contract from "../../../utils/blockchain/contract";
import { GET_SUBGRAPH_NFT } from "../../../subgraph/nft/query";
import { GraphNodeClientContext } from "../../../providers/Apollo/ApolloProvider";
import moment from "moment";
import { defaultValidUntil } from "../../../constants/blockchain";
import CustomLoader from "../../../components/customLoader/CustomLoader";
import { GET_NFT } from "../../../graphql/nft/query";

const NFTSellForm = ({ cid, handleClose, handleSwitchMenu }) => {
    const { authContext } = useContext(AuthContext);
    const { myProfile } = authContext;
    const { displaySnackBar } = useContext(SnackBarContext);
    const { graphNodeClient } = useContext(GraphNodeClientContext);
    const navigate = useNavigate();
    const [validUntil, setValidUntil] = useState("");
    const [sellPricePerShare, setSellPricePerShare] = useState(0);
    const [sellShares, setSellShares] = useState(0);
    const [myCurrentShareAmount, setMyCurrentShareAmount] = useState(0);
    const [loadingSignature, setLoadingSignature] = useState(false);

    const wallet = authContext.wallet;
    const signer = wallet?.signer;
    const provider = wallet?.provider;

    const nftErc20Id = getIpId({
        IdealNFTAddress: contract?.contracts?.IdealNFT?.address,
        ip: {
            contentType: 1,
            content: ethers.utils.toUtf8Bytes(cid),
        },
        chainId: contract?.chainId,
    });

    const [createSale, { loading: loadingCreateSale }] = useMutation(
        MAKE_SIGNED_SALE,
        {
            onCompleted: (data) => {
                if (data && data.makeSignedSale) {
                    if (handleClose) {
                        handleClose();
                        if (handleSwitchMenu) handleSwitchMenu();
                    } else {
                        navigate(`${ROUTES.nftDetail}/${cid}`);
                    }

                    displaySnackBar({
                        message: "You made a sale",
                        type: "success",
                    });
                }
            },
            onError: (err) => {
                console.log("err ==== ", err);
                setLoadingSignature(false);
                if (err.message === "NFT can only be updated by its creator") {
                    displaySnackBar({
                        message:
                            "You cannot sell this NFT because you are not the owner",
                        type: "error",
                    });
                } else if (err.message === "Bad signature") {
                    displaySnackBar({
                        message: "Bad signature",
                        type: "error",
                    });
                } else {
                    displaySnackBar({
                        message: "Error on making sale",
                        type: "error",
                    });
                }

                console.log(`err : `, err);
                displaySnackBar({
                    message: "Error on making sale",
                    type: "error",
                });
            },
            refetchQueries: [
                {
                    query: GET_NFT,
                    variables: { id: cid, saleOfferFilter: "active" },
                },
            ],
        }
    );

    const { data: mintedNFTData, loading: loadingGraphnode } = useQuery(
        GET_SUBGRAPH_NFT,
        {
            fetchPolicy: "cache-and-network",
            variables: { id: nftErc20Id },
            client: graphNodeClient,
            onCompleted: (data) => {
                if (data && data.nfterc20) {
                    const mintedNFT = data?.nfterc20;
                    const currentUserBalance = mintedNFT?.balances?.find(
                        (balance) =>
                            balance?.owner?.address?.toLowerCase() ===
                            myProfile?.id?.toLowerCase()
                    );

                    // Check if the user is one of the share holders
                    if (!currentUserBalance) {
                        displaySnackBar({
                            message:
                                "You cannot sell this NFT because you are not one of the share holders",
                            type: "error",
                        });
                        if (handleClose) {
                            handleClose();
                        } else {
                            navigate(`${ROUTES.nftDetail}/${cid}`);
                        }
                        return;
                    }

                    const currentUserShareAmount = currentUserBalance?.amount
                        ? ethers.utils.formatEther(currentUserBalance?.amount)
                        : 0;

                    setMyCurrentShareAmount(Number(currentUserShareAmount));
                    setSellShares(Number(currentUserShareAmount));
                }
            },
            onError: (err) => {
                console.log("err : ", err);
            },
        }
    );

    const mintedNFT = mintedNFTData?.nfterc20;

    const handleChangePrice = (value) => {
        setSellPricePerShare(Number(value));
    };

    const handleChangeShareCount = (value) => {
        setSellShares(Number(value));
    };

    const handleExpirationDate = (value) => {
        setValidUntil(value);
    };

    const validUntilDate = moment(validUntil);
    const todayDate = moment();

    const invalidValidUntil = !!(
        validUntil &&
        validUntilDate &&
        validUntilDate.isBefore(todayDate)
    );

    useEffect(() => {
        const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
        if (!token) {
            displaySnackBar({
                message: "You must be logged in to sell NFT",
                type: "error",
            });
            navigate(ROUTES.wallets);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myProfile]);

    const missmingValues = !sellPricePerShare || !sellShares;

    const total = (sellPricePerShare * sellShares).toString();

    const handleSell = async () => {
        if (!mintedNFT) {
            displaySnackBar({
                message: "This NFT is not minted yet",
                type: "error",
            });
            return;
        }

        if (missmingValues) {
            displaySnackBar({
                message: "You must set a price per share and a share count",
                type: "error",
            });
            return;
        }

        try {
            setLoadingSignature(true);
            console.log("--------------- handleSale --------------------");
            const defaultSale = fillSaleOrOffer({});
            console.log(`defaultSale : `, defaultSale);
            const sale = {
                ...defaultSale,
                saleOrOffer: 1,
                item: {
                    tokenContract: mintedNFT.address,
                    value: ethers.utils.parseEther(sellShares.toString()),
                },
                payment: {
                    ...defaultSale.payment,
                    value: ethers.utils.parseEther(total),
                },
                owner: {
                    ...defaultSale.owner,
                    tokenOwner: authContext.myProfile.id,
                },
                salt: ethers.utils.hexlify(ethers.utils.randomBytes(32)),
                feeReceiver: contract?.contracts?.PaymentSplitter?.address,
                validUntil: {
                    timeLimitType: 0,
                    blockNumberOrTimestamp: validUntil
                        ? Math.floor(new Date(validUntil).getTime() / 1000)
                        : defaultValidUntil,
                },
            };

            console.log(`readySale : `, sale);

            const chainId = provider?.provider.chainId;

            const domain = {
                ...partialDomain,
                chainId,
                verifyingContract:
                    contract.contracts?.IdealMarketplace?.address,
            };

            const signature = await signer._signTypedData(
                domain,
                IDEAL_MARKETPLACE_TYPES,
                sale
            );

            console.log(`signature : `, signature);
            setLoadingSignature(false);

            createSale({
                variables: {
                    input: {
                        ipCid: cid,
                        sale,
                        signature,
                        validUntil: new Date(validUntil || defaultValidUntil),
                    },
                },
            });
        } catch (error) {
            console.log(`error : `, error);
            displaySnackBar({
                message:
                    error?.data?.message || "Error while signing your sale",
                type: "error",
            });
            setLoadingSignature(false);
        }
    };

    const maxShareError = sellShares > myCurrentShareAmount;
    const maxShareErrorMessage = `Share count cannot be more than the total you own : ${myCurrentShareAmount}`;

    const requiredShareMessage = "Share count is required";
    const requiredPricePerShareMessage = "Price per share is required";

    return (
        <div>
            {(loadingGraphnode || loadingCreateSale || loadingSignature) && (
                <CustomLoader
                    text={
                        loadingCreateSale
                            ? "Creating your sale..."
                            : loadingSignature
                            ? "Signing your sale..."
                            : undefined
                    }
                />
            )}

            <div className="field-set">
                <CustomInput
                    label="Shares"
                    placeholder="enter share count"
                    value={sellShares}
                    handleChangeValue={handleChangeShareCount}
                    type="number"
                    error={maxShareError || !sellShares}
                    errorText={
                        !sellShares
                            ? requiredShareMessage
                            : maxShareErrorMessage
                    }
                />

                <CustomInput
                    label="Price per share (ETH)"
                    placeholder="enter price per share (ETH)"
                    value={sellPricePerShare}
                    handleChangeValue={handleChangePrice}
                    type="number"
                    error={!sellPricePerShare}
                    errorText={requiredPricePerShareMessage}
                />

                <CustomInput
                    label="Total price (ETH)"
                    placeholder="enter the total price (ETH)"
                    value={total}
                    disabled
                />

                <CustomInput
                    label="Expiration date"
                    placeholder="enter the expiration date of your sale"
                    value={
                        validUntil
                            ? moment(validUntil).format("YYYY-MM-DD")
                            : undefined
                    }
                    handleChangeValue={handleExpirationDate}
                    type="date"
                    min={moment(new Date()).format("YYYY-MM-DD")}
                    error={invalidValidUntil}
                    errorText="Expiration date must be a future date"
                />

                <CustomButton
                    text="Sell Item"
                    onClick={handleSell}
                    disabled={
                        loadingCreateSale ||
                        missmingValues ||
                        maxShareError ||
                        invalidValidUntil
                    }
                />
            </div>
        </div>
    );
};

export default NFTSellForm;
