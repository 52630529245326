import React, { useContext } from "react";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";
import { useQuery } from "@apollo/client";
import Footer from "../../components/footer/Footer";
import CustomLoader from "../../components/customLoader/CustomLoader";
import CustomButton from "../../components/button/CustomButton";
import { MY_NOTIFICATIONS } from "../../graphql/notification/query";
import NotificationItem from "./item/NotificationItem";
import HeaderWithImage from "../../components/header/HeaderWithImage";

const PAGE_SIZE = 5;

const Notification = () => {
    const { isDark } = useContext(ThemeContext);

    const { data, fetchMore, loading } = useQuery(MY_NOTIFICATIONS, {
        variables: {
            take: PAGE_SIZE,
            skip: 0,
        },
    });

    const myNotifications = data?.myNotifications?.notifications || [];

    const handleFetchMore = () => {
        if (myNotifications?.length) {
            fetchMore({
                variables: {
                    skip: myNotifications?.length,
                    take: PAGE_SIZE,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    if (
                        !fetchMoreResult?.myNotifications?.notifications?.length
                    ) {
                        return previousResult;
                    }

                    const next = {
                        ...previousResult,
                        myNotifications: {
                            ...previousResult?.myNotifications,
                            notifications: [
                                ...previousResult?.myNotifications
                                    ?.notifications,
                                ...fetchMoreResult?.myNotifications
                                    ?.notifications,
                            ],
                        },
                    };

                    return next;
                },
            });
        }
    };

    const noMoreToFetch =
        data?.myNotifications?.totalCount === myNotifications?.length;

    return (
        <div className={isDark ? "greyscheme" : ""}>
            {loading && <CustomLoader />}

            <HeaderWithImage title={"Notifications"} />

            <section className="container">
                <div className="row">
                    <div className="col-md-8">
                        {myNotifications?.length ? (
                            <ul className="activity-list">
                                {myNotifications?.map((notification) => (
                                    <NotificationItem
                                        notification={notification}
                                    />
                                ))}
                                {!noMoreToFetch && (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <CustomButton
                                            onClick={handleFetchMore}
                                            text="Load More"
                                            disabled={loading}
                                        />
                                    </div>
                                )}
                            </ul>
                        ) : (
                            <div>No notification.</div>
                        )}
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
};
export default Notification;
