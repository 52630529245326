import React, { useContext } from "react";
import { Link } from "@reach/router";
import { useIntl } from "react-intl";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";
import COLORS from "../../constants/colors";

const NavLink = (props) => (
    <Link
        {...props}
        getProps={({ isCurrent }) => {
            // the object returned here is passed to the
            // anchor element's props
            return {
                className: isCurrent ? "active" : "non-active",
            };
        }}
    />
);

const HeaderMenuL = ({
    ref,
    label,
    subMenus,
    openSubMenu,
    handleOpenMenu,
    handleCloseMenu,
}) => {
    const { isDark } = useContext(ThemeContext);
    const { formatMessage } = useIntl();

    return (
        <div className="navbar-item">
            <div ref={ref}>
                <div
                    className="dropdown-custom dropdown-toggle btn"
                    onClick={handleOpenMenu}
                    style={{
                        color: isDark && COLORS.v2.white,
                    }}
                >
                    {formatMessage({
                        id: label,
                    })}
                </div>
                {openSubMenu && subMenus.length && (
                    <div
                        className={
                            isDark ? "item-dropdown dark" : "item-dropdown"
                        }
                    >
                        <div className="dropdown" onClick={handleCloseMenu}>
                            {subMenus.map((subMenu) => (
                                <div
                                    key={subMenu?.label}
                                    onClick={handleCloseMenu}
                                >
                                    <NavLink to={subMenu?.path}>
                                        {formatMessage({
                                            id: subMenu?.label,
                                        })}
                                    </NavLink>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default HeaderMenuL;
