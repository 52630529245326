import { gql } from "@apollo/client";
import { BLOG_FRAGMENT } from "./fragment";

export const GET_BLOGS = gql`
    query GetBlogs($input: GetBlogsInput) {
        getBlogs(input: $input) {
            ...BlogFields
        }
    }
    ${BLOG_FRAGMENT}
`;

export const GET_BLOG = gql`
    query GetBlog($id: Int!) {
        getBlog(id: $id) {
            blog {
                ...BlogFields
            }
            recentBlogs {
                ...BlogFields
            }
        }
    }
    ${BLOG_FRAGMENT}
`;
