import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import CustomInput from "../../../../../components/input/CustomInput";
import { SnackBarContext } from "../../../../../providers/SnackBar/SnackBarProvider";
import { useNavigate } from "@reach/router";
import { ThemeContext } from "../../../../../providers/Theme/ThemeProvider";
import ROUTES from "../../../../../components/constants/routes";
import CustomLoader from "../../../../../components/customLoader/CustomLoader";
import HeaderWithImage from "../../../../../components/header/HeaderWithImage";
import CustomButton from "../../../../../components/button/CustomButton";
import Footer from "../../../../../components/footer/Footer";
import { AuthContext } from "../../../../../providers/Auth/AuthProvider";
import { CREATE_HELP } from "../../../../../graphql/help/mutation";
import { BloggersClientContext } from "../../../../../providers/Apollo/ApolloProvider";

const CreateHelp = () => {
    const { displaySnackBar } = useContext(SnackBarContext);
    const { isBlogAdmin } = useContext(AuthContext);
    const { bloggersClient } = useContext(BloggersClientContext);
    const navigate = useNavigate();
    const { isDark } = useContext(ThemeContext);
    const [help, setHelp] = useState({
        title: undefined,
        description: undefined,
        priority: undefined,
    });

    const [createHelp, { loading }] = useMutation(CREATE_HELP, {
        client: bloggersClient,
        onCompleted: (data) => {
            if (data && data.createHelp) {
                displaySnackBar({
                    message: "Help created",
                    type: "success",
                });
                navigate(`${ROUTES.helpCenter}`);
            }
        },
        onError: (err) => {
            if (err.message) {
                displaySnackBar({
                    message: err.message,
                    type: "error",
                });
            }
        },
    });

    const handleChangeTitle = (value) => {
        setHelp({ ...help, title: value });
    };

    const handleChangeDescription = (value) => {
        setHelp({ ...help, description: value });
    };

    const handleChangePriority = (value) => {
        setHelp({ ...help, priority: Number(value) });
    };

    const onCreateHelp = () => {
        const { title, description } = help;
        if (title && description) {
            createHelp({
                variables: {
                    input: {
                        ...help,
                    },
                },
            });
        }
    };

    const titleMissing = !help.title;

    useEffect(() => {
        if (!isBlogAdmin) {
            displaySnackBar({
                message: "Only blog admin can have access to this interface",
                type: "error",
            });
            navigate(ROUTES.helpCenter);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBlogAdmin]);

    return (
        <div className={isDark ? "greyscheme" : ""}>
            {loading && <CustomLoader text="Creating your Help..." />}

            <HeaderWithImage title="Create Help" />

            <section className="container">
                <div className="row">
                    <div className="col-lg-7 offset-lg-1 mb-5">
                        <form
                            id="form-create-item"
                            className="form-border"
                            action="#"
                        >
                            <div className="field-set">
                                <div className="spacer-single"></div>

                                <CustomInput
                                    label="Title"
                                    placeholder="Give a title to your help"
                                    value={help.title}
                                    handleChangeValue={handleChangeTitle}
                                    error={titleMissing}
                                    errorText="This field is required"
                                />

                                <CustomInput
                                    label="Description"
                                    placeholder="Tell about your help documentation"
                                    value={help.description}
                                    handleChangeValue={handleChangeDescription}
                                    muiltiline
                                    style={{ height: 200 }}
                                    error={!help?.description}
                                    errorText="This field is required"
                                />

                                <CustomInput
                                    label="Priority"
                                    placeholder="Priority of your help"
                                    value={help.priority}
                                    handleChangeValue={handleChangePriority}
                                    type="number"
                                />

                                <CustomButton
                                    text="CREATE HELP"
                                    onClick={onCreateHelp}
                                    disabled={loading || titleMissing}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default CreateHelp;
