import { createStyles, makeStyles } from "@material-ui/core";
import { useContext } from "react";
import COLORS from "../../../../constants/colors";
import { ThemeContext } from "../../../../providers/Theme/ThemeProvider";

const FeatureBoxImage = ({ featureBoxKey }) => {
    const { isDark } = useContext(ThemeContext);

    const useStyles = makeStyles((theme) =>
        createStyles({
            disabledButton: {
                opacity: 0.5,
            },
            featureBox1ImagesContainer: {
                display: "flex",
                justifyContent: "center",
            },
            featureBox1Image: {
                maxHeight: 80,
                maxWidth: 80,
                borderRadius: 15,
                opacity: 1,
            },
            featureBox2ImagesContainer: {
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
            },
            featureBox2Image: {
                height: 40,
                width: 40,
                borderRadius: 10,
                opacity: 1,
                backgroundColor: isDark
                    ? COLORS.v2.grey_background
                    : COLORS.v2.white,
                margin: 5,
            },
            featureBox3Image: {
                height: "auto",
                width: "100%",
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15,
                marginBottom: 30,
                opacity: 1,
            },
        })
    );

    const classes = useStyles();

    if (featureBoxKey === 1) {
        return (
            <div className={classes.featureBox1ImagesContainer}>
                <div className="image_border">
                    <img
                        key="/img/v2/avatars/avatar-2.jpg"
                        src="/img/v2/avatars/avatar-2.jpg"
                        className={classes.featureBox1Image}
                        alt="#"
                    />
                </div>
                <div className="image_border">
                    <img
                        key="/img/v2/avatars/avatar-3.png"
                        src="/img/v2/avatars/avatar-3.png"
                        className={classes.featureBox1Image}
                        alt="#"
                    />
                </div>
                <div className="image_border">
                    <img
                        key="/img/v2/avatars/avatar-4.png"
                        src="/img/v2/avatars/avatar-4.png"
                        className={classes.featureBox1Image}
                        alt="#"
                    />
                </div>
            </div>
        );
    }

    if (featureBoxKey === 2) {
        return (
            <div className={classes.featureBox2ImagesContainer}>
                {[...Array(12)].map((e, i) => (
                    <div className={classes.featureBox2Image} />
                ))}
            </div>
        );
    }

    return (
        <img
            key="/img/v2/avatars/avatar-1.png"
            src="/img/v2/avatars/avatar-1.png"
            className={classes.featureBox3Image}
            alt="#"
        />
    );
};

export default FeatureBoxImage;
