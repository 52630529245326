import React, { useContext } from "react";
import { useLocation } from "@reach/router";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";
import ROUTES from "../../components/constants/routes";
import COLORS from "../../constants/colors";
import AppLogo from "../../components/logo/AppLogo";
import { FONT_SIZES } from "../../constants/sizes";
import BloggerProfile from "./BloggerProfile";

const BloggerHeader = () => {
    const { isDark } = useContext(ThemeContext);
    const location = useLocation();

    if (location?.pathname === ROUTES.bloggerLogin) return null;

    return (
        <header
            id="myHeader"
            className={"navbar white"}
            style={{
                backgroundColor: isDark && COLORS.v2.black_grey,
                borderBottom: isDark && `solid 1px ${COLORS.v2.black_grey}`,
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 30,
                    paddingTop: 10,
                    paddingBottom: 10,
                }}
            >
                <AppLogo />
                <div
                    style={{
                        fontSize: FONT_SIZES.common.larger,
                        fontWeight: "bold",
                        color: isDark ? COLORS.v2.white : COLORS.v2.black_grey,
                    }}
                >
                    Blogger Interface
                </div>
                <BloggerProfile />
            </div>
        </header>
    );
};
export default BloggerHeader;
