import authentification from "./authentification";
import common from "./common";
import header from "./header";
import home from "./home";
import myProfile from "./myProfile";
import net from "./net";
import wallet from "./wallet";

const fr = {
    ...common,
    ...home,
    ...header,
    ...authentification,
    ...myProfile,
    ...net,
    ...wallet,
};

export default fr;
