import { useEffect, useState } from "react";
import { blobToBase64 } from "../../utils/file/getBase64";
import getImagePreview from "../../utils/nft/getImagePreview";
import CustomButton from "../button/CustomButton";
import CustomGGLandDialog from "../dialog/CustomGGLandDialog";

const FilePreview = ({
    fileUrl,
    fileBlob,
    fileName,
    fileType,
    handleClose,
}) => {
    const [preview, setPreview] = useState(false);
    const [b64, setB64] = useState("");
    const handlePreviewOrHide = () => {
        if (fileUrl) {
            setPreview(!preview);
        }
    };

    const handleDownload = () => {
        if (!fileUrl) {
            return;
        }
        var link = document.createElement("a");
        link.download = fileName || "";
        link.href = fileUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const extension = fileType || "";

    const isPreviewable =
        extension.includes("text") ||
        extension.includes("pdf") ||
        extension.includes("video") ||
        extension.includes("audio");
    const isAudio = extension.includes("audio");

    useEffect(() => {
        if (fileBlob) {
            blobToBase64(fileBlob, (result) => {
                setB64(result);
            });
        }
    }, [fileBlob]);

    return (
        <CustomGGLandDialog
            title={fileName}
            handleClose={handleClose}
            noSubmit
            mainCheckoutStyle={preview ? { maxWidth: 1000 } : undefined}
        >
            <div style={{ height: "fit-content" }}>
                {isAudio ? (
                    <audio controls src={fileUrl}></audio>
                ) : preview ? (
                    <iframe
                        title={fileName}
                        className={extension}
                        width="100%"
                        height="100%"
                        style={{ minHeight: 450 }}
                        frameborder="0"
                        src={b64 ? `data:${extension};base64,${b64}` : fileUrl}
                    ></iframe>
                ) : (
                    <img
                        src={getImagePreview({
                            extension,
                            imageUrl: fileUrl,
                        })}
                        className="img-fluid img-rounded mb-sm-30"
                        alt=""
                    />
                )}

                <div style={{ display: "flex" }}>
                    {isPreviewable && !isAudio && (
                        <CustomButton
                            isWhite
                            text={preview ? "Hide Preview" : "Preview"}
                            onClick={handlePreviewOrHide}
                            disabled={!fileUrl}
                            style={{ marginRight: 20 }}
                        />
                    )}
                    <CustomButton
                        text="Download"
                        onClick={handleDownload}
                        disabled={!fileUrl}
                    />
                </div>
            </div>
        </CustomGGLandDialog>
    );
};

export default FilePreview;
