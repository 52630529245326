export const ADMIN_TABS_ENUM = {
    pendingIps: "pendingIps",
    myPermits: "myPermits",
    myDenies: "myDenies",
    moderators: "moderators",
    featured: "featured",
};

export const ADMIN_TABS = [
    ADMIN_TABS_ENUM.pendingIps,
    ADMIN_TABS_ENUM.myPermits,
    ADMIN_TABS_ENUM.myDenies,
    ADMIN_TABS_ENUM.moderators,
    ADMIN_TABS_ENUM.featured,
];

export const ADMIN_BLOG_TABS = [ADMIN_TABS_ENUM.community];

export const MODERATOR_TABS = [
    ADMIN_TABS_ENUM.pendingIps,
    ADMIN_TABS_ENUM.myPermits,
    ADMIN_TABS_ENUM.myDenies,
];

export const getAdminTabName = (tab) => {
    switch (tab) {
        case ADMIN_TABS_ENUM.pendingIps:
            return "Pending IPs";

        case ADMIN_TABS_ENUM.myPermits:
            return "My Permits";

        case ADMIN_TABS_ENUM.myDenies:
            return "Pending IPs";

        case ADMIN_TABS_ENUM.moderators:
            return "Moderators management";

        case ADMIN_TABS_ENUM.featured:
            return "Featured IPs";

        default:
            return "";
    }
};
