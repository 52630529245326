import { Box, createStyles, makeStyles } from "@material-ui/core";
import Reveal from "react-awesome-reveal";
import CustomButton from "../../../../components/button/CustomButton";
import COLORS from "../../../../constants/colors";
import { useNavigate } from "@reach/router";
import ROUTES from "../../../../components/constants/routes";
import { useIntl } from "react-intl";
import { useContext } from "react";
import { AuthContext } from "../../../../providers/Auth/AuthProvider";
import { fadeInUp } from "../../../../constants/animations";

const useStyles = makeStyles((theme) =>
    createStyles({
        container: {
            display: "flex",
            color: COLORS.v2.black_grey,
            alignItems: "center",
        },
        left: {
            width: "60%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
        },
        right: {
            width: "40%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
        },
        title: {
            fontWeight: "bold",
            marginBottom: 60,
            marginTop: 30,
        },
        logosContainer: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
        },
        imagesContainer: {
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: 20,
        },
        image: {
            maxHeight: 80,
            maxWidth: 80,
            borderRadius: 15,
            opacity: 1,
            marginRight: 15,
        },
    })
);

const Featured = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const { isUserConnected } = useContext(AuthContext);

    const seeProject = () => {
        navigate(ROUTES.explore);
    };

    const connect = () => {
        navigate(ROUTES.wallets);
    };

    const createIP = () => {
        navigate(ROUTES.createNFT);
    };

    return (
        <section className="left_right_container featured">
            <Box className="left">
                <h6 style={{ color: COLORS.v2.black_grey, marginBottom: 40 }}>
                    FEATURED
                </h6>
                <div className={classes.imagesContainer}>
                    <img
                        key="/img/v2/avatars/avatar-3.png"
                        src="/img/v2/avatars/avatar-3.png"
                        className={classes.image}
                        alt="#"
                    />
                    <img
                        key="/img/v2/avatars/kodak.png"
                        src="/img/v2/avatars/kodak.png"
                        className={classes.image}
                        alt="#"
                    />
                    <img
                        key="/img/v2/avatars/avatar-4.png"
                        src="/img/v2/avatars/avatar-4.png"
                        className={classes.image}
                        alt="#"
                    />
                </div>
                <h4 style={{ color: COLORS.v2.black_grey, marginBottom: 0 }}>
                    COMING SOON
                </h4>
                {/* <Reveal
                    className="onStep"
                    keyframes={fadeInUp}
                    delay={0}
                    duration={600}
                    triggerOnce
                >
                    <p
                        style={{
                            color: COLORS.v2.black_grey,
                            fontSize: 15,
                            marginBottom: 40,
                            paddingRight: 70,
                        }}
                    >
                        As part of an ongoing artist series for Genesis, I
                        reimagined their newly redesigned G70 sedan as
                        reflective sculptural artworks in a rustic neoclassical
                        setting.
                    </p>
                </Reveal> */}

                <CustomButton
                    text="See Project"
                    isBlack
                    style={{ marginTop: 40 }}
                    onClick={seeProject}
                />
            </Box>
            <Box className="right">
                <Reveal
                    className="onStep"
                    keyframes={fadeInUp}
                    delay={100}
                    duration={600}
                    triggerOnce
                >
                    <p
                        style={{
                            color: COLORS.v2.black_grey,
                            fontSize: 15,
                            marginBottom: 40,
                            paddingRight: 70,
                        }}
                    >
                        {formatMessage({ id: "home.featured.desc.right" })}
                    </p>
                </Reveal>
                <CustomButton
                    text={isUserConnected ? "Create IP" : "Connect Wallet"}
                    onClick={isUserConnected ? createIP : connect}
                    isBlack
                />
            </Box>
        </section>
    );
};

export default Featured;
