const net = {
    "net.switch.main.error":
        "Erreur lors de la tentative de basculement vers le réseau principal.",
    "net.switch.main.success":
        "Basculement vers le réseau principal avec succès !",
    "net.unknown.message":
        "Votre portefeuille est connecté à un réseau inconnu. Veuillez passer au réseau {linkSwitchToMainNet}.",
    "net.wrong.net.message":
        "Votre portefeuille est connecté au réseau {name}. Pour utiliser Ideal sur {name}, veuillez passer à {linkText}.",
    "net.main": "Principal",
};

export default net;
