/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext } from "react";
import { Box, createStyles, makeStyles } from "@material-ui/core";
import COLORS from "../../constants/colors";
import { NetworkContext } from "../../providers/NetTester/NetTesterProvider";
import { GraphNodeClientContext } from "../../providers/Apollo/ApolloProvider";
import { useQuery } from "@apollo/client";
import { GET_TICKER_STAT } from "../../subgraph/stat/query";
import EtherLogo from "./EtherLogo";
import { useLocation } from "@reach/router";
import ROUTES, { BLOGGER_ROUTES } from "../constants/routes";

const ITEM_DISTANCE = 10;

const useStyles = makeStyles((theme) =>
    createStyles({
        listContainer: {
            display: "flex",
        },
        oneStat: {
            display: "flex",
            marginRight: ITEM_DISTANCE * 2,
        },
        title: {
            color: COLORS.v2.green,
            textTransform: "uppercase",
            fontWeight: "500",
            marginRight: ITEM_DISTANCE,
        },
        onePeriodicity: {
            display: "flex",
            marginRight: ITEM_DISTANCE,
        },
        pertiodicityLabel: {
            fontWeight: "500",
            marginRight: ITEM_DISTANCE,
        },
        statItem: {
            marginRight: ITEM_DISTANCE,
        },
        deltaNegative: {
            color: "red",
            marginRight: ITEM_DISTANCE,
        },
        deltaPositive: {
            color: COLORS.v2.green,
            marginRight: ITEM_DISTANCE,
        },
    })
);

const TickerBanner = () => {
    const { isNetworkMismatched, isUnsupportedNetwork } =
        useContext(NetworkContext);
    const { graphNodeClient } = useContext(GraphNodeClientContext);
    const classes = useStyles();
    const location = useLocation();

    const { data } = useQuery(GET_TICKER_STAT, {
        fetchPolicy: "cache-and-network",
        client: graphNodeClient,
        onCompleted: (data) => {
            // here
        },
    });

    const stats = data?.nfterc20S || [];

    const formattedStats = stats.length
        ? stats.filter((stat) => {
              return stat?.dailyStats?.length;
              //    || stat?.monthlyStats?.length ||
              //   stat?.weeklyStats?.length
          })
        : [];

    if (isNetworkMismatched || isUnsupportedNetwork || !formattedStats?.length)
        return null;

    const generateDeltaItem = (delta) => {
        if (!delta) return null;

        if (delta === "1") return <Box className={classes.statItem}>0%</Box>;

        if (1 - Number(delta) < 0)
            return (
                <Box className={classes.deltaNegative}>
                    {((1 - Number(delta)) * 100).toFixed(2)}%
                </Box>
            );

        return (
            <Box className={classes.deltaPositive}>
                +{((1 - Number(delta)) * 100).toFixed(2)}%
            </Box>
        );
    };

    if (
        location?.pathname === ROUTES.bloggerLogin ||
        !!BLOGGER_ROUTES.some((route) => location?.pathname?.includes(route))
    )
        return null;

    return (
        <Box className="ticker-banner">
            <marquee speed={50} loop="-1" pauseOnHover>
                <Box className={classes.listContainer}>
                    {formattedStats.map((stat) => {
                        return (
                            <Box className={classes.oneStat}>
                                <Box className={classes.title}>
                                    {stat?.title}
                                </Box>

                                {/* ----------- DAILY STATS ----------- */}
                                {stat?.dailyStats[0] && (
                                    <Box className={classes.onePeriodicity}>
                                        {/* <Box
                                            className={
                                                classes.pertiodicityLabel
                                            }
                                        >
                                            %24h
                                        </Box> */}
                                        {stat?.dailyStats[0]?.averagePrice && (
                                            <Box className={classes.statItem}>
                                                {
                                                    <EtherLogo
                                                        style={{
                                                            marginRight:
                                                                ITEM_DISTANCE /
                                                                2,
                                                        }}
                                                    />
                                                }
                                                {
                                                    stat?.dailyStats[0]
                                                        ?.averagePrice
                                                }
                                            </Box>
                                        )}
                                        {generateDeltaItem(
                                            stat?.dailyStats[0].delta
                                        )}
                                    </Box>
                                )}
                                {/* ----------- DAILY STATS ----------- */}

                                {/* ----------- WEEKLY STATS ----------- */}
                                {/* {stat?.weeklyStats[0] && (
                                    <Box className={classes.onePeriodicity}>
                                        <Box
                                            className={
                                                classes.pertiodicityLabel
                                            }
                                        >
                                            %7d
                                        </Box>
                                        {stat?.weeklyStats[0]?.averagePrice && (
                                            <Box className={classes.statItem}>
                                                {ETHERS_LOGO}
                                                {
                                                    stat?.weeklyStats[0]
                                                        ?.averagePrice
                                                }
                                            </Box>
                                        )}
                                        {generateDeltaItem(
                                            stat?.weeklyStats[0].delta
                                        )}
                                    </Box>
                                )} */}
                                {/* ----------- WEEKLY STATS ----------- */}

                                {/* ----------- MONTHLY STATS ----------- */}
                                {/* {stat?.monthlyStats[0] && (
                                    <Box className={classes.onePeriodicity}>
                                        <Box
                                            className={
                                                classes.pertiodicityLabel
                                            }
                                        >
                                            %1m
                                        </Box>
                                        {stat?.monthlyStats[0]
                                            ?.averagePrice && (
                                            <Box className={classes.statItem}>
                                                {ETHERS_LOGO}
                                                {
                                                    stat?.monthlyStats[0]
                                                        ?.averagePrice
                                                }
                                            </Box>
                                        )}
                                        {generateDeltaItem(
                                            stat?.monthlyStats[0].delta
                                        )}
                                    </Box>
                                )} */}
                                {/* ----------- MONTHLY STATS ----------- */}
                            </Box>
                        );
                    })}
                </Box>
            </marquee>
        </Box>
    );
};

export default TickerBanner;
