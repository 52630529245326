import { memo, useContext, useState } from "react";
import COLORS from "../../constants/colors";
import { FONT_SIZES } from "../../constants/sizes";
import DeleteIcon from "@material-ui/icons/Delete";
import { AuthContext } from "../../providers/Auth/AuthProvider";
import EditIcon from "@material-ui/icons/Edit";
import { useMutation } from "@apollo/client";
import { DELETE_SUB_HELP } from "../../graphql/help/mutation";
import { SnackBarContext } from "../../providers/SnackBar/SnackBarProvider";
import CustomGGLandDialog from "../dialog/CustomGGLandDialog";
import CustomButton from "../button/CustomButton";
import UpdateSubHelpForm from "./UpdateSubHelpForm";
import { useNavigate } from "@reach/router";
import ROUTES from "../constants/routes";
import { BloggersClientContext } from "../../providers/Apollo/ApolloProvider";

const SubHelpItem = ({ subHelp, refetchHelp }) => {
    const { isBlogAdmin } = useContext(AuthContext);
    const { displaySnackBar } = useContext(SnackBarContext);
    const { bloggersClient } = useContext(BloggersClientContext);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openUpdateSubHelpDialog, setOpenUpdateSubHelpDialog] =
        useState(false);
    const navigate = useNavigate();

    const [deleteSubHelp, { loading: loadingDelete }] = useMutation(
        DELETE_SUB_HELP,
        {
            client: bloggersClient,
            onCompleted: (data) => {
                if (data && data.deleteSubHelp) {
                    displaySnackBar({
                        message: "Sub Help deleted",
                        type: "success",
                    });
                    onCloseDeleteDialog();
                    refetchHelp();
                }
            },
            onError: (err) => {
                if (err.message) {
                    displaySnackBar({
                        message: err.message,
                        type: "error",
                    });
                }
            },
        }
    );

    const onCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const onCloseUpdateDialog = () => {
        setOpenUpdateSubHelpDialog(false);
    };

    const onDeleteSubHelp = () => {
        if (subHelp?.id) {
            deleteSubHelp({
                variables: {
                    id: Number(subHelp?.id),
                },
            });
        }
    };

    return (
        <div style={{ display: "flex" }}>
            <div
                style={{
                    width: "100%",
                    paddingTop: 15,
                    paddingBottom: 15,
                    borderBottomWidth: 1,
                    borderBottomStyle: "solid",
                    borderBottomColor: COLORS.v2.greyText,
                    fontWeight: "500",
                    fontSize: FONT_SIZES.common.larger,
                    color: COLORS.v2.greyText,
                    cursor: "pointer",
                }}
                onClick={() =>
                    navigate(`${ROUTES.subHelpDetail}/${subHelp?.id}`)
                }
            >
                {subHelp?.title}
            </div>
            {isBlogAdmin && (
                <EditIcon
                    style={{
                        alignSelf: "center",
                        cursor: "pointer",
                        marginLeft: 20,
                    }}
                    onClick={() => setOpenUpdateSubHelpDialog(true)}
                />
            )}
            {isBlogAdmin && (
                <DeleteIcon
                    style={{
                        alignSelf: "center",
                        cursor: "pointer",
                        marginLeft: 20,
                    }}
                    onClick={() => setOpenDeleteDialog(true)}
                />
            )}

            {openDeleteDialog && (
                <CustomGGLandDialog
                    title="Do you really want to delete this sub help documentation ?"
                    handleClose={onCloseDeleteDialog}
                >
                    <div>
                        {`"${subHelp?.title}"`}
                        <div style={{ display: "flex" }}>
                            <CustomButton
                                text="DELETE"
                                onClick={onDeleteSubHelp}
                                disabled={loadingDelete}
                                style={{ marginRight: 20 }}
                            />
                            <CustomButton
                                text="CANCEL"
                                onClick={onCloseDeleteDialog}
                                disabled={loadingDelete}
                                isWhite
                            />
                        </div>
                    </div>
                </CustomGGLandDialog>
            )}

            {openUpdateSubHelpDialog && (
                <CustomGGLandDialog
                    title="Update this sub help documentation"
                    handleClose={onCloseUpdateDialog}
                >
                    <UpdateSubHelpForm
                        subHelp={subHelp}
                        handleCloseDialog={onCloseUpdateDialog}
                        refetchHelp={refetchHelp}
                    />
                </CustomGGLandDialog>
            )}
        </div>
    );
};
export default memo(SubHelpItem);
