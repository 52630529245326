import { useQuery } from "@apollo/client";
import React, { createContext } from "react";
import { GET_NFT_CATEGORIES } from "../../graphql/category/query";

export const CategoriesContecxt = createContext({
    defaultCategories: [],
    defaultSelectCategories: [],
    refetch: () => {},
    loadingCategories: false,
});

const CategoriesProvider = ({ children }) => {
    const { data, refetch, loading } = useQuery(GET_NFT_CATEGORIES);

    const defaultCategories = data?.getIpCategories || [];

    const defaultSelectCategories = defaultCategories?.length
        ? defaultCategories.map((category) => ({
              value: category?.code,
              label: category?.name,
          }))
        : [];

    return (
        <CategoriesContecxt.Provider
            value={{
                defaultCategories,
                defaultSelectCategories,
                refetch,
                loadingCategories: loading,
            }}
        >
            {children}
        </CategoriesContecxt.Provider>
    );
};

export default CategoriesProvider;
