import { gql } from "@apollo/client";
import {
    HELP_FRAGMENT,
    PARAGRAPH_FRAGMENT,
    SUB_HELP_FRAGMENT,
} from "./fragment";

export const GET_HELPS = gql`
    query GetHelps($filter: String) {
        getHelps(filter: $filter) {
            ...HelpFields
        }
    }
    ${HELP_FRAGMENT}
`;

export const GET_HELP = gql`
    query GetHelp($id: Int!) {
        getHelp(id: $id) {
            ...HelpFields
            subHelps {
                ...SubHelpFields
            }
        }
    }
    ${HELP_FRAGMENT}
    ${SUB_HELP_FRAGMENT}
`;

export const GET_SUB_HELP = gql`
    query GetSubHelp($id: Int!) {
        getSubHelp(id: $id) {
            ...SubHelpFields
            content {
                ...ParagraphFields
            }
            help {
                ...HelpFields
            }
        }
    }
    ${SUB_HELP_FRAGMENT}
    ${PARAGRAPH_FRAGMENT}
    ${HELP_FRAGMENT}
`;
