import { useQuery } from "@apollo/client";
import React, { useContext } from "react";
import CustomLoader from "../../../components/customLoader/CustomLoader";
import NFTCardList from "../../../components/nftCard/NFTCardList";
import { GET_PENDING_NFTS } from "../../../graphql/nft/query";
import { ThemeContext } from "../../../providers/Theme/ThemeProvider";

const PendingIpsList = () => {
    const { isDark } = useContext(ThemeContext);

    const { data, loading } = useQuery(GET_PENDING_NFTS, {
        fetchPolicy: "cache-and-network",
    });

    const pendingNFTs = data ? data.getPendingIps : [];

    return (
        <div className={isDark ? "greyscheme" : ""}>
            {loading && <CustomLoader />}
            {pendingNFTs.length ? (
                <NFTCardList nfts={pendingNFTs} navigateToNFTPermit />
            ) : (
                <div>No pending item.</div>
            )}
        </div>
    );
};
export default PendingIpsList;
