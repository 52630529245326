import React, { useContext, useState } from "react";
import CustomInput from "../../components/input/CustomInput";
import { AuthContext } from "../../providers/Auth/AuthProvider";
import { SnackBarContext } from "../../providers/SnackBar/SnackBarProvider";
import CustomButton from "../../components/button/CustomButton";
import Footer from "../../components/footer/Footer";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";
import contract from "../../utils/blockchain/contract";
import { ethers } from "ethers";
import CustomLoader from "../../components/customLoader/CustomLoader";
import HeaderWithImage from "../../components/header/HeaderWithImage";

const Deposit = () => {
    const { authContext, myDeposit, refetchDeposit } = useContext(AuthContext);
    const { myProfile } = authContext;
    const wallet = authContext?.wallet;
    const signer = wallet?.signer;

    const { displaySnackBar } = useContext(SnackBarContext);
    const { isDark } = useContext(ThemeContext);
    const [amount, setAmount] = useState(0);
    const [loading, setLoading] = useState(false);

    const contractAddress = contract?.contracts?.IdealMarketplace?.address;
    const contractAbi = contract?.contracts?.IdealMarketplace?.abi;
    let IdealMarketplace = new ethers.Contract(contractAddress, contractAbi);

    IdealMarketplace = IdealMarketplace.connect(signer);

    const handleChangeAmount = (value) => {
        setAmount(value);
    };

    const onDeposit = async () => {
        try {
            console.log("--------------- Depositing -----------------");
            setLoading(true);
            await IdealMarketplace.deposit(
                myProfile?.id,
                ethers.utils.parseEther(amount.toString()),
                { value: ethers.utils.parseEther(amount.toString()) }
            );

            setTimeout(() => {
                refetchDeposit();
                setLoading(false);
                setAmount(0);
            }, 3000);
        } catch (error) {
            setLoading(false);
            displaySnackBar({
                message:
                    error?.data?.message ||
                    error?.message ||
                    "Error occured when exectuting",
                type: "error",
            });
        }
    };

    return (
        <div className={isDark ? "greyscheme" : ""}>
            {loading && <CustomLoader text="Depositing your amount..." />}

            <HeaderWithImage title={"Make a deposit"} />

            <section className="container">
                <div className="row">
                    <div className="col-lg-7 offset-lg-1 mb-5">
                        <div style={{ marginBottom: 20 }}>
                            <h4>
                                <span
                                    id="wallet"
                                    className="profile_wallet"
                                    style={{ marginTop: 10 }}
                                >
                                    Your deposit :
                                </span>
                                <span
                                    style={{
                                        marginLeft: 20,
                                        fontSize: 30,
                                    }}
                                >
                                    {myDeposit} ETH
                                </span>
                            </h4>
                        </div>
                        <form
                            id="form-create-item"
                            className="form-border"
                            action="#"
                        >
                            <div className="field-set">
                                <CustomInput
                                    label="Amount (ETH)"
                                    placeholder="enter the amount you want to deposit"
                                    value={amount}
                                    type="number"
                                    handleChangeValue={handleChangeAmount}
                                />

                                {/* <CustomInput
                                    label={formatMessage({
                                        id: "myProfile.settings.bio",
                                    })}
                                    value={me?.bio}
                                    handleChangeValue={handleChangeBio}
                                    placeholder={formatMessage({
                                        id: "myProfile.settings.bio.placeholder",
                                    })}
                                    muiltiline
                                />
                                <CustomInput
                                    label={formatMessage({
                                        id: "myProfile.settings.email",
                                    })}
                                    placeholder={formatMessage({
                                        id: "myProfile.settings.email.placeholder",
                                    })}
                                    value={me?.email}
                                    handleChangeValue={handleChangeEmail}
                                    error={emailError}
                                    errorText={formatMessage({
                                        id: "myProfile.settings.email.format.error",
                                    })}
                                /> */}
                            </div>
                        </form>
                    </div>
                </div>
                <div className="update-profile-save">
                    <CustomButton
                        text="Deposit your amount"
                        onClick={onDeposit}
                        disabled={!amount}
                    />
                </div>
            </section>

            <Footer />
        </div>
    );
};

export default Deposit;
