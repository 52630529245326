import React from "react";
import CreatorsAndCollaborators from "./creatorsAndCollaborators/CreatorsAndCollaborators";
import Featured from "./featured/Featured";
import TrustedPartners from "./partners/TrustedPartners";

const AdditionalInfo = () => {
    return (
        <section style={{ paddingTop: 0 }}>
            <TrustedPartners />
            <CreatorsAndCollaborators />
            <Featured />
        </section>
    );
};
export default AdditionalInfo;
