import { gql } from "@apollo/client";

export const GET_DEPOSIT = gql`
    query Deposit($id: String!) {
        deposit(id: $id) {
            id
            beneficiary {
                address
            }
            amount
        }
    }
`;
