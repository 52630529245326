import { ApolloClient, InMemoryCache } from "@apollo/client";

const createGraphNodeApolloClient = () => {
    const cache = new InMemoryCache();
    return new ApolloClient({
        uri: process.env.REACT_APP_GRAPHNODE_HTTP_URL,
        cache,
        queryDeduplication: false,
        defaultOptions: {
            watchQuery: {
                fetchPolicy: "cache-and-network",
            },
        },
    });
};

export default createGraphNodeApolloClient;
