import React, { useContext, useState } from "react";
import config from "../../../config/config";
import Footer from "../../components/footer/Footer";
import CustomInput from "../../components/input/CustomInput";
import { NewsletterContext } from "../../providers/NewsLetter/NewsLetterProvider";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";
import { isValidEmail } from "../../utils/string/isValidEmail";

const Newsletter = () => {
    const { listUuid } = useContext(NewsletterContext);
    const { isDark } = useContext(ThemeContext);

    const [email, setEmail] = useState("");
    const [name, setName] = useState("");

    const handleChangeEmail = (value) => {
        setEmail(value?.trim() || "");
    };

    const handleChangeName = (value) => {
        setName(value);
    };

    const invalidEmailFormat = !isValidEmail(email);

    return (
        <div className={isDark ? "greyscheme" : ""}>
            <section
                className="jumbotron breadcumb no-bg"
                style={{
                    backgroundImage: isDark
                        ? `url(${"./img/background/8.jpg"})`
                        : `url(${"./img/background/2.jpg"})`,
                }}
            >
                <div className="mainbreadcumb">
                    <div className="container">
                        <div className="row align-items-center">
                            <div
                                className="col-lg-5 text-light wow fadeInRight"
                                data-wow-delay=".5s"
                            >
                                <div className="spacer-10"></div>
                                <h1>
                                    Sign up for Listmonk, Ideal’s newsletter!
                                </h1>
                                <p className="lead">
                                    Sign up to receive our monthly newsletter,
                                    featuring updates from the team, new
                                    decentralized applications and NFT projects,
                                    and trends we’re seeing in the space.
                                </p>
                            </div>
                            <div
                                className="col-lg-4 offset-lg-2 wow fadeIn"
                                data-wow-delay=".5s"
                            >
                                <div className="box-login">
                                    <h3 className="mb10">Subscription</h3>
                                    <p>
                                        {
                                            "Enter your email and your name (optional) to subscribe to our newsletter."
                                        }
                                    </p>
                                    <form
                                        name="contactForm"
                                        id="contact_form"
                                        className="form-border"
                                        action={config.newsletter.endpoint}
                                        method="post"
                                    >
                                        <div className="field-set">
                                            <CustomInput
                                                label="Email address"
                                                placeholder="Enter your email"
                                                value={email}
                                                handleChangeValue={
                                                    handleChangeEmail
                                                }
                                                error={
                                                    !email || invalidEmailFormat
                                                }
                                                errorText={
                                                    !email
                                                        ? "Email is required"
                                                        : "Invalid email format"
                                                }
                                                type="email"
                                                name="email"
                                                required
                                            />
                                        </div>

                                        <div className="field-set">
                                            <CustomInput
                                                label="Name (optional)"
                                                placeholder="Enter your name"
                                                value={name}
                                                handleChangeValue={
                                                    handleChangeName
                                                }
                                                type="text"
                                                name="name"
                                            />
                                        </div>
                                        <div className="field-set">
                                            <input
                                                type="hidden"
                                                name="l"
                                                value={listUuid}
                                            />
                                        </div>

                                        <div className="field-set">
                                            <input
                                                type="submit"
                                                value="SUBSCRIBE"
                                                className="btn-main"
                                                disabled={
                                                    !email ||
                                                    invalidEmailFormat ||
                                                    !listUuid
                                                }
                                            />
                                        </div>

                                        <div className="clearfix"></div>

                                        <div className="spacer-single"></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
};
export default Newsletter;
