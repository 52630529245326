export const WHATS_UP_MENU = {
    originsClub: "Origins Club",
    all: "All",
    recent: "Recent",
    upcoming: "Upcoming",
    whitelisting: "Whitelisting",
};

export const WHATS_UP_MENUS = [
    WHATS_UP_MENU.originsClub,
    WHATS_UP_MENU.all,
    WHATS_UP_MENU.recent,
];
