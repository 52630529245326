import { useQuery } from "@apollo/client";
import { useNavigate } from "@reach/router";
import { ethers } from "ethers";
import { useContext } from "react";
import { GET_USER } from "../../graphql/user/query";
import { AuthContext } from "../../providers/Auth/AuthProvider";
import { formatNumber } from "../../utils/string/formatNumber";
import CustomAddress from "../address/CustomAddress";
import CustomButton from "../button/CustomButton";
import ROUTES from "../constants/routes";
import NFTAuthorPicture from "../nftCard/authorPicture/NFTAuthorPicture";

const BalanceItem = ({ balance, totalShares, cid, proposeAnOffer }) => {
    const { authContext, isUserConnected } = useContext(AuthContext);
    const { myProfile } = authContext;
    const navigate = useNavigate();

    const { data, loading } = useQuery(GET_USER, {
        variables: { id: balance?.owner?.address },
    });

    const user = data?.getUser;

    const nAmount = Number(ethers.utils.formatEther(balance?.amount));
    const nPercentage = (nAmount * 100) / totalShares;

    const handleProposeOfferToAnHolder = () => {
        if (proposeAnOffer) {
            proposeAnOffer(balance?.owner?.address);
        } else {
            navigate(`${ROUTES.nftOffer}/${cid}/${balance?.owner?.address}`);
        }
    };

    return (
        <div className="p_list" style={{ display: "flex" }}>
            <NFTAuthorPicture
                address={user?.id}
                src={user?.profilePictureUri}
                noAnimation
            />
            <div className="p_list_info">
                <span>
                    {myProfile?.id?.toLowerCase() ===
                    user?.id?.toLowerCase() ? (
                        <b>You</b>
                    ) : (
                        <b>{user?.userName}</b>
                    )}
                </span>
                <div>
                    <CustomAddress address={user?.id} />
                </div>

                <span>
                    Amount : <b>{formatNumber(nAmount)} shares</b>
                </span>

                <span>
                    Percentage : <b>{nPercentage.toFixed(2)}</b> %
                </span>
            </div>
            {isUserConnected && (
                <div>
                    {authContext?.myProfile?.id?.toLowerCase() !==
                        balance?.owner?.address?.toLowerCase() && (
                        <CustomButton
                            text="Propose an offer"
                            onClick={handleProposeOfferToAnHolder}
                            disabled={loading}
                            isWhite
                            style={{
                                marginLeft: 25,
                            }}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default BalanceItem;
