import React from "react";
import CustomButton from "../button/CustomButton";

const CustomGGLandDialog = ({
    title,
    children,
    handleClose,
    handleSubmit,
    submitText,
    noSubmit,
    handleCancel,
    cancelText,
    noCancel,
    checkoutStyle,
    mainCheckoutStyle,
}) => {
    return (
        <div
            className="checkout"
            style={{
                overflow: "auto",
                msOverflowStyle: "none",
                ...checkoutStyle,
            }}
        >
            <div className="maincheckout" style={mainCheckoutStyle}>
                <button className="btn-close" onClick={handleClose}>
                    x
                </button>
                <div className="heading" style={{ lineBreak: "anywhere" }}>
                    <h3>{title}</h3>
                </div>
                {children}
                {!noCancel && handleCancel && (
                    <CustomButton
                        text={cancelText || "Cancel"}
                        isWhite
                        onClick={handleCancel}
                    />
                )}
                {!noSubmit && handleSubmit && (
                    <CustomButton
                        text={submitText || "Save"}
                        onClick={handleSubmit}
                        style={{ marginTop: 10 }}
                    />
                )}
            </div>
        </div>
    );
};

export default CustomGGLandDialog;
