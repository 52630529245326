import React from "react";
import ScrollToTopBtn from "./menu/ScrollToTop";
import Header from "./menu/Header";
import { createGlobalStyle } from "styled-components";
import Routes from "./routes/Routes";
import SnackBarProvider from "./providers/SnackBar/SnackBarProvider";
import GlobalSnackbar from "./components/globalSnackBar/GlobalSnackbar";
import TranslationProvider from "./providers/Translation/TranslationProvider";
import NetTesterProvider from "./providers/NetTester/NetTesterProvider";
import ApolloAppProvider from "./providers/Apollo/ApolloProvider";
import AuthProvider from "./providers/Auth/AuthProvider";
import ThemeProvider from "./providers/Theme/ThemeProvider";
import { createHistory, LocationProvider } from "@reach/router";
import CategoriesProvider from "./providers/Categories/CategoriesProvider";
import TickerBanner from "./components/ticker/TickerBanner";
import BloggerAuthProvider from "./providers/BloggerAuth/BloggerAuthProvider";
import NewsletterProvider from "./providers/NewsLetter/NewsLetterProvider";

const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
`;

const app = () => {
    let history = createHistory(window);

    return (
        <div className="wraper">
            <LocationProvider history={history}>
                <ThemeProvider>
                    <SnackBarProvider>
                        <TranslationProvider>
                            <ApolloAppProvider>
                                <BloggerAuthProvider>
                                    <AuthProvider>
                                        <GlobalSnackbar />
                                        <GlobalStyles />
                                        <NetTesterProvider>
                                            <NewsletterProvider>
                                                <CategoriesProvider>
                                                    <Header />
                                                    <TickerBanner />
                                                    <Routes />
                                                    <ScrollToTopBtn />
                                                </CategoriesProvider>
                                            </NewsletterProvider>
                                        </NetTesterProvider>
                                    </AuthProvider>
                                </BloggerAuthProvider>
                            </ApolloAppProvider>
                        </TranslationProvider>
                    </SnackBarProvider>
                </ThemeProvider>
            </LocationProvider>
        </div>
    );
};
export default app;
