import { useQuery } from "@apollo/client";
import { ethers } from "ethers";
import moment from "moment";
import { useContext } from "react";
import { GET_USER } from "../../graphql/user/query";
import { AuthContext } from "../../providers/Auth/AuthProvider";
import { formatNumber } from "../../utils/string/formatNumber";
import CustomAddress from "../address/CustomAddress";
import NFTAuthorPicture from "../nftCard/authorPicture/NFTAuthorPicture";

const NFTActivityItem = ({ activity }) => {
    const { authContext } = useContext(AuthContext);

    const isPayment = activity?.activity === "PAYMENT";
    const paymentBuyerAddress = activity?.buyer?.address?.toLowerCase() || "";
    const paymentOwnerAddress = activity?.owner?.address?.toLowerCase() || "";

    const isFromAddressExist =
        activity?.from?.address?.toLowerCase() !==
        "0x0000000000000000000000000000000000000000";
    const transferFromAddress = activity?.from?.address?.toLowerCase() || "";
    const transferToAddress = activity?.to?.address?.toLowerCase() || "";

    const { data: buyerUserData } = useQuery(GET_USER, {
        fetchPolicy: "cache-first",
        variables: { id: paymentBuyerAddress },
        skip: !isPayment || !paymentBuyerAddress,
    });

    const buyerUser = buyerUserData?.getUser;

    const { data: sellerUserData } = useQuery(GET_USER, {
        fetchPolicy: "cache-first",
        variables: { id: paymentOwnerAddress },
        skip: !isPayment || !paymentOwnerAddress,
    });

    const sellerUser = sellerUserData?.getUser;

    const { data: fromUserData } = useQuery(GET_USER, {
        fetchPolicy: "cache-first",
        variables: { id: transferFromAddress },
        skip: isPayment || !transferFromAddress || !isFromAddressExist,
    });

    const fromUser = fromUserData?.getUser;

    const { data: toUserData } = useQuery(GET_USER, {
        fetchPolicy: "cache-first",
        variables: { id: transferToAddress },
        skip: isPayment || !transferToAddress,
    });

    const toUser = toUserData?.getUser;

    const paymentAmount = activity?.value
        ? Number(ethers.utils.formatEther(activity?.value))
        : "";

    const paymentShares = activity?.shares
        ? Number(ethers.utils.formatEther(activity?.shares))
        : "";

    const transferAmount = activity?.amount
        ? Number(ethers.utils.formatEther(activity?.amount))
        : "";

    const date = moment(new Date(activity?.timestamp * 1000)).format(
        "DD/MM/YYYY, hh:mm A"
    );

    if (isPayment)
        return (
            <div className="p_list" style={{ display: "flex" }}>
                <NFTAuthorPicture
                    address={sellerUser?.id}
                    src={sellerUser?.profilePictureUri}
                    noAnimation
                />
                <div className="p_list_info">
                    Sale of <b>{paymentAmount} ETH</b>
                    <span>
                        <b>{formatNumber(paymentShares)}</b> shares
                    </span>
                    <span>
                        From{" "}
                        <b>
                            {authContext?.myProfile?.id === sellerUser?.id
                                ? "You"
                                : sellerUser?.userName}
                        </b>{" "}
                        <CustomAddress address={sellerUser?.id} />
                    </span>
                    <span>
                        to{" "}
                        <b>
                            {authContext?.myProfile?.id === buyerUser?.id
                                ? "You"
                                : buyerUser?.userName}
                            <CustomAddress address={buyerUser?.id} />
                        </b>
                    </span>
                    <span>at {date}</span>
                </div>
            </div>
        );

    if (!isFromAddressExist)
        return (
            <div className="p_list" style={{ display: "flex" }}>
                <NFTAuthorPicture
                    address={toUser?.id}
                    src={toUser?.profilePictureUri}
                    noAnimation
                />
                <div className="p_list_info">
                    Mint of
                    <span>
                        <b>{formatNumber(transferAmount)}</b> shares
                    </span>
                    <span>
                        By{" "}
                        <b>
                            {authContext?.myProfile?.id === toUser?.id
                                ? "You"
                                : toUser?.userName}
                        </b>{" "}
                        <CustomAddress address={toUser?.id} />
                    </span>
                    <span>at {date}</span>
                </div>
            </div>
        );

    return (
        <div className="p_list" style={{ display: "flex" }}>
            <NFTAuthorPicture
                address={fromUser?.id}
                src={fromUser?.profilePictureUri}
                noAnimation
            />
            <div className="p_list_info">
                Transfer of <b>{transferAmount} ETH</b>
                <span>
                    From{" "}
                    <b>
                        {authContext?.myProfile?.id === fromUser?.id
                            ? "You"
                            : fromUser?.userName}
                    </b>{" "}
                    <CustomAddress address={fromUser?.id} />
                </span>
                <span>
                    to{" "}
                    <b>
                        {authContext?.myProfile?.id === toUser?.id
                            ? "You"
                            : toUser?.userName}
                        <CustomAddress address={toUser?.id} />
                    </b>
                </span>
                <span>at {date}</span>
            </div>
        </div>
    );
};

export default NFTActivityItem;
