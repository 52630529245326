import { useQuery } from "@apollo/client";
import React, { useContext } from "react";
import CustomLoader from "../../../components/customLoader/CustomLoader";
import DenyCardList from "../../../components/denyCard/DenyCardList";
import { MY_DENIES } from "../../../graphql/deny/query";
import { ThemeContext } from "../../../providers/Theme/ThemeProvider";

const MyDenies = () => {
    const { isDark } = useContext(ThemeContext);

    const { data, loading } = useQuery(MY_DENIES, {
        fetchPolicy: "cache-and-network",
    });

    const myDenies = data ? data.myDenies : [];

    return (
        <div className={isDark ? "greyscheme" : ""}>
            {loading && <CustomLoader />}
            {myDenies.length ? (
                <DenyCardList denies={myDenies} />
            ) : (
                <div>No deny.</div>
            )}
        </div>
    );
};
export default MyDenies;
