import React, { memo, useState } from "react";
import DenyCard from "./DenyCard";

const DenyCardList = ({ denies, loadMore, showLoadMore }) => {
    const [height, setHeight] = useState(0);

    const onImgLoad = ({ target: img }) => {
        let currentHeight = height;
        if (currentHeight < img.offsetHeight) {
            setHeight(img.offsetHeight);
        }
    };

    return (
        <div className="row">
            {denies &&
                denies.map((deny, index) => (
                    <DenyCard
                        deny={deny}
                        key={index}
                        onImgLoad={onImgLoad}
                        height={height}
                    />
                ))}
            {showLoadMore && denies.length && (
                <div className="col-lg-12">
                    <div className="spacer-single"></div>
                    <span onClick={loadMore} className="btn-main lead m-auto">
                        Load More
                    </span>
                </div>
            )}
        </div>
    );
};

export default memo(DenyCardList);
