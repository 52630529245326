import React, { useContext } from "react";
import Reveal from "react-awesome-reveal";
import { useIntl } from "react-intl";
import { ThemeContext } from "../../../providers/Theme/ThemeProvider";
import FeatureBoxImage from "./image/FeatureBoxImage";
import CustomButton from "../../../components/button/CustomButton";
import IdealSlogan from "../../../components/slogan/IdealSlogan";
import { useNavigate } from "@reach/router";
import ROUTES from "../../../components/constants/routes";
import IDXLogo from "../../../components/logo/IDXLogo";
import AppLogoOnly from "../../../components/logo/AppLogoOnly";
import { fadeInUp } from "../../../constants/animations";
import SectionSubtitle from "../../../components/section/SectionSubtitle";

const FeatureboxV2 = () => {
    const { formatMessage } = useIntl();
    const { isDark } = useContext(ThemeContext);
    const navigate = useNavigate();

    const navigateToExplore = () => {
        navigate(ROUTES.explore);
    };

    return (
        <section
            className={
                isDark
                    ? "how_it_works_container dark"
                    : "how_it_works_container"
            }
        >
            <div className="row">
                <div className="section_title">
                    <div className="text-center">
                        {/* <h2>
                            {formatMessage({ id: "home.featurebox.title1" })}
                        </h2> */}

                        <IDXLogo isGrey={!isDark} beforeText="Ideal Exchange" />
                        <h6>
                            {formatMessage({ id: "home.featurebox.title2" })}
                        </h6>
                        <div className="section_sub_title">
                            <p>
                                {formatMessage({ id: "home.featurebox.desc" })}
                            </p>
                        </div>
                    </div>
                    <AppLogoOnly />
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-3">
                        <div className="feature-box f-boxed style-3">
                            <Reveal
                                className="onStep"
                                keyframes={fadeInUp}
                                delay={0}
                                duration={600}
                                triggerOnce
                            >
                                <FeatureBoxImage featureBoxKey={1} />
                            </Reveal>
                            <div className="text">
                                <Reveal
                                    className="onStep"
                                    keyframes={fadeInUp}
                                    delay={100}
                                    duration={600}
                                    triggerOnce
                                >
                                    <h4 className="">
                                        {formatMessage({
                                            id: "home.featurebox3.title",
                                        })}
                                    </h4>
                                </Reveal>
                                <Reveal
                                    className="onStep"
                                    keyframes={fadeInUp}
                                    delay={200}
                                    duration={600}
                                    triggerOnce
                                >
                                    <p className="">
                                        {formatMessage({
                                            id: "home.featurebox3.desc",
                                        })}
                                    </p>
                                </Reveal>
                            </div>
                            <i className="wm icon_tags_alt"></i>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 mb-3">
                        <div className="feature-box f-boxed style-3">
                            <Reveal
                                className="onStep"
                                keyframes={fadeInUp}
                                delay={0}
                                duration={600}
                                triggerOnce
                            >
                                <FeatureBoxImage featureBoxKey={2} />
                            </Reveal>
                            <div className="text">
                                <Reveal
                                    className="onStep"
                                    keyframes={fadeInUp}
                                    delay={100}
                                    duration={600}
                                    triggerOnce
                                >
                                    <h4 className="">
                                        {formatMessage({
                                            id: "home.featurebox1.title",
                                        })}
                                    </h4>
                                </Reveal>
                                <Reveal
                                    className="onStep"
                                    keyframes={fadeInUp}
                                    delay={200}
                                    duration={600}
                                    triggerOnce
                                >
                                    <p className="">
                                        {formatMessage({
                                            id: "home.featurebox1.desc1",
                                        })}
                                    </p>
                                </Reveal>
                            </div>
                            <i className="wm icon_wallet"></i>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 mb-3">
                        <div className="feature-box f-boxed style-3">
                            <Reveal
                                className="onStep"
                                keyframes={fadeInUp}
                                delay={0}
                                duration={600}
                                triggerOnce
                            >
                                <FeatureBoxImage />
                            </Reveal>
                            <div className="text">
                                <Reveal
                                    className="onStep"
                                    keyframes={fadeInUp}
                                    delay={100}
                                    duration={600}
                                    triggerOnce
                                >
                                    <h4 className="">
                                        {formatMessage({
                                            id: "home.featurebox2.title",
                                        })}
                                    </h4>
                                </Reveal>
                                <Reveal
                                    className="onStep"
                                    keyframes={fadeInUp}
                                    delay={200}
                                    duration={600}
                                    triggerOnce
                                >
                                    <p className="">
                                        {formatMessage({
                                            id: "home.featurebox2.desc",
                                        })}
                                    </p>
                                </Reveal>
                            </div>
                            <i className="wm icon_cloud-upload_alt"></i>
                        </div>
                    </div>
                </div>
            </div>
            <CustomButton
                text={formatMessage({
                    id: "Get Started Now",
                })}
                style={{ marginTop: 70, marginBottom: 70 }}
                onClick={navigateToExplore}
                isBlack={!isDark}
            />
            <IdealSlogan />
        </section>
    );
};
export default FeatureboxV2;
