import { useLocation } from "@reach/router";
import React, { useContext } from "react";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";
import ROUTES from "../constants/routes";
import NavLink from "../link/NavLink";

const AppLogo = ({ isDark: isDarkProps }) => {
    const { isDark: isDarkContext } = useContext(ThemeContext);
    const location = useLocation();

    const isNFTDetail = location.pathname.includes(ROUTES.nftDetail);

    const isDark = isDarkProps || isDarkContext;

    if (isNFTDetail && !isDarkContext)
        return (
            <NavLink to="/">
                <img
                    key="/img/logos/Ideal-Brand-Full-Logo.png"
                    src="/img/logos/Ideal-Brand-Full-Logo.png"
                    className="logo-brand-full"
                    alt="#"
                />
            </NavLink>
        );

    return (
        <NavLink to="/">
            {/* <img alt="" className="f-logo d-1" src="./img/logo.png" />
                <img alt="" className="f-logo d-3" src="./img/logo-2-light.png" />
                <img alt="" className="f-logo d-4" src="./img/logo-3.png" /> */}
            <img
                key={
                    isDark
                        ? "/img/v2/logos/ideal-logo-dark-horizontal.png"
                        : "/img/v2/logos/Ideal_BLK@3x.png"
                }
                src={
                    isDark
                        ? "/img/v2/logos/ideal-logo-dark-horizontal.png"
                        : "/img/v2/logos/Ideal_BLK@3x.png"
                }
                className="logo-brand-full"
                alt="#"
            />
        </NavLink>
    );
};
export default AppLogo;
