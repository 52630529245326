import React from "react";
import { useContext } from "react";
import Footer from "../../components/footer/Footer";
import HeaderWithImage from "../../components/header/HeaderWithImage";
import WalletList from "../../components/walletList/WalletList";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";

const Wallets = () => {
    const { isDark } = useContext(ThemeContext);

    return (
        <div className={isDark ? "greyscheme" : ""}>
            <HeaderWithImage title={"Connect with your Wallet"} />

            <section className="container">
                <WalletList />
            </section>

            <Footer />
        </div>
    );
};
export default Wallets;
