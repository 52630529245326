import { gql } from "@apollo/client";
import { NFT_FRAGMENT } from "./fragment";

export const UPLOAD = gql`
    mutation UploadSingle($input: UploadInput!) {
        uploadSingle(input: $input) {
            ...NFTFields
        }
    }
    ${NFT_FRAGMENT}
`;

export const UPDATE_NFT = gql`
    mutation UpdateIp($input: UpdateIpInput!) {
        updateIp(input: $input) {
            ...NFTFields
        }
    }
    ${NFT_FRAGMENT}
`;

export const LIKE_OR_DISLIKE_NFT = gql`
    mutation LikeOrDislikeIp($cid: String!) {
        likeOrDislikeIp(cid: $cid) {
            liked
            like {
                user {
                    id
                }
                IP {
                    cid
                }
                createdAt
            }
        }
    }
`;

export const SET_FEATURED_NFT = gql`
    mutation SetFeatured($input: SetFeaturedInput!) {
        setFeatured(input: $input)
    }
`;
