import { useMutation } from "@apollo/client";
import { Box } from "@material-ui/core";
import { Link, useNavigate } from "@reach/router";
import moment from "moment";
import { useContext } from "react";
import ROUTES from "../../../components/constants/routes";
import COLORS from "../../../constants/colors";
import { READ_NOTIFICATION } from "../../../graphql/notification/mutation";
import { MY_NOTIFICATIONS } from "../../../graphql/notification/query";
import useGetMinimalNFT from "../../../hooks/useGetNFT/useGetMinimalNFT";
import useGetUser from "../../../hooks/useGetUser/useGetUser";
import { SnackBarContext } from "../../../providers/SnackBar/SnackBarProvider";
import { ThemeContext } from "../../../providers/Theme/ThemeProvider";
import { getNotificationText } from "../../../utils/notification/getNotificationText";

const NotificationItem = ({ notification }) => {
    const { isDark } = useContext(ThemeContext);
    const { displaySnackBar } = useContext(SnackBarContext);
    const navigate = useNavigate();

    const byUser = useGetUser(notification?.variables?.by);
    const nft = useGetMinimalNFT(notification?.variables?.ip);
    const isApprouved = notification?.code === "notification.PERMIT_APPROVED";

    const unread = !notification?.read;

    const [readNotification] = useMutation(READ_NOTIFICATION, {
        onCompleted: () => {
            navigate(`${ROUTES.nftDetail}/${notification?.variables?.ip}`);
        },
        onError: (err) => {
            displaySnackBar({
                message: err?.message || "Error when reading notification",
                type: "error",
            });
        },
        refetchQueries: [
            {
                query: MY_NOTIFICATIONS,
                variables: {
                    take: 5,
                    skip: 0,
                },
            },
        ],
    });

    const onClickNotification = () => {
        if (unread)
            readNotification({ variables: { id: Number(notification?.id) } });
    };

    return (
        <li
            className={isApprouved ? "act_notif_permit" : "act_notif_deny"}
            style={{
                cursor: "pointer",
                borderWidth: unread && 1,
                borderStyle: unread && "solid",
                borderColor: !unread
                    ? undefined
                    : isDark
                    ? COLORS.dark.color_2
                    : COLORS.light.color_2,
            }}
            onClick={onClickNotification}
        >
            <img className="lazy" src={byUser?.profilePictureUri} alt="" />
            <div className="act_list_text">
                <Box>
                    <b>{byUser?.userName || byUser?.id}</b>
                    {getNotificationText(
                        notification?.code,
                        byUser?.userName || byUser?.id,
                        nft?.title
                    )}
                </Box>
                <h4>
                    <Link
                        to={`${ROUTES.nftDetail}/${notification?.variables?.ip}`}
                        className="name_link"
                    >
                        {nft?.title}
                    </Link>
                </h4>
                <Box
                    style={{
                        color: COLORS.light.gray,
                    }}
                >
                    {moment(notification?.createdAt).fromNow()}
                </Box>
            </div>
        </li>
    );
};

export default NotificationItem;
