export const formatNumber = (value) => {
    const parts = value.toString().split(".");

    const firstPart = parts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    // ex: 5 382,3 => 5 382,30
    const secondPart =
        parts.length > 1 && parts[1] && parts[1].length === 1
            ? `${parts[1]}0`
            : parts[1];

    return firstPart + (parts.length > 1 ? "," + secondPart : "");
};
