import React from "react";
import Reveal from "react-awesome-reveal";
import CustomButton from "../../../components/button/CustomButton";
import { useNavigate } from "@reach/router";
import ROUTES from "../../../components/constants/routes";
import { useIntl } from "react-intl";
import { fadeInUp, inline } from "../../../constants/animations";

const SliderOriginsClub = () => {
    const navigate = useNavigate();
    const { formatMessage } = useIntl();

    const navigateToExplore = () => {
        navigate(ROUTES.explore);
    };

    return (
        <div
            className="container"
            style={{ paddingTop: 100, paddingBottom: 100 }}
        >
            <div className="row align-items-center">
                <div className="col-md-6" style={{ maxWidth: 400 }}>
                    <Reveal
                        className="onStep"
                        keyframes={fadeInUp}
                        delay={300}
                        duration={900}
                        triggerOnce
                    >
                        <h1
                            className="col-white"
                            style={{
                                maxWidth: 300,
                                textTransform: "uppercase",
                            }}
                        >
                            {formatMessage({
                                id: "originsClub.big.title1",
                            })}
                        </h1>
                    </Reveal>
                    <Reveal
                        className="onStep"
                        keyframes={fadeInUp}
                        delay={600}
                        duration={900}
                        triggerOnce
                    >
                        <p className="lead col-white">
                            {formatMessage({
                                id: "originsClub.subtitle",
                            })}
                        </p>
                    </Reveal>
                    <div className="spacer-10"></div>
                    <Reveal
                        className="onStep d-inline"
                        keyframes={inline}
                        delay={800}
                        duration={900}
                        triggerOnce
                    >
                        <CustomButton
                            text={formatMessage({
                                id: "originsClub.btn.main",
                            })}
                            onClick={navigateToExplore}
                            isGreen
                        />

                        <div className="mb-sm-30"></div>
                    </Reveal>
                </div>
                <div className="col-md-6 xs-hide">
                    <Reveal
                        className="onStep d-inline"
                        keyframes={inline}
                        delay={300}
                        duration={1200}
                        triggerOnce
                    >
                        {/* <TrustedPartnersLogos /> */}
                    </Reveal>
                </div>
            </div>
        </div>
    );
};
export default SliderOriginsClub;
