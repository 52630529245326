import { gql } from "@apollo/client";

export const PERMIT_FRAGMENT = gql`
    fragment PermitFields on SignedPermit {
        id
        tokenId
        blockHeight
        deadline
        validUntil
        isExpired
        salt
        signature
        createdAt
    }
`;
