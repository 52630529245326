import { Avatar, createStyles, makeStyles, Tooltip } from "@material-ui/core";
import { useContext } from "react";
import Files from "react-files";
import { useIntl } from "react-intl";
import COLORS from "../../constants/colors";
import { DEFAULT_AVATAR, PICTURE_MAX_SIZE } from "../../constants/file";
import { SnackBarContext } from "../../providers/SnackBar/SnackBarProvider";

const useStyles = makeStyles((theme) =>
    createStyles({
        filePicker: {
            cursor: "pointer",
            margin: 10,
        },
        filePickerDisabled: {},
        avatar: {
            height: 150,
            width: 150,
            "&:hover": {
                WebkitBoxShadow: `0px 0px 0px 2px ${COLORS.light.color}`,
                transition: "0.3s",
                borderRadius: 100,
            },
        },
        avatarSmall: {
            height: 50,
            width: 50,
            "&:hover": {
                padding: 3,
            },
        },
    })
);

// interface Props {
//     src?: string | null;
//     isSmall?: boolean;
//     isEditable?: boolean;
//     handleEdit?(src: string): void;
// }

const ProfilePicture = (props) => {
    const classes = useStyles();
    const { displaySnackBar } = useContext(SnackBarContext);
    const { formatMessage } = useIntl();
    const { src: srcProps, isSmall, isEditable, handleEdit } = props;

    const src = srcProps || DEFAULT_AVATAR;

    const onFilesChange = (files) => {
        if (!isEditable) return;
        getBase64(files[0], (result) => {
            if (handleEdit) handleEdit(result);
        });
    };

    const unknownFileError = () => {
        displaySnackBar({
            message: formatMessage({
                id: "myProfile.settings.uploading.picture.unknown.error",
            }),
            type: "error",
        });
    };

    const onFilesError = (error, file) => {
        console.log("error code " + error.code + ": " + error.message);
        if (error.code === 2) {
            displaySnackBar({
                message: `${formatMessage({
                    id: "myProfile.settings.uploading.picture.size.error",
                })} ${PICTURE_MAX_SIZE}Kb.`,
                type: "error",
            });
        }
    };

    const getBase64 = (file, callback) => {
        try {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                callback(reader.result);
            };
            reader.onerror = (error) => {
                unknownFileError();
                console.log("Error: ", error);
            };
        } catch (err) {
            onFilesError(err, null);
            console.log(`Error : `, err);
        }
    };

    if (isSmall) {
        return (
            <Avatar src={src || undefined} className={classes.avatarSmall} />
        );
    }

    return (
        <Files
            className={
                isEditable ? classes.filePicker : classes.filePickerDisabled
            }
            onChange={onFilesChange}
            onError={onFilesError}
            accepts={["image/*"]}
            multiple={false}
            maxFiles={100}
            maxFileSize={PICTURE_MAX_SIZE * 1000}
            minFileSize={0}
            clickable={!!isEditable}
        >
            <Tooltip
                title={
                    isEditable
                        ? formatMessage({
                              id: "myProfile.settings.profile.picture.edit.tooltip",
                          })
                        : formatMessage({
                              id: "myProfile.settings.profile.picture.tooltip",
                          })
                }
            >
                <Avatar src={src || undefined} className={classes.avatar} />
            </Tooltip>
        </Files>
    );
};

export default ProfilePicture;
