import React, { memo } from "react";
import { useQuery } from "@apollo/client";
import CustomLoader from "../../../components/customLoader/CustomLoader";
import NFTCardList from "../../../components/nftCard/NFTCardList";
import { GET_MY_CREATED_NFTS } from "../../../graphql/nft/query";

const MyCreatedNFTs = () => {
    const { data, loading } = useQuery(GET_MY_CREATED_NFTS);

    const createdNFTs = data ? data.myCreatedIps : [];

    return (
        <div>
            {loading && <CustomLoader />}
            {createdNFTs?.length ? (
                <NFTCardList nfts={createdNFTs} />
            ) : (
                <div>No item.</div>
            )}
        </div>
    );
};
export default memo(MyCreatedNFTs);
