import { Link } from "@reach/router";
import React, { useContext } from "react";
import ROUTES from "../../components/constants/routes";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";

const BloggerHome = () => {
    const { isDark } = useContext(ThemeContext);

    return (
        <div
            className={isDark ? "greyscheme" : ""}
            style={{ height: "calc(100vh)" }}
        >
            <section className="container">
                <div className="row">
                    <div
                        className="col-md-6 offset-md-3"
                        style={{ textAlign: "center" }}
                    >
                        <p>Manage your community information</p>
                        <Link to={ROUTES.blogs} className="opt-create">
                            <img src="./img/v2/logos/blog.png" alt="" />
                            <h3>Blogs</h3>
                        </Link>
                        <Link to={ROUTES.helpCenter} className="opt-create">
                            <img src="./img/v2/logos/help.png" alt="" />
                            <h3>Help Center</h3>
                        </Link>
                    </div>
                </div>
            </section>
        </div>
    );
};
export default BloggerHome;
