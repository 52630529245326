import React, { useContext } from "react";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";

const AppLogoOnly = ({ style }) => {
    const { isDark } = useContext(ThemeContext);

    return (
        <img
            key={
                isDark
                    ? "/img/v2/logos/ideal-logo-green-only.png"
                    : "/img/v2/logos/ideal-logo-grey-only.png"
            }
            src={
                isDark
                    ? "/img/v2/logos/ideal-logo-green-only.png"
                    : "/img/v2/logos/ideal-logo-grey-only.png"
            }
            className="logo-brand-full"
            style={style}
            alt="#"
        />
    );
};
export default AppLogoOnly;
