import {
    Avatar,
    Box,
    Button,
    createStyles,
    makeStyles,
} from "@material-ui/core";
import { useContext } from "react";
import { Link } from "@reach/router";
import { ThemeContext } from "../../../providers/Theme/ThemeProvider";
import COLORS from "../../../constants/colors";
import { getNotificationText } from "../../../utils/notification/getNotificationText";
import ROUTES from "../../../components/constants/routes";
import useGetUser from "../../../hooks/useGetUser/useGetUser";
import useGetMinimalNFT from "../../../hooks/useGetNFT/useGetMinimalNFT";
import moment from "moment";
import { useMutation } from "@apollo/client";
import { READ_NOTIFICATION } from "../../../graphql/notification/mutation";
import { MY_NOTIFICATIONS } from "../../../graphql/notification/query";
import { SnackBarContext } from "../../../providers/SnackBar/SnackBarProvider";

const RecentNotificationItem = ({ notification, popoverLeave }) => {
    const { isDark } = useContext(ThemeContext);
    const { displaySnackBar } = useContext(SnackBarContext);
    const unread = !notification?.read;

    const [readNotification] = useMutation(READ_NOTIFICATION, {
        onCompleted: () => {
            popoverLeave();
        },
        onError: (err) => {
            displaySnackBar({
                message: err?.message || "Error when reading notification",
                type: "error",
            });
            popoverLeave();
        },
        refetchQueries: [
            {
                query: MY_NOTIFICATIONS,
                variables: {
                    take: 5,
                    skip: 0,
                },
            },
        ],
    });

    const useStyles = makeStyles((theme) =>
        createStyles({
            container: {
                paddingTop: 5,
                paddingBottom: 5,
                textTransform: "none",
                color: COLORS.light.black_soft,
                backgroundColor: unread
                    ? COLORS.light.gray
                    : COLORS.light.white,
                justifyContent: "flex-start",
                fontSize: 14,
                borderRadius: 0,
                width: "100%",
                "&:hover": {
                    backgroundColor: COLORS.v2.green,
                    color: COLORS.v2.black_grey,
                },
            },
            textContainer: {
                marginLeft: 10,
            },
            separator: {
                height: 1,
                width: "100%",
                backgroundColor: COLORS.light.black_soft,
            },
            fromNowDate: {
                color: unread ? COLORS.light.black : COLORS.light.gray,
            },
        })
    );

    const classes = useStyles();

    const byUser = useGetUser(notification?.variables?.by);
    const nft = useGetMinimalNFT(notification?.variables?.ip);

    const onClickNotification = () => {
        if (unread)
            readNotification({ variables: { id: Number(notification?.id) } });
    };

    return (
        <Box>
            <Button
                className={classes.container}
                component={Link}
                onClick={onClickNotification}
                to={`${ROUTES.nftDetail}/${notification?.variables?.ip}`}
            >
                <Avatar src={byUser?.profilePictureUri} />
                <Box className={classes.textContainer}>
                    <Box>
                        <b>{byUser?.userName || byUser?.id}</b>
                        {getNotificationText(
                            notification?.code,
                            byUser?.userName || byUser?.id,
                            nft?.title
                        )}
                        <b>{nft?.title}</b>
                    </Box>
                    <Box className={classes.fromNowDate}>
                        {moment(notification?.createdAt).fromNow()}
                    </Box>
                </Box>
            </Button>
            <Box className={classes.separator} />
        </Box>
    );
};

export default RecentNotificationItem;
