import { useQuery } from "@apollo/client";
import { GET_NFT_LIKES } from "../../graphql/nft/query";
import CustomLoader from "../customLoader/CustomLoader";
import NFTLikeItem from "./item/NFTLikeItem";

const NFTLikesList = ({ cid }) => {
    const { data, loading } = useQuery(GET_NFT_LIKES, {
        variables: { id: cid },
    });

    const likes = data?.getIp?.likes || [];

    return (
        <div className="tab-2 onStep fadeIn">
            {loading && <CustomLoader />}
            {likes?.length ? (
                likes?.map((like) => <NFTLikeItem like={like} />)
            ) : (
                <div className="p_list">No like</div>
            )}
        </div>
    );
};

export default NFTLikesList;
