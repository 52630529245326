import React, { useContext } from "react";
import COLORS from "../../constants/colors";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";

const SectionSubtitle = ({ style, text, forceGreen, forceGrey }) => {
    const { isDark } = useContext(ThemeContext);

    return (
        <h6
            style={{
                color: forceGreen
                    ? COLORS.v2.green
                    : forceGrey
                    ? COLORS.v2.greyText
                    : isDark
                    ? COLORS.v2.green
                    : COLORS.v2.greyText,
                textTransform: "uppercase",
                letterSpacing: 1,
                ...style,
            }}
        >
            {text}
        </h6>
    );
};
export default SectionSubtitle;
