import { useQuery } from "@apollo/client";
import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../../config/config";
import LOCAL_STORAGE_KEYS from "../../constants/localStorage";
import { GET_MINIMAL_NFT } from "../../graphql/nft/query";

const useGetMinimalNFT = (cid) => {
    const [nftUrl, setNftUrl] = useState(undefined);

    const Authorization = `Bearer ${localStorage.getItem(
        LOCAL_STORAGE_KEYS.TOKEN
    )}`;

    const { data } = useQuery(GET_MINIMAL_NFT, {
        fetchPolicy: "cache-first",
        variables: { id: cid },
    });

    useEffect(() => {
        if (cid) {
            axios
                .get(`${config.nft.ipsUrl}/${cid}`, {
                    headers: {
                        Authorization,
                    },
                    responseType: "blob",
                })
                .then((res) => {
                    const blobURL = URL.createObjectURL(res.data);
                    setNftUrl(blobURL);
                });
        }
    }, [Authorization, cid]);

    return {
        url: nftUrl,
        ...data?.getIp,
    };
};

export default useGetMinimalNFT;
