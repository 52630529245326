import { gql } from "@apollo/client";

export const GET_HOME_STAT = gql`
    query GetHomeStat {
        getHomeStat {
            mintedNftsCount
            creatorsCount
        }
    }
`;
