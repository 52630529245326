import { useNavigate } from "@reach/router";
import moment from "moment";
import { memo } from "react";
import ROUTES from "../constants/routes";

const BlogItem = ({ blog }) => {
    const navigate = useNavigate();

    return (
        <div className="col-lg-4 col-md-6 mb30">
            <div className="bloglist item">
                <div className="post-content">
                    <div className="post-image">
                        <img alt="" src={blog?.picture} className="lazy" />
                    </div>
                    <div className="post-text">
                        {blog?.tags && blog?.tags[0] && (
                            <span className="p-tagline">{blog.tags[0]}</span>
                        )}
                        {blog?.date && (
                            <span className="p-date">
                                {moment(blog?.date).format("DD/MM/YYYY")}
                            </span>
                        )}
                        <h4>
                            <span>
                                {blog?.title}
                                <span></span>
                            </span>
                        </h4>
                        <p>
                            {blog?.description?.substring(0, 134)}
                            {blog?.description?.length > 134 && "..."}
                        </p>
                        <span
                            onClick={() =>
                                navigate(`${ROUTES.blogDetail}/${blog.id}`)
                            }
                            className="btn-main"
                        >
                            Read more
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default memo(BlogItem);
