import { Tooltip } from "@material-ui/core";
import { DEFAULT_AVATAR } from "../../../constants/file";

const NFTAuthorPicture = ({
    address,
    src,
    noAnimation,
    iconClassName,
    iconStyle,
    style,
}) => {
    if (!address)
        return (
            <div
                className={noAnimation ? "p_list_pp" : "author_list_pp"}
                style={style}
            >
                <span>
                    <img src={src || DEFAULT_AVATAR} alt="" />
                    {/* <i
                        className={iconClassName || "fa fa-check"}
                        style={iconStyle}
                    ></i> */}
                </span>
            </div>
        );

    return (
        <Tooltip title={address}>
            <div
                className={noAnimation ? "p_list_pp" : "author_list_pp"}
                style={style}
            >
                <span>
                    <img src={src || DEFAULT_AVATAR} alt="" />
                    {/* <i
                        className={iconClassName || "fa fa-check"}
                        style={iconStyle}
                    ></i> */}
                </span>
            </div>
        </Tooltip>
    );
};

export default NFTAuthorPicture;
