import { createStyles, makeStyles } from "@material-ui/core";
import Reveal from "react-awesome-reveal";
import AppLogoOnly from "../../../../components/logo/AppLogoOnly";
import SectionSubtitle from "../../../../components/section/SectionSubtitle";
import { fadeInUp } from "../../../../constants/animations";
import COLORS from "../../../../constants/colors";

const FeaturedIdeasItem = ({ item }) => {
    const { profilPictureUrl, title, pseudo, descriprion, idea, nft, members } =
        item;

    const useStyles = makeStyles((theme) =>
        createStyles({
            spaceBetweenContainer: {
                display: "flex",
                justifyContent: "space-between",
            },
            featureBox1Image: {
                maxHeight: 80,
                maxWidth: 80,
                borderRadius: 15,
                opacity: 1,
            },
            pseudo: {
                textTransform: "uppercase",
            },
            stats: {
                color: COLORS.v2.green,
            },
        })
    );

    const classes = useStyles();

    return (
        <div className="col-lg-4 col-md-6" style={{ marginBottom: 50 }}>
            <div className="feature-box f-boxed style-3 idea">
                <Reveal
                    className="onStep"
                    keyframes={fadeInUp}
                    delay={0}
                    duration={600}
                    triggerOnce
                >
                    <div className={classes.spaceBetweenContainer}>
                        <div className="image_border">
                            <img
                                key={profilPictureUrl}
                                src={profilPictureUrl}
                                className={classes.featureBox1Image}
                                alt="#"
                            />
                        </div>
                        <AppLogoOnly />
                    </div>
                </Reveal>
                <div className="text">
                    <Reveal
                        className="onStep"
                        keyframes={fadeInUp}
                        delay={100}
                        duration={600}
                        triggerOnce
                    >
                        <div className={classes.spaceBetweenContainer}>
                            <h4 style={{ letterSpacing: 1 }}>{title}</h4>
                            <div className={classes.pseudo}>{pseudo}</div>
                        </div>
                    </Reveal>
                    <Reveal
                        className="onStep"
                        keyframes={fadeInUp}
                        delay={200}
                        duration={600}
                        triggerOnce
                    >
                        <p>{descriprion}</p>

                        <div className={classes.spaceBetweenContainer}>
                            <SectionSubtitle
                                text="# IDEA"
                                style={{ letterSpacing: 1 }}
                                forceGrey
                            />
                            <SectionSubtitle
                                text="# NFT"
                                style={{ letterSpacing: 1 }}
                                forceGrey
                            />
                            <SectionSubtitle
                                text="# MEMBERS"
                                style={{ letterSpacing: 1 }}
                                forceGrey
                            />
                        </div>
                        <div className={classes.spaceBetweenContainer}>
                            <div className={classes.stats}>{idea}</div>
                            <div className={classes.stats}>{nft}</div>
                            <div className={classes.stats}>{members}</div>
                        </div>
                    </Reveal>
                </div>
                <i className="wm icon_tags_alt"></i>
            </div>
        </div>
    );
};

export default FeaturedIdeasItem;
