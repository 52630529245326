import React, { useContext } from "react";
import Footer from "../../components/footer/Footer";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";
import PopularItemsMarquee from "../home/PopularItems/PopularItemsMarquee";
import PromoMovie from "../home/PromoMovie/PromoMovie";
import OriginsClubAdditionalInfo from "./additionalInfo/OriginsClubAdditionalInfo";
import FeaturedIdeas from "./featuredIdeas/FeaturedIdeas";
import SliderOriginsClub from "./slider/SliderOriginsClub";
import WhatsHot from "./whatsHot/WhatsHot";

const OriginsClub = () => {
    const { isDark } = useContext(ThemeContext);

    return (
        <div className={isDark ? "greyscheme" : ""}>
            <section
                className="jumbotron no-bg"
                style={{
                    background: `url(${"./img/v2/backgrounds/origins_club_background.png"})`,
                    backgroundSize: "cover",
                    backgroundPosition: "top 70px",
                    backgroundRepeat: "no-repeat",
                    overflow: "hidden",
                }}
            >
                <SliderOriginsClub />
            </section>

            <OriginsClubAdditionalInfo />
            <FeaturedIdeas />
            <PromoMovie />
            <WhatsHot />
            <PopularItemsMarquee />

            <Footer />
        </div>
    );
};
export default OriginsClub;
