import Snackbar from "@material-ui/core/Snackbar";
import React, { useContext } from "react";
import Alert from "@material-ui/lab/Alert";
import { SnackBarContext } from "../../providers/SnackBar/SnackBarProvider";

const GlobalSnackbar = () => {
    const {
        infos: { isOpen, message, type },
        closeSnackBar,
    } = useContext(SnackBarContext);

    const closeSnack = () => {
        closeSnackBar();
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={isOpen}
            autoHideDuration={5000}
            onClose={closeSnack}
        >
            <Alert severity={type} variant="filled" elevation={6}>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default GlobalSnackbar;
