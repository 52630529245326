import { useQuery } from "@apollo/client";
import {
    Badge,
    Box,
    createStyles,
    makeStyles,
    Popover,
} from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { useNavigate } from "@reach/router";
import { useContext, useEffect, useRef, useState } from "react";
import ROUTES from "../../components/constants/routes";
import COLORS from "../../constants/colors";
import { MY_NOTIFICATIONS } from "../../graphql/notification/query";
import { LISTEN_TO_NEW_NOTIF } from "../../graphql/notification/subscription";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";
import RecentNotificationItem from "./item/RecentNotificationItem";

const LAST_NOTIFICATIONS_COUNT = 5;

const NotificationIcon = () => {
    const { isDark } = useContext(ThemeContext);
    const [openedPopover, setOpenedPopover] = useState(false);
    const popoverAnchor = useRef(null);
    const navigate = useNavigate();

    const { data, subscribeToMore } = useQuery(MY_NOTIFICATIONS, {
        variables: {
            take: LAST_NOTIFICATIONS_COUNT,
            skip: 0,
        },
    });

    const useStyles = makeStyles((theme) =>
        createStyles({
            popover: {
                pointerEvents: "none",
                opacity: 0.9,
            },
            popoverPaper: {
                minWidth: 200,
                marginTop: 10,
                borderRadius: 7,
                display: "flex",
                flexDirection: "column",
                pointerEvents: "auto",
                backgroundColor: COLORS.light.white,
            },
            logoutButton: {
                paddingTop: 5,
                paddingBottom: 5,
                textTransform: "none",
                color: COLORS.light.black_soft,
                backgroundColor: COLORS.light.white,
                justifyContent: "flex-start",
                fontSize: 14,
                borderRadius: 0,
                "&:hover": {
                    backgroundColor: isDark
                        ? COLORS.dark.color_2
                        : COLORS.light.color,
                    color: COLORS.light.white,
                },
            },
            noNotificationText: {
                padding: 10,
                color: COLORS.light.black_soft,
                backgroundColor: COLORS.light.white,
                justifyContent: "center",
                textAlign: "center",
            },
            logoutText: {
                marginLeft: 10,
            },
            separator: {
                height: 1,
                width: "100%",
                backgroundColor: isDark
                    ? COLORS.light.black_soft
                    : COLORS.light.white,
            },
        })
    );

    const classes = useStyles();

    const popoverEnter = () => {
        setOpenedPopover(true);
    };

    const popoverLeave = () => {
        setOpenedPopover(false);
    };

    const myNotifications = data?.myNotifications?.notifications || [];
    const unreadCount = data?.myNotifications?.unreadCount || 0;

    const navigateToNotifications = () => {
        navigate(ROUTES.notifications);
        popoverLeave();
    };

    const subscribeToNewNotification = () =>
        subscribeToMore({
            document: LISTEN_TO_NEW_NOTIF,
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData) return prev;
                const next = {
                    ...prev,
                    myNotifications: {
                        ...prev?.myNotifications,
                        totalCount: prev?.myNotifications?.totalCount
                            ? prev?.myNotifications?.totalCount + 1
                            : 1,
                        unreadCount: prev?.myNotifications?.unreadCount
                            ? prev?.myNotifications?.unreadCount + 1
                            : 1,
                        notifications: [
                            subscriptionData?.data?.onMyPermitStatus,
                            ...prev?.myNotifications?.notifications,
                        ],
                    },
                };

                return next;
            },
        });

    useEffect(() => {
        subscribeToNewNotification();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscribeToMore]);

    return (
        <Badge
            badgeContent={unreadCount}
            color="secondary"
            style={{
                marginTop: 10,
                marginRight: 20,
            }}
        >
            <span
                ref={popoverAnchor}
                aria-owns="mouse-over-popover"
                aria-haspopup="true"
                onMouseEnter={popoverEnter}
                onMouseLeave={popoverLeave}
            >
                <NotificationsIcon
                    style={{
                        fontSize: 30,
                        color: COLORS.light.gray,
                        cursor: "pointer",
                    }}
                />
            </span>
            <Popover
                id="mouse-over-popover"
                open={openedPopover}
                anchorEl={popoverAnchor.current}
                disableScrollLock
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                className={classes.popover}
                classes={{ paper: classes.popoverPaper }}
                PaperProps={{
                    onMouseEnter: popoverEnter,
                    onMouseLeave: popoverLeave,
                }}
                placement="bottom-end"
            >
                {myNotifications.length ? (
                    <Box>
                        {myNotifications.map((notification) => (
                            <RecentNotificationItem
                                notification={notification}
                                popoverLeave={popoverLeave}
                            />
                        ))}
                        <Box
                            style={{
                                textAlign: "center",
                                color: COLORS.v2.green,
                                backgroundColor: COLORS.v2.grey_background,
                                cursor: "pointer",
                                padding: 5,
                            }}
                            onClick={navigateToNotifications}
                        >
                            Show all notifications
                        </Box>
                    </Box>
                ) : (
                    <Box className={classes.noNotificationText}>
                        No notification.
                    </Box>
                )}
            </Popover>
        </Badge>
    );
};

export default NotificationIcon;
