import { Box, createStyles, makeStyles } from "@material-ui/core";
import { useIntl } from "react-intl";
import COLORS from "../../../../constants/colors";
import TrustedPartnersLogos from "./logos/TrustedPartnersLogos";

const useStyles = makeStyles((theme) =>
    createStyles({
        container: {
            display: "flex",
            flexDirection: "column",
            color: COLORS.v2.black_grey,
            alignItems: "center",
        },
        title: {
            fontWeight: "bold",
            marginBottom: 30,
        },
        logosContainer: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            alignItems: "center",
            width: "100%",
        },
        logo: {
            margin: 20,
            width: "auto",
            height: 50,
        },
        copyrightLogo: {
            margin: 20,
            width: "auto",
            height: 100,
        },
    })
);

const TrustedPartners = () => {
    const classes = useStyles();
    const { formatMessage } = useIntl();

    return (
        <section
            className={classes.container}
            style={{ backgroundColor: "white" }}
        >
            <Box className={classes.title}>
                {formatMessage({ id: "home.trusted.partners.title" })}
            </Box>
            <TrustedPartnersLogos />
        </section>
    );
};

export default TrustedPartners;
