import React, { useState, createContext } from "react";

export const SnackBarContext = createContext({
    infos: {
        isOpen: false,
        message: "",
        type: "error",
    },
    displaySnackBar: () => {},
    closeSnackBar: () => {},
});

const SnackBarProvider = ({ children }) => {
    const [infos, setInfos] = useState({
        isOpen: false,
        message: "",
        type: "error",
    });

    return (
        <SnackBarContext.Provider
            value={{
                infos,
                displaySnackBar: (args) => {
                    setInfos({
                        isOpen: true,
                        message: args.message,
                        type: args.type,
                    });
                },
                closeSnackBar: () => {
                    setInfos({
                        isOpen: false,
                        message: "",
                        type: infos.type,
                    });
                },
            }}
        >
            {children}
        </SnackBarContext.Provider>
    );
};

export default SnackBarProvider;
