import { useMutation } from "@apollo/client";
import { memo, useContext, useState } from "react";
import CustomButton from "../../components/button/CustomButton";
import { CREATE_PARAGRAPH } from "../../graphql/help/mutation";
import { SnackBarContext } from "../../providers/SnackBar/SnackBarProvider";
import CustomInput from "../input/CustomInput";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import COLORS from "../../constants/colors";
import config from "../../../config/config";
import Files from "react-files";
import { useIntl } from "react-intl";
import { FONT_SIZES } from "../../constants/sizes";
import { BloggersClientContext } from "../../providers/Apollo/ApolloProvider";

export const PARAGRAPH_TYPES = {
    text: "TEXT",
    image: "IMAGE",
};

export const GreenRadio = withStyles({
    root: {
        color: COLORS.v2.green,
        "&$checked": {
            color: COLORS.v2.green,
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

const CreateParagraphForm = ({
    subHelpId,
    handleCloseDialog,
    refetchSubHelp,
}) => {
    const { displaySnackBar } = useContext(SnackBarContext);
    const { bloggersClient } = useContext(BloggersClientContext);
    const { formatMessage } = useIntl();
    const [text, setText] = useState(undefined);
    const [picture, setPicture] = useState(undefined);
    const [files, setFiles] = useState([]);
    const [paragraphType, setParagraphType] = useState(PARAGRAPH_TYPES.text);
    const [priority, setPriority] = useState(undefined);

    const [createParagraph, { loading }] = useMutation(CREATE_PARAGRAPH, {
        client: bloggersClient,
        onCompleted: (data) => {
            if (data && data.createParagraph) {
                displaySnackBar({
                    message: "Paragraph created",
                    type: "success",
                });
                handleCloseDialog();
                refetchSubHelp();
            }
        },
        onError: (err) => {
            if (err.message) {
                displaySnackBar({
                    message: err.message,
                    type: "error",
                });
            }
        },
    });

    const onCreate = () => {
        if (subHelpId && (text || picture)) {
            createParagraph({
                variables: {
                    input: {
                        subHelpId: Number(subHelpId),
                        text,
                        picture,
                        priority: Number(priority),
                    },
                },
            });
        }
    };

    const handleChangeText = (value) => {
        setText(value);
    };

    const handleChangePriority = (value) => {
        setPriority(Number(value));
    };

    const handleChangeParagraphType = (event) => {
        const value = event.target.value;

        if (value === PARAGRAPH_TYPES.text) {
            setPicture(undefined);
        }
        if (value === PARAGRAPH_TYPES.image) {
            setText(undefined);
        }

        setParagraphType(event.target.value);
    };

    const unknownFileError = () => {
        displaySnackBar({
            message: formatMessage({
                id: "myProfile.settings.uploading.picture.unknown.error",
            }),
            type: "error",
        });
    };

    const getBase64 = (file, callback) => {
        try {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                callback(reader.result);
            };
            reader.onerror = (error) => {
                unknownFileError();
                console.log("Error: ", error);
            };
        } catch (err) {
            onFilesError(err, null);
            console.log(`Error : `, err);
        }
    };

    const onFilesChange = (browsedFiles) => {
        if (browsedFiles && browsedFiles[0]) {
            getBase64(browsedFiles[0], (result) => {
                setPicture(result);
                setFiles(browsedFiles);
            });
        } else {
            setFiles([]);
            setPicture(undefined);
        }
    };

    const onFilesError = (error, file) => {
        console.log("error code " + error.code + ": " + error.message);
        if (error.code === 2) {
            displaySnackBar({
                message: `${formatMessage({
                    id: "myProfile.settings.uploading.picture.size.error",
                })} ${config.nft.fileSizeLimit} Mb.`,
                type: "error",
            });
        } else {
            displaySnackBar({
                message: `Error when uploading the file.`,
                type: "error",
            });
        }
    };

    const error =
        (paragraphType === PARAGRAPH_TYPES.text && !text) ||
        (paragraphType === PARAGRAPH_TYPES.image && !picture);

    return (
        <div>
            <FormControl component="fieldset" style={{ marginBottom: 20 }}>
                <h5>Paragraph type</h5>
                <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={paragraphType}
                    onChange={handleChangeParagraphType}
                >
                    <FormControlLabel
                        value={PARAGRAPH_TYPES.text}
                        control={<GreenRadio />}
                        label="Text"
                    />
                    <FormControlLabel
                        value={PARAGRAPH_TYPES.image}
                        control={<GreenRadio />}
                        label="Image"
                    />
                </RadioGroup>
            </FormControl>

            {paragraphType === PARAGRAPH_TYPES.text && (
                <CustomInput
                    label="Text"
                    placeholder="Add a paragraph text"
                    value={text}
                    handleChangeValue={handleChangeText}
                    error={!text}
                    errorText="This field is required"
                    muiltiline
                    style={{ height: 200 }}
                />
            )}

            {paragraphType === PARAGRAPH_TYPES.image && (
                <div>
                    <h5>Paragraph Image</h5>

                    <div className="d-create-file">
                        <p id="file_name">
                            PNG, JPG or GIF. Max {config.nft.fileSizeLimit}mb.
                        </p>

                        {files.map((x) => (
                            <p key="{index}">{x.name}</p>
                        ))}

                        <div className="browse">
                            <Files
                                className="upload_file"
                                onChange={onFilesChange}
                                onError={onFilesError}
                                accepts={["image/*"]}
                                multiple={false}
                                maxFiles={100}
                                maxFileSize={config.nft.fileSizeLimit * 1000000}
                                minFileSize={0}
                            >
                                <CustomButton text="Browse" />
                            </Files>
                        </div>
                    </div>
                    {!picture && (
                        <div
                            style={{
                                fontSize: FONT_SIZES.common.small,
                                color: "#EB5757",
                                marginTop: 10,
                            }}
                        >
                            Paragraph Image is required
                        </div>
                    )}
                    {picture && (
                        <div
                            className="col-lg-3 col-sm-6 col-xs-12"
                            style={{ marginTop: 20 }}
                        >
                            <h5>Image Preview</h5>
                            <div className="bloglist item">
                                <div className="post-content">
                                    <div className="post-image">
                                        <img
                                            alt=""
                                            src={picture}
                                            className="lazy"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}

            <CustomInput
                label="Priority"
                placeholder="Priority of your paragraph"
                value={priority}
                handleChangeValue={handleChangePriority}
                type="number"
            />
            <div style={{ display: "flex" }}>
                <CustomButton
                    text="CREATE"
                    onClick={onCreate}
                    disabled={loading || error || !subHelpId}
                    style={{ marginRight: 20 }}
                />
                <CustomButton
                    text="CANCEL"
                    onClick={handleCloseDialog}
                    disabled={loading}
                    isWhite
                />
            </div>
        </div>
    );
};
export default memo(CreateParagraphForm);
