import React, { useContext, useEffect, useState } from "react";
import Clock from "../../components/Clock";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_NFT } from "../../graphql/nft/mutation";
import { AuthContext } from "../../providers/Auth/AuthProvider";
import CustomInput from "../../components/input/CustomInput";
import AddProperties from "../../components/property/AddProperties";
import PropertyItem from "../../components/property/PropertyItem";
import { SnackBarContext } from "../../providers/SnackBar/SnackBarProvider";
import { useIntl } from "react-intl";
import { useNavigate } from "@reach/router";
import ROUTES from "../../components/constants/routes";
import CustomButton from "../../components/button/CustomButton";
import { GET_NFT } from "../../graphql/nft/query";
import CustomSwitchProperty from "../../components/switch/CustomSwitchProperty";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";
import NFTAuthorPicture from "../../components/nftCard/authorPicture/NFTAuthorPicture";
import getImagePreview from "../../utils/nft/getImagePreview";
import LOCAL_STORAGE_KEYS from "../../constants/localStorage";
import axios from "axios";
import config from "../../../config/config";
import { ethers } from "ethers";
import Footer from "../../components/footer/Footer";
import CustomExtraFilesInput from "../../components/extraFiles/CustomExtraFilesInput";
import { CategoriesContecxt } from "../../providers/Categories/CategoriesProvider";
import CustomSelect from "../../components/select/CustomSelect";
import { GET_SUBGRAPH_NFT } from "../../subgraph/nft/query";
import contract from "../../utils/blockchain/contract";
import { getIpId } from "common";
import { GraphNodeClientContext } from "../../providers/Apollo/ApolloProvider";
import CustomLoader from "../../components/customLoader/CustomLoader";
import { NetworkContext } from "../../providers/NetTester/NetTesterProvider";
import HeaderWithImage from "../../components/header/HeaderWithImage";

const UpdateNFT = ({ cid }) => {
    const { authContext } = useContext(AuthContext);
    const { myProfile } = authContext;
    const { displaySnackBar } = useContext(SnackBarContext);
    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const { isDark } = useContext(ThemeContext);
    const { graphNodeClient } = useContext(GraphNodeClientContext);
    const { isNetworkMismatched, isUnsupportedNetwork } =
        useContext(NetworkContext);

    const { defaultSelectCategories, loadingCategories } =
        useContext(CategoriesContecxt);
    const [category, setCategory] = useState(undefined);

    const [mimetype, setMimetype] = useState("");
    const [nftUrl, setNftUrl] = useState(undefined);
    const [isMinted, setIsMinted] = useState(false);
    const [mintedNft, setMintedNft] = useState(null);
    const [extraFiles, setExtraFiles] = useState([]);
    const [loadingSetInfo, setLoadingSetInfo] = useState(false);

    const wallet = authContext?.wallet;
    const signer = wallet?.signer;

    const [infos, setInfos] = useState({
        cid,
        title: undefined,
        description: undefined,
        price: undefined,
        shareCount: undefined,
        royalties: undefined,
        externalLink: undefined,
        isSensitiveContent: false,
        properties: [],
    });

    const setPartialInfos = (partial) => {
        setInfos((current) => ({ ...current, ...partial }));
    };

    const nftErc20Id = getIpId({
        IdealNFTAddress: contract?.contracts?.IdealNFT?.address,
        ip: {
            contentType: 1,
            content: ethers.utils.toUtf8Bytes(cid),
        },
        chainId: contract?.chainId,
    });

    const { loading: loadingGraphnode } = useQuery(GET_SUBGRAPH_NFT, {
        fetchPolicy: "cache-and-network",
        variables: { id: nftErc20Id },
        client: graphNodeClient,
        onCompleted: (data) => {
            if (data && data.nfterc20) {
                setMintedNft(data.nfterc20);
                setIsMinted(true);
                setPartialInfos({
                    title: data.nfterc20?.title || "",
                    description: data.nfterc20?.description || "",
                    externalLink: data.nfterc20?.externalLink || "",
                    royalties: Number(data.nfterc20?.royalties) || 0,
                });
            } else {
                setIsMinted(false);
            }
        },
    });

    const { data, loading: loadingQuery } = useQuery(GET_NFT, {
        variables: { id: cid },
        onCompleted: (data) => {
            if (data && data.getIp) {
                const {
                    cid,
                    title,
                    description,
                    price,
                    shareCount,
                    royalties,
                    externalLink,
                    isSensitiveContent,
                    properties,
                    categories,
                } = data.getIp;

                setMimetype(data.getIp.mimetype);
                if (data.getIp?.privacyStatus === "minted" || mintedNft) {
                    setIsMinted(true);
                    setPartialInfos({
                        cid,
                        price,
                        shareCount: shareCount
                            ? ethers.utils.formatEther(shareCount)
                            : undefined,
                        isSensitiveContent,
                    });
                } else {
                    setIsMinted(false);
                    setInfos({
                        cid,
                        title,
                        description,
                        price,
                        shareCount: shareCount
                            ? ethers.utils.formatEther(shareCount)
                            : undefined,
                        royalties,
                        externalLink,
                        isSensitiveContent,
                        properties,
                    });
                }

                if (categories && categories.length && categories[0]) {
                    setCategory({
                        value: categories[0].code,
                        label: categories[0].name,
                    });
                }
            }
        },
    });

    const [updateNFT, { loading }] = useMutation(UPDATE_NFT, {
        onCompleted: (data) => {
            if (data && data.updateIp && data.updateIp.cid) {
                displaySnackBar({
                    message: formatMessage({
                        id: "upload.update.message.success",
                    }),
                    type: "success",
                });
                navigate(`${ROUTES.nftDetail}/${data.updateIp.cid}`);
            }
        },
        onError: (err) => {
            displaySnackBar({
                message: formatMessage({ id: "upload.update.message.error" }),
                type: "error",
            });
        },
        refetchQueries: [{ query: GET_NFT, variables: { id: cid } }],
    });

    const handleChangeCategory = (value) => {
        setCategory(value);
    };

    const handleChangeTitle = (value) => {
        setInfos({ ...infos, title: value });
    };

    const handleChangeDescription = (value) => {
        setInfos({ ...infos, description: value });
    };

    const handleChangePrice = (value) => {
        setInfos({ ...infos, price: Number(value) });
    };

    const handleChangeShareCount = (value) => {
        setInfos({ ...infos, shareCount: value });
    };

    const handleChangeRoyalties = (value) => {
        setInfos({ ...infos, royalties: Number(value) });
    };

    const handleChangeExternalLink = (value) => {
        setInfos({ ...infos, externalLink: value });
    };

    const handleChangeisSensitiveContent = (value) => {
        setInfos({ ...infos, isSensitiveContent: !infos.isSensitiveContent });
    };

    const handleChangeProperties = (value) => {
        setInfos({ ...infos, properties: value });
    };

    const onUpdate = () => {
        updateNFT({
            variables: {
                input: {
                    ...infos,
                    price: Number(infos.price),
                    properties: infos.properties.map((prop) => {
                        const { index, id, __typename, ...data } = prop;
                        return {
                            ...data,
                            id: id ? Number(id) : undefined,
                        };
                    }),
                    extraFiles: extraFiles.map((extraFile) => ({
                        cid: extraFile.cid,
                        file: extraFile.file,
                    })),
                    categoriesCode: category ? [category.value] : undefined,
                },
            },
        });
    };

    const handleSetInfoTx = async () => {
        if (isNetworkMismatched || isUnsupportedNetwork) {
            displaySnackBar({
                message: formatMessage({
                    id: "net.issue.block.transaction",
                }),
                type: "error",
            });
            return;
        }

        if (!mintedNft || !mintedNft?.id) {
            return;
        }

        const contractAddress = contract?.contracts?.IdealNFT?.address;
        const contractAbi = contract?.contracts?.IdealNFT?.abi;
        let contractInstance = new ethers.Contract(
            contractAddress,
            contractAbi
        );
        contractInstance = contractInstance.connect(signer);

        console.log("contractInstance ==== ", contractInstance);

        try {
            setLoadingSetInfo(true);

            let setInfoTransaction = await contractInstance.setInfo(
                mintedNft.id.toLowerCase(),
                {
                    title: infos?.title || "",
                    description: infos?.description || "",
                    externalLink: infos?.externalLink || "",
                    royalties: infos?.royalties || 0,
                }
            );

            const receipt = await setInfoTransaction.wait();
            console.log("Set Info receipt ===== ", receipt);

            if (receipt) {
                console.log("********** infos updated ************");

                setTimeout(() => {
                    setLoadingSetInfo(false);
                    displaySnackBar({
                        message: "NFT infos successfully updated on Blockchain",
                        type: "success",
                    });

                    onUpdate();
                }, 3000);
            }
        } catch (error) {
            console.log(`error : `, error);
            setLoadingSetInfo(false);

            displaySnackBar({
                message: error.message || "Error occured while minting the NFT",
                type: "error",
            });
        }
    };

    const Authorization = `Bearer ${localStorage.getItem(
        LOCAL_STORAGE_KEYS.TOKEN
    )}`;

    useEffect(() => {
        if (cid) {
            axios
                .get(`${config.nft.ipsUrl}/${cid}`, {
                    headers: {
                        Authorization,
                    },
                    responseType: "blob",
                })
                .then((res) => {
                    const blobURL = URL.createObjectURL(res.data);
                    setNftUrl(blobURL);
                });
        }
    }, [Authorization, cid]);

    useEffect(() => {
        if (data?.getIp?.extraFiles && data?.getIp?.extraFiles.length) {
            for (const extraFile of data?.getIp?.extraFiles) {
                axios
                    .get(`${config.nft.filesUrl}/${extraFile?.cid}`, {
                        headers: {
                            Authorization,
                        },
                        responseType: "blob",
                    })
                    .then((res) => {
                        const blobURL = URL.createObjectURL(res.data);
                        setExtraFiles((currentExtraFiles) => {
                            if (
                                currentExtraFiles.some(
                                    (file) => file.cid === extraFile?.cid
                                )
                            ) {
                                return currentExtraFiles;
                            }
                            return [
                                ...currentExtraFiles,
                                {
                                    cid: extraFile?.cid,
                                    id: extraFile?.cid,
                                    name: extraFile?.filename,
                                    type: extraFile?.mimetype,
                                    preview: {
                                        url: blobURL,
                                        blob: res.data,
                                    },
                                    file: null,
                                },
                            ];
                        });
                    });
            }
        }
    }, [Authorization, data?.getIp?.extraFiles]);

    const onExtraFilesChange = (browsedFiles) => {
        if (browsedFiles && browsedFiles.length) {
            const filtredFiles = browsedFiles.filter(
                (file) =>
                    !extraFiles.some(
                        (extraFile) =>
                            extraFile?.file?.size === file.size &&
                            extraFile?.name === file?.name
                    )
            );
            const formattedFiles = filtredFiles.map((file) => {
                return {
                    cid: null,
                    id: file?.id,
                    name: file?.name,
                    type: file?.type,
                    preview: file?.preview,
                    file,
                };
            });
            setExtraFiles((currentExtraFiles) => [
                ...currentExtraFiles,
                ...formattedFiles,
            ]);
        }
    };

    const titleMissing = !infos.title;

    useEffect(() => {
        const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
        if (!token) {
            displaySnackBar({
                message: "You must be logged in to update NFT",
                type: "error",
            });
            navigate(ROUTES.wallets);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myProfile]);

    return (
        <div className={isDark ? "greyscheme" : ""}>
            {(loading ||
                loadingQuery ||
                loadingGraphnode ||
                loadingSetInfo) && (
                <CustomLoader
                    text={
                        loadingSetInfo
                            ? "Setting your NFT Blockchain information"
                            : loading
                            ? "Updating your NFT"
                            : undefined
                    }
                />
            )}

            <HeaderWithImage title={"Update"} />

            <section className="container">
                <div className="row">
                    <div className="col-lg-7 offset-lg-1 mb-5">
                        <form
                            id="form-create-item"
                            className="form-border"
                            action="#"
                        >
                            <div className="field-set">
                                <CustomSelect
                                    title="Category"
                                    placeholder="Select category"
                                    options={defaultSelectCategories}
                                    value={category}
                                    onChange={handleChangeCategory}
                                    noMaxWidth
                                    isLoading={loadingCategories}
                                />

                                <CustomInput
                                    label="Title"
                                    placeholder="e.g. 'Crypto Funk"
                                    value={infos.title}
                                    handleChangeValue={handleChangeTitle}
                                    error={titleMissing}
                                    errorText="This field is required"
                                />

                                <CustomInput
                                    label="Description"
                                    placeholder="e.g. 'This is very limited item'"
                                    value={infos.description}
                                    handleChangeValue={handleChangeDescription}
                                    muiltiline
                                />

                                <CustomInput
                                    label="Shares"
                                    placeholder="enter share count"
                                    value={infos.shareCount}
                                    handleChangeValue={handleChangeShareCount}
                                    type="number"
                                    disabled={isMinted}
                                />

                                <CustomInput
                                    label="Estimated Price Per Share (ETH)"
                                    placeholder="enter price per share (ETH)"
                                    value={infos.price}
                                    handleChangeValue={handleChangePrice}
                                    type="number"
                                />

                                <CustomInput
                                    label="Royalties"
                                    placeholder="suggested: 0, 10%, 20%, 30%. Maximum is 70%"
                                    value={infos.royalties}
                                    handleChangeValue={handleChangeRoyalties}
                                    type="number"
                                />

                                <CustomInput
                                    label="External link"
                                    placeholder="IdealExchange will include a link to this URL on this item's detail page"
                                    value={infos.externalLink}
                                    handleChangeValue={handleChangeExternalLink}
                                    muiltiline
                                />

                                <CustomSwitchProperty
                                    title="Explicit or Sensitive content (Adult/Pornography/NSFW)"
                                    subtitle="Set this item as explicit and sensitive
                                content."
                                    value={infos.isSensitiveContent}
                                    handleChangeValue={
                                        handleChangeisSensitiveContent
                                    }
                                />

                                <AddProperties
                                    properties={infos.properties}
                                    handleChangeProperties={
                                        handleChangeProperties
                                    }
                                />

                                <div
                                    className="row"
                                    style={{
                                        marginTop: 0,
                                        marginBottom: 20,
                                    }}
                                >
                                    {infos.properties.map((property) => (
                                        <PropertyItem property={property} />
                                    ))}
                                </div>

                                <CustomExtraFilesInput
                                    extraFiles={extraFiles}
                                    onExtraFilesChange={onExtraFilesChange}
                                    setExtraFiles={setExtraFiles}
                                />

                                <CustomButton
                                    text="Update Item"
                                    onClick={
                                        mintedNft?.id
                                            ? handleSetInfoTx
                                            : onUpdate
                                    }
                                    disabled={
                                        loading ||
                                        titleMissing ||
                                        loadingQuery ||
                                        loadingGraphnode
                                    }
                                />
                            </div>
                        </form>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-xs-12">
                        <h5>Preview item</h5>
                        <div
                            className="nft__item m-0"
                            style={{ height: "auto" }}
                        >
                            <div className="de_countdown">
                                <Clock deadline="December, 30, 2021" />
                            </div>
                            <NFTAuthorPicture
                                src={data?.getIp?.user?.profilePictureUri}
                            />
                            <div className="nft__item_wrap">
                                <span>
                                    <img
                                        src={getImagePreview({
                                            extension: mimetype,
                                            imageUrl: nftUrl,
                                        })}
                                        id="get_file_2"
                                        className="lazy nft__item_preview"
                                        alt=""
                                    />
                                </span>
                            </div>
                            <div className="nft__item_info">
                                <span>
                                    <h4>{infos.title}</h4>
                                </span>
                                <div className="nft__item_price">
                                    {infos.price ? (
                                        <div>{infos.price} ETH</div>
                                    ) : null}
                                    {infos.royalties ? (
                                        <div>{infos.royalties}%</div>
                                    ) : null}
                                </div>
                                {/* <div className="nft__item_action">
                                    <span>Place a bid</span>
                                </div> */}
                                <div className="idx">IDX</div>
                                <div className="nft__item_like">
                                    <i className="fa fa-heart"></i>
                                    <span>0</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
};

export default UpdateNFT;
