import { gql } from "@apollo/client";

export const SALE_FRAGMENT = gql`
    fragment SaleFields on SignedSale {
        id
        version
        sale
        signature
        validUntil
        IP {
            cid
        }
        seller {
            id
        }
    }
`;
