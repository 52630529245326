import { Link } from "@material-ui/core";
import { useState } from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import PropertyItem from "../../../../components/property/PropertyItem";
import ExtraFile from "./ExtraFile/ExtraFile";

const NFTDetailsContent = ({ nft, mintedNft }) => {
    const [selectedExtraFile, setSelectedExtraFile] = useState(null);

    const handleCloseExtraFileModal = () => {
        setSelectedExtraFile(null);
    };

    return (
        <>
            <div className="tab-1 onStep fadeIn">
                <Accordion>
                    {nft?.price ? (
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey="1"
                                >
                                    Estimated price
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>{nft?.price} ETH</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ) : null}
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey="2"
                            >
                                Royalties
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body>
                                {mintedNft?.royalties || nft?.royalties || 0}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    {mintedNft && mintedNft.externalLink && (
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey="3"
                                >
                                    External link
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="3">
                                <Card.Body>
                                    <Link href={mintedNft?.externalLink}>
                                        {mintedNft?.externalLink}
                                    </Link>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    )}
                    {!mintedNft && nft && nft?.externalLink && (
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey="3"
                                >
                                    External link
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="3">
                                <Card.Body>
                                    <Link href={nft?.externalLink}>
                                        {nft?.externalLink}
                                    </Link>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    )}

                    <Card>
                        <Card.Header>
                            <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey="4"
                            >
                                Explicit or Sensitive content
                                (Adult/Pornography/NSFW)
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="4">
                            <Card.Body>
                                {nft?.isSensitiveContent ? "Yes" : "No"}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    {nft && nft.properties && nft.properties.length ? (
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey="5"
                                >
                                    Properties
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="5">
                                <Card.Body>
                                    <div className="row mt-5">
                                        {nft?.properties?.map((property) => (
                                            <PropertyItem
                                                key={property.name}
                                                property={property}
                                            />
                                        ))}
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ) : null}
                    {nft && nft.extraFiles && nft.extraFiles.length ? (
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey="6"
                                >
                                    Extra Files
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="6">
                                <Card.Body>
                                    <div className="row mt-5">
                                        {nft?.extraFiles?.map((file) => (
                                            <p
                                                key={file?.cid}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setSelectedExtraFile(file);
                                                }}
                                            >
                                                {file?.filename}
                                            </p>
                                        ))}
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ) : null}
                </Accordion>
            </div>
            {selectedExtraFile && (
                <ExtraFile
                    extraFile={selectedExtraFile}
                    handleClose={handleCloseExtraFileModal}
                />
            )}
        </>
    );
};

export default NFTDetailsContent;
