import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Avatar, Box, Tooltip } from "@material-ui/core";
import COLORS from "../../../../../constants/colors";
import { getIpId } from "common";
import contract from "../../../../../utils/blockchain/contract";
import { ethers } from "ethers";
import { GET_NFT_STAT } from "../../../../../subgraph/nft/query";
import { useQuery } from "@apollo/client";
import { GraphNodeClientContext } from "../../../../../providers/Apollo/ApolloProvider";
import EtherLogo from "../../../../../components/ticker/EtherLogo";

const ITEM_DISTANCE = 10;

const useStyles = makeStyles({
    tableRow: {
        background: COLORS.v2.grey_background,
        borderColor: COLORS.v2.grey_background,
    },
    tableRowOdd: {
        background: COLORS.v2.black_grey,
        borderColor: COLORS.v2.grey_background,
    },
    tableCell: {
        color: COLORS.v2.white,
    },
    noBorder: {
        borderBottom: "none",
        borderTop: "none",
    },
    deltaNegative: {
        display: "flex",
        color: "red",
        marginRight: ITEM_DISTANCE,
    },
    deltaPositive: {
        display: "flex",
        color: COLORS.v2.green,
        marginRight: ITEM_DISTANCE,
    },
    flex: {
        display: "flex",
    },
    white: {
        display: "flex",
        color: COLORS.v2.white,
        marginRight: ITEM_DISTANCE,
        marginLeft: ITEM_DISTANCE,
    },
});

const WhatsHotTableItem = ({ item, index, filter }) => {
    const classes = useStyles();
    const { graphNodeClient } = useContext(GraphNodeClientContext);

    const isOdd = (num) => {
        return num % 2;
    };

    const nftErc20Id = getIpId({
        IdealNFTAddress: contract?.contracts?.IdealNFT?.address,
        ip: {
            contentType: 1,
            content: ethers.utils.toUtf8Bytes(item?.cid),
        },
        chainId: contract?.chainId,
    });

    const { data } = useQuery(GET_NFT_STAT, {
        fetchPolicy: "cache-and-network",
        variables: {
            id: nftErc20Id,
        },
        client: graphNodeClient,
    });

    const nfterc20 = data?.nfterc20;

    const dailyStats = nfterc20?.dailyStats[0];
    const weeklyStats = nfterc20?.weeklyStats[0];
    const monthlyStats = nfterc20?.monthlyStats[0];

    const totalShares = nfterc20?.totalShares
        ? Number(ethers.utils.formatEther(nfterc20?.totalShares)).toFixed(0)
        : 0;

    const CustomTableCell = ({ children, style }) => (
        <TableCell
            align="left"
            style={style}
            className={classes.tableCell}
            classes={{ root: classes.noBorder }}
        >
            {children}
        </TableCell>
    );

    const generateDeltaItem = (delta, averagePrice) => {
        if (!delta || !averagePrice)
            return <Box className={classes.flex}>-</Box>;

        const deltaPercentage = (1 - Number(delta)) * 100;
        // const deltaPrice = Math.abs(
        //     (deltaPercentage * Number(averagePrice)) / 100
        // );

        if (delta === "1") return <Box className={classes.flex}>0%</Box>;

        if (1 - Number(delta) < 0)
            return (
                <Box className={classes.deltaNegative}>
                    {deltaPercentage.toFixed(2)}%
                </Box>
            );

        return (
            <Box className={classes.deltaPositive}>
                +{deltaPercentage.toFixed(2)}%
            </Box>
        );
    };

    const volume = nfterc20?.[filter]
        ? nfterc20?.[filter][0]?.volume
        : undefined;

    const averagePrice = nfterc20?.[filter]
        ? nfterc20?.[filter][0]?.averagePrice
        : undefined;

    return (
        <TableRow
            key={item?.cid}
            className={!isOdd(index) ? classes.tableRowOdd : classes.tableRow}
        >
            <CustomTableCell
                style={{
                    color: COLORS.v2.white,
                    paddingLeft: 90,
                }}
            >
                <Tooltip title={item?.user?.id}>
                    <Avatar src={item?.user?.profilePictureUri} alt="" />
                </Tooltip>
            </CustomTableCell>
            <CustomTableCell>{nfterc20?.title}</CustomTableCell>
            <CustomTableCell>{volume || "-"}</CustomTableCell>
            <CustomTableCell>{totalShares || "-"}</CustomTableCell>
            <CustomTableCell>
                {averagePrice ? (
                    <Box className={classes.flex}>
                        ~
                        <EtherLogo
                            style={{
                                marginRight: ITEM_DISTANCE / 2,
                                marginLeft: ITEM_DISTANCE / 2,
                                marginTop: 2,
                            }}
                        />
                        {averagePrice}
                    </Box>
                ) : (
                    "-"
                )}
            </CustomTableCell>

            <CustomTableCell>
                {generateDeltaItem(dailyStats?.delta, dailyStats?.averagePrice)}
            </CustomTableCell>

            <CustomTableCell>
                {generateDeltaItem(
                    weeklyStats?.delta,
                    weeklyStats?.averagePrice
                )}
            </CustomTableCell>

            <CustomTableCell
                style={{
                    color: COLORS.v2.white,
                    paddingRight: 90,
                }}
            >
                {generateDeltaItem(
                    monthlyStats?.delta,
                    monthlyStats?.averagePrice
                )}
            </CustomTableCell>
        </TableRow>
    );
};

export default WhatsHotTableItem;
