import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { ThemeContext } from "../../../providers/Theme/ThemeProvider";
import { useNavigate } from "@reach/router";
import ROUTES from "../../../components/constants/routes";
import SectionSubtitle from "../../../components/section/SectionSubtitle";
import CustomButton from "../../../components/button/CustomButton";
import FeaturedIdeasItem from "./item/FeaturedIdeasItem";

const FeaturedIdeas = () => {
    const { formatMessage } = useIntl();
    const { isDark } = useContext(ThemeContext);
    const navigate = useNavigate();

    const navigateToExplore = () => {
        navigate(ROUTES.explore);
    };

    const featuredIdeas = [
        {
            profilPictureUrl: "/img/v2/avatars/avatar-5.jpg",
            title: "BILLIE ILISH X UNIQLO",
            pseudo: "BILSH",
            descriprion:
                "Welcome to The Creature World. You have arrived in a nearby magical dimension of love, divine intervention.",
            idea: 12,
            nft: 215,
            members: 120.426,
        },
        {
            profilPictureUrl: "/img/v2/avatars/avatar-6.jpg",
            title: "M.BAY X oPTIMUS",
            pseudo: "BAYOPT",
            descriprion:
                "Welcome to The Creature World. You have arrived in a nearby magical dimension of love, divine intervention.",
            idea: 12,
            nft: 215,
            members: 120.426,
        },
        {
            profilPictureUrl: "/img/v2/avatars/avatar-7.png",
            title: "THE BPATIST",
            pseudo: "BAPTS",
            descriprion:
                "Welcome to The Creature World. You have arrived in a nearby magical dimension of love, divine intervention.",
            idea: 12,
            nft: 215,
            members: 120.426,
        },
        {
            profilPictureUrl: "/img/v2/avatars/avatar-8.png",
            title: "PEGBOARD NERDS",
            pseudo: "BILSH",
            descriprion:
                "Welcome to The Creature World. You have arrived in a nearby magical dimension of love, divine intervention.",
            idea: 12,
            nft: 215,
            members: 120.426,
        },
        {
            profilPictureUrl: "/img/v2/avatars/avatar-9.jpg",
            title: "GEOGO",
            pseudo: "BAYOPT",
            descriprion:
                "Welcome to The Creature World. You have arrived in a nearby magical dimension of love, divine intervention.",
            idea: 12,
            nft: 215,
            members: 120.426,
        },
        {
            profilPictureUrl: "/img/v2/avatars/avatar-10.png",
            title: "RAREST EXP.",
            pseudo: "RSTS",
            descriprion:
                "Welcome to The Creature World. You have arrived in a nearby magical dimension of love, divine intervention.",
            idea: 12,
            nft: 215,
            members: 120.426,
        },
    ];

    return (
        <section
            className={
                isDark
                    ? "how_it_works_container dark"
                    : "how_it_works_container"
            }
            style={{ paddingTop: 70 }}
        >
            <div className="container">
                <SectionSubtitle
                    text="FEATURED IDEAS"
                    style={{ marginBottom: 20 }}
                />
                <div className="row">
                    {featuredIdeas.map((item) => (
                        <FeaturedIdeasItem item={item} />
                    ))}
                </div>
            </div>
            <CustomButton
                text={formatMessage({
                    id: "More IDEAs",
                })}
                style={{ marginTop: 70, marginBottom: 70 }}
                onClick={navigateToExplore}
                isBlack={!isDark}
            />
        </section>
    );
};
export default FeaturedIdeas;
