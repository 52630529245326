import { gql } from "@apollo/client";

export const MY_NOTIFICATIONS = gql`
    query MyNotifications($take: Int!, $skip: Int!) {
        myNotifications(take: $take, skip: $skip) {
            notifications {
                id
                code
                variables
                createdAt
                read
            }
            totalCount
            unreadCount
        }
    }
`;
