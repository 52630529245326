import { useQuery } from "@apollo/client";
import React, { useContext } from "react";
import { useState } from "react";
import { GET_USERS } from "../../../graphql/user/query";
import { ThemeContext } from "../../../providers/Theme/ThemeProvider";
import UsersModeratorList from "./UsersModeratorList/UsersModeratorList";

const ModeratorsManagement = () => {
    const { isDark } = useContext(ThemeContext);
    const [filter, setFilter] = useState("");

    const { data } = useQuery(GET_USERS, {
        variables: { input: { filter } },
        fetchPolicy: "cache-and-network",
    });

    const users = data ? data.users : [];

    const onChangeFilter = (e) => {
        const { value } = e.target;
        setFilter(value);
    };

    return (
        <div className={isDark ? "greyscheme" : ""}>
            <form
                className="row form-dark"
                id="form_quick_search"
                name="form_quick_search"
                style={{ marginBottom: 20 }}
            >
                <div className="col">
                    <input
                        className="form-control"
                        id="name_1"
                        name="name_1"
                        placeholder="search user here..."
                        type="text"
                        value={filter}
                        onChange={onChangeFilter}
                    />
                    <button id="btn-submit" disabled>
                        <i className="fa fa-search bg-color-secondary"></i>
                    </button>
                    <div className="clearfix"></div>
                </div>
            </form>

            {users.length ? (
                <UsersModeratorList users={users} />
            ) : (
                <div>No user.</div>
            )}
        </div>
    );
};
export default ModeratorsManagement;
