import React, { createContext, useContext, useEffect, useState } from "react";
import { ethers } from "ethers";
import { GET_ME } from "../../graphql/user/query";
import createApolloClient from "../../../services/apolloClient";
import { SnackBarContext } from "../SnackBar/SnackBarProvider";
import { populateMessage } from "common";
import { OBTAIN_JWT } from "../../graphql/auth/mutation";
import { decode } from "jsonwebtoken";
import config from "../../../config/config";
import { useIntl } from "react-intl";
import { delay } from "../../utils/wallet/delay";
import LOCAL_STORAGE_KEYS from "../../constants/localStorage";
import ROUTES from "../../components/constants/routes";
import { useMutation, useQuery } from "@apollo/client";
import createGraphNodeApolloClient from "../../../services/graphNodeApolloClient";
import { GET_DEPOSIT } from "../../subgraph/deposit/query";
import contract from "../../utils/blockchain/contract";
import { BloggerAuthContext } from "../BloggerAuth/BloggerAuthProvider";

// export interface IWallet {
//     name: string;
//     provider: ethers.providers.JsonRpcProvider | ethers.providers.Web3Provider;
//     signer: ethers.Signer & TypedDataSigner;
//     address: string;
//     encryptionPublicKey?: string;
//     lastAction: number;
// }

// export interface IUserContext {
//     wallet: IWallet | null;
//     jwt: string;
//     myProfile: GetMe_me | null;
// }

// export interface IUserContextProvider {
//     userContext: IUserContext;
//     setUserContext: (p: Partial<IUserContext>) => void;
//     logout: () => void;
//     login: (wallet: string) => any;
// }

const DEFAULT_USER_CONTEXT = {
    wallet: null,
    jwt: "",
    myProfile: null,
};

export const AuthContext = createContext({
    authContext: DEFAULT_USER_CONTEXT,
    loadingAuth: false,
    isUserConnected: false,
    myDeposit: 0,
    setAuthContext: (partial) => {},
    logout: (isError) => {},
    login: () => {},
    refetchDeposit: () => {},
    isAdmin: true,
    isBlogAdmin: true,
    isModerator: true,
    setIsAdmin: () => {},
    setIsBlogAdmin: () => {},
    setIsModerator: () => {},
    checkAdminAndModerator: () => {},
});

const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
const client = createApolloClient(token);
const graphNodeClient = createGraphNodeApolloClient();

const AuthProvider = ({ children }) => {
    const { displaySnackBar } = useContext(SnackBarContext);
    const { isBloggerConnected } = useContext(BloggerAuthContext);
    const { formatMessage } = useIntl();

    const [authContext, setAuthContext] = useState(DEFAULT_USER_CONTEXT);
    const [loadingAuth, setLoadingAuth] = useState(false);
    const [isAdmin, setIsAdmin] = useState(true);
    const [isModerator, setIsModerator] = useState(true);

    const _setAuthContext = (partial) => {
        setAuthContext((current) => ({ ...current, ...partial }));
    };

    const logout = (isError) => {
        setAuthContext(DEFAULT_USER_CONTEXT);
        localStorage.removeItem(LOCAL_STORAGE_KEYS.ADDRESS);
        localStorage.removeItem(LOCAL_STORAGE_KEYS.WALLET);
        localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN);
        setIsAdmin(false);
        setIsModerator(false);

        if (!isError) {
            displaySnackBar({
                message: formatMessage({
                    id: "auth.signout.message.success",
                }),
                type: "success",
            });
        }
    };

    const authError = (err) => {
        setLoadingAuth(false);
        if (err) {
            console.error("Error == ", err);
        }

        logout(true);
        if (err && err.message && err?.message?.includes("missing provider")) {
            displaySnackBar({
                message: "Missing provider, please install Metamask 🦊",
                type: "error",
            });
        } else if (err && err.code === 4100) {
            displaySnackBar({
                message: formatMessage({
                    id: "auth.metamask.signin.locked.message.error",
                }),
                type: "error",
            });
        } else {
            displaySnackBar({
                message:
                    err?.message ||
                    formatMessage({
                        id: "auth.metamask.signin.message.error",
                    }),
                type: "error",
            });
        }
    };

    const authSuccess = () => {
        setLoadingAuth(false);
        window.history.replaceState({}, "home", ROUTES.home);
        window.location.reload();
        displaySnackBar({
            message: formatMessage({
                id: "auth.metamask.signin.message.success",
            }),
            type: "success",
        });
    };

    const { loading: loadingGetMe } = useQuery(GET_ME, {
        client,
        skip: !token,
        // Check every 1 hour
        pollInterval: 3600 * 1 * 1000,
        onCompleted: (data) => {
            if (data && data.me && window.ethereum) {
                _setAuthContext({
                    jwt: token || undefined,
                    myProfile: data.me,
                });
            } else {
                logout();
            }
        },
        onError: (error) => {
            console.log("error : ", error);
            if (token) {
                setLoadingAuth(false);
                logout(true);
                displaySnackBar({
                    message:
                        "Your token has expired, please reconnect with your wallet.",
                    type: "error",
                });
            }
        },
    });

    const { data, refetch: refetchDeposit } = useQuery(GET_DEPOSIT, {
        client: graphNodeClient,
        skip: !authContext?.myProfile,
        variables: { id: authContext?.myProfile?.id?.toLowerCase() },
    });

    const myCurrentDeposit = Number(
        ethers.utils.formatEther(data?.deposit?.amount || "0")
    );

    const [getJWT, { loading: loadingGetJWT }] = useMutation(OBTAIN_JWT, {
        client,
    });

    const handleConnect = (walletName) => {
        if (walletName === "metamask") {
            return async () => {
                let address = "";
                try {
                    const provider = new ethers.providers.Web3Provider(
                        window.ethereum
                    );
                    console.log("provider ******* ", provider);
                    await provider._ready;
                    await delay(500);
                    provider.on("accountsChanged", (_account) => {
                        logout(true);
                        handleConnect("metamask")();
                    });

                    const accounts = await provider.send(
                        "eth_requestAccounts",
                        []
                    );
                    const firstAccount = accounts[0];

                    console.log("firstAccount ---------- ", firstAccount);

                    const signer = provider.getSigner(firstAccount);
                    await delay(500);
                    address = await signer.getAddress();

                    localStorage.setItem(LOCAL_STORAGE_KEYS.ADDRESS, address);
                    localStorage.setItem(LOCAL_STORAGE_KEYS.WALLET, walletName);
                    _setAuthContext({
                        jwt: token || undefined,
                        wallet: {
                            name: walletName,
                            signer,
                            provider,
                            address,
                            lastAction: new Date().getTime(),
                        },
                    });
                } catch (error) {
                    authError(error);
                }
                return address;
            };
        } else {
            logout(true);
            displaySnackBar({
                message: formatMessage({
                    id: "auth.metamask.unsupported.wallet.error",
                }),
                type: "error",
            });
            throw Error("Unsupported wallet type");
        }
    };

    const authenticate = async () => {
        const { wallet } = authContext;
        if (wallet !== null) {
            if (wallet.name === "metamask") {
                try {
                    const message = populateMessage({
                        date: new Date(),
                        website: config.app.name,
                        address: wallet.address,
                    });
                    const signature = await wallet.signer.signMessage(message);
                    setLoadingAuth(true);
                    getJWT({ variables: { signature, message } })
                        .then((res) => {
                            if (res && res.data && res.data.jwt) {
                                const { access } = res.data.jwt;
                                localStorage.setItem(
                                    LOCAL_STORAGE_KEYS.TOKEN,
                                    access
                                );
                                _setAuthContext({ jwt: access });
                                authSuccess();
                            }
                        })
                        .catch((error) => authError(error));
                } catch (error) {
                    authError(error);
                }
            }
        }
    };

    useEffect(() => {
        if (authContext?.wallet?.provider?.provider) {
            authContext?.wallet?.provider?.provider?.on(
                "accountsChanged",
                (_account) => {
                    logout(true);
                    handleConnect("metamask")();
                }
            );
        }
    }, [authContext?.wallet?.provider]);

    useEffect(() => {
        (async () => {
            const { wallet } = authContext;
            const newToken = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
            if (newToken) {
                if (wallet === null) {
                    const { address } = decode(newToken);
                    const _wallet = localStorage.getItem(
                        LOCAL_STORAGE_KEYS.WALLET
                    );

                    if (_wallet !== null) {
                        const addr = await handleConnect(_wallet)();
                        try {
                            if (
                                ethers.utils.getAddress(address) !==
                                ethers.utils.getAddress(addr)
                            ) {
                                logout(true);
                            }
                        } catch (error) {
                            console.log("error ==== ", error);
                            logout(true);
                        }
                    }
                }
            } else {
                if (wallet !== null) {
                    await authenticate();
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authContext.wallet]);

    const loadingAllAuth = loadingAuth || loadingGetJWT || loadingGetMe;

    const checkAdminAndModerator = () => {
        if (authContext?.myProfile?.id && authContext?.wallet?.signer) {
            const contractAddress = contract?.contracts?.IdealNFT?.address;
            const contractAbi = contract?.contracts?.IdealNFT?.abi;
            let contractInstance = new ethers.Contract(
                contractAddress,
                contractAbi
            );

            if (contractInstance) {
                contractInstance = authContext?.wallet?.signer
                    ? contractInstance.connect(authContext?.wallet?.signer)
                    : null;

                try {
                    contractInstance?.admin().then((admin) => {
                        if (
                            authContext?.myProfile?.id &&
                            authContext?.myProfile?.id?.toLowerCase() ===
                                admin?.toLowerCase()
                        ) {
                            setIsAdmin(true);
                        } else {
                            setIsAdmin(false);
                        }
                    });
                } catch (error) {
                    setIsAdmin(false);
                }

                try {
                    contractInstance
                        ?.moderators(authContext?.myProfile?.id)
                        .then((isModeratorRes) => {
                            setIsModerator(isModeratorRes);
                        });
                } catch (error) {
                    setIsModerator(false);
                }
            }
        }
    };

    return (
        <AuthContext.Provider
            value={{
                authContext,
                loadingAuth: loadingAllAuth,
                setAuthContext: _setAuthContext,
                logout,
                login: handleConnect,
                isUserConnected: !!authContext?.myProfile,
                myDeposit: myCurrentDeposit,
                refetchDeposit,
                isAdmin: isAdmin && token,
                isBlogAdmin: isBloggerConnected,
                isModerator: isModerator && token,
                setIsAdmin,
                setIsBlogAdmin: setIsAdmin,
                setIsModerator,
                checkAdminAndModerator,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
