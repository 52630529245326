import ROUTES from "../../components/constants/routes";

export const CATALOG_ITEMS = [
    { label: "title.catalog.subtitle.explore", path: ROUTES.explore },
    {
        label: "title.catalog.subtitle.popular.items",
        path: `${ROUTES.home}?section=popularItems`,
    },
    {
        label: "title.catalog.subtitle.top.sellers",
        path: `${ROUTES.home}?section=topSellers`,
    },
    {
        label: "title.catalog.subtitle.activity",
        path: ROUTES.activity,
    },
];

export const MY_PORTFOLIO_ITEMS = [
    { label: "title.my.portfolio.subtitle.create", path: ROUTES.createNFT },
    {
        label: "title.my.portfolio.subtitle.my.created",
        path: `${ROUTES.myProfile}?tab=created`,
    },
    {
        label: "title.my.portfolio.subtitle.ownership",
        path: `${ROUTES.myProfile}?tab=ownership`,
    },
    {
        label: "title.my.portfolio.subtitle.coownership",
        path: `${ROUTES.myProfile}?tab=coownership`,
    },
];

export const COMMUNITY_ITEMS = [
    { label: "Blogs", path: ROUTES.blogs },
    { label: "Help Center", path: ROUTES.helpCenter },
    { label: "Newsletter", path: ROUTES.newsletter },
    { label: "title.community.subtitle.pro", path: "/" },
    { label: "title.community.subtitle.advanced", path: "/" },
    { label: "title.community.subtitle.beginner", path: "/" },
];
