import { gql } from "@apollo/client";

export const BLOG_FRAGMENT = gql`
    fragment BlogFields on Blog {
        id
        title
        picture
        description
        aboutUsDescription
        date
        priority
        tags
    }
`;
