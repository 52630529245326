import { useQuery } from "@apollo/client";
import React, { createContext } from "react";
import { GET_MAILLIST_QUERY } from "../../graphql/newsletter/query";

export const NewsletterContext = createContext({
    listUuid: undefined,
});

const NewsletterProvider = ({ children }) => {
    const { data } = useQuery(GET_MAILLIST_QUERY);

    const listUuid =
        data?.getMailingLists && data?.getMailingLists[0]
            ? data?.getMailingLists[0]
            : undefined;

    return (
        <NewsletterContext.Provider
            value={{
                listUuid,
            }}
        >
            {children}
        </NewsletterContext.Provider>
    );
};

export default NewsletterProvider;
