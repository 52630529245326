import { Box, createStyles, makeStyles } from "@material-ui/core";
import { useContext } from "react";
import COLORS from "../../constants/colors";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";

const IdealSlogan = ({ forceDark, forceLight }) => {
    const { isDark: isDarkContext } = useContext(ThemeContext);

    const isDark = forceDark ? true : forceLight ? false : isDarkContext;

    const useStyles = makeStyles((theme) =>
        createStyles({
            main: {
                letterSpacing: 7,
                display: "flex",
                fontSize: 12,
                color: isDark ? COLORS.v2.white : COLORS.v2.black_grey,
                marginTop: 15,
                marginBottom: 15,
            },
            separator: {
                color: COLORS.v2.green,
                marginRight: 20,
                marginLeft: 20,
            },
        })
    );
    const classes = useStyles();

    return (
        <Box className={classes.main}>
            {`DREAM   ALWAYS`}
            <Box className={classes.separator}>|</Box>
            {`IDEAL  EXCHANGE`}
        </Box>
    );
};

export default IdealSlogan;
