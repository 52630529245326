import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useQuery } from "@apollo/client";
import { GET_NFT } from "../../graphql/nft/query";
import CustomLoader from "../../components/customLoader/CustomLoader";
import CustomButton from "../../components/button/CustomButton";
import { AuthContext } from "../../providers/Auth/AuthProvider";
import { useLocation, useNavigate } from "@reach/router";
import ROUTES from "../../components/constants/routes";
import getImagePreview from "../../utils/nft/getImagePreview";
import config from "../../../config/config";
import LOCAL_STORAGE_KEYS from "../../constants/localStorage";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";
import NFTAuthorPicture from "../../components/nftCard/authorPicture/NFTAuthorPicture";
import { ethers } from "ethers";
import { SnackBarContext } from "../../providers/SnackBar/SnackBarProvider";
import contract from "../../utils/blockchain/contract";
import { NetworkContext } from "../../providers/NetTester/NetTesterProvider";
import { getIpId, encodeData } from "common";
import { GraphNodeClientContext } from "../../providers/Apollo/ApolloProvider";
import { GET_SUBGRAPH_NFT } from "../../subgraph/nft/query";
import SaleOrOfferItemList from "../../components/saleOrOfferItemList/SaleOrOfferItemList";
import Footer from "../../components/footer/Footer";
import TabMenu from "./menu/TabMenu";
import NFTDetailsContent from "./contents/details/NFTDetailsContent";
import NFTShareHoldersContent from "./contents/shareHolders/NFTShareHoldersContent";
import CustomAddress from "../../components/address/CustomAddress";
import { formatNumber } from "../../utils/string/formatNumber";
import CustomGGLandDialog from "../../components/dialog/CustomGGLandDialog";
import NFTLikesList from "../../components/nftLike/NFTLikesList";
import LikeButton from "../../components/nftLike/button/LikeButton";
import { Tooltip } from "@material-ui/core";
import { parse } from "query-string";
import NFTSellForm from "../nftSell/form/NFTSellForm";
import NFTOfferForm from "../nftOffer/form/NFTOfferForm";
import { GET_USER } from "../../graphql/user/query";
import { useIntl } from "react-intl";
import NFTActivityItem from "../../components/activityItem/NFTActivityItem";
import SaleOrOfferFilter from "./filters/SaleOrOfferFilter";
import ActivityFilter from "./filters/ActivityFilter";
import { FONT_SIZES } from "../../constants/sizes";
import { defaultValidUntil } from "../../constants/blockchain";
import Clock from "../../components/Clock";
import USER_ROLES from "../../components/constants/userRoles";

const NFTDetail = ({ cid }) => {
    const { formatMessage } = useIntl();
    const { authContext, isAdmin, isModerator } = useContext(AuthContext);
    const { myProfile } = authContext;
    const { graphNodeClient } = useContext(GraphNodeClientContext);
    const location = useLocation();
    const searchParams = parse(location.search);

    const [nftUrl, setNftUrl] = useState(undefined);
    const [menu, setMenu] = useState(searchParams?.tab || "details");
    const navigate = useNavigate();
    const { isDark } = useContext(ThemeContext);
    const { displaySnackBar } = useContext(SnackBarContext);
    const { isNetworkMismatched, isUnsupportedNetwork } =
        useContext(NetworkContext);
    const [mintedNft, setMintedNft] = useState(null);
    const [loadingMint, setLoadingMint] = useState(false);
    const [showLikesList, setShowLikesList] = useState(false);
    const [showSellForm, setShowSellForm] = useState(false);
    const [showOfferForm, setShowOfferForm] = useState(false);
    const [offerProposedShareHolder, setOfferProposedShareHolder] =
        useState(null);
    const [saleOfferFilter, setSaleOfferFilter] = useState({
        value: "active",
        label: "Active",
    });
    const [activityFilter, setActivityFilter] = useState({
        value: undefined,
        label: "All",
    });
    const [userRole, setUserRole] = useState(USER_ROLES.normalUser);
    const [denierRole, setDenierRole] = useState(USER_ROLES.normalUser);

    const handleChangeSaleOfferFilter = (value) => {
        setSaleOfferFilter(value);
    };

    const handleChangeActivityFilter = (value) => {
        setActivityFilter(value);
    };

    const wallet = authContext?.wallet;
    const signer = wallet?.signer;
    const provider = wallet?.provider;

    const handleClickMenu = (clickedMenu) => {
        setMenu(clickedMenu);
        window.history.replaceState(
            {},
            `${ROUTES.nftDetail}/${cid}`,
            `${ROUTES.nftDetail}/${cid}?tab=${clickedMenu}`
        );
    };

    const navigateToUpdate = () => {
        navigate(`${ROUTES.nftUpdate}/${cid}`);
    };

    const { data: dataShareHolderOffer } = useQuery(GET_USER, {
        variables: { id: offerProposedShareHolder },
        skip: !offerProposedShareHolder,
    });

    const shareHolderUser = dataShareHolderOffer?.getUser;

    const nftErc20Id = getIpId({
        IdealNFTAddress: contract?.contracts?.IdealNFT?.address,
        ip: {
            contentType: 1,
            content: ethers.utils.toUtf8Bytes(cid),
        },
        chainId: contract?.chainId,
    });

    const { loading: loadingGraphnode, refetch } = useQuery(GET_SUBGRAPH_NFT, {
        fetchPolicy: "cache-and-network",
        variables: {
            id: nftErc20Id,
            activityWhere: { activity: activityFilter?.value },
        },
        client: graphNodeClient,
        pollInterval: 15 * 1000,
        onCompleted: (data) => {
            if (data && data.nfterc20) {
                setMintedNft(data.nfterc20);
            }
        },
    });

    const currentUserBalance = mintedNft?.balances?.find(
        (balance) =>
            balance?.owner?.address?.toLowerCase() ===
            myProfile?.id?.toLowerCase()
    );

    const currentUserBalanceAmount = Number(
        ethers.utils.formatEther(currentUserBalance?.amount || "0")
    );

    const { data, loading } = useQuery(GET_NFT, {
        variables: { id: cid, saleOfferFilter: saleOfferFilter.value },
        fetchPolicy: "cache-and-network",
    });

    const extension =
        data && data.getIp && data.getIp.mimetype ? data.getIp.mimetype : "";

    const Authorization = `Bearer ${localStorage.getItem(
        LOCAL_STORAGE_KEYS.TOKEN
    )}`;

    useEffect(() => {
        if (cid) {
            axios
                .get(`${config.nft.ipsUrl}/${cid}`, {
                    headers: {
                        Authorization,
                    },
                    responseType: "blob",
                })
                .then((res) => {
                    const blobURL = URL.createObjectURL(res.data);
                    setNftUrl(blobURL);
                });
        }
    }, [Authorization, cid, data]);

    const nft = data ? data.getIp : undefined;
    const nftCategories = nft?.categories || [];

    const permitterId = nft?.permit?.moderator?.id;

    useEffect(() => {
        if (authContext?.wallet?.signer && permitterId) {
            const contractAddressIdeal = contract?.contracts?.IdealNFT?.address;
            const contractAbiIdeal = contract?.contracts?.IdealNFT?.abi;
            let contractInstance = new ethers.Contract(
                contractAddressIdeal,
                contractAbiIdeal
            );

            contractInstance = authContext?.wallet?.signer
                ? contractInstance.connect(authContext?.wallet?.signer)
                : null;

            if (contractInstance) {
                contractInstance?.admin().then((admin) => {
                    if (permitterId?.toLowerCase() === admin?.toLowerCase()) {
                        setUserRole(USER_ROLES.admin);
                    }
                });

                contractInstance
                    ?.moderators(permitterId)
                    .then((isModeratorRes) => {
                        if (isModeratorRes === true) {
                            setUserRole(USER_ROLES.moderator);
                        }
                    });
            }
        }
    }, [authContext?.wallet?.signer, permitterId]);

    const denierId = nft?.deny?.moderator?.id;

    useEffect(() => {
        if (authContext?.wallet?.signer && denierId) {
            const contractAddressIdeal = contract?.contracts?.IdealNFT?.address;
            const contractAbiIdeal = contract?.contracts?.IdealNFT?.abi;
            let contractInstance = new ethers.Contract(
                contractAddressIdeal,
                contractAbiIdeal
            );

            contractInstance = authContext?.wallet?.signer
                ? contractInstance.connect(authContext?.wallet?.signer)
                : null;

            if (contractInstance) {
                contractInstance?.admin().then((admin) => {
                    if (denierId?.toLowerCase() === admin?.toLowerCase()) {
                        setDenierRole(USER_ROLES.admin);
                    }
                });

                contractInstance
                    ?.moderators(denierId)
                    .then((isModeratorRes) => {
                        if (isModeratorRes === true) {
                            setDenierRole(USER_ROLES.moderator);
                        }
                    });
            }
        }
    }, [authContext?.wallet?.signer, denierId]);

    const permit = nft?.permit;
    const isPermitted = nft?.isPermitted;
    const isPermitExpired = nft?.permit?.isExpired;

    const graphnodeTotalShares = mintedNft?.totalShares;
    const graphqlTotalShares = nft?.shareCount || 0;
    const totalShares = graphnodeTotalShares
        ? ethers.utils.formatEther(graphnodeTotalShares)
        : ethers.utils.formatEther(graphqlTotalShares);

    const createdByMe = nft?.createdByMe;

    const handleMint = async () => {
        if (isNetworkMismatched || isUnsupportedNetwork) {
            displaySnackBar({
                message: formatMessage({
                    id: "net.issue.block.transaction",
                }),
                type: "error",
            });
            return;
        }

        const contractAddress = contract?.contracts?.IdealNFT?.address;
        const contractAbi = contract?.contracts?.IdealNFT?.abi;
        let contractInstance = new ethers.Contract(
            contractAddress,
            contractAbi
        );
        contractInstance = contractInstance.connect(signer);

        try {
            setLoadingMint(true);

            const _data = encodeData({
                title: nft?.title || "",
                description: nft?.description || "",
                externalLink: nft?.externalLink || "",
                royalties: nft?.royalties || 0,
            });

            console.log("_data ==== ", _data);

            const _permit = {
                moderator: permit?.moderator?.id,
                user: permit?.user?.id,
                tokenId: nftErc20Id,
                blockHeight: permit?.blockHeight,
                deadline: permit?.deadline,
                salt: permit?.salt,
            };

            console.log("_permit === ", _permit);

            const _permitSignature = permit?.signature;
            console.log("_permitSignature ===== ", _permitSignature);

            const shares = nft?.shareCount || 0;
            let mintTransaction = await contractInstance.permit(
                1,
                ethers.utils.toUtf8Bytes(cid),
                shares,
                "",
                "",
                _data,
                _permit,
                _permitSignature
            );

            await mintTransaction.wait();

            const mintReceipt = await provider.getTransactionReceipt(
                mintTransaction.hash
            );

            console.log(`Mint receipt ===== `, mintReceipt);

            if (mintReceipt) {
                console.log("********** minted ************");
                setTimeout(() => {
                    refetch();
                    setLoadingMint(false);
                }, 3000);

                displaySnackBar({
                    message: "NFT minted successfully",
                    type: "success",
                });
            }
        } catch (error) {
            console.log(`error : `, error);
            setLoadingMint(false);

            if (
                error &&
                error.message &&
                error.message === "Internal JSON-RPC error."
            ) {
                displaySnackBar({
                    message: "You have already minted this NFT.",
                    type: "error",
                });
            } else {
                displaySnackBar({
                    message: "Error occured while minting the NFT",
                    type: "error",
                });
            }
        }
    };

    const balances = mintedNft?.balances || [];

    const shareHolders =
        mintedNft?.balances?.map((bal) => bal?.owner?.address) || [];

    const userIsTheOnlyShareHolder =
        balances?.length === 1 &&
        balances[0]?.owner?.address?.toLowerCase() ===
            myProfile?.id?.toLowerCase();

    const sell = () => {
        // navigate(`${ROUTES.nftSell}/${cid}`);
        setShowSellForm(true);
    };

    const makeOffer = () => {
        // navigate(`${ROUTES.nftOffer}/${cid}`);
        setShowOfferForm(true);
    };

    const proposeAnOffer = (shareHolder) => {
        setOfferProposedShareHolder(shareHolder);
        setShowOfferForm(true);
    };

    const handleCloseSellForm = () => {
        setShowSellForm(false);
    };

    const handleCloseOfferForm = () => {
        setOfferProposedShareHolder(null);
        setShowOfferForm(false);
    };

    const contractAddress = contract?.contracts?.IdealMarketplace?.address;
    const contractAbi = contract?.contracts?.IdealMarketplace?.abi;
    let idealMarketplaceContractInstance = new ethers.Contract(
        contractAddress,
        contractAbi
    );
    idealMarketplaceContractInstance =
        idealMarketplaceContractInstance.connect(signer);

    return (
        <div className={isDark ? "greyscheme" : ""}>
            {(loading || loadingGraphnode || loadingMint) && (
                <CustomLoader
                    text={loadingMint ? "Proceeding to Mint..." : undefined}
                />
            )}

            <section className="container">
                <div className="row mt-md-5 pt-md-4">
                    {/* ------------------ NFT Image start ------------------ */}
                    <div
                        className="col-md-6 text-center"
                        style={{ height: "fit-content", width: "40%" }}
                    >
                        <img
                            src={getImagePreview({
                                extension,
                                imageUrl: nftUrl,
                            })}
                            className="img-fluid img-rounded mb-sm-30"
                            alt=""
                        />
                    </div>
                    {/* ------------------ NFT Image end ------------------ */}

                    <div className="col-md-6" style={{ width: "60%" }}>
                        <div className="item_info">
                            {/* Auctions ends in
                            <div className="de_countdown">
                                <Clock deadline="December, 30, 2021" />
                            </div> */}

                            {/* ------------------ Infos start ------------------ */}
                            <h2>{mintedNft ? mintedNft?.title : nft?.title}</h2>
                            <div className="nft__item_like">
                                <LikeButton
                                    cid={nft?.cid}
                                    likedByMe={nft?.likedByMe}
                                    refetchQueries={[
                                        {
                                            query: GET_NFT,
                                            variables: {
                                                id: cid,
                                                saleOfferFilter:
                                                    saleOfferFilter.value,
                                            },
                                        },
                                    ]}
                                    style={{
                                        fontSize: 25,
                                        cursor: "pointer",
                                    }}
                                />
                            </div>

                            <CustomAddress
                                address={mintedNft?.id}
                                maxWidth={400}
                            />

                            {/* <div className="nft__item_price">
                                {nft?.price || 0} ETH
                                <span>1/20</span>
                            </div> */}
                            <div className="item_info_counts">
                                {nftCategories?.map((category) => (
                                    <div className="item_info_type">
                                        <i className="fa fa-image"></i>
                                        {category?.name}
                                    </div>
                                ))}
                                <div className="item_info_views">
                                    <i className="fa fa-eye"></i>0
                                </div>
                                <Tooltip title="View likes">
                                    <div
                                        className="item_info_like"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setShowLikesList(true)}
                                    >
                                        <i className="fa fa-heart"></i>
                                        {nft?.likesCount}
                                    </div>
                                </Tooltip>
                            </div>
                            <p>
                                {mintedNft
                                    ? mintedNft?.description
                                    : nft?.description}
                            </p>
                            <h6>Creator</h6>
                            <div className="item_author">
                                <NFTAuthorPicture
                                    address={nft?.user?.id}
                                    src={nft?.user?.profilePictureUri}
                                />
                                <div
                                    className="author_list_info"
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        paddingTop: nft?.user?.userName && 0,
                                    }}
                                >
                                    {nft?.user?.userName && (
                                        <span>{nft?.user?.userName}</span>
                                    )}

                                    <CustomAddress address={nft?.user?.id} />
                                </div>
                            </div>

                            {nft?.permit && (
                                <div style={{ marginTop: 20 }}>
                                    <h6>Permitted by</h6>
                                    <div className="item_author">
                                        <NFTAuthorPicture
                                            address={nft?.permit?.moderator?.id}
                                            src={
                                                nft?.permit?.moderator
                                                    ?.profilePictureUri
                                            }
                                        />
                                        <div
                                            className="author_list_info"
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                paddingTop:
                                                    nft?.permit?.moderator
                                                        ?.userName && 0,
                                            }}
                                        >
                                            {nft?.permit?.moderator
                                                ?.userName && (
                                                <span>
                                                    {
                                                        nft?.permit?.moderator
                                                            ?.userName
                                                    }
                                                </span>
                                            )}

                                            <CustomAddress
                                                address={
                                                    nft?.permit?.moderator?.id
                                                }
                                            />
                                            <div
                                                style={{
                                                    fontSize: 16,
                                                    color: "#727272",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {userRole}
                                            </div>
                                            {!mintedNft &&
                                                !nft?.permit?.isExpired &&
                                                nft?.permit?.deadline &&
                                                nft?.permit?.deadline <
                                                    defaultValidUntil && (
                                                    <span>
                                                        Expired in
                                                        <div className="de_countdown">
                                                            <Clock
                                                                deadline={
                                                                    new Date(
                                                                        nft?.permit?.validUntil
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </span>
                                                )}
                                            {!mintedNft &&
                                                nft?.permit?.isExpired && (
                                                    <span>Expired</span>
                                                )}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {nft?.deny && (
                                <div style={{ marginTop: 20 }}>
                                    <h6>Denied by</h6>
                                    <div className="item_author">
                                        <NFTAuthorPicture
                                            address={nft?.deny?.moderator?.id}
                                            src={
                                                nft?.deny?.moderator
                                                    ?.profilePictureUri
                                            }
                                        />
                                        <div
                                            className="author_list_info"
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                paddingTop:
                                                    nft?.deny?.moderator
                                                        ?.userName && 0,
                                            }}
                                        >
                                            {nft?.deny?.moderator?.userName && (
                                                <span>
                                                    {
                                                        nft?.deny?.moderator
                                                            ?.userName
                                                    }
                                                </span>
                                            )}

                                            <CustomAddress
                                                address={
                                                    nft?.deny?.moderator?.id
                                                }
                                            />
                                            <div
                                                style={{
                                                    fontSize: 16,
                                                    color: "#727272",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {denierRole}
                                            </div>

                                            {!mintedNft &&
                                                nft?.permit?.isExpired && (
                                                    <span></span>
                                                )}
                                            {nft?.deny?.publicReason && (
                                                <div>
                                                    Reason :
                                                    <span
                                                        style={{
                                                            fontSize: 16,
                                                            color: "red",
                                                            fontWeight: "bold",
                                                            marginLeft: 10,
                                                        }}
                                                    >
                                                        {
                                                            nft?.deny
                                                                ?.publicReason
                                                        }
                                                    </span>
                                                </div>
                                            )}
                                            {nft?.deny?.internalReason &&
                                                (isAdmin || isModerator) && (
                                                    <div>
                                                        Internal reason :
                                                        <span
                                                            style={{
                                                                fontSize: 16,
                                                                color: "red",
                                                                fontWeight:
                                                                    "bold",
                                                                marginLeft: 10,
                                                            }}
                                                        >
                                                            {
                                                                nft?.deny
                                                                    ?.internalReason
                                                            }
                                                        </span>
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="spacer-40"></div>
                            <div
                                className="p_list_info"
                                style={{
                                    paddingLeft: 0,
                                    marginBottom: 20,
                                }}
                            >
                                <span style={{ marginBottom: 10 }}>
                                    Total :{" "}
                                    <b>{formatNumber(Number(totalShares))}</b>{" "}
                                    shares (
                                    {mintedNft ? "Minted" : "Not minted"})
                                </span>
                                {currentUserBalance && mintedNft && (
                                    <span>
                                        You own{" "}
                                        <b>
                                            {(currentUserBalanceAmount * 100) /
                                                totalShares}{" "}
                                            %
                                        </b>{" "}
                                        of shares
                                    </span>
                                )}
                            </div>
                            {/* ------------------ Infos end ------------------ */}

                            <div className="de_tab">
                                {/* ------------------ Menu start ------------------ */}
                                <ul className="de_nav">
                                    <TabMenu
                                        text="Details"
                                        isActive={menu === "details"}
                                        handleClickMenu={() =>
                                            handleClickMenu("details")
                                        }
                                    />
                                    <TabMenu
                                        text={`Share holders (${
                                            shareHolders?.length || 0
                                        })`}
                                        isActive={menu === "shareHolders"}
                                        handleClickMenu={() =>
                                            handleClickMenu("shareHolders")
                                        }
                                    />
                                    <TabMenu
                                        text={`Sales (${
                                            nft?.sales?.length || 0
                                        })`}
                                        isActive={menu === "sales"}
                                        handleClickMenu={() =>
                                            handleClickMenu("sales")
                                        }
                                    />
                                    <TabMenu
                                        text={`Offers (${
                                            nft?.offers?.length || 0
                                        })`}
                                        isActive={menu === "offers"}
                                        handleClickMenu={() =>
                                            handleClickMenu("offers")
                                        }
                                    />
                                    <TabMenu
                                        text={`Activity`}
                                        isActive={menu === "activity"}
                                        handleClickMenu={() =>
                                            handleClickMenu("activity")
                                        }
                                    />
                                </ul>
                                {/* ------------------ Menu end ------------------ */}

                                <div className="de_tab_content">
                                    {/* ------------------ Filters start ------------------ */}
                                    {(menu === "sales" ||
                                        menu === "offers") && (
                                        <SaleOrOfferFilter
                                            value={saleOfferFilter}
                                            handleChangeValue={
                                                handleChangeSaleOfferFilter
                                            }
                                        />
                                    )}

                                    {menu === "activity" && (
                                        <ActivityFilter
                                            value={activityFilter}
                                            handleChangeValue={
                                                handleChangeActivityFilter
                                            }
                                        />
                                    )}
                                    {/* ------------------ Filters end ------------------ */}

                                    {/* ------------------ Contents start ------------------ */}
                                    {menu === "details" && (
                                        <NFTDetailsContent
                                            nft={nft}
                                            mintedNft={mintedNft}
                                        />
                                    )}

                                    {menu === "shareHolders" && (
                                        <NFTShareHoldersContent
                                            cid={cid}
                                            totalShares={totalShares}
                                            balances={balances}
                                            proposeAnOffer={proposeAnOffer}
                                        />
                                    )}

                                    {menu === "sales" && (
                                        <div className="tab-2 onStep fadeIn">
                                            {nft?.sales?.length ? (
                                                nft?.sales?.map((sale) => (
                                                    <SaleOrOfferItemList
                                                        key={sale.id}
                                                        saleOrOfferJSON={
                                                            sale?.sale
                                                        }
                                                        saleOrOffer={sale}
                                                        user={sale?.seller}
                                                        contractInstance={
                                                            idealMarketplaceContractInstance
                                                        }
                                                        shareHolders={
                                                            shareHolders
                                                        }
                                                        refetch={refetch}
                                                    />
                                                ))
                                            ) : (
                                                <div className="p_list">
                                                    No sale
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    {menu === "offers" && (
                                        <div className="tab-2 onStep fadeIn">
                                            {nft?.offers?.length ? (
                                                nft?.offers?.map((offer) => (
                                                    <SaleOrOfferItemList
                                                        key={offer.id}
                                                        saleOrOfferJSON={
                                                            offer?.offer
                                                        }
                                                        saleOrOffer={offer}
                                                        user={offer?.user}
                                                        contractInstance={
                                                            idealMarketplaceContractInstance
                                                        }
                                                        shareHolders={
                                                            shareHolders
                                                        }
                                                        refetch={refetch}
                                                    />
                                                ))
                                            ) : (
                                                <div className="p_list">
                                                    No offer
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    {menu === "activity" && (
                                        <div className="tab-2 onStep fadeIn">
                                            {mintedNft?.activities?.length ? (
                                                mintedNft?.activities?.map(
                                                    (activity) => (
                                                        <NFTActivityItem
                                                            activity={activity}
                                                        />
                                                    )
                                                )
                                            ) : (
                                                <div className="p_list">
                                                    No activity
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {/* ------------------ Contents end ------------------ */}
                                </div>
                                {/* ------------------ Buttons start ------------------ */}
                                {wallet && (
                                    <div style={{ display: "flex" }}>
                                        {createdByMe && (
                                            <div style={{ display: "flex" }}>
                                                <CustomButton
                                                    text="Update"
                                                    onClick={navigateToUpdate}
                                                    disabled={loading}
                                                    style={{
                                                        marginTop: 25,
                                                        marginRight: 25,
                                                    }}
                                                />
                                                {!mintedNft && (
                                                    <CustomButton
                                                        text="Proceed to Mint"
                                                        onClick={handleMint}
                                                        disabled={
                                                            loading ||
                                                            !isPermitted
                                                        }
                                                        style={{
                                                            marginTop: 25,
                                                            marginRight: 25,
                                                        }}
                                                    />
                                                )}
                                                {!mintedNft &&
                                                    !permit &&
                                                    !nft?.isDenied && (
                                                        <div
                                                            style={{
                                                                fontSize:
                                                                    FONT_SIZES
                                                                        .common
                                                                        .small,
                                                                color: "#EB5757",
                                                                marginTop: 25,
                                                            }}
                                                        >
                                                            Your NFT is being
                                                            analyzed by
                                                            admin/moderators
                                                            before you can mint.
                                                        </div>
                                                    )}
                                                {!mintedNft &&
                                                    permit &&
                                                    isPermitExpired && (
                                                        <div
                                                            style={{
                                                                fontSize:
                                                                    FONT_SIZES
                                                                        .common
                                                                        .small,
                                                                color: "#EB5757",
                                                                marginTop: 25,
                                                            }}
                                                        >
                                                            Your permission to
                                                            mint has expired
                                                        </div>
                                                    )}
                                                {!mintedNft && nft?.isDenied && (
                                                    <div
                                                        style={{
                                                            fontSize:
                                                                FONT_SIZES
                                                                    .common
                                                                    .small,
                                                            color: "#EB5757",
                                                            marginTop: 25,
                                                        }}
                                                    >
                                                        Your NFT has been denied
                                                        by admin/moderators and
                                                        can not be minted.
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        {mintedNft && (
                                            <div style={{ display: "flex" }}>
                                                {!userIsTheOnlyShareHolder && (
                                                    <CustomButton
                                                        text="Make an offer"
                                                        onClick={makeOffer}
                                                        disabled={!mintedNft}
                                                        style={{
                                                            marginTop: 25,
                                                            marginRight: 25,
                                                        }}
                                                    />
                                                )}
                                                {currentUserBalance && (
                                                    <CustomButton
                                                        text="Make a sale"
                                                        onClick={sell}
                                                        disabled={!mintedNft}
                                                        style={{
                                                            marginTop: 25,
                                                            marginRight: 25,
                                                        }}
                                                    />
                                                )}
                                                {currentUserBalance && (
                                                    <CustomButton
                                                        text="Transfer"
                                                        disabled={!mintedNft}
                                                        style={{
                                                            marginTop: 25,
                                                            marginRight: 25,
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </div>
                                )}
                                {/* ------------------ Buttons end ------------------ */}
                            </div>
                        </div>
                    </div>
                </div>

                {showLikesList && (
                    <CustomGGLandDialog
                        title="Likes..."
                        handleClose={() => setShowLikesList(false)}
                        noSubmit
                    >
                        <NFTLikesList cid={nft?.cid} />
                    </CustomGGLandDialog>
                )}
                {showSellForm && (
                    <CustomGGLandDialog
                        title="Sell your NFT..."
                        handleClose={handleCloseSellForm}
                        noSubmit
                    >
                        <NFTSellForm
                            cid={nft?.cid}
                            handleClose={handleCloseSellForm}
                            handleSwitchMenu={() => handleClickMenu("sales")}
                        />
                    </CustomGGLandDialog>
                )}
                {showOfferForm && (
                    <CustomGGLandDialog
                        title={
                            shareHolderUser
                                ? `Propose an offer to ${
                                      shareHolderUser?.userName ||
                                      shareHolderUser?.id
                                  }`
                                : "Make an offer..."
                        }
                        handleClose={handleCloseOfferForm}
                        noSubmit
                    >
                        <NFTOfferForm
                            cid={nft?.cid}
                            handleClose={handleCloseOfferForm}
                            handleSwitchMenu={() => handleClickMenu("offers")}
                            shareHolder={offerProposedShareHolder}
                        />
                    </CustomGGLandDialog>
                )}
            </section>

            <Footer />
        </div>
    );
};
export default NFTDetail;
