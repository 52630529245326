import React, { memo, useContext, useState } from "react";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";
import HeaderWithImage from "../../components/header/HeaderWithImage";
import moment from "moment";
import Footer from "../../components/footer/Footer";
import { GET_BLOG } from "../../graphql/blog/query";
import { useMutation, useQuery } from "@apollo/client";
import CustomLoader from "../../components/customLoader/CustomLoader";
import CustomButton from "../../components/button/CustomButton";
import { useNavigate } from "@reach/router";
import ROUTES from "../../components/constants/routes";
import { AuthContext } from "../../providers/Auth/AuthProvider";
import { DELETE_BLOG } from "../../graphql/blog/mutation";
import { SnackBarContext } from "../../providers/SnackBar/SnackBarProvider";
import CustomGGLandDialog from "../../components/dialog/CustomGGLandDialog";
import { BloggersClientContext } from "../../providers/Apollo/ApolloProvider";

const BlogDetail = ({ blogId }) => {
    const { isDark } = useContext(ThemeContext);
    const { isBlogAdmin } = useContext(AuthContext);
    const { bloggersClient } = useContext(BloggersClientContext);
    const navigate = useNavigate();
    const { displaySnackBar } = useContext(SnackBarContext);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const { data, loading } = useQuery(GET_BLOG, {
        variables: {
            id: Number(blogId),
        },
        fetchPolicy: "cache-and-network",
    });

    const blog = data?.getBlog?.blog;
    const recentBlogs = data?.getBlog?.recentBlogs;

    const [deleteBlog, { loading: loadingDelete }] = useMutation(DELETE_BLOG, {
        client: bloggersClient,
        onCompleted: (data) => {
            if (data && data.deleteBlog) {
                displaySnackBar({
                    message: "Blog deleted",
                    type: "success",
                });
                navigate(`${ROUTES.blogs}`);
            }
        },
        onError: (err) => {
            if (err.message) {
                displaySnackBar({
                    message: err.message,
                    type: "error",
                });
            }
        },
    });

    const onDeleteBlog = () => {
        deleteBlog({
            variables: {
                id: Number(blogId),
            },
        });
    };

    const onCloseDialog = () => {
        setOpenDeleteDialog(false);
    };

    return (
        <div className={isDark ? "greyscheme" : ""}>
            {(loading || loadingDelete) && <CustomLoader />}
            <HeaderWithImage title="Blog detail" />

            <section aria-label="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="blog-read">
                                {blog && blog?.picture && (
                                    <img
                                        alt=""
                                        src={blog.picture}
                                        className="img-fullwidth rounded"
                                    />
                                )}

                                <div className="post-text">
                                    {blog?.description && (
                                        <p>{blog.description}</p>
                                    )}
                                    {blog?.date && (
                                        <span className="post-date">
                                            {moment(blog?.date).format(
                                                "MMMM D, yyyy"
                                            )}
                                        </span>
                                    )}
                                    <span className="post-comment">{0}</span>
                                    <span className="post-like">{0}</span>
                                </div>
                            </div>

                            <div className="spacer-single"></div>

                            {/* <div id="blog-comment">
                                <h4>Comments ({commentCount})</h4>

                                <div className="spacer-half"></div>

                                <ol>
                                    {comments &&
                                        comments.map((comment, index) => (
                                            <li key={index}>
                                                <div className="avatar">
                                                    <img
                                                        src={
                                                            api.baseUrl +
                                                            comment.avatar
                                                        }
                                                        alt=""
                                                        width="70px"
                                                    />
                                                </div>
                                                <div className="comment-info">
                                                    <span className="c_name">
                                                        {comment.username}
                                                    </span>
                                                    <span className="c_date id-color">
                                                        {moment(
                                                            comment.timestamp
                                                        ).format(
                                                            "MMMM D yyyy, h:m A"
                                                        )}
                                                    </span>
                                                    <span className="c_reply">
                                                        <a href="/news">
                                                            Reply
                                                        </a>
                                                    </span>
                                                    <div className="clearfix"></div>
                                                </div>

                                                <div className="comment">
                                                    {comment.comment}
                                                </div>
                                                {comment.replies &&
                                                    comment.replies.length !==
                                                        0 && (
                                                        <ol>
                                                            {comment.replies.map(
                                                                (
                                                                    reply,
                                                                    replyIndex
                                                                ) => (
                                                                    <li
                                                                        key={
                                                                            replyIndex
                                                                        }
                                                                    >
                                                                        <div className="avatar">
                                                                            <img
                                                                                src={
                                                                                    api.baseUrl +
                                                                                    reply.avatar
                                                                                }
                                                                                alt=""
                                                                                width="70px"
                                                                            />
                                                                        </div>
                                                                        <div className="comment-info">
                                                                            <span className="c_name">
                                                                                {
                                                                                    reply.username
                                                                                }
                                                                            </span>
                                                                            <span className="c_date id-color">
                                                                                {moment(
                                                                                    reply.timestamp
                                                                                ).format(
                                                                                    "MMMM D yyyy, h:m A"
                                                                                )}
                                                                            </span>
                                                                            <span className="c_reply">
                                                                                <a href="/news">
                                                                                    Reply
                                                                                </a>
                                                                            </span>
                                                                            <div className="clearfix"></div>
                                                                        </div>
                                                                        <div className="comment">
                                                                            {
                                                                                reply.comment
                                                                            }
                                                                        </div>
                                                                    </li>
                                                                )
                                                            )}
                                                        </ol>
                                                    )}
                                            </li>
                                        ))}
                                </ol>

                                <div className="spacer-single"></div>

                                <div id="comment-form-wrapper">
                                    <h4>Leave a Comment</h4>
                                    <div className="comment_form_holder">
                                        <form
                                            id="contact_form"
                                            name="form1"
                                            className="form-border"
                                            method="post"
                                            action="#"
                                        >
                                            <label>Name</label>
                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                className="form-control"
                                            />

                                            <label>
                                                Email{" "}
                                                <span className="req">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                name="email"
                                                id="email"
                                                className="form-control"
                                            />
                                            <div
                                                id="error_email"
                                                className="error"
                                            >
                                                Please check your email
                                            </div>

                                            <label>
                                                Message{" "}
                                                <span className="req">*</span>
                                            </label>
                                            <textarea
                                                cols="10"
                                                rows="10"
                                                name="message"
                                                id="message"
                                                className="form-control"
                                            ></textarea>
                                            <div
                                                id="error_message"
                                                className="error"
                                            >
                                                Please check your message
                                            </div>
                                            <div
                                                id="mail_success"
                                                className="success"
                                            >
                                                Thank you. Your message has been
                                                sent.
                                            </div>
                                            <div
                                                id="mail_failed"
                                                className="error"
                                            >
                                                Error, email not sent
                                            </div>

                                            <p id="btnsubmit">
                                                <input
                                                    type="submit"
                                                    id="send"
                                                    value="Send"
                                                    className="btn btn-main"
                                                />
                                            </p>
                                        </form>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                        <div id="sidebar" className="col-md-4">
                            <div className="widget widget-post">
                                <h4>Recent Posts</h4>
                                <div className="small-border"></div>
                                <ul>
                                    {recentBlogs &&
                                        recentBlogs.map((recentBlog, index) => (
                                            <li key={index}>
                                                <span className="date">
                                                    {moment(
                                                        recentBlog?.date
                                                    ).format("D MMM")}
                                                </span>
                                                <a href="/news">
                                                    {recentBlog?.title}
                                                </a>
                                            </li>
                                        ))}
                                </ul>
                            </div>

                            {blog?.aboutUsDescription && (
                                <div className="widget widget-text">
                                    <h4>About Us</h4>
                                    <div className="small-border"></div>
                                    {blog?.aboutUsDescription}
                                </div>
                            )}
                            {blog?.tags && (
                                <div className="widget widget_tags">
                                    <h4>Tags</h4>
                                    <div className="small-border"></div>
                                    <ul>
                                        {blog?.tags.map((tag, index) => (
                                            <li key={index}>
                                                <a href="#link">{tag}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                    {isBlogAdmin && (
                        <div style={{ display: "flex" }}>
                            <CustomButton
                                text="UPDATE BLOG"
                                onClick={() =>
                                    navigate(`${ROUTES.updateBlog}/${blogId}`)
                                }
                                disabled={loading}
                                style={{ marginRight: 20 }}
                            />
                            <CustomButton
                                text="DELETE BLOG"
                                onClick={() => setOpenDeleteDialog(true)}
                                disabled={loading}
                                isWhite
                            />
                        </div>
                    )}
                    {openDeleteDialog && (
                        <CustomGGLandDialog
                            title="Do you really want to delete this blog ?"
                            handleClose={onCloseDialog}
                        >
                            <div>
                                {`"${blog?.title}"`}
                                <div style={{ display: "flex" }}>
                                    <CustomButton
                                        text="DELETE"
                                        onClick={onDeleteBlog}
                                        disabled={loading}
                                        style={{ marginRight: 20 }}
                                    />
                                    <CustomButton
                                        text="CANCEL"
                                        onClick={onCloseDialog}
                                        disabled={loading}
                                        isWhite
                                    />
                                </div>
                            </div>
                        </CustomGGLandDialog>
                    )}
                </div>
            </section>

            <Footer />
        </div>
    );
};

export default memo(BlogDetail);
