const ROUTES = {
    home: "/home",
    wallets: "/wallets",
    myProfile: "/my-profile",
    myProfileSettings: "/my-profile-settings",
    createNFT: "/create",
    nftDetail: "/nft-detail",
    nftUpdate: "/nft-update",
    nftSell: "/nft-sell",
    nftOffer: "/nft-offer",
    explore: "/explore",
    deposit: "/deposit",
    activity: "/activity",
    adminHome: "/admin",
    moderatorHome: "/moderator",
    blogs: "/blogs",
    helpCenter: "/help-center",
    createBlog: "/blog-create",
    updateBlog: "/blog-update",
    blogDetail: "/blog-detail",
    createHelp: "/help-create",
    helpDetail: "/help-detail",
    updateHelp: "/help-update",
    subHelpDetail: "/sub-help-detail",
    nftPermit: "/nft-permit",
    notifications: "/notifications",
    originsClub: "/origins-club",
    newsletter: "/newsletter",
    bloggerHome: "/blogger-home",
    bloggerLogin: "/blogger-login",
};

export const BLOGGER_ROUTES = [
    ROUTES.bloggerHome,
    ROUTES.bloggerLogin,
    ROUTES.blogs,
    ROUTES.helpCenter,
    ROUTES.createBlog,
    ROUTES.updateBlog,
    ROUTES.createHelp,
    ROUTES.updateHelp,
    ROUTES.helpDetail,
    ROUTES.blogDetail,
    ROUTES.subHelpDetail,
];

export default ROUTES;
