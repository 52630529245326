import { gql } from "@apollo/client";
import { CATEGORY_FRAGMENT } from "./fragment";

export const GET_NFT_CATEGORIES = gql`
    query GetIpCategories {
        getIpCategories {
            ...CategoryFields
        }
    }
    ${CATEGORY_FRAGMENT}
`;
