import { useQuery } from "@apollo/client";
import React, { useContext, useState } from "react";
import CustomLoader from "../../../components/customLoader/CustomLoader";
import ExploreFilter from "../../../components/explore/ExploreFilter";
import NFTCardList from "../../../components/nftCard/NFTCardList";
import { GET_EXPLORE_NFTS } from "../../../graphql/nft/query";
import useDebounce from "../../../hooks/useDebounce/useDebounce";
import { ThemeContext } from "../../../providers/Theme/ThemeProvider";

const FeaturedIpsManagement = () => {
    const { isDark } = useContext(ThemeContext);

    const [categoryFilter, setCategoryFilter] = useState(undefined);

    const [titleFilter, setTitleFilter] = useState("");

    const debouncedTitleFilter = useDebounce(titleFilter);

    const handleChangeCategoryFilter = (value) => {
        setCategoryFilter(value);
    };

    const handleChangeTitleFilter = (value) => {
        setTitleFilter(value);
    };

    const { data, loading } = useQuery(GET_EXPLORE_NFTS, {
        variables: {
            input: {
                title: debouncedTitleFilter,
                categoriesCode: categoryFilter
                    ? [categoryFilter.value]
                    : undefined,
                orderByFeatured: true,
            },
        },
        fetchPolicy: "cache-and-network",
    });

    const exploreNFTs = data ? data.getMintedIps : [];

    return (
        <div className={isDark ? "greyscheme" : ""}>
            {loading && <CustomLoader />}

            <div className="row">
                <div className="col-lg-12">
                    <ExploreFilter
                        titleFilter={titleFilter}
                        handleChangeTitleFilter={handleChangeTitleFilter}
                        category={categoryFilter}
                        handleChangeCategoryFilter={handleChangeCategoryFilter}
                    />
                </div>
            </div>
            {exploreNFTs.length ? (
                <NFTCardList nfts={exploreNFTs} featuredEditable />
            ) : (
                <div>No item.</div>
            )}
        </div>
    );
};
export default FeaturedIpsManagement;
