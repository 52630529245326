export const getNotificationText = (notificationCode) => {
    switch (notificationCode) {
        case "notification.PERMIT_APPROVED":
            return ` has given you a permit to mint a NFT : `;

        case "notification.PERMIT_DENIED":
            return ` has denied the permission to mint a NFT `;

        default:
            return "";
    }
};
