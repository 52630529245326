import React, { memo, useContext, useEffect, useRef, useState } from "react";
import Footer from "../../components/footer/Footer";
import { AuthContext } from "../../providers/Auth/AuthProvider";
import ProfilePicture from "../../components/profilePicture/ProfilePicture";
import getUserPseudo from "../../utils/user/getUserPseudo";
import { SnackBarContext } from "../../providers/SnackBar/SnackBarProvider";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "@reach/router";
import ROUTES from "../../components/constants/routes";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";
import { DEFAULT_AVATAR } from "../../constants/file";
import MyCreatedNFTs from "./myCreatedNFTs/MyCreatedNFTs";
import { useQuery } from "@apollo/client";
import { GET_USER_BALANCES } from "../../subgraph/nft/query";
import { GraphNodeClientContext } from "../../providers/Apollo/ApolloProvider";
import { ethers } from "ethers";
import CustomLoader from "../../components/customLoader/CustomLoader";
import NFTCardListCid from "../../components/nftCard/NFTCardListCid";
import { parse } from "query-string";
import HeaderWithImage from "../../components/header/HeaderWithImage";
import CustomButton from "../../components/button/CustomButton";

const MyProfile = () => {
    const { authContext, myDeposit } = useContext(AuthContext);
    const { myProfile } = authContext;
    const { displaySnackBar } = useContext(SnackBarContext);
    const { graphNodeClient } = useContext(GraphNodeClientContext);
    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = parse(location.search);
    const { isDark } = useContext(ThemeContext);
    const [menu, setMenu] = useState(searchParams?.tab || "created");

    const contentRef = useRef(null);

    const scrollToContent = () =>
        contentRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });

    const { data: dataBalances, loading: loadingGraphnodeBalances } = useQuery(
        GET_USER_BALANCES,
        {
            variables: { address: myProfile?.id?.toLowerCase() },
            skip: !myProfile?.id,
            fetchPolicy: "cache-and-network",
            client: graphNodeClient,
        }
    );

    const userBalances = dataBalances?.balances || [];

    const fullyOwnedNFTs = userBalances
        .filter((balance) => balance?.contract?.balances?.length === 1)
        .map((balance) =>
            ethers.utils.toUtf8String(balance?.contract?.content)
        );

    const partiallyOwnedNFTs = userBalances
        .filter((balance) => balance?.contract?.balances?.length > 1)
        .map((balance) =>
            ethers.utils.toUtf8String(balance?.contract?.content)
        );

    const handleClickMenu = (clickedMenu) => {
        setMenu(clickedMenu);
        window.history.pushState(
            {},
            ROUTES.myProfile,
            `${ROUTES.myProfile}?tab=${clickedMenu}`
        );
    };

    const copyWalletAddress = () => {
        if (myProfile.id) {
            navigator.clipboard.writeText(myProfile.id).then(() => {
                displaySnackBar({
                    message: formatMessage({
                        id: "wallet.copied.success.message",
                    }),
                    type: "success",
                });
            });
        }
    };

    const goToMyProfileSettings = () => {
        navigate(ROUTES.myProfileSettings);
    };

    const goToMakeDeposit = () => {
        navigate(ROUTES.deposit);
    };

    useEffect(() => {
        if (searchParams?.tab) {
            scrollToContent();
        }
    }, [searchParams]);

    return (
        <div className={isDark ? "greyscheme" : ""}>
            {loadingGraphnodeBalances && <CustomLoader />}

            <HeaderWithImage title={"myProfile.title"} />

            <section className="container no-bottom">
                <div className="row">
                    {/* <div className="spacer-double"></div> */}
                    <div className="col-md-12">
                        <div className="d_profile de-flex">
                            <div className="de-flex-col">
                                <div className="profile_avatar">
                                    <ProfilePicture
                                        src={
                                            myProfile?.profilePictureUri ||
                                            DEFAULT_AVATAR
                                        }
                                    />
                                    {myProfile &&
                                        myProfile.profilePictureUri && (
                                            <i className="fa fa-check"></i>
                                        )}
                                    <div className="profile_name">
                                        <h4>
                                            {myProfile?.userName}
                                            <span className="profile_username">
                                                {getUserPseudo(
                                                    myProfile?.userName
                                                )}
                                            </span>
                                            <span
                                                id="wallet"
                                                className="profile_wallet"
                                            >
                                                {myProfile?.id}
                                            </span>
                                            <button
                                                id="btn_copy"
                                                title={formatMessage({
                                                    id: "myProfile.wallet.address.copy",
                                                })}
                                                onClick={copyWalletAddress}
                                            >
                                                {formatMessage({
                                                    id: "common.copy",
                                                })}
                                            </button>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="profile_follow de-flex">
                                <div className="de-flex-col">
                                    <CustomButton
                                        text={formatMessage({
                                            id: "myProfile.update",
                                        })}
                                        onClick={goToMyProfileSettings}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={contentRef} />

                <div className="row">
                    {/* <div className="spacer-double"></div> */}
                    <div className="col-md-12">
                        <div className="d_profile de-flex">
                            <div className="de-flex-col">
                                <div className="profile_avatar">
                                    <div className="profile_name">
                                        <h4 style={{ marginBottom: 0 }}>
                                            <span
                                                id="wallet"
                                                className="profile_wallet"
                                                // style={{ fontSize: 25 }}
                                            >
                                                Your deposit :
                                            </span>
                                            <span
                                                style={{
                                                    marginLeft: 20,
                                                }}
                                            >
                                                {myDeposit} ETH
                                            </span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="profile_follow de-flex">
                                <div className="de-flex-col">
                                    <CustomButton
                                        text="Make a deposit"
                                        onClick={goToMakeDeposit}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container no-top">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="items_filter">
                            <ul className="de_nav text-left">
                                <li
                                    id="Mainbtn"
                                    className={
                                        menu === "created" ? "active" : ""
                                    }
                                >
                                    <span
                                        onClick={() =>
                                            handleClickMenu("created")
                                        }
                                    >
                                        {formatMessage({
                                            id: "title.my.portfolio.subtitle.my.created",
                                        })}
                                    </span>
                                </li>
                                <li
                                    id="Mainbtn1"
                                    className={
                                        menu === "ownership" ? "active" : ""
                                    }
                                >
                                    <span
                                        onClick={() =>
                                            handleClickMenu("ownership")
                                        }
                                    >
                                        {formatMessage({
                                            id: "title.my.portfolio.subtitle.ownership",
                                        })}
                                    </span>
                                </li>
                                <li
                                    id="Mainbtn2"
                                    className={
                                        menu === "coownership" ? "active" : ""
                                    }
                                >
                                    <span
                                        onClick={() =>
                                            handleClickMenu("coownership")
                                        }
                                    >
                                        {formatMessage({
                                            id: "title.my.portfolio.subtitle.coownership",
                                        })}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                {menu === "created" && <MyCreatedNFTs />}
                {menu === "ownership" && (
                    <NFTCardListCid cids={fullyOwnedNFTs} />
                )}

                {menu === "coownership" && (
                    <NFTCardListCid cids={partiallyOwnedNFTs} />
                )}
            </section>

            <Footer />
        </div>
    );
};
export default memo(MyProfile);
