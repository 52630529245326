import { Tooltip } from "@material-ui/core";
import { useContext } from "react";
import { SnackBarContext } from "../../providers/SnackBar/SnackBarProvider";

const CustomAddress = ({ address, maxWidth }) => {
    const { displaySnackBar } = useContext(SnackBarContext);

    const copyAddress = () => {
        if (address) {
            navigator.clipboard.writeText(address).then(() => {
                displaySnackBar({
                    message: `Copied address : ${address}`,
                    type: "success",
                });
            });
        }
    };

    return (
        <Tooltip title="Click to copy address">
            <div
                id="wallet"
                onClick={copyAddress}
                style={{
                    fontSize: 16,
                    display: "block",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: maxWidth || 200,
                    color: "#727272",
                    fontWeight: "bold",
                }}
            >
                {address}
            </div>
        </Tooltip>
    );
};

export default CustomAddress;
