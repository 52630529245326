import React, { useContext, useState } from "react";
import Clock from "../../components/Clock";
import Files from "react-files";
import { useMutation } from "@apollo/client";
import { UPLOAD } from "../../graphql/nft/mutation";
import { AuthContext } from "../../providers/Auth/AuthProvider";
import CustomInput from "../../components/input/CustomInput";
import AddProperties from "../../components/property/AddProperties";
import PropertyItem from "../../components/property/PropertyItem";
import { SnackBarContext } from "../../providers/SnackBar/SnackBarProvider";
import { useIntl } from "react-intl";
import config from "../../../config/config";
import { useNavigate } from "@reach/router";
import ROUTES from "../../components/constants/routes";
import CustomButton from "../../components/button/CustomButton";
import getImagePreview from "../../utils/nft/getImagePreview";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";
import CustomSwitchProperty from "../../components/switch/CustomSwitchProperty";
import NFTAuthorPicture from "../../components/nftCard/authorPicture/NFTAuthorPicture";
import { GET_MY_CREATED_NFTS } from "../../graphql/nft/query";
import Footer from "../../components/footer/Footer";
import CustomLoader from "../../components/customLoader/CustomLoader";
import CustomExtraFilesInput from "../../components/extraFiles/CustomExtraFilesInput";
import { FONT_SIZES } from "../../constants/sizes";
import { CategoriesContecxt } from "../../providers/Categories/CategoriesProvider";
import CustomSelect from "../../components/select/CustomSelect";
import HeaderWithImage from "../../components/header/HeaderWithImage";

const CreateNFT = () => {
    const { authContext } = useContext(AuthContext);
    const { myProfile } = authContext;
    const { displaySnackBar } = useContext(SnackBarContext);
    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const { isDark } = useContext(ThemeContext);

    const { defaultSelectCategories, loadingCategories } =
        useContext(CategoriesContecxt);
    const [category, setCategory] = useState(undefined);

    const [files, setFiles] = useState([]);
    const [extraFiles, setExtraFiles] = useState([]);
    const [extension, setExtension] = useState("");
    const [infos, setInfos] = useState({
        title: undefined,
        description: undefined,
        price: undefined,
        shareCount: undefined,
        royalties: undefined,
        externalLink: undefined,
        isSensitiveContent: false,
        properties: [],
    });

    const [upload, { loading }] = useMutation(UPLOAD, {
        refetchQueries: [{ query: GET_MY_CREATED_NFTS }],
        onCompleted: (data) => {
            if (data && data.uploadSingle && data.uploadSingle.cid) {
                displaySnackBar({
                    message: formatMessage({ id: "upload.message.success" }),
                    type: "success",
                });
                navigate(`${ROUTES.nftDetail}/${data.uploadSingle.cid}`);
            }
        },
        onError: (err) => {
            if (err.message === "Duplicated NFT") {
                displaySnackBar({
                    message: formatMessage({
                        id: "upload.message.duplicated.error",
                    }),
                    type: "error",
                });
            } else {
                displaySnackBar({
                    message: formatMessage({ id: "upload.message.error" }),
                    type: "error",
                });
            }
        },
    });

    const onFilesChange = (browsedFiles) => {
        if (browsedFiles && browsedFiles[0] && browsedFiles[0].type) {
            setExtension(browsedFiles[0].type);
        }
        setFiles(browsedFiles);
    };

    const onFilesError = (error, file) => {
        console.log("error code " + error.code + ": " + error.message);
        if (error.code === 2) {
            displaySnackBar({
                message: `${formatMessage({
                    id: "myProfile.settings.uploading.picture.size.error",
                })} ${config.nft.fileSizeLimit} Mb.`,
                type: "error",
            });
        } else {
            displaySnackBar({
                message: `Error when uploading the file.`,
                type: "error",
            });
        }
    };

    const handleChangeCategory = (value) => {
        setCategory(value);
    };

    const handleChangeTitle = (value) => {
        setInfos({ ...infos, title: value });
    };

    const handleChangeDescription = (value) => {
        setInfos({ ...infos, description: value });
    };

    const handleChangePrice = (value) => {
        setInfos({ ...infos, price: parseFloat(value) });
    };

    const handleChangeShareCount = (value) => {
        setInfos({ ...infos, shareCount: value });
    };

    const handleChangeRoyalties = (value) => {
        setInfos({ ...infos, royalties: parseFloat(value) });
    };

    const handleChangeExternalLink = (value) => {
        setInfos({ ...infos, externalLink: value });
    };

    const handleChangeisSensitiveContent = (value) => {
        setInfos({ ...infos, isSensitiveContent: !infos.isSensitiveContent });
    };

    const handleChangeProperties = (value) => {
        setInfos({ ...infos, properties: value });
    };

    const onUpload = () => {
        if (files && files[0]) {
            upload({
                variables: {
                    input: {
                        file: files[0],
                        extraFiles,
                        categoriesCode: category ? [category.value] : undefined,
                        infos: {
                            ...infos,
                            properties: infos.properties.map((prop) => {
                                const { index, ...data } = prop;
                                return data;
                            }),
                        },
                    },
                },
            });
        } else {
            displaySnackBar({
                message: "Uploading file is required",
                type: "error",
            });
        }
    };

    const titleMissing = !infos.title;

    const imageUrl =
        files && files[0] && files[0].preview && files[0].preview.url
            ? files[0].preview.url
            : undefined;

    return (
        <div className={isDark ? "greyscheme" : ""}>
            {loading && <CustomLoader text="Uploading your NFT..." />}

            <HeaderWithImage title={"title.my.portfolio.subtitle.create"} />

            <section className="container">
                <div className="row">
                    <div className="col-lg-7 offset-lg-1 mb-5">
                        <form
                            id="form-create-item"
                            className="form-border"
                            action="#"
                        >
                            <div className="field-set">
                                <h5>Upload file</h5>

                                <div className="d-create-file">
                                    <p id="file_name">
                                        PNG, JPG, GIF, WEBP or MP4. Max{" "}
                                        {config.nft.fileSizeLimit}mb.
                                    </p>

                                    {files.map((x) => (
                                        <p key="{index}">{x.name}</p>
                                    ))}

                                    <div className="browse">
                                        <Files
                                            className="upload_file"
                                            onChange={onFilesChange}
                                            onError={onFilesError}
                                            accepts={[
                                                "image/*",
                                                ".pdf",
                                                "audio/*",
                                                "video/*",
                                                ".txt",
                                                ".docx",
                                            ]}
                                            multiple={false}
                                            maxFiles={100}
                                            maxFileSize={
                                                config.nft.fileSizeLimit *
                                                1000000
                                            }
                                            minFileSize={0}
                                        >
                                            <CustomButton text="Browse" />
                                        </Files>
                                    </div>
                                </div>
                                {!files.length && (
                                    <div
                                        style={{
                                            fontSize: FONT_SIZES.common.small,
                                            color: "#EB5757",
                                            marginTop: 10,
                                        }}
                                    >
                                        Upload file is required
                                    </div>
                                )}
                                <div className="spacer-single"></div>

                                <CustomSelect
                                    title="Category"
                                    placeholder="Select category"
                                    options={defaultSelectCategories}
                                    value={category}
                                    onChange={handleChangeCategory}
                                    noMaxWidth
                                    isLoading={loadingCategories}
                                />

                                <CustomInput
                                    label="Title"
                                    placeholder="e.g. 'Crypto Funk"
                                    value={infos.title}
                                    handleChangeValue={handleChangeTitle}
                                    error={titleMissing}
                                    errorText="This field is required"
                                />

                                <CustomInput
                                    label="Description"
                                    placeholder="e.g. 'This is very limited item'"
                                    value={infos.description}
                                    handleChangeValue={handleChangeDescription}
                                    muiltiline
                                />

                                <CustomInput
                                    label="Shares"
                                    placeholder="enter share count"
                                    value={infos.shareCount}
                                    handleChangeValue={handleChangeShareCount}
                                    type="number"
                                />

                                <CustomInput
                                    label="Estimated Price Per Share (ETH)"
                                    placeholder="enter price per share (ETH)"
                                    value={infos.price}
                                    handleChangeValue={handleChangePrice}
                                    type="number"
                                />

                                <CustomInput
                                    label="Royalties"
                                    placeholder="suggested: 0, 10%, 20%, 30%. Maximum is 70%"
                                    value={infos.royalties}
                                    handleChangeValue={handleChangeRoyalties}
                                    type="number"
                                />

                                <CustomInput
                                    label="External link"
                                    placeholder="IdealExchange will include a link to this URL on this item's detail page"
                                    value={infos.externalLink}
                                    handleChangeValue={handleChangeExternalLink}
                                    muiltiline
                                />

                                <CustomSwitchProperty
                                    title="Explicit or Sensitive content (Adult/Pornography/NSFW)"
                                    subtitle="Set this item as explicit and sensitive
                                content."
                                    value={infos.isSensitiveContent}
                                    handleChangeValue={
                                        handleChangeisSensitiveContent
                                    }
                                />

                                <AddProperties
                                    properties={infos.properties}
                                    handleChangeProperties={
                                        handleChangeProperties
                                    }
                                />

                                <div
                                    className="row"
                                    style={{
                                        marginTop: 0,
                                        marginBottom: 20,
                                    }}
                                >
                                    {infos.properties.map((property) => (
                                        <PropertyItem property={property} />
                                    ))}
                                </div>

                                <CustomExtraFilesInput
                                    extraFiles={extraFiles}
                                    setExtraFiles={setExtraFiles}
                                    onError={onFilesError}
                                />

                                <CustomButton
                                    text="Create Item"
                                    onClick={onUpload}
                                    disabled={
                                        loading || titleMissing || !files.length
                                    }
                                />
                            </div>
                        </form>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-xs-12">
                        <h5>Preview item</h5>
                        <div
                            className="nft__item m-0"
                            style={{ height: "auto" }}
                        >
                            <div className="de_countdown">
                                <Clock deadline="December, 30, 2021" />
                            </div>
                            <NFTAuthorPicture
                                src={myProfile?.profilePictureUri}
                            />
                            <div className="nft__item_wrap">
                                <span>
                                    <img
                                        src={getImagePreview({
                                            extension,
                                            imageUrl,
                                        })}
                                        id="get_file_2"
                                        className="lazy nft__item_preview"
                                        alt=""
                                    />
                                </span>
                            </div>
                            <div className="nft__item_info">
                                <span>
                                    <h4>{infos.title}</h4>
                                </span>
                                <div className="nft__item_price">
                                    {infos.price ? (
                                        <div>{infos.price} ETH</div>
                                    ) : null}
                                    {infos.royalties ? (
                                        <div>{infos.royalties}%</div>
                                    ) : null}
                                </div>
                                <div className="nft__item_action">
                                    <span>Place a bid</span>
                                </div>
                                <div className="nft__item_like">
                                    <i className="fa fa-heart"></i>
                                    <span>0</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
};

export default CreateNFT;
