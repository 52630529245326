import React, { useContext, useEffect } from "react";
import { useNavigate } from "@reach/router";
import ROUTES from "../components/constants/routes";
import { SnackBarContext } from "../providers/SnackBar/SnackBarProvider";
import LOCAL_STORAGE_KEYS from "../constants/localStorage";
import { AuthContext } from "../providers/Auth/AuthProvider";
import { BloggerAuthContext } from "../providers/BloggerAuth/BloggerAuthProvider";

const CustomRoute = ({
    authRequired,
    authErrorMessage,
    Component,
    path,
    bloggerAuthRequired,
}) => {
    const { displaySnackBar } = useContext(SnackBarContext);
    const { isAdmin, isModerator } = useContext(AuthContext);
    const { isBloggerConnected } = useContext(BloggerAuthContext);
    const navigate = useNavigate();
    const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);

    useEffect(() => {
        if (authRequired) {
            if (!token) {
                displaySnackBar({
                    message:
                        authErrorMessage ||
                        "You must be logged in to perform this action",
                    type: "error",
                });
                navigate(ROUTES.wallets);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authErrorMessage, authRequired, token]);

    useEffect(() => {
        if (path === ROUTES.adminHome) {
            if (!isAdmin) {
                displaySnackBar({
                    message:
                        authErrorMessage ||
                        "Only admin can have access to this interface",
                    type: "error",
                });
                navigate(ROUTES.home);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAdmin]);

    useEffect(() => {
        if (path === ROUTES.moderatorHome) {
            if (!isModerator) {
                displaySnackBar({
                    message:
                        authErrorMessage ||
                        "Only moderators can have access to this interface",
                    type: "error",
                });
                navigate(ROUTES.home);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModerator]);

    useEffect(() => {
        if (bloggerAuthRequired) {
            if (!isBloggerConnected) {
                displaySnackBar({
                    message:
                        authErrorMessage ||
                        "Only bloggers can have access to this interface",
                    type: "error",
                });
                navigate(ROUTES.bloggerLogin);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBloggerConnected]);

    return <Component path={path} />;
};
export default CustomRoute;
