import { Box, createStyles, makeStyles } from "@material-ui/core";
import React from "react";
import { FONT_SIZES } from "../../constants/sizes";

const useStyles = makeStyles((theme) =>
    createStyles({
        errorText: {
            fontSize: FONT_SIZES.common.small,
            color: "#EB5757",
            marginTop: -10,
        },
    })
);

const CustomInput = (props) => {
    const classes = useStyles();
    const {
        label,
        value,
        name,
        placeholder,
        muiltiline,
        type,
        containerStyle,
    } = props;

    const { errorText, error, handleChangeValue, ...defaultProps } = props;

    const onChange = (e) => {
        const { value, name } = e.target;
        if (handleChangeValue) handleChangeValue(value, name);
    };

    if (muiltiline)
        return (
            <div style={containerStyle}>
                <h5>{label}</h5>
                <textarea
                    {...defaultProps}
                    type="text"
                    name={name || "item_title"}
                    id="item_title"
                    className={error ? "form-control error" : "form-control"}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                />
                {errorText && error && (
                    <Box className={classes.errorText}>{errorText}</Box>
                )}
                <div className="spacer-10"></div>
            </div>
        );

    return (
        <div style={containerStyle}>
            <h5>{label}</h5>
            <input
                {...defaultProps}
                type={type || "text"}
                name={name || "item_title"}
                id="item_title"
                className={error ? "form-control error" : "form-control"}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                // Disable wheel to change number
                onWheel={() => document.activeElement.blur()}
            />
            {errorText && error && (
                <Box className={classes.errorText}>{errorText}</Box>
            )}
            <div className="spacer-10"></div>
        </div>
    );
};

export default CustomInput;
