import React, { useContext, useState } from "react";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";
import { GET_EXPLORE_NFTS } from "../../graphql/nft/query";
import { useQuery } from "@apollo/client";
import NFTCardList from "../../components/nftCard/NFTCardList";
import ExploreFilter from "../../components/explore/ExploreFilter";
import useDebounce from "../../hooks/useDebounce/useDebounce";
import Footer from "../../components/footer/Footer";
import CustomLoader from "../../components/customLoader/CustomLoader";
import HeaderWithImage from "../../components/header/HeaderWithImage";

const Explore = () => {
    const { isDark } = useContext(ThemeContext);
    const [categoryFilter, setCategoryFilter] = useState(undefined);

    const [titleFilter, setTitleFilter] = useState("");

    const debouncedTitleFilter = useDebounce(titleFilter);

    const { data, loading } = useQuery(GET_EXPLORE_NFTS, {
        variables: {
            input: {
                title: debouncedTitleFilter,
                categoriesCode: categoryFilter
                    ? [categoryFilter.value]
                    : undefined,
            },
        },
        fetchPolicy: "cache-and-network",
    });

    const exploreNFTs = data ? data.getMintedIps : [];

    const handleChangeCategoryFilter = (value) => {
        setCategoryFilter(value);
    };

    const handleChangeTitleFilter = (value) => {
        setTitleFilter(value);
    };

    return (
        <div className={isDark ? "greyscheme" : ""}>
            {loading && <CustomLoader />}

            <HeaderWithImage title={"title.catalog.subtitle.explore"} />

            <section className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <ExploreFilter
                            titleFilter={titleFilter}
                            handleChangeTitleFilter={handleChangeTitleFilter}
                            category={categoryFilter}
                            handleChangeCategoryFilter={
                                handleChangeCategoryFilter
                            }
                        />
                    </div>
                </div>
                {exploreNFTs.length ? (
                    <NFTCardList nfts={exploreNFTs} />
                ) : (
                    <div>No item.</div>
                )}
            </section>

            <Footer />
        </div>
    );
};
export default Explore;
