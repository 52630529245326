/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext } from "react";
import { Box, Button, createStyles, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import COLORS from "../../../constants/colors";
import { SnackBarContext } from "../../SnackBar/SnackBarProvider";
import { AuthContext } from "../../Auth/AuthProvider";
import { useIntl } from "react-intl";
import { getEnvFromChainId } from "../../../utils/network/network";
import config from "../../../../config/config";

const useStyles = makeStyles((theme) =>
    createStyles({
        link: {
            color: COLORS.light.black,
            cursor: "pointer",
            textDecoration: "underline",
            "&:hover": {
                color: COLORS.light.black,
            },
        },
    })
);

const GlobalNetMismatchBanner = ({
    hostEnv,
    networkEnv,
    handleCloseBanner,
    isUnsupportedNetwork,
}) => {
    const { formatMessage } = useIntl();
    const {
        authContext: { wallet },
    } = useContext(AuthContext);
    const classes = useStyles();
    const { displaySnackBar } = useContext(SnackBarContext);

    const switchToNetwork = (chainId, linkToNavigate) => {
        if (wallet && wallet.provider) {
            const { provider } = wallet;
            const chainId0x = `0x${chainId.toString(16)}`;
            const envToSwitch = getEnvFromChainId(chainId);

            provider
                .send("wallet_switchEthereumChain", [{ chainId: chainId0x }])
                .catch((switchError) => {
                    if (switchError.code === 4902) {
                        if (envToSwitch.chainId) {
                            provider.send("wallet_addEthereumChain", [
                                {
                                    chainId: chainId0x,
                                    chainName: envToSwitch.name,
                                    nativeCurrency: {
                                        name: "DEV-ETH",
                                        symbol: "D-ETH",
                                        decimals: 18,
                                    },
                                    rpcUrls: [envToSwitch.rpcUrl],
                                    blockExplorerUrls: null,
                                },
                            ]);
                            if (linkToNavigate) {
                                window.location.href = linkToNavigate;
                            }
                        }
                    }
                })
                .then(() => {
                    displaySnackBar({
                        message: formatMessage(
                            {
                                id: "net.switch.success",
                            },
                            { name: envToSwitch.name }
                        ),
                        type: "success",
                    });
                    if (linkToNavigate) {
                        window.location.href = linkToNavigate;
                    }
                });
        } else {
            displaySnackBar({
                message: formatMessage({
                    id: "wallet.no.provider.found.error",
                }),
                type: "error",
            });
        }
    };

    const linkSwitchToMainnet = (
        <a
            className={classes.link}
            onClick={() =>
                switchToNetwork(
                    config.network.main.chainId,
                    config.network.main.link
                )
            }
        >
            {config.network.main.name}
        </a>
    );

    const linkSwitchToTestnet = (
        <a
            className={classes.link}
            onClick={() =>
                switchToNetwork(
                    config.network.test.chainId,
                    config.network.test.link
                )
            }
        >
            {config.network.test.name}
        </a>
    );

    const linkSwitchToDevnet = (
        <a
            className={classes.link}
            onClick={() =>
                switchToNetwork(
                    config.network.dev.chainId,
                    config.network.dev.link
                )
            }
        >
            {config.network.dev.name}
        </a>
    );

    if (isUnsupportedNetwork)
        return (
            <Box className="net-banner">
                <Box>
                    <Box>Unsupported network detected</Box>
                    <Box>
                        {formatMessage(
                            { id: "net.unsupported.net.suggestion" },
                            {
                                mainnet: linkSwitchToMainnet,
                                testnet: linkSwitchToTestnet,
                                devnet: linkSwitchToDevnet,
                            }
                        )}
                    </Box>
                </Box>

                <Button onClick={handleCloseBanner}>
                    <CloseIcon />
                </Button>
            </Box>
        );

    const linkToNetworkHost = (
        <a href={networkEnv.link} className={classes.link}>
            {networkEnv.host}
        </a>
    );

    const linkSwitchToNetworkEnv = (
        <a
            className={classes.link}
            onClick={() => switchToNetwork(hostEnv.chainId)}
        >
            {hostEnv?.name}
        </a>
    );

    // A : hostEnv
    // B : networkEnv
    // Network mismatch detected
    // If you want to connect to B, then please visit this <link to the correct host>.
    // If you want to connect to A, then press this <link to switch to the correct network> or change the network in your wallet manually.

    return (
        <Box className="net-banner">
            <Box>
                <Box>Network mismatch detected</Box>
                <Box>
                    {formatMessage(
                        { id: "net.switch.host.suggestion" },
                        { name: networkEnv.name, link: linkToNetworkHost }
                    )}
                </Box>
                <Box>
                    {formatMessage(
                        { id: "net.switch.net.suggestion" },
                        {
                            name: hostEnv.name,
                            link: linkSwitchToNetworkEnv,
                        }
                    )}
                </Box>
            </Box>

            <Button onClick={handleCloseBanner}>
                <CloseIcon />
            </Button>
        </Box>
    );
};

export default GlobalNetMismatchBanner;
