import React, { useState, createContext } from "react";
import { IntlProvider } from "react-intl";
import locales from "../../../locales";
import LANGUAGES from "../../constants/language";

// type ITranslation = {
//     language: LANGUAGES;
//     changeLanguage: (language: LANGUAGES) => void;
// };

export const TranslationContext = createContext({
    language: LANGUAGES.EN,
    changeLanguage: () => {},
});

const TranslationProvider = ({ children }) => {
    const [language, setLanguage] = useState(LANGUAGES.EN);

    const changeLanguage = (lang) => {
        setLanguage(lang);
    };

    return (
        <TranslationContext.Provider
            value={{
                language,
                changeLanguage,
            }}
        >
            <IntlProvider
                locale={language}
                defaultLocale={LANGUAGES.EN}
                messages={locales[language]}
            >
                {children}
            </IntlProvider>
        </TranslationContext.Provider>
    );
};

export default TranslationProvider;
