import { gql } from "@apollo/client";

export const DENY_FRAGMENT = gql`
    fragment DenyFields on Deny {
        id
        internalReason
        publicReason
        createdAt
    }
`;
