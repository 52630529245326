import { gql } from "@apollo/client";
import { BLOG_FRAGMENT } from "./fragment";

export const CREATE_BLOG = gql`
    mutation CreateBlog($input: CreateBlogInput!) {
        createBlog(input: $input) {
            ...BlogFields
        }
    }
    ${BLOG_FRAGMENT}
`;

export const UPDATE_BLOG = gql`
    mutation UpdateBlog($input: UpdateBlogInput!) {
        updateBlog(input: $input) {
            ...BlogFields
        }
    }
    ${BLOG_FRAGMENT}
`;

export const DELETE_BLOG = gql`
    mutation DeleteBlog($id: Int!) {
        deleteBlog(id: $id) {
            ...BlogFields
        }
    }
    ${BLOG_FRAGMENT}
`;
