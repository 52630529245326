import React, { useContext, useState } from "react";
import { Link } from "@reach/router";
import AppLogo from "../logo/AppLogo";
import IdealSlogan from "../slogan/IdealSlogan";
import { NewsletterContext } from "../../providers/NewsLetter/NewsLetterProvider";
import config from "../../../config/config";
import { isValidEmail } from "../../utils/string/isValidEmail";

const Footer = () => {
    const { listUuid } = useContext(NewsletterContext);
    const [email, setEmail] = useState("");

    const handleChangeEmail = (e) => {
        const { value } = e.target;
        setEmail(value?.trim() || "");
    };

    const invalidEmailFormat = !isValidEmail(email);

    return (
        <footer className="footer-light">
            <div className="container">
                <div className="footeritems">
                    <div>
                        <div className="widget">
                            <h5>Marketplace</h5>
                            {/* <ul>
                                <li>
                                    <Link to="">All NFTs</Link>
                                </li>
                                <li>
                                    <Link to="">Art</Link>
                                </li>
                                <li>
                                    <Link to="">Music</Link>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                    <div>
                        <div className="widget">
                            <h5>Resources</h5>
                            <ul>
                                <li>
                                    <Link to="">Help Center</Link>
                                </li>
                                <li>
                                    <Link to="">Docs</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <div className="widget">
                            <h5>Community</h5>
                            <ul>
                                <li>
                                    <Link to="">Community</Link>
                                </li>
                                <li>
                                    <Link to="">Documentation</Link>
                                </li>
                                <li>
                                    <Link to="">Blog</Link>
                                </li>
                                <li>
                                    <Link to="">FAQ</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <div className="widget">
                            <h5>Newsletter</h5>

                            <form
                                className="row form-dark"
                                id="form_subscribe"
                                name="form_subscribe"
                                action={config.newsletter.endpoint}
                                method="post"
                            >
                                <div
                                    className="col text-center"
                                    style={{ display: "flex" }}
                                >
                                    <input
                                        className="form-control"
                                        id="txt_subscribe"
                                        placeholder="Email address"
                                        type="text"
                                        onChange={handleChangeEmail}
                                        name="email"
                                    />
                                    <input
                                        type="hidden"
                                        name="l"
                                        value={listUuid}
                                    />
                                    <input
                                        id="btn-subscribe"
                                        type="submit"
                                        value="SUBSCRIBE"
                                        disabled={
                                            !email ||
                                            invalidEmailFormat ||
                                            !listUuid
                                        }
                                    />

                                    <div className="clearfix"></div>
                                </div>
                            </form>
                            <div className="spacer-20"></div>
                            {email && invalidEmailFormat && (
                                <p style={{ color: "red" }}>
                                    Invalid email format
                                </p>
                            )}
                            {!listUuid && (
                                <p style={{ color: "red" }}>
                                    Listmonk configuration is not ready
                                </p>
                            )}
                            <p>
                                Signup for our newsletter to get the latest news
                                in your inbox.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="subfooter">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="de-flex">
                                <div className="de-flex-col">
                                    <span
                                        onClick={() => window.open("", "_self")}
                                    >
                                        <AppLogo isDark />
                                    </span>
                                </div>
                                <div className="de-flex-col">
                                    <span
                                        onClick={() => window.open("", "_self")}
                                    >
                                        <IdealSlogan forceDark />
                                    </span>
                                </div>
                                <div className="de-flex-col">
                                    <div className="social-icons">
                                        <span
                                            onClick={() =>
                                                window.open("", "_self")
                                            }
                                        >
                                            <i className="fa fa-facebook fa-lg"></i>
                                        </span>
                                        <span
                                            onClick={() =>
                                                window.open("", "_self")
                                            }
                                        >
                                            <i className="fa fa-twitter fa-lg"></i>
                                        </span>
                                        <span
                                            onClick={() =>
                                                window.open("", "_self")
                                            }
                                        >
                                            <i className="fa fa-linkedin fa-lg"></i>
                                        </span>
                                        <span
                                            onClick={() =>
                                                window.open("", "_self")
                                            }
                                        >
                                            <i className="fa fa-pinterest fa-lg"></i>
                                        </span>
                                        <span
                                            onClick={() =>
                                                window.open("", "_self")
                                            }
                                        >
                                            <i className="fa fa-rss fa-lg"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};
export default Footer;
