import { Tooltip } from "@material-ui/core";

const TagItem = ({ tag, onClickTag }) => {
    return (
        <Tooltip title="Click to remove tag">
            <div
                className="col-lg-4 col-md-6 col-sm-6"
                onClick={() => onClickTag(tag)}
            >
                <div className="nft_attr">
                    <h5>{tag}</h5>
                </div>
            </div>
        </Tooltip>
    );
};

export default TagItem;
