import BalanceItem from "../../../../components/balanceItem/BalanceItem";

const NFTShareHoldersContent = ({
    cid,
    totalShares,
    balances,
    proposeAnOffer,
}) => {
    const sortedBalances = balances
        ? balances?.slice()?.sort((a, b) => {
              return Number(b?.amount || 0) - Number(a?.amount || 0);
          })
        : [];

    return (
        <div className="tab-1 onStep fadeIn">
            {sortedBalances?.length ? (
                sortedBalances?.map((balance) => (
                    <BalanceItem
                        key={balance?.id}
                        balance={balance}
                        totalShares={totalShares}
                        cid={cid}
                        proposeAnOffer={proposeAnOffer}
                    />
                ))
            ) : (
                <div className="p_list">No share holder</div>
            )}
        </div>
    );
};

export default NFTShareHoldersContent;
