const config = {
    app: {
        name: process.env.REACT_APP_NAME || "IdealExchange-NFT",
    },
    network: {
        main: {
            chainId: process.env.REACT_APP_MAIN_NET_CHAIN_ID
                ? Number(process.env.REACT_APP_MAIN_NET_CHAIN_ID)
                : 1,
            name: process.env.REACT_APP_MAIN_NET_NAME,
            host: process.env.REACT_APP_MAIN_NET_HOST,
            link: process.env.REACT_APP_MAIN_NET_LINK,
            rpcUrl: process.env.REACT_APP_MAIN_NET_RPC_URL,
        },
        test: {
            chainId: process.env.REACT_APP_TEST_NET_CHAIN_ID
                ? Number(process.env.REACT_APP_TEST_NET_CHAIN_ID)
                : 4,
            name: process.env.REACT_APP_TEST_NET_NAME,
            host: process.env.REACT_APP_TEST_NET_HOST,
            link: process.env.REACT_APP_TEST_NET_LINK,
            rpcUrl: process.env.REACT_APP_TEST_NET_RPC_URL,
        },
        dev: {
            chainId: process.env.REACT_APP_DEV_NET_CHAIN_ID
                ? Number(process.env.REACT_APP_DEV_NET_CHAIN_ID)
                : 1338,
            name: process.env.REACT_APP_DEV_NET_NAME,
            host: process.env.REACT_APP_DEV_NET_HOST,
            link: process.env.REACT_APP_DEV_NET_LINK,
            rpcUrl: process.env.REACT_APP_DEV_NET_RPC_URL,
        },
        local: {
            chainId: process.env.REACT_APP_LOCAL_NET_CHAIN_ID
                ? Number(process.env.REACT_APP_LOCAL_NET_CHAIN_ID)
                : 31337,
            name: process.env.REACT_APP_LOCAL_NET_NAME,
            host: process.env.REACT_APP_LOCAL_NET_HOST,
            link: process.env.REACT_APP_LOCAL_NET_LINK,
            rpcUrl: process.env.REACT_APP_LOCAL_NET_RPC_URL,
        },
        targetNetwork: process.env.REACT_APP_TARGET_NETWORK,
    },
    nft: {
        ipsUrl:
            process.env.REACT_APP_DAPP_IPS_URL || "http://localhost:4000/ips",
        filesUrl:
            process.env.REACT_APP_DAPP_FILES_URL ||
            "http://localhost:4000/files",
        fileSizeLimit: process.env.REACT_APP_NFT_SIZE_LIMIT_MB
            ? Number(process.env.REACT_APP_NFT_SIZE_LIMIT_MB)
            : 100,
    },
    newsletter: {
        endpoint: process.env.REACT_APP_NEWSLETTER_FORM_ENDPOINT
    }
};

export default config;
