import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "@reach/router";
import ROUTES from "../constants/routes";
import NFTAuthorPicture from "../nftCard/authorPicture/NFTAuthorPicture";
import getImagePreview from "../../utils/nft/getImagePreview";
import Clock from "../Clock";
import LikeButton from "../nftLike/button/LikeButton";
import useNftUrl from "../../hooks/useNftUrl/useNftUrl";
import { defaultValidUntil } from "../../constants/blockchain";
import { useContext } from "react";
import { AuthContext } from "../../providers/Auth/AuthProvider";

const Outer = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 8px;
`;

const PermitCard = ({
    permit,
    className = "d-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4",
    clockTop = true,
    height,
    onImgLoad,
}) => {
    const { authContext } = useContext(AuthContext);
    const navigate = useNavigate();

    const navigateToDetails = () => {
        navigate(`${ROUTES.nftPermit}/${permit?.ip?.cid}`);
    };

    const nftUrl = useNftUrl(permit?.ip?.cid);

    const [likesCount, setLikesCount] = useState(permit?.ip?.likesCount);
    const [likedByMe, setLikedByMe] = useState(permit?.ip?.likedByMe);

    const onCompleted = (data) => {
        if (data && data.likeOrDislikeIp) {
            if (data.likeOrDislikeIp.liked) {
                setLikedByMe(true);
                setLikesCount(likesCount + 1);
            } else {
                setLikedByMe(false);
                if (likesCount > 0) {
                    setLikesCount(likesCount - 1);
                }
            }
        }
    };

    useEffect(() => {
        setLikedByMe(permit?.ip?.likedByMe);
        setLikesCount(permit?.ip?.likesCount);
    }, [permit?.ip]);

    const isMinted = permit?.ip?.privacyStatus === "minted";
    return (
        <div className={className}>
            <div className="nft__item m-0">
                <div className="icontype">
                    <i className="fa fa-bookmark"></i>
                </div>
                <NFTAuthorPicture
                    address={permit?.moderator?.id}
                    src={permit?.moderator?.profilePictureUri}
                />
                <div
                    className="nft__item_wrap"
                    style={{ height: `${height}px` }}
                >
                    <Outer>
                        <span>
                            <img
                                onLoad={onImgLoad}
                                src={getImagePreview({
                                    extension: permit?.ip?.mimetype,
                                    imageUrl: nftUrl,
                                })}
                                className="lazy nft__item_preview"
                                alt=""
                            />
                        </span>
                    </Outer>
                </div>
                {isMinted ? (
                    <div className="de_countdown">Minted</div>
                ) : (
                    <>
                        {!permit?.isExpired &&
                            permit?.validUntil &&
                            permit?.deadline &&
                            permit?.deadline < defaultValidUntil && (
                                <div className="de_countdown">
                                    <Clock deadline={permit?.validUntil} />
                                </div>
                            )}
                        {permit?.isExpired && (
                            <div className="de_countdown">Expired</div>
                        )}
                    </>
                )}

                <div className="nft__item_info">
                    <span onClick={navigateToDetails}>
                        <h4>{permit?.ip?.title}</h4>
                    </span>
                    <div className="nft__item_price">
                        {permit?.user ? (
                            <div>
                                Permission given to{" "}
                                {authContext?.myProfile?.id === permit?.user?.id
                                    ? "You"
                                    : permit?.user?.userName ||
                                      permit?.user?.id}
                            </div>
                        ) : null}
                    </div>
                    {/* <div className="nft__item_action">
                        <span onClick={navigateToDetails}>View details</span>
                    </div> */}
                    <div className="nft__item_like">
                        <LikeButton
                            cid={permit?.ip?.cid}
                            likedByMe={likedByMe}
                            onCompleted={onCompleted}
                        />
                        <span>{likesCount}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(PermitCard);
