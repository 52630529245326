import React, { useContext, useState } from "react";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";
import { useQuery } from "@apollo/client";
import Footer from "../../components/footer/Footer";
import { GET_GLOBAL_ACTIVITY } from "../../subgraph/activity/query";
import { GraphNodeClientContext } from "../../providers/Apollo/ApolloProvider";
import ActivityItem from "./item/ActivityItem";
import CustomLoader from "../../components/customLoader/CustomLoader";
import CustomButton from "../../components/button/CustomButton";
import { SnackBarContext } from "../../providers/SnackBar/SnackBarProvider";
import HeaderWithImage from "../../components/header/HeaderWithImage";

const PAGE_SIZE = 10;

const Activity = () => {
    const { isDark } = useContext(ThemeContext);
    const { graphNodeClient } = useContext(GraphNodeClientContext);
    const { displaySnackBar } = useContext(SnackBarContext);
    const [noMoreToFetch, setNoMoreToFetch] = useState(false);
    const [activityFilter, setActivityFilter] = useState(undefined);

    const { data, loading, fetchMore } = useQuery(GET_GLOBAL_ACTIVITY, {
        fetchPolicy: "cache-and-network",
        client: graphNodeClient,
        variables: {
            skip: 0,
            first: PAGE_SIZE,
            where: { activity: activityFilter },
        },
    });

    const activities = data?.activities || [];

    const handleFetchMore = () => {
        if (activities?.length) {
            fetchMore({
                variables: {
                    skip: activities?.length,
                    take: PAGE_SIZE,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    if (!fetchMoreResult?.activities?.length) {
                        setNoMoreToFetch(true);
                        displaySnackBar({
                            message: "No more activity",
                            type: "info",
                        });
                        return previousResult;
                    }

                    return {
                        ...previousResult,
                        activities: [
                            ...previousResult?.activities,
                            ...fetchMoreResult?.activities,
                        ],
                    };
                },
            });
        }
    };

    const handleChangeFilter = (filter) => {
        if (activityFilter === filter) {
            setActivityFilter(undefined);
        } else {
            setActivityFilter(filter);
        }

        setNoMoreToFetch(false);
    };

    return (
        <div className={isDark ? "greyscheme" : ""}>
            {loading && <CustomLoader />}

            <HeaderWithImage title={"title.catalog.subtitle.activity"} />

            <section className="container">
                <div className="row">
                    <div className="col-md-8">
                        {activities?.length ? (
                            <ul className="activity-list">
                                {activities?.map((activity) => (
                                    <ActivityItem activity={activity} />
                                ))}
                                {!noMoreToFetch && (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <CustomButton
                                            onClick={handleFetchMore}
                                            text="Load More"
                                            disabled={loading}
                                        />
                                    </div>
                                )}
                            </ul>
                        ) : (
                            <div>No activity.</div>
                        )}
                    </div>

                    <div className="col-md-4">
                        <span className="filter__l">Filter</span>
                        <span
                            className="filter__r"
                            onClick={() => handleChangeFilter(undefined)}
                        >
                            Reset
                        </span>
                        <div className="spacer-half"></div>
                        <div className="clearfix"></div>
                        <ul className="activity-filter">
                            <li
                                id="sale"
                                className={
                                    activityFilter === "PAYMENT"
                                        ? "filter_by_sales active"
                                        : "filter_by_sales"
                                }
                                onClick={() => handleChangeFilter("PAYMENT")}
                            >
                                <i className="fa fa-shopping-basket"></i>Sales
                            </li>

                            <li
                                id="follow"
                                className={
                                    activityFilter === "TRANSFER"
                                        ? "filter_by_followings active"
                                        : "filter_by_followings"
                                }
                                onClick={() => handleChangeFilter("TRANSFER")}
                            >
                                <i className="fa fa-check"></i>Mints
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
};
export default Activity;
