const titles = {
    "title.home": "Home",

    "title.catalog": "Catalog",
    "title.catalog.subtitle.explore": "Invest",
    "title.catalog.subtitle.top.sellers": "Top Projects",
    "title.catalog.subtitle.popular.items": "Popular Ideas",
    "title.catalog.subtitle.activity": "Activity",

    "title.my.portfolio": "My Portfolio",
    "title.my.portfolio.subtitle.create": "Create",
    "title.my.portfolio.subtitle.my.created": "IP",
    "title.my.portfolio.subtitle.ownership": "Dream Investments",
    "title.my.portfolio.subtitle.coownership": "My Shared Dreams",

    "title.community": "Community",
    "title.community.subtitle.pro": "Pro : Tier A",
    "title.community.subtitle.advanced": "Advanced : Tier B",
    "title.community.subtitle.beginner": "Beginner : Tier C",
};

export default titles;
