import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import Clock from "./../Clock";
import { useNavigate } from "@reach/router";
import LOCAL_STORAGE_KEYS from "../../constants/localStorage";
import axios from "axios";
import config from "../../../config/config";
import getImagePreview from "../../utils/nft/getImagePreview";
import ROUTES from "../constants/routes";
import NFTAuthorPicture from "./authorPicture/NFTAuthorPicture";
import { ethers } from "ethers";
import LikeButton from "../nftLike/button/LikeButton";
import { GET_NFT } from "../../graphql/nft/query";
import { useQuery } from "@apollo/client";

const Outer = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 8px;
`;

const NFTCardCid = ({
    cid,
    className = "d-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4",
    clockTop = true,
    height,
    onImgLoad,
}) => {
    const navigate = useNavigate();

    const navigateToDetails = () => {
        navigate(`${ROUTES.nftDetail}/${cid}`);
    };

    const [nftUrl, setNftUrl] = useState(undefined);
    const [likesCount, setLikesCount] = useState(0);
    const [likedByMe, setLikedByMe] = useState(false);

    const { data } = useQuery(GET_NFT, {
        variables: { id: cid },
        onCompleted: (data) => {
            if (data && data.getIp) {
                setLikesCount(data?.getIp?.likesCount || 0);
                setLikedByMe(data?.getIp?.likedByMe || false);
            }
        },
    });

    const nft = data?.getIp;

    const sales = nft?.sales || [];

    const Authorization = `Bearer ${localStorage.getItem(
        LOCAL_STORAGE_KEYS.TOKEN
    )}`;

    const onCompleted = (data) => {
        if (data && data.likeOrDislikeIp) {
            if (data.likeOrDislikeIp.liked) {
                setLikedByMe(true);
                setLikesCount(likesCount + 1);
            } else {
                setLikedByMe(false);
                if (likesCount > 0) {
                    setLikesCount(likesCount - 1);
                }
            }
        }
    };

    useEffect(() => {
        if (nft?.cid) {
            axios
                .get(`${config.nft.ipsUrl}/${nft.cid}`, {
                    headers: {
                        Authorization,
                    },
                    responseType: "blob",
                })
                .then((res) => {
                    const blobURL = URL.createObjectURL(res.data);
                    setNftUrl(blobURL);
                });
        }
    }, [nft, Authorization]);

    useEffect(() => {
        setLikedByMe(nft?.likedByMe);
        setLikesCount(nft?.likesCount);
    }, [nft]);

    const salesTotalShares = sales
        .map((s) => Number(ethers.utils.formatEther(s?.sale?.item?.value)))
        .reduce((partialSum, a) => partialSum + a, 0);

    const salesTotalPrice = sales
        .map((s) => Number(ethers.utils.formatEther(s?.sale?.payment?.value)))
        .reduce((partialSum, a) => partialSum + a, 0);

    const averageSalesPrice = salesTotalShares
        ? salesTotalPrice / salesTotalShares
        : 0;

    return (
        <div className={className}>
            <div className="nft__item m-0">
                <div className="icontype">
                    <i className="fa fa-bookmark"></i>
                </div>
                <NFTAuthorPicture
                    address={nft?.user?.id}
                    src={nft?.user?.profilePictureUri}
                />
                <div
                    className="nft__item_wrap"
                    style={{ height: `${height}px` }}
                >
                    <Outer>
                        <span>
                            <img
                                onLoad={onImgLoad}
                                src={getImagePreview({
                                    extension: nft?.mimetype,
                                    imageUrl: nftUrl,
                                })}
                                className="lazy nft__item_preview"
                                alt=""
                            />
                        </span>
                    </Outer>
                </div>
                {nft?.sale?.validUntil && (
                    <div className="de_countdown">
                        <Clock deadline={nft?.sale?.validUntil} />
                    </div>
                )}
                <div className="nft__item_info">
                    <span onClick={navigateToDetails}>
                        <h4>{nft?.title}</h4>
                    </span>
                    <div className="nft__item_price">
                        {averageSalesPrice ? (
                            <div>
                                {averageSalesPrice.toFixed(4)} ETH / Share
                            </div>
                        ) : null}
                        {/* {nft.royalties ? <div>{nft.royalties}%</div> : null} */}
                        {sales?.length ? (
                            <div>
                                {sales.length} Active sales ({salesTotalShares}{" "}
                                Shares)
                            </div>
                        ) : null}
                    </div>
                    {/* <div className="nft__item_action">
                        <span onClick={navigateToDetails}>View details</span>
                    </div> */}
                    <div className="idx">IDX</div>
                    <div className="nft__item_like">
                        <LikeButton
                            cid={nft?.cid}
                            likedByMe={likedByMe}
                            onCompleted={onCompleted}
                        />
                        <span>{likesCount}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(NFTCardCid);
