import React, { useContext, useState } from "react";
import CustomButton from "../button/CustomButton";
import CustomInput from "../input/CustomInput";
import COLORS from "../../constants/colors";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";

const AddTag = ({ tags, setTags }) => {
    const { isDark } = useContext(ThemeContext);
    const [newTag, setNewTag] = useState("");

    const handleAddTag = () => {
        if (newTag) {
            setTags([...tags, newTag]);
            setNewTag("");
        }
    };

    const handleChangeNewTag = (value) => {
        setNewTag(value);
    };

    return (
        <div className="switch-with-title">
            <h5>
                <i className="fa fa- fa-list id-color-2 mr10"></i>
                Tags
            </h5>
            <CustomInput
                placeholder="Write a tag and then click on the add button"
                value={newTag}
                handleChangeValue={handleChangeNewTag}
            />
            <div
                className="de-switch"
                style={{ float: "left", marginTop: -10 }}
            >
                <CustomButton
                    text="+"
                    className="btn-main inline white lead"
                    onClick={handleAddTag}
                    disabled={!newTag}
                    style={{
                        marginRight: 0,
                        borderStyle: !isDark ? "solid" : undefined,
                        borderWidth: !isDark ? 1 : undefined,
                        borderColor: !isDark ? COLORS.light.color : undefined,
                    }}
                />
            </div>
            <div className="clearfix"></div>
        </div>
    );
};

export default AddTag;
