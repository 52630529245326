const header = {
    "header.home": "Home",
    "header.about": "About",
    "header.my.profile": "My Profile",
    "header.signin": "Sign in",
    "header.choose.wallet": "Choose wallet",
    "header.signout": "Logout",
};

export default header;
