export const LAYOUT_HEIGHT = 84;
export const GLOBAL_NET_BANNER_HEIGHT = 48;
export const FONT_SIZES = {
    common: {
        screenTitle: 40,
        larger: 20,
        large: 18,
        normal: 16,
        small: 14,
    },
    userProfile: {
        name: 30,
    },
};
