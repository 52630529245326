import { gql } from "@apollo/client";

export const GET_GLOBAL_ACTIVITY = gql`
    query Activities($skip: Int, $first: Int, $where: Activity_filter) {
        activities(
            orderBy: timestamp
            orderDirection: desc
            skip: $skip
            first: $first
            where: $where
        ) {
            id
            activity
            timestamp
            itemContract {
                ... on NFTERC20 {
                    id
                    content
                    title
                }
            }
            ... on Payment {
                id
                owner {
                    address
                }
                buyer {
                    address
                }
                shares
                value
            }
            ... on Transfer {
                id
                from {
                    address
                }
                to {
                    address
                }
                amount
            }
        }
    }
`;
