import { Link } from "@reach/router";
import { memo } from "react";
import ROUTES from "../constants/routes";

const HelpItem = ({ help, isPreviewMode }) => {
    return (
        <div className={isPreviewMode ? "" : "col-lg-4 col-md-6 mb-4"}>
            <div className="feature-box f-boxed style-3 text-center">
                <div className="text">
                    <h4>{help?.title}</h4>
                    <p>{help?.description}</p>
                    {!isPreviewMode && (
                        <Link
                            to={`${ROUTES.helpDetail}/${help?.id}`}
                            className="btn-main m-auto"
                        >
                            Read more
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
};
export default memo(HelpItem);
