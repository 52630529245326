import { gql } from "@apollo/client";
import {
    HELP_FRAGMENT,
    PARAGRAPH_FRAGMENT,
    SUB_HELP_FRAGMENT,
} from "./fragment";

export const CREATE_HELP = gql`
    mutation CreateHelp($input: CreateHelpInput!) {
        createHelp(input: $input) {
            ...HelpFields
        }
    }
    ${HELP_FRAGMENT}
`;

export const UPDATE_HELP = gql`
    mutation UpdateHelp($input: UpdateHelpInput!) {
        updateHelp(input: $input) {
            ...HelpFields
        }
    }
    ${HELP_FRAGMENT}
`;

export const DELETE_HELP = gql`
    mutation DeleteHelp($id: Int!) {
        deleteHelp(id: $id) {
            ...HelpFields
        }
    }
    ${HELP_FRAGMENT}
`;

export const CREATE_SUB_HELP = gql`
    mutation CreateSubHelp($input: AddSubHelpToHelpInput!) {
        createSubHelp(input: $input) {
            ...SubHelpFields
        }
    }
    ${SUB_HELP_FRAGMENT}
`;

export const UPDATE_SUB_HELP = gql`
    mutation UpdateSubHelp($input: UpdateSubHelpInput!) {
        updateSubHelp(input: $input) {
            ...SubHelpFields
        }
    }
    ${SUB_HELP_FRAGMENT}
`;

export const DELETE_SUB_HELP = gql`
    mutation DeleteSubHelp($id: Int!) {
        deleteSubHelp(id: $id) {
            ...SubHelpFields
        }
    }
    ${SUB_HELP_FRAGMENT}
`;

export const CREATE_PARAGRAPH = gql`
    mutation CreateParagraph($input: AddParagraphToSubHelpInput!) {
        createParagraph(input: $input) {
            ...ParagraphFields
        }
    }
    ${PARAGRAPH_FRAGMENT}
`;

export const UPDATE_PARAGRAPH = gql`
    mutation UpdateParagraph($input: UpdateParagraphInput!) {
        updateParagraph(input: $input) {
            ...ParagraphFields
        }
    }
    ${PARAGRAPH_FRAGMENT}
`;

export const DELETE_PARAGRAPH = gql`
    mutation DeleteParagraph($id: Int!) {
        deleteParagraph(id: $id) {
            ...ParagraphFields
        }
    }
    ${PARAGRAPH_FRAGMENT}
`;
