import { memo } from "react";
import { useQuery } from "@apollo/client";
import { GET_BLOGS } from "../../graphql/blog/query";
import CustomLoader from "../customLoader/CustomLoader";
import BlogItem from "./BlogItem";

const BlogsList = () => {
    const { data, loading } = useQuery(GET_BLOGS, {
        fetchPolicy: "cache-and-network",
    });

    const blogs = data?.getBlogs || [];

    return (
        <div className="row">
            {loading && <CustomLoader />}
            {blogs &&
                blogs.map((blog, index) => (
                    <BlogItem blog={blog} key={index} />
                ))}

            <div className="spacer-single"></div>

            {/* <ul className="pagination">
                <li>
                    <span className="a">Prev</span>
                </li>
                <li className="active">
                    <span className="a">1</span>
                </li>
                <li>
                    <span className="a">2</span>
                </li>
                <li>
                    <span className="a">3</span>
                </li>
                <li>
                    <span className="a">4</span>
                </li>
                <li>
                    <span className="a">5</span>
                </li>
                <li>
                    <span className="a">Next</span>
                </li>
            </ul> */}
        </div>
    );
};
export default memo(BlogsList);
