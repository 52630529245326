import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useMutation, useQuery } from "@apollo/client";
import { GET_NFT_TO_PERMIT } from "../../graphql/nft/query";
import CustomLoader from "../../components/customLoader/CustomLoader";
import CustomButton from "../../components/button/CustomButton";
import { AuthContext } from "../../providers/Auth/AuthProvider";
import getImagePreview from "../../utils/nft/getImagePreview";
import config from "../../../config/config";
import LOCAL_STORAGE_KEYS from "../../constants/localStorage";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";
import NFTAuthorPicture from "../../components/nftCard/authorPicture/NFTAuthorPicture";
import { ethers } from "ethers";
import { SnackBarContext } from "../../providers/SnackBar/SnackBarProvider";
import contract from "../../utils/blockchain/contract";
import { NetworkContext } from "../../providers/NetTester/NetTesterProvider";
import { getIpId, PERMIT_TYPE, partialDomainNFT } from "common";
import { GraphNodeClientContext } from "../../providers/Apollo/ApolloProvider";
import { GET_SUBGRAPH_NFT } from "../../subgraph/nft/query";
import Footer from "../../components/footer/Footer";
import CustomAddress from "../../components/address/CustomAddress";
import { formatNumber } from "../../utils/string/formatNumber";
import CustomGGLandDialog from "../../components/dialog/CustomGGLandDialog";
import NFTLikesList from "../../components/nftLike/NFTLikesList";
import LikeButton from "../../components/nftLike/button/LikeButton";
import { Tooltip } from "@material-ui/core";
import { useIntl } from "react-intl";
import NFTDetailsContent from "../nftDetail/contents/details/NFTDetailsContent";
import { SIGN_PERMIT_MUTATION } from "../../graphql/permit/mutation";
import CustomInput from "../../../components/components/input/CustomInput";
import moment from "moment";
import { defaultValidUntil } from "../../constants/blockchain";
import Clock from "../../components/Clock";
import ROUTES from "../../components/constants/routes";
import { useNavigate } from "@reach/router";
import useIsUserRole from "../../hooks/useIsUserRole/useIsUserRole";
import { DENY_NFT_MUTATION } from "../../graphql/deny/mutation";

const NFTPermit = ({ cid }) => {
    const { formatMessage } = useIntl();
    const { authContext, isAdmin, isModerator } = useContext(AuthContext);
    const { graphNodeClient } = useContext(GraphNodeClientContext);

    const [nftUrl, setNftUrl] = useState(undefined);
    const { isDark } = useContext(ThemeContext);
    const navigate = useNavigate();
    const { displaySnackBar } = useContext(SnackBarContext);
    const { isNetworkMismatched, isUnsupportedNetwork } =
        useContext(NetworkContext);
    const [mintedNft, setMintedNft] = useState(null);
    const [loadingMint, setLoadingMint] = useState(false);
    const [showLikesList, setShowLikesList] = useState(false);
    const [showPermiModal, setShowPermitModal] = useState(false);
    const [validUntil, setValidUntil] = useState("");
    const [showDenyModal, setShowDenyModal] = useState(false);
    const [internalMessage, setInternalMessage] = useState("");
    const [publicMessage, setPublicMessage] = useState("");

    const contractAddress = contract?.contracts?.IdealNFT?.address;
    const contractAbi = contract?.contracts?.IdealNFT?.abi;
    let contractInstance = new ethers.Contract(contractAddress, contractAbi);

    contractInstance = authContext?.wallet?.signer
        ? contractInstance.connect(authContext?.wallet?.signer)
        : null;

    const handleExpirationDate = (value) => {
        setValidUntil(value);
    };

    const validUntilDate = moment(validUntil);
    const todayDate = moment();

    const invalidValidUntil = !!(
        validUntil &&
        validUntilDate &&
        validUntilDate.isBefore(todayDate)
    );

    const wallet = authContext?.wallet;
    const provider = wallet?.provider;

    const nftErc20Id = getIpId({
        IdealNFTAddress: contract?.contracts?.IdealNFT?.address,
        ip: {
            contentType: 1,
            content: ethers.utils.toUtf8Bytes(cid),
        },
        chainId: contract?.chainId,
    });

    const { loading: loadingGraphnode } = useQuery(GET_SUBGRAPH_NFT, {
        fetchPolicy: "cache-and-network",
        variables: {
            id: nftErc20Id,
        },
        client: graphNodeClient,
        pollInterval: 15 * 1000,
        onCompleted: (data) => {
            if (data && data.nfterc20) {
                setMintedNft(data.nfterc20);
            }
        },
    });

    const { data, loading } = useQuery(GET_NFT_TO_PERMIT, {
        variables: { id: cid },
        fetchPolicy: "cache-and-network",
    });

    const extension =
        data && data.getIp && data.getIp.mimetype ? data.getIp.mimetype : "";

    const Authorization = `Bearer ${localStorage.getItem(
        LOCAL_STORAGE_KEYS.TOKEN
    )}`;

    const nft = data ? data.getIp : undefined;
    const nftCategories = nft?.categories || [];
    const isMinted = nft?.privacyStatus === "minted";

    const [permitMutation] = useMutation(SIGN_PERMIT_MUTATION, {
        onCompleted: (data) => {
            setLoadingMint(false);
            setShowPermitModal(false);
            setValidUntil("");
            if (data) {
                displaySnackBar({
                    message: `You have successfully signed a permit to allow the creator to mint this item : ${nft?.title}`,
                    type: "success",
                });
            }
        },
        onError: (error) => {
            setLoadingMint(false);
            setShowPermitModal(false);
            setValidUntil("");
            displaySnackBar({
                message: error.message,
                type: "error",
            });
        },
        refetchQueries: [{ query: GET_NFT_TO_PERMIT, variables: { id: cid } }],
    });

    const closeDenyModal = () => {
        setShowDenyModal(false);
        setInternalMessage("");
        setPublicMessage("");
    };

    const [denyMutation] = useMutation(DENY_NFT_MUTATION, {
        onCompleted: (data) => {
            closeDenyModal();
            if (data) {
                displaySnackBar({
                    message: `You have successfully denied the creator to mint this item : ${nft?.title}`,
                    type: "success",
                });
            }
        },
        onError: (error) => {
            closeDenyModal();
            displaySnackBar({
                message: error.message,
                type: "error",
            });
        },
        refetchQueries: [{ query: GET_NFT_TO_PERMIT, variables: { id: cid } }],
    });

    useEffect(() => {
        if (cid) {
            axios
                .get(`${config.nft.ipsUrl}/${cid}`, {
                    headers: {
                        Authorization,
                    },
                    responseType: "blob",
                })
                .then((res) => {
                    const blobURL = URL.createObjectURL(res.data);
                    setNftUrl(blobURL);
                });
        }
    }, [Authorization, cid, data]);

    useEffect(() => {
        if (!isAdmin && !isModerator) {
            displaySnackBar({
                message:
                    "Only admin or moderators can have access to this interface",
                type: "error",
            });
            navigate(ROUTES.home);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAdmin]);

    const graphnodeTotalShares = mintedNft?.totalShares;
    const graphqlTotalShares = nft?.shareCount || 0;
    const totalShares = graphnodeTotalShares
        ? ethers.utils.formatEther(graphnodeTotalShares)
        : ethers.utils.formatEther(graphqlTotalShares);

    const openPermiModal = () => {
        setShowPermitModal(true);
    };

    const openDenyModal = () => {
        setShowDenyModal(true);
    };

    const handlePermitToMint = async () => {
        if (isNetworkMismatched || isUnsupportedNetwork) {
            displaySnackBar({
                message: formatMessage({
                    id: "net.issue.block.transaction",
                }),
                type: "error",
            });
            return;
        }

        const contractAddress = contract?.contracts?.IdealNFT?.address;

        try {
            setLoadingMint(true);

            const chainId = provider?.provider.chainId;

            const domain = {
                ...partialDomainNFT,
                chainId,
                verifyingContract: contractAddress,
            };
            const types = { Permit: PERMIT_TYPE };
            const moderator = wallet.address;

            const value = {
                moderator,
                user: nft?.user?.id,
                tokenId: nftErc20Id,
                blockHeight: "0x00",
                deadline: validUntil
                    ? Math.floor(new Date(validUntil).getTime() / 1000)
                    : defaultValidUntil,
                salt: ethers.utils.hexlify(ethers.utils.randomBytes(32)),
            };

            const signature = await wallet.signer._signTypedData(
                domain,
                types,
                value
            );

            permitMutation({
                variables: {
                    permit: {
                        ...value,
                        ip: cid,
                    },
                    signature,
                },
            });
        } catch (error) {
            console.log(`error : `, error);
            setLoadingMint(false);

            if (
                error &&
                error.message &&
                error.message === "Internal JSON-RPC error."
            ) {
                displaySnackBar({
                    message: "You have already minted this NFT.",
                    type: "error",
                });
            } else {
                displaySnackBar({
                    message: "Error occured while minting the NFT",
                    type: "error",
                });
            }
        }
    };

    const permitterId = nft?.permit?.moderator?.id;
    const denierId = nft?.deny?.moderator?.id;
    const userRole = useIsUserRole(permitterId, contractInstance);
    const denierRole = useIsUserRole(denierId, contractInstance);

    const handleChangeInternalDenyMessage = (value) => {
        setInternalMessage(value);
    };

    const handleChangePublicDenyMessage = (value) => {
        setPublicMessage(value);
    };

    const handleDeny = () => {
        denyMutation({
            variables: {
                input: {
                    cid,
                    internalReason: internalMessage,
                    publicReason: publicMessage,
                },
            },
        });
    };

    return (
        <div className={isDark ? "greyscheme" : ""}>
            {(loading || loadingGraphnode || loadingMint) && (
                <CustomLoader
                    text={loadingMint ? "Proceeding to Mint..." : undefined}
                />
            )}

            <section className="container">
                <div className="row mt-md-5 pt-md-4">
                    {/* ------------------ NFT Image start ------------------ */}
                    <div
                        className="col-md-6 text-center"
                        style={{ height: "fit-content", width: "40%" }}
                    >
                        <img
                            src={getImagePreview({
                                extension,
                                imageUrl: nftUrl,
                            })}
                            className="img-fluid img-rounded mb-sm-30"
                            alt=""
                        />
                    </div>
                    {/* ------------------ NFT Image end ------------------ */}

                    <div className="col-md-6" style={{ width: "60%" }}>
                        <div className="item_info">
                            {/* Auctions ends in
                            <div className="de_countdown">
                                <Clock deadline="December, 30, 2021" />
                            </div> */}

                            {/* ------------------ Infos start ------------------ */}
                            <h2>{mintedNft ? mintedNft?.title : nft?.title}</h2>
                            <div className="nft__item_like">
                                <LikeButton
                                    cid={nft?.cid}
                                    likedByMe={nft?.likedByMe}
                                    refetchQueries={[
                                        {
                                            query: GET_NFT_TO_PERMIT,
                                            variables: {
                                                id: cid,
                                            },
                                        },
                                    ]}
                                    style={{
                                        fontSize: 25,
                                        cursor: "pointer",
                                    }}
                                />
                            </div>

                            <CustomAddress
                                address={mintedNft?.id}
                                maxWidth={400}
                            />

                            {/* <div className="nft__item_price">
                                {nft?.price || 0} ETH
                                <span>1/20</span>
                            </div> */}
                            <div className="item_info_counts">
                                {nftCategories?.map((category) => (
                                    <div className="item_info_type">
                                        <i className="fa fa-image"></i>
                                        {category?.name}
                                    </div>
                                ))}
                                <div className="item_info_views">
                                    <i className="fa fa-eye"></i>0
                                </div>
                                <Tooltip title="View likes">
                                    <div
                                        className="item_info_like"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setShowLikesList(true)}
                                    >
                                        <i className="fa fa-heart"></i>
                                        {nft?.likesCount}
                                    </div>
                                </Tooltip>
                            </div>
                            <p>
                                {mintedNft
                                    ? mintedNft?.description
                                    : nft?.description}
                            </p>

                            <h6>Creator</h6>
                            <div className="item_author">
                                <NFTAuthorPicture
                                    address={nft?.user?.id}
                                    src={nft?.user?.profilePictureUri}
                                />
                                <div
                                    className="author_list_info"
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        paddingTop: nft?.user?.userName && 0,
                                    }}
                                >
                                    {nft?.user?.userName && (
                                        <span>{nft?.user?.userName}</span>
                                    )}

                                    <CustomAddress address={nft?.user?.id} />
                                </div>
                            </div>

                            {nft?.permit && (
                                <div style={{ marginTop: 20 }}>
                                    <h6>Permitted by</h6>
                                    <div className="item_author">
                                        <NFTAuthorPicture
                                            address={nft?.permit?.moderator?.id}
                                            src={
                                                nft?.permit?.moderator
                                                    ?.profilePictureUri
                                            }
                                        />
                                        <div
                                            className="author_list_info"
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                paddingTop:
                                                    nft?.permit?.moderator
                                                        ?.userName && 0,
                                            }}
                                        >
                                            {nft?.permit?.moderator
                                                ?.userName && (
                                                <span>
                                                    {
                                                        nft?.permit?.moderator
                                                            ?.userName
                                                    }
                                                </span>
                                            )}

                                            <CustomAddress
                                                address={
                                                    nft?.permit?.moderator?.id
                                                }
                                            />
                                            <div
                                                style={{
                                                    fontSize: 16,
                                                    color: "#727272",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {userRole}
                                            </div>
                                            {!mintedNft &&
                                                !nft?.permit?.isExpired &&
                                                nft?.permit?.deadline &&
                                                nft?.permit?.deadline <
                                                    defaultValidUntil && (
                                                    <span>
                                                        Expired in
                                                        <div className="de_countdown">
                                                            <Clock
                                                                deadline={
                                                                    new Date(
                                                                        nft?.permit?.validUntil
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </span>
                                                )}
                                            {!mintedNft &&
                                                nft?.permit?.isExpired && (
                                                    <span>Expired</span>
                                                )}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {nft?.deny && (
                                <div style={{ marginTop: 20 }}>
                                    <h6>Denied by</h6>
                                    <div className="item_author">
                                        <NFTAuthorPicture
                                            address={nft?.deny?.moderator?.id}
                                            src={
                                                nft?.deny?.moderator
                                                    ?.profilePictureUri
                                            }
                                        />
                                        <div
                                            className="author_list_info"
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                paddingTop:
                                                    nft?.deny?.moderator
                                                        ?.userName && 0,
                                            }}
                                        >
                                            {nft?.deny?.moderator?.userName && (
                                                <span>
                                                    {
                                                        nft?.deny?.moderator
                                                            ?.userName
                                                    }
                                                </span>
                                            )}

                                            <CustomAddress
                                                address={
                                                    nft?.deny?.moderator?.id
                                                }
                                            />
                                            <div
                                                style={{
                                                    fontSize: 16,
                                                    color: "#727272",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {denierRole}
                                            </div>

                                            {!mintedNft &&
                                                nft?.permit?.isExpired && (
                                                    <span></span>
                                                )}
                                            {nft?.deny?.publicReason && (
                                                <div>
                                                    Reason :
                                                    <span
                                                        style={{
                                                            fontSize: 16,
                                                            color: "red",
                                                            fontWeight: "bold",
                                                            marginLeft: 10,
                                                        }}
                                                    >
                                                        {
                                                            nft?.deny
                                                                ?.publicReason
                                                        }
                                                    </span>
                                                </div>
                                            )}
                                            {nft?.deny?.internalReason &&
                                                (isAdmin || isModerator) && (
                                                    <div>
                                                        Internal reason :
                                                        <span
                                                            style={{
                                                                fontSize: 16,
                                                                color: "red",
                                                                fontWeight:
                                                                    "bold",
                                                                marginLeft: 10,
                                                            }}
                                                        >
                                                            {
                                                                nft?.deny
                                                                    ?.internalReason
                                                            }
                                                        </span>
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="spacer-40"></div>
                            <div
                                className="p_list_info"
                                style={{
                                    paddingLeft: 0,
                                    marginBottom: 20,
                                }}
                            >
                                <span style={{ marginBottom: 10 }}>
                                    Total :{" "}
                                    <b>{formatNumber(Number(totalShares))}</b>{" "}
                                    shares (
                                    {mintedNft ? "Minted" : "Not minted"})
                                </span>
                            </div>
                            {/* ------------------ Infos end ------------------ */}

                            <div className="de_tab">
                                <div className="de_tab_content">
                                    {/* ------------------ Contents start ------------------ */}
                                    <NFTDetailsContent
                                        nft={nft}
                                        mintedNft={mintedNft}
                                    />

                                    {/* ------------------ Contents end ------------------ */}
                                </div>
                                {/* ------------------ Buttons start ------------------ */}
                                {isMinted ||
                                nft?.isPermitted ||
                                nft?.isDenied ? (
                                    <CustomButton
                                        text={
                                            isMinted
                                                ? "Already minted"
                                                : nft?.isPermitted
                                                ? "Already permitted"
                                                : "Already denied"
                                        }
                                        disabled
                                        style={{
                                            marginTop: 25,
                                            marginRight: 25,
                                        }}
                                    />
                                ) : (
                                    <div style={{ display: "flex" }}>
                                        <CustomButton
                                            text="Permit to mint"
                                            onClick={openPermiModal}
                                            disabled={
                                                loading ||
                                                nft?.isPermitted ||
                                                nft?.isDenied
                                            }
                                            style={{
                                                marginTop: 25,
                                                marginRight: 25,
                                            }}
                                        />
                                        <CustomButton
                                            text="Deny to mint"
                                            onClick={openDenyModal}
                                            disabled={
                                                loading ||
                                                nft?.isPermitted ||
                                                nft?.isDenied
                                            }
                                            isWhite
                                            style={{
                                                marginTop: 25,
                                                marginRight: 25,
                                            }}
                                        />
                                    </div>
                                )}
                                {/* ------------------ Buttons end ------------------ */}
                            </div>
                        </div>
                    </div>
                </div>

                {showLikesList && (
                    <CustomGGLandDialog
                        title="Likes..."
                        handleClose={() => setShowLikesList(false)}
                        noSubmit
                    >
                        <NFTLikesList cid={nft?.cid} />
                    </CustomGGLandDialog>
                )}

                {showPermiModal && (
                    <CustomGGLandDialog
                        title="Permit to mint"
                        handleClose={() => {
                            setValidUntil("");
                            setShowPermitModal(false);
                        }}
                        noSubmit
                    >
                        <div className="tab-2 onStep fadeIn">
                            <div className="p_list">
                                Sign a mint permit to allow{" "}
                                {nft?.user?.userName || nft?.user?.id} to mint{" "}
                                {nft?.title}.
                                <p>
                                    You can choose an expiration date for your
                                    signature.
                                </p>
                                <CustomInput
                                    label="Expiration date"
                                    placeholder="enter the expiration date of your permit"
                                    value={
                                        validUntil
                                            ? moment(validUntil).format(
                                                  "YYYY-MM-DD"
                                              )
                                            : undefined
                                    }
                                    handleChangeValue={handleExpirationDate}
                                    type="date"
                                    min={moment(new Date()).format(
                                        "YYYY-MM-DD"
                                    )}
                                    error={invalidValidUntil}
                                    errorText="Expiration date must be a future date"
                                />
                                <CustomButton
                                    text="Sign mint permit"
                                    onClick={handlePermitToMint}
                                    disabled={loading}
                                    style={{
                                        marginTop: 25,
                                        marginRight: 25,
                                    }}
                                />
                            </div>
                        </div>
                    </CustomGGLandDialog>
                )}

                {showDenyModal && (
                    <CustomGGLandDialog
                        title="Deny to mint"
                        handleClose={() => {
                            setShowDenyModal(false);
                        }}
                        noSubmit
                    >
                        <div className="tab-2 onStep fadeIn">
                            <div className="p_list">
                                Disallow {nft?.user?.userName || nft?.user?.id}{" "}
                                to mint {nft?.title}.
                                <p>
                                    You can write additional messages justifying
                                    the reason why you denied this item : an
                                    internal message (for your moderating
                                    friends) and a public message (for the
                                    creator of the NFT and all your moderating
                                    friends).
                                </p>
                                <CustomInput
                                    label="Internal message"
                                    placeholder="Write a message for your moderating
                                    friends"
                                    value={internalMessage}
                                    handleChangeValue={
                                        handleChangeInternalDenyMessage
                                    }
                                    muiltiline
                                />
                                <CustomInput
                                    label="Public message"
                                    placeholder="Write a message for the creator of this NFT and your moderating
                                    friends"
                                    value={publicMessage}
                                    handleChangeValue={
                                        handleChangePublicDenyMessage
                                    }
                                    muiltiline
                                />
                                <CustomButton
                                    text="Deny"
                                    onClick={handleDeny}
                                    disabled={loading}
                                    className="btn-main inline white lead"
                                    style={{
                                        marginTop: 25,
                                        marginRight: 25,
                                    }}
                                />
                            </div>
                        </div>
                    </CustomGGLandDialog>
                )}
            </section>

            <Footer />
        </div>
    );
};
export default NFTPermit;
