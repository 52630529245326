import React, { memo, useState } from "react";
import NFTCard from "./NFTCard";

const NFTCardList = ({
    nfts,
    loadMore,
    showLoadMore,
    navigateToNFTPermit,
    featuredEditable,
}) => {
    const [height, setHeight] = useState(0);

    const onImgLoad = ({ target: img }) => {
        let currentHeight = height;
        if (currentHeight < img.offsetHeight) {
            setHeight(img.offsetHeight);
        }
    };

    return (
        <div className="row">
            {nfts &&
                nfts.map((nft, index) => (
                    <NFTCard
                        nft={nft}
                        key={index}
                        onImgLoad={onImgLoad}
                        height={height}
                        navigateToNFTPermit={navigateToNFTPermit}
                        featuredEditable={featuredEditable}
                    />
                ))}
            {showLoadMore && nfts.length && (
                <div className="col-lg-12">
                    <div className="spacer-single"></div>
                    <span onClick={loadMore} className="btn-main lead m-auto">
                        Load More
                    </span>
                </div>
            )}
        </div>
    );
};

export default memo(NFTCardList);
