import React, { useContext, useState } from "react";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";
import Footer from "../../components/footer/Footer";
import { useIntl } from "react-intl";
import { parse } from "query-string";
import { useLocation } from "@reach/router";
import ROUTES from "../../components/constants/routes";
import PendingIpsList from "../admin/pendingIps/PendingIpsList";
import MyPermits from "../admin/myPermits/MyPermits";
import MyDenies from "../admin/myDenies/MyDenies";
import HeaderWithImage from "../../components/header/HeaderWithImage";
import {
    ADMIN_TABS_ENUM,
    getAdminTabName,
    MODERATOR_TABS,
} from "../admin/tabs/adminHomeTabs";

const ModeratorHome = () => {
    const { isDark } = useContext(ThemeContext);
    const { formatMessage } = useIntl();
    const location = useLocation();
    const searchParams = parse(location.search);
    const [menu, setMenu] = useState(searchParams?.tab || "pendingIps");

    const handleClickMenu = (clickedMenu) => {
        setMenu(clickedMenu);
        window.history.pushState(
            {},
            ROUTES.adminHome,
            `${ROUTES.moderatorHome}?tab=${clickedMenu}`
        );
    };

    return (
        <div className={isDark ? "greyscheme" : ""}>
            <HeaderWithImage title={"Moderator"} />

            <section className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="items_filter">
                            <ul className="de_nav text-left">
                                {MODERATOR_TABS.map((tab) => (
                                    <li
                                        id="Mainbtn"
                                        className={menu === tab ? "active" : ""}
                                    >
                                        <span
                                            onClick={() => handleClickMenu(tab)}
                                        >
                                            {formatMessage({
                                                id: getAdminTabName(tab),
                                            })}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>

                {menu === ADMIN_TABS_ENUM.pendingIps && <PendingIpsList />}

                {menu === ADMIN_TABS_ENUM.myPermits && <MyPermits />}

                {menu === ADMIN_TABS_ENUM.myDenies && <MyDenies />}
            </section>

            <Footer />
        </div>
    );
};
export default ModeratorHome;
