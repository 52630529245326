import React, { memo, useContext } from "react";
import { CategoriesContecxt } from "../../providers/Categories/CategoriesProvider";
import CustomSelect from "../select/CustomSelect";

const ExploreFilter = ({
    titleFilter,
    handleChangeTitleFilter,
    categoryFilter,
    handleChangeCategoryFilter,
}) => {
    const { defaultSelectCategories, loadingCategories } =
        useContext(CategoriesContecxt);

    const onChangeTitle = (e) => {
        const { value, name } = e.target;
        if (handleChangeTitleFilter) handleChangeTitleFilter(value, name);
    };

    return (
        <div className="items_filter">
            <form
                className="row form-dark"
                id="form_quick_search"
                name="form_quick_search"
            >
                <div className="col">
                    <input
                        className="form-control"
                        id="name_1"
                        name="name_1"
                        placeholder="search item here..."
                        type="text"
                        value={titleFilter}
                        onChange={onChangeTitle}
                    />
                    <button id="btn-submit" disabled>
                        <i className="fa fa-search bg-color-secondary"></i>
                    </button>
                    <div className="clearfix"></div>
                </div>
            </form>
            <CustomSelect
                placeholder="Select category"
                options={defaultSelectCategories}
                value={categoryFilter}
                onChange={handleChangeCategoryFilter}
                noMaxWidth
                isLoading={loadingCategories}
            />
        </div>
    );
};

export default memo(ExploreFilter);
