import React, { useContext, useEffect, useState } from "react";
import Files from "react-files";
import { useMutation, useQuery } from "@apollo/client";
import { useIntl } from "react-intl";
import CustomInput from "../../../../../components/input/CustomInput";
import { SnackBarContext } from "../../../../../providers/SnackBar/SnackBarProvider";
import { useNavigate } from "@reach/router";
import { ThemeContext } from "../../../../../providers/Theme/ThemeProvider";
import ROUTES from "../../../../../components/constants/routes";
import config from "../../../../../../config/config";
import CustomLoader from "../../../../../components/customLoader/CustomLoader";
import HeaderWithImage from "../../../../../components/header/HeaderWithImage";
import CustomButton from "../../../../../components/button/CustomButton";
import { FONT_SIZES } from "../../../../../constants/sizes";
import Footer from "../../../../../components/footer/Footer";
import moment from "moment";
import AddTag from "../../../../../components/tag/AddTag";
import TagItem from "../../../../../components/tag/TagItem";
import { UPDATE_BLOG } from "../../../../../graphql/blog/mutation";
import { AuthContext } from "../../../../../providers/Auth/AuthProvider";
import { GET_BLOG } from "../../../../../graphql/blog/query";
import { BloggersClientContext } from "../../../../../providers/Apollo/ApolloProvider";

const UpdateBlog = ({ blogId }) => {
    const { displaySnackBar } = useContext(SnackBarContext);
    const { isBlogAdmin } = useContext(AuthContext);
    const { bloggersClient } = useContext(BloggersClientContext);
    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const { isDark } = useContext(ThemeContext);
    const [files, setFiles] = useState([]);

    const { data, loading } = useQuery(GET_BLOG, {
        variables: {
            id: Number(blogId),
        },
        fetchPolicy: "cache-and-network",
    });

    const currentBlog = data?.getBlog?.blog;

    const [blog, setBlog] = useState({
        title: currentBlog?.title,
        picture: currentBlog?.picture,
        description: currentBlog?.description,
        aboutUsDescription: currentBlog?.aboutUsDescription,
        priority: currentBlog?.priority
            ? Number(currentBlog?.priority)
            : undefined,
        date: currentBlog?.date,
    });

    const [tags, setTags] = useState(currentBlog?.tags || []);

    const [updateBlog, { loading: loadingUpdate }] = useMutation(UPDATE_BLOG, {
        client: bloggersClient,
        onCompleted: (data) => {
            if (data && data.updateBlog) {
                displaySnackBar({
                    message: "Blog updated",
                    type: "success",
                });
                navigate(`${ROUTES.blogs}`);
            }
        },
        onError: (err) => {
            if (err.message) {
                displaySnackBar({
                    message: err.message,
                    type: "error",
                });
            }
        },
    });

    const unknownFileError = () => {
        displaySnackBar({
            message: formatMessage({
                id: "myProfile.settings.uploading.picture.unknown.error",
            }),
            type: "error",
        });
    };

    const getBase64 = (file, callback) => {
        try {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                callback(reader.result);
            };
            reader.onerror = (error) => {
                unknownFileError();
                console.log("Error: ", error);
            };
        } catch (err) {
            onFilesError(err, null);
            console.log(`Error : `, err);
        }
    };

    const onFilesChange = (browsedFiles) => {
        if (browsedFiles && browsedFiles[0]) {
            getBase64(browsedFiles[0], (result) => {
                setBlog({ ...blog, picture: result });
                setFiles(browsedFiles);
            });
        } else {
            setFiles([]);
            setBlog({ ...blog, picture: undefined });
        }
    };

    const onFilesError = (error, file) => {
        console.log("error code " + error.code + ": " + error.message);
        if (error.code === 2) {
            displaySnackBar({
                message: `${formatMessage({
                    id: "myProfile.settings.uploading.picture.size.error",
                })} ${config.nft.fileSizeLimit} Mb.`,
                type: "error",
            });
        } else {
            displaySnackBar({
                message: `Error when uploading the file.`,
                type: "error",
            });
        }
    };

    const handleChangeTitle = (value) => {
        setBlog({ ...blog, title: value });
    };

    const handleChangeDescription = (value) => {
        setBlog({ ...blog, description: value });
    };

    const handleChangeAboutUsDescription = (value) => {
        setBlog({ ...blog, aboutUsDescription: value });
    };

    const handleChangePriority = (value) => {
        setBlog({ ...blog, priority: Number(value) });
    };

    const handleChangeDate = (value) => {
        setBlog({ ...blog, date: value });
    };

    const handleRemoveTag = (value) => {
        const filtredTags = tags.filter((t) => t !== value);
        setTags(filtredTags);
    };

    const onUpdateBlog = () => {
        const { title, picture, description } = blog;
        if (title && picture && description) {
            updateBlog({
                variables: {
                    input: {
                        id: Number(blogId),
                        ...blog,
                        date: blog.date ? new Date(blog.date) : undefined,
                        tags,
                    },
                },
            });
        }
    };

    const titleMissing = !blog.title;

    useEffect(() => {
        if (!isBlogAdmin) {
            displaySnackBar({
                message: "Only blog admin can have access to this interface",
                type: "error",
            });
            navigate(ROUTES.blogs);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBlogAdmin]);

    useEffect(() => {
        setBlog({
            title: currentBlog?.title,
            picture: currentBlog?.picture,
            description: currentBlog?.description,
            aboutUsDescription: currentBlog?.aboutUsDescription,
            priority: currentBlog?.priority
                ? Number(currentBlog?.priority)
                : undefined,
            date: currentBlog?.date,
        });

        setTags(currentBlog?.tags || []);
    }, [currentBlog]);

    return (
        <div className={isDark ? "greyscheme" : ""}>
            {(loading || loadingUpdate) && <CustomLoader />}

            <HeaderWithImage title="Update Blog" />

            <section className="container">
                <div className="row">
                    <div className="col-lg-7 offset-lg-1 mb-5">
                        <form
                            id="form-create-item"
                            className="form-border"
                            action="#"
                        >
                            <div className="field-set">
                                <h5>Cover Image</h5>

                                <div className="d-create-file">
                                    <p id="file_name">
                                        PNG, JPG or GIF. Max{" "}
                                        {config.nft.fileSizeLimit}mb.
                                    </p>

                                    {files.map((x) => (
                                        <p key="{index}">{x.name}</p>
                                    ))}

                                    <div className="browse">
                                        <Files
                                            className="upload_file"
                                            onChange={onFilesChange}
                                            onError={onFilesError}
                                            accepts={["image/*"]}
                                            multiple={false}
                                            maxFiles={100}
                                            maxFileSize={
                                                config.nft.fileSizeLimit *
                                                1000000
                                            }
                                            minFileSize={0}
                                        >
                                            <CustomButton text="Browse" />
                                        </Files>
                                    </div>
                                </div>
                                {!blog.picture && (
                                    <div
                                        style={{
                                            fontSize: FONT_SIZES.common.small,
                                            color: "#EB5757",
                                            marginTop: 10,
                                        }}
                                    >
                                        Cover Image is required
                                    </div>
                                )}
                                <div className="spacer-single"></div>

                                <CustomInput
                                    label="Title"
                                    placeholder="Give a title to your blog"
                                    value={blog.title}
                                    handleChangeValue={handleChangeTitle}
                                    error={titleMissing}
                                    errorText="This field is required"
                                />

                                <CustomInput
                                    label="Description"
                                    placeholder="Tell about your blog"
                                    value={blog.description}
                                    handleChangeValue={handleChangeDescription}
                                    muiltiline
                                    style={{ height: 200 }}
                                />

                                <CustomInput
                                    label="About us"
                                    placeholder="What does this blog mean for your site ?"
                                    value={blog.aboutUsDescription}
                                    handleChangeValue={
                                        handleChangeAboutUsDescription
                                    }
                                    muiltiline
                                    style={{ height: 200 }}
                                />

                                <CustomInput
                                    label="Priority"
                                    placeholder="Priority of your blog"
                                    value={blog.priority}
                                    handleChangeValue={handleChangePriority}
                                    type="number"
                                />

                                <CustomInput
                                    label="Date"
                                    placeholder="Enter the date of the blog"
                                    value={
                                        blog.date
                                            ? moment(blog.date).format(
                                                  "YYYY-MM-DD"
                                              )
                                            : undefined
                                    }
                                    handleChangeValue={handleChangeDate}
                                    type="date"
                                    min={moment(new Date()).format(
                                        "DD/MM/YYYY"
                                    )}
                                />

                                <AddTag tags={tags} setTags={setTags} />

                                <div
                                    className="row"
                                    style={{
                                        marginTop: 20,
                                        marginBottom: 20,
                                    }}
                                >
                                    {tags.map((t) => (
                                        <TagItem
                                            tag={t}
                                            onClickTag={handleRemoveTag}
                                        />
                                    ))}
                                </div>

                                <CustomButton
                                    text="UPDATE BLOG"
                                    onClick={onUpdateBlog}
                                    disabled={
                                        loading ||
                                        titleMissing ||
                                        !blog?.picture
                                    }
                                />
                            </div>
                        </form>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-xs-12">
                        <h5>Image Preview</h5>
                        <div className="bloglist item">
                            <div className="post-content">
                                <div className="post-image">
                                    <img
                                        alt=""
                                        src={blog.picture}
                                        className="lazy"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default UpdateBlog;
