import { Box, createStyles, makeStyles } from "@material-ui/core";
import Reveal from "react-awesome-reveal";
import CustomButton from "../../../../components/button/CustomButton";
import COLORS from "../../../../constants/colors";
import { useIntl } from "react-intl";
import { fadeInUp } from "../../../../constants/animations";

const useStyles = makeStyles((theme) =>
    createStyles({
        container: {
            display: "flex",
            color: COLORS.v2.black_grey,
            alignItems: "center",
        },
        left: {
            width: "40%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
        },
        right: {
            width: "60%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
        },
        title: {
            fontWeight: "bold",
            marginBottom: 60,
            marginTop: 30,
        },
        logosContainer: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
        },
        image1: {
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
            height: "auto",
            width: "90%",
        },
        image2: {
            borderBottomLeftRadius: 15,
            borderBottomRightRadius: 15,
            height: "auto",
            width: "90%",
        },
    })
);

const CreatorsAndCollaborators = () => {
    const classes = useStyles();
    const { formatMessage } = useIntl();

    return (
        <section className="left_right_container creators_and_collaborators">
            <Box className="left">
                <h2 style={{ color: COLORS.v2.black_grey, marginBottom: 0 }}>
                    Creators &
                </h2>
                <h2 style={{ color: COLORS.v2.black_grey, marginBottom: 40 }}>
                    Collaborators
                </h2>
                <Reveal
                    className="onStep"
                    keyframes={fadeInUp}
                    delay={0}
                    duration={600}
                    triggerOnce
                >
                    <p
                        style={{
                            color: COLORS.v2.black_grey,
                            fontSize: 15,
                            marginBottom: 40,
                            paddingRight: 70,
                        }}
                    >
                        {formatMessage({ id: "home.creators.collaborators" })}
                    </p>
                </Reveal>

                <CustomButton text="Learn More" isBlack />
            </Box>
            <Box className="right">
                <img
                    key="/img/v2/backgrounds/creators_contributors_background1.png"
                    src="/img/v2/backgrounds/creators_contributors_background1.png"
                    className={classes.image1}
                    alt="#"
                />
                <img
                    key="/img/v2/backgrounds/creators_contributors_background2.png"
                    src="/img/v2/backgrounds/creators_contributors_background2.png"
                    className={classes.image2}
                    alt="#"
                />
            </Box>
        </section>
    );
};

export default CreatorsAndCollaborators;
