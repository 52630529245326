const home = {
    "home.welcome": "Welcome",
    "home.market.name": " ",
    "home.big.title1": "IDEAL EXCHANGE :",
    "home.big.title2": "The World's Stock Market for Creative Ideas and Works.",
    "home.subtitle1":
        "Don't just support the next big franchise, own a part of it.",
    "home.subtitle2":
        "Sell, invest, and trade the hottest creative works of today and the future.",
    "home.stat.minted.items": "Franchises",
    "home.stat.auctions": "Auctions",
    "home.stat.minted.item.creators": "Creators",
    "home.featurebox.title1": "Ideal Exchange (IDX)",
    "home.featurebox.title2": "HOW IT WORKS",
    "home.featurebox.desc":
        "Sell, Invest, and Trade the hottest creative works of today and the future.",
    "home.featurebox1.title": "Set up your wallet",
    "home.featurebox1.desc1":
        "Use your existing crypto wallets or setup here in two easy steps.",
    "home.featurebox1.desc2": "Need help ? The community is here for you.",
    "home.featurebox2.title": "Establish your IP",
    "home.featurebox2.desc":
        "Add your texts, pics, and videos that define your idea or franchise and present it to potential investors and fans.",
    "home.featurebox3.title": "Share your idea with the world",
    "home.featurebox3.desc":
        "Decide how do you want to sell your idea, and invest in your fellow dreamers for each other's benefit.",
    "home.trusted.partners.title": "TRUSTED PARTNERS",
    "home.creators.collaborators":
        "CR8 : The creative share of the future. CR8 tokens gain value as the IP itself grows and enters new mediums. As the IP expands and becomes more popular, other CR8 tokens related to the original IP can help each other gain value faster.",
    "home.featured.desc.right":
        "Featured creators and investors coming in April.",
};

export default home;
