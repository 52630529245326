import React, { memo, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Clock from "./../Clock";
import { useNavigate } from "@reach/router";
import LOCAL_STORAGE_KEYS from "../../constants/localStorage";
import axios from "axios";
import config from "../../../config/config";
import getImagePreview from "../../utils/nft/getImagePreview";
import ROUTES from "../constants/routes";
import NFTAuthorPicture from "./authorPicture/NFTAuthorPicture";
import { ethers } from "ethers";
import LikeButton from "../nftLike/button/LikeButton";
import { AuthContext } from "../../providers/Auth/AuthProvider";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import COLORS from "../../constants/colors";
import { Tooltip } from "@material-ui/core";
import { SET_FEATURED_NFT } from "../../graphql/nft/mutation";
import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../providers/SnackBar/SnackBarProvider";

const Outer = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 8px;
`;

const NFTCard = ({
    nft,
    className = "d-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4",
    clockTop = true,
    height,
    onImgLoad,
    navigateToNFTPermit,
    featuredEditable,
}) => {
    const { authContext } = useContext(AuthContext);
    const navigate = useNavigate();
    const { displaySnackBar } = useContext(SnackBarContext);
    const [featured, setFeatured] = useState(nft?.featured);

    const [setFeaturedMutation] = useMutation(SET_FEATURED_NFT, {
        onCompleted: (data) => {
            if (data) {
                setFeatured(data?.setFeatured);
                if (data?.setFeatured === true) {
                    displaySnackBar({
                        message: "Item added to featured",
                        type: "success",
                    });
                } else {
                    displaySnackBar({
                        message: "Item removed from featured",
                        type: "info",
                    });
                }
            }
        },
        onError: (err) => {
            displaySnackBar({
                message:
                    err.message || "Error occured on performing this action",
                type: "error",
            });
        },
    });

    const navigateToDetails = () => {
        navigate(`${ROUTES.nftDetail}/${nft.cid}`);
    };

    const navigateToNFTPermitScreen = () => {
        navigate(`${ROUTES.nftPermit}/${nft.cid}`);
    };

    const [nftUrl, setNftUrl] = useState(undefined);
    const [likesCount, setLikesCount] = useState(nft?.likesCount);
    const [likedByMe, setLikedByMe] = useState(nft?.likedByMe);

    const sales = nft?.sales || [];

    const Authorization = `Bearer ${localStorage.getItem(
        LOCAL_STORAGE_KEYS.TOKEN
    )}`;

    const onCompleted = (data) => {
        if (data && data.likeOrDislikeIp) {
            if (data.likeOrDislikeIp.liked) {
                setLikedByMe(true);
                setLikesCount(likesCount + 1);
            } else {
                setLikedByMe(false);
                if (likesCount > 0) {
                    setLikesCount(likesCount - 1);
                }
            }
        }
    };

    useEffect(() => {
        if (nft.cid) {
            axios
                .get(`${config.nft.ipsUrl}/${nft.cid}`, {
                    headers: {
                        Authorization,
                    },
                    responseType: "blob",
                })
                .then((res) => {
                    const blobURL = URL.createObjectURL(res.data);
                    setNftUrl(blobURL);
                });
        }
    }, [nft, Authorization]);

    useEffect(() => {
        setLikedByMe(nft?.likedByMe);
        setLikesCount(nft?.likesCount);
        setFeatured(nft?.featured);
    }, [nft]);

    const salesTotalShares = sales
        .map((s) => Number(ethers.utils.formatEther(s?.sale?.item?.value)))
        .reduce((partialSum, a) => partialSum + a, 0);

    const salesTotalPrice = sales
        .map((s) => Number(ethers.utils.formatEther(s?.sale?.payment?.value)))
        .reduce((partialSum, a) => partialSum + a, 0);

    const averageSalesPrice = salesTotalShares
        ? salesTotalPrice / salesTotalShares
        : 0;

    const changeFeatured = () => {
        setFeaturedMutation({
            variables: {
                input: {
                    cid: nft?.cid,
                    featured: !featured,
                },
            },
        });
    };

    return (
        <div className={className}>
            <div className="nft__item m-0">
                {/* <div className="icontype">
                    <i className="fa fa-bookmark"></i>
                </div> */}
                {featuredEditable && (
                    <div className="featuredButton" onClick={changeFeatured}>
                        <Tooltip
                            title={
                                featured
                                    ? "Remove from featured"
                                    : "Add to featured"
                            }
                        >
                            {featured ? (
                                <CheckCircleIcon
                                    style={{
                                        color: COLORS.v2.green,
                                        fontSize: 30,
                                    }}
                                />
                            ) : (
                                <RadioButtonUncheckedIcon
                                    style={{
                                        color: COLORS.v2.green,
                                        fontSize: 30,
                                    }}
                                />
                            )}
                        </Tooltip>
                    </div>
                )}
                <NFTAuthorPicture
                    address={nft?.user?.id}
                    src={nft?.user?.profilePictureUri}
                />
                <div
                    className="nft__item_wrap"
                    style={{ height: `${height}px` }}
                >
                    <Outer>
                        <span>
                            <img
                                onLoad={onImgLoad}
                                src={getImagePreview({
                                    extension: nft.mimetype,
                                    imageUrl: nftUrl,
                                })}
                                className="lazy nft__item_preview"
                                alt=""
                            />
                        </span>
                    </Outer>
                </div>
                {nft?.sale?.validUntil && (
                    <div className="de_countdown">
                        <Clock deadline={nft?.sale?.validUntil} />
                    </div>
                )}
                <div className="nft__item_info">
                    <span
                        onClick={
                            navigateToNFTPermit
                                ? navigateToNFTPermitScreen
                                : navigateToDetails
                        }
                    >
                        <h4>{nft.title}</h4>
                    </span>
                    <div className="nft__item_price">
                        {averageSalesPrice ? (
                            <div>{averageSalesPrice.toFixed(4)} ETH</div>
                        ) : null}
                        {/* {nft.royalties ? <div>{nft.royalties}%</div> : null} */}
                        {sales?.length ? (
                            <div>
                                {sales.length} Active sales ({salesTotalShares}{" "}
                                Shares)
                            </div>
                        ) : null}
                        {navigateToNFTPermit && (
                            <div>
                                {nft?.isPermitted ? (
                                    <div>
                                        Permitted by{" "}
                                        {authContext?.myProfile?.id ===
                                        nft?.permit?.moderator?.id
                                            ? "You"
                                            : nft?.permit?.moderator
                                                  ?.userName ||
                                              nft?.permit?.moderator?.id}
                                    </div>
                                ) : nft?.isDenied ? (
                                    <div>
                                        Denied by{" "}
                                        {authContext?.myProfile?.id ===
                                        nft?.deny?.moderator?.id
                                            ? "You"
                                            : nft?.deny?.moderator?.userName ||
                                              nft?.deny?.moderator?.id}
                                    </div>
                                ) : (
                                    <div>Pending</div>
                                )}
                            </div>
                        )}
                        {/* {navigateToNFTPermit && nft?.isPermitted ? (
                            <div>
                                Permitted by{" "}
                                {authContext?.myProfile?.id ===
                                nft?.permit?.moderator?.id
                                    ? "You"
                                    : nft?.permit?.moderator?.userName ||
                                      nft?.permit?.moderator?.id}
                            </div>
                        ) : (
                            <div>Pending</div>
                        )} */}
                    </div>
                    {/* <div className="nft__item_action">
                        <span
                            onClick={
                                navigateToNFTPermit
                                    ? navigateToNFTPermitScreen
                                    : navigateToDetails
                            }
                        >
                            {!navigateToNFTPermit ||
                            nft?.isPermitted ||
                            nft?.isDenied
                                ? "View details"
                                : "Permit"}
                        </span>
                    </div> */}
                    <div className="idx">IDX</div>
                    <div className="nft__item_like">
                        <LikeButton
                            cid={nft?.cid}
                            likedByMe={likedByMe}
                            onCompleted={onCompleted}
                        />
                        <span>{likesCount}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(NFTCard);
