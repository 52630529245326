import React, { useState, createContext } from "react";
import LOCAL_STORAGE_KEYS from "../../constants/localStorage";
import THEMES from "../../constants/theme";

export const ThemeContext = createContext({
    theme: THEMES.DARK,
    isDark: false,
    changeTheme: () => {},
    switchTheme: () => {},
});

const ThemeProvider = ({ children }) => {
    const savedTheme = localStorage.getItem(LOCAL_STORAGE_KEYS.THEME);
    const [theme, setTheme] = useState(savedTheme || THEMES.DARK);

    const changeTheme = (choosenTheme) => {
        setTheme(choosenTheme);
        localStorage.setItem(LOCAL_STORAGE_KEYS.THEME, choosenTheme);
    };

    const switchTheme = () => {
        const toSwitchTheme =
            theme === THEMES.LIGHT ? THEMES.DARK : THEMES.LIGHT;
        setTheme(toSwitchTheme);
        localStorage.setItem(LOCAL_STORAGE_KEYS.THEME, toSwitchTheme);
    };

    const isDark = theme === THEMES.DARK;

    return (
        <ThemeContext.Provider
            value={{
                theme,
                isDark,
                changeTheme,
                switchTheme,
            }}
        >
            {children}
        </ThemeContext.Provider>
    );
};

export default ThemeProvider;
