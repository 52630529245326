import { Box } from "@material-ui/core";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import { useContext, useRef, useState } from "react";
import CustomButton from "../../../components/button/CustomButton";
import COLORS from "../../../constants/colors";
import IDXLogo from "../../../components/logo/IDXLogo";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import useComponentDimensions from "../../../hooks/useComponentDimensions/useComponentDimensions";
import { AuthContext } from "../../../providers/Auth/AuthProvider";
import { useNavigate } from "@reach/router";
import ROUTES from "../../../components/constants/routes";
import SectionSubtitle from "../../../components/section/SectionSubtitle";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

const ICON_SIZE = 75;

const PromoMovie = () => {
    const movieRef = useRef(null);
    const { isUserConnected } = useContext(AuthContext);
    const [showControls, setShowControls] = useState(false);
    const [muted, setMuted] = useState(true);
    const { width, height } = useComponentDimensions(movieRef);
    const navigate = useNavigate();

    const handlePlayVideo = () => {
        movieRef.current.play();
        setMuted(false);
    };

    const handleOnPlay = () => {
        setShowControls(true);
    };

    const handleOnEnded = () => {
        setShowControls(false);
    };

    const handleJoinNow = () => {
        navigate(ROUTES.wallets);
    };

    return (
        <section className="left_right_container promo_movie">
            <Box className="left">
                <SectionSubtitle
                    text="FEATURED"
                    style={{ color: COLORS.v2.black_grey, marginBottom: 40 }}
                />
                <IDXLogo isGrey style={{ justifyContent: "flex-start" }} />

                <h3
                    style={{
                        color: COLORS.v2.black_grey,
                        marginBottom: 40,
                        fontWeight: "800",
                    }}
                >
                    A BEGINNERS GUIDE TO THE IDX
                </h3>
                <Reveal
                    className="onStep"
                    keyframes={fadeInUp}
                    delay={0}
                    duration={600}
                    triggerOnce
                >
                    <p
                        style={{
                            color: COLORS.v2.black_grey,
                            fontSize: 15,
                            marginBottom: 40,
                            paddingRight: 70,
                        }}
                    >
                        As part of an ongoing artist series for Genesis, I
                        reimagined their newly redesigned G70 sedan as
                        reflective sculptural artworks in a rustic neoclassical
                        setting.
                    </p>
                </Reveal>

                {!isUserConnected && (
                    <CustomButton
                        text="Join Now"
                        isBlack
                        onClick={handleJoinNow}
                    />
                )}
            </Box>
            <Box
                className="right"
                style={{ marginTop: showControls && ICON_SIZE }}
            >
                {!showControls && (
                    <PlayArrowIcon
                        style={{
                            position: "relative",
                            zIndex: 900,
                            color: COLORS.v2.green,
                            top: height
                                ? ICON_SIZE + height / 2 - ICON_SIZE / 2
                                : 0,
                            left: width ? width / 2 - ICON_SIZE / 2 : 0,
                            fontSize: ICON_SIZE,
                            cursor: "pointer",
                        }}
                        onClick={handlePlayVideo}
                    />
                )}
                <video
                    ref={movieRef}
                    controls={showControls}
                    onPlay={handleOnPlay}
                    onEnded={handleOnEnded}
                    style={{ marginBottom: 30 }}
                    autoPlay
                    muted={muted}
                >
                    <source
                        src="/img/v2/videos/Ideal_0301.mp4"
                        type="video/mp4"
                    />
                </video>
            </Box>
        </section>
    );
};

export default PromoMovie;
