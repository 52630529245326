import { gql } from "@apollo/client";

export const LISTEN_TO_NEW_NOTIF = gql`
    subscription OnMyPermitStatus {
        onMyPermitStatus {
            id
            code
            variables
            createdAt
            read
        }
    }
`;
