import { gql } from "@apollo/client";
import { DENY_FRAGMENT } from "./fragment";

export const DENY_NFT_MUTATION = gql`
    mutation DenyIp($input: DenyIpInput!) {
        denyIp(input: $input) {
            ...DenyFields
        }
    }
    ${DENY_FRAGMENT}
`;
