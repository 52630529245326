import { useQuery } from "@apollo/client";
import React, { useContext } from "react";
import CustomLoader from "../../../components/customLoader/CustomLoader";
import NFTCardMarqueeList from "../../../components/nftCard/NFTCardMarqueeList";
import COLORS from "../../../constants/colors";
import { GET_POPULAR_NFTS } from "../../../graphql/nft/query";
import { ThemeContext } from "../../../providers/Theme/ThemeProvider";

const PopularItemsMarquee = () => {
    const { isDark } = useContext(ThemeContext);

    const { data, loading } = useQuery(GET_POPULAR_NFTS, {
        fetchPolicy: "cache-and-network",
    });

    const popularNFTs = data ? data.getPopularIps : [];

    if (!popularNFTs || !popularNFTs.length) return null;

    return (
        <div
            style={{
                backgroundColor: isDark
                    ? COLORS.v2.grey_background
                    : COLORS.v2.grey_button,
            }}
        >
            <section
                style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: 0,
                    paddingRight: 0,
                }}
            >
                {loading && <CustomLoader />}

                <NFTCardMarqueeList nfts={popularNFTs} />
            </section>
        </div>
    );
};
export default PopularItemsMarquee;
