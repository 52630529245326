import { gql } from "@apollo/client";
import { PERMIT_FRAGMENT } from "./fragment";

export const MY_PERMITS = gql`
    query GetMyPermits {
        myPermits {
            ...PermitFields
            moderator {
                id
                userName
                profilePictureUri
            }
            user {
                id
                userName
                profilePictureUri
            }
            ip {
                cid
                title
                mimetype
                likesCount
                likedByMe
                isPermitted
                privacyStatus
            }
        }
    }
    ${PERMIT_FRAGMENT}
`;
