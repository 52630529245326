const COLORS = {
    light: {
        white: "#fff",
        gray: "#bbb",
        black: "#111",
        general: "#727272",
        black_soft: "#333",
        black_col: "#2e333c",
        black_more: "#0f0f0f",
        color: "#3bffae",
        color_2: "#805bab",
        blue_ideal: "#4daae1",
    },
    dark: {
        background_grey: "#212428",
        color: "#3bffae",
        color_2: "#3bffae",
    },
    v2: {
        white: "#fff",
        green: "#3bffae",
        black_grey: "#1e1e1e",
        grey_button: "#e3e3e3",
        grey_background: "#171719",
        grey_light_backgroung: "#d9d9d9",
        like_active: "#e51364",
        blue_grey_hover: "#212428",
        greyText: "#808080",
    },
};

export default COLORS;
