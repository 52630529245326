import { gql } from "@apollo/client";

export const NFT_FRAGMENT = gql`
    fragment NFTFields on IP {
        cid
        size
        filename
        mimetype
        encoding
        path

        title
        description
        price
        shareCount
        royalties
        externalLink
        isSensitiveContent
        privacyStatus

        createdByMe

        likesCount
        likedByMe
        featured
    }
`;
