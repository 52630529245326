import React, { useContext } from "react";
import { useIntl } from "react-intl";
import COLORS from "../../constants/colors";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";

const HeaderNoSubmenu = ({ label, handleClick, isXL }) => {
    const { formatMessage } = useIntl();
    const { isDark } = useContext(ThemeContext);

    return (
        <div className="navbar-item">
            <div>
                <div
                    className="dropdown-custom dropdown-toggle btn"
                    onClick={handleClick}
                    style={{
                        color: isDark && COLORS.v2.white,
                    }}
                >
                    {formatMessage({
                        id: label,
                    })}
                    {isXL && <span className="lines"></span>}
                </div>
            </div>
        </div>
    );
};

export default HeaderNoSubmenu;
