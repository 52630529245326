import {
    localhost_deployments,
    devnet_deployments,
    rinkeby_deployments,
} from "common";
import config from "../../../config/config";

let contract;

if (config.network.targetNetwork === "rinkeby") {
    contract = rinkeby_deployments;
} else if (config.network.targetNetwork === "devnet") {
    contract = devnet_deployments;
} else {
    contract = localhost_deployments;
}

export default contract;
