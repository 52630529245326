import React, { useContext, useRef, useState } from "react";
import { useIntl } from "react-intl";
import {
    Box,
    Button,
    createStyles,
    makeStyles,
    Popover,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Brightness2Icon from "@material-ui/icons/Brightness2";
import { BloggerAuthContext } from "../../providers/BloggerAuth/BloggerAuthProvider";
import THEMES from "../../constants/theme";
import CustomSwitch from "../../components/switch/CustomSwitch";
import COLORS from "../../constants/colors";
import { SnackBarContext } from "../../providers/SnackBar/SnackBarProvider";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";

const BloggerProfile = () => {
    const { meAsblogger, logoutAsBlogger } = useContext(BloggerAuthContext);
    const { formatMessage } = useIntl();

    const [openedPopover, setOpenedPopover] = useState(false);
    const popoverAnchor = useRef(null);
    const { displaySnackBar } = useContext(SnackBarContext);
    const { isDark, changeTheme, switchTheme } = useContext(ThemeContext);

    const useStyles = makeStyles((theme) =>
        createStyles({
            popover: {
                pointerEvents: "none",
                opacity: 0.9,
            },
            popoverPaper: {
                marginTop: 10,
                borderRadius: 7,
                display: "flex",
                flexDirection: "column",
                pointerEvents: "auto",
                backgroundColor: isDark
                    ? COLORS.dark.background_grey
                    : COLORS.light.white,
            },
            logoutButton: {
                paddingTop: 5,
                paddingBottom: 5,
                textTransform: "none",
                color: isDark ? COLORS.light.white : COLORS.light.black_soft,
                backgroundColor: isDark
                    ? COLORS.v2.black_grey
                    : COLORS.light.white,

                justifyContent: "flex-start",
                fontSize: 14,
                borderRadius: 0,
                "&:hover": {
                    backgroundColor: COLORS.v2.green,
                    color: COLORS.v2.black_grey,
                },
            },
            logoutText: {
                marginLeft: 10,
            },
            separator: {
                height: 1,
                width: "100%",
                backgroundColor: isDark
                    ? COLORS.light.black_soft
                    : COLORS.light.white,
            },
        })
    );

    const classes = useStyles();

    const popoverEnter = () => {
        setOpenedPopover(true);
    };

    const popoverLeave = () => {
        setOpenedPopover(false);
    };

    const _logout = () => {
        logoutAsBlogger();
        popoverLeave();
        displaySnackBar({
            message: formatMessage({ id: "auth.signout.message.success" }),
            type: "success",
        });
    };

    const handleChangeTheme = (event) => {
        const isChecked = event.target.checked;
        const theme = isChecked ? THEMES.DARK : THEMES.LIGHT;
        changeTheme(theme);
    };

    if (meAsblogger)
        return (
            <div style={{ display: "flex" }}>
                <span
                    ref={popoverAnchor}
                    aria-owns="mouse-over-popover"
                    aria-haspopup="true"
                    onMouseEnter={popoverEnter}
                    onMouseLeave={popoverLeave}
                >
                    <Button
                        style={{
                            fontWeight: "normal",
                            borderRadius: 60,
                            backgroundColor: "black",
                            color: COLORS.v2.green,
                            textTransform: "none",
                            paddingLeft: 20,
                            paddingRight: 20,
                        }}
                    >
                        {meAsblogger?.username}
                    </Button>
                </span>

                <Popover
                    id="mouse-over-popover"
                    open={openedPopover}
                    anchorEl={popoverAnchor.current}
                    disableScrollLock
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    className={classes.popover}
                    classes={{ paper: classes.popoverPaper }}
                    PaperProps={{
                        onMouseEnter: popoverEnter,
                        onMouseLeave: popoverLeave,
                    }}
                    placement="bottom-end"
                >
                    <Button
                        className={classes.logoutButton}
                        onClick={switchTheme}
                    >
                        <Brightness2Icon />
                        <Box className={classes.logoutText}>Dark Mode</Box>
                        <CustomSwitch
                            checked={isDark}
                            handleChange={handleChangeTheme}
                        />
                    </Button>
                    <Box className={classes.separator} />
                    <Button className={classes.logoutButton} onClick={_logout}>
                        <ExitToAppIcon />
                        <Box className={classes.logoutText}>
                            {formatMessage({ id: "header.signout" })}
                        </Box>
                    </Button>
                </Popover>
            </div>
        );

    return null;
};
export default BloggerProfile;
