import React, { memo, useState } from "react";
import NFTCard from "./NFTCard";

const NFTCardMarqueeList = ({
    nfts,
    loadMore,
    showLoadMore,
    navigateToNFTPermit,
}) => {
    const [height, setHeight] = useState(0);

    const onImgLoad = ({ target: img }) => {
        let currentHeight = height;
        if (currentHeight < img.offsetHeight) {
            setHeight(img.offsetHeight);
        }
    };

    return (
        <marquee speed={50} style={{ paddingTop: 20 }} loop="-1" pauseOnHover>
            <div
                className="row"
                style={{
                    display: "flex",
                    flexWrap: "nowrap",
                }}
            >
                {nfts &&
                    nfts.map((nft, index) => (
                        <NFTCard
                            nft={nft}
                            key={index}
                            onImgLoad={onImgLoad}
                            height={height}
                            navigateToNFTPermit={navigateToNFTPermit}
                        />
                    ))}
            </div>
        </marquee>
    );
};

export default memo(NFTCardMarqueeList);
