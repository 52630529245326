export const fileToBase64 = (file, callback) => {
    try {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            callback(reader.result);
        };
        reader.onerror = (error) => {
            console.log("Error: ", error);
        };
    } catch (err) {
        console.log(`Error : `, err);
    }
};

export const blobToBase64 = (blob, callback) => {
    try {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
            const dataUrl = reader.result;
            const base64 = dataUrl.split(",")[1];
            callback(base64);
        };
        reader.onerror = (error) => {
            console.log("Error: ", error);
        };
    } catch (err) {
        console.log(`Error : `, err);
    }
};
