import React, { useContext } from "react";
import { Switch, withStyles } from "@material-ui/core";
import COLORS from "../../constants/colors";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";

const CustomSwitch = (props) => {
    const { handleChange, checked, followTheme } = props;
    const { isDark } = useContext(ThemeContext);

    const IOSSwitch = withStyles((theme) => ({
        root: {
            width: 42,
            height: 23,
            padding: 0,
            margin: theme.spacing(1),
        },
        switchBase: {
            padding: 1,
            "&$checked": {
                transform: "translateX(16px)",
                color: theme.palette.common.white,
                "& + $track": {
                    opacity: 1,
                    backgroundColor:
                        followTheme && isDark
                            ? COLORS.dark.color_2
                            : COLORS.v2.like_active,
                    border: "none",
                },
            },
            "&$focusVisible $thumb": {
                color: "#52d869",
                border: "6px solid #fff",
            },
        },
        thumb: {
            width: 20,
            height: 20,
            backgroundColor: COLORS.light.white,
        },
        track: {
            borderRadius: 26 / 2,
            border: `1px solid ${COLORS.light.gray}`,
            backgroundColor: COLORS.light.gray,
            opacity: 1,
            transition: theme.transitions.create([
                "background-color",
                "border",
            ]),
        },
        checked: {},
        focusVisible: {},
    }))(({ classes, ...props }) => {
        return (
            <Switch
                focusVisibleClassName={classes.focusVisible}
                disableRipple
                classes={{
                    root: classes.root,
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                    checked: classes.checked,
                }}
                {...props}
            />
        );
    });

    return <IOSSwitch checked={checked} onChange={handleChange} />;
};
export default CustomSwitch;
