import { useQuery } from "@apollo/client";
import { GET_MINIMAL_USER } from "../../graphql/user/query";

const useGetUser = (id) => {
    const { data } = useQuery(GET_MINIMAL_USER, {
        fetchPolicy: "cache-first",
        variables: { id },
    });

    return data?.getUser;
};

export default useGetUser;
