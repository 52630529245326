import { gql } from "@apollo/client";

export const JWT_FRAGMENT = gql`
    fragment JwtFields on Jwt {
        access
        refresh
        lastLogin
        encryptionPublicKey
    }
`;
