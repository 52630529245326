import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../../../../../config/config";
import CustomButton from "../../../../../components/button/CustomButton";
import CustomGGLandDialog from "../../../../../components/dialog/CustomGGLandDialog";
import LOCAL_STORAGE_KEYS from "../../../../../constants/localStorage";
import { blobToBase64 } from "../../../../../utils/file/getBase64";
import getImagePreview from "../../../../../utils/nft/getImagePreview";

const ExtraFile = ({ extraFile, handleClose }) => {
    const [fileUrl, setFileUrl] = useState(undefined);
    const [b64, setB64] = useState("");

    const fileName = extraFile?.filename;

    const extension = extraFile?.mimetype || "";

    const isPreviewable =
        extension.includes("text") ||
        extension.includes("pdf") ||
        extension.includes("video") ||
        extension.includes("audio");
    const isAudio = extension.includes("audio");

    const [preview, setPreview] = useState(false);
    const handlePreviewOrHide = () => {
        if (fileUrl) {
            setPreview(!preview);
        }
    };

    const Authorization = `Bearer ${localStorage.getItem(
        LOCAL_STORAGE_KEYS.TOKEN
    )}`;

    const handleDownload = () => {
        if (!fileUrl) {
            return;
        }
        var link = document.createElement("a");
        link.download = fileName || "";
        link.href = fileUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        if (extraFile?.cid) {
            axios
                .get(`${config.nft.filesUrl}/${extraFile?.cid}`, {
                    headers: {
                        Authorization,
                    },
                    responseType: "blob",
                })
                .then((res) => {
                    const blobURL = URL.createObjectURL(res.data);
                    setFileUrl(blobURL);
                    blobToBase64(res.data, (result) => {
                        setB64(result);
                    });
                });
        }
    }, [Authorization, extraFile]);

    return (
        <CustomGGLandDialog
            title={fileName}
            handleClose={handleClose}
            mainCheckoutStyle={preview ? { maxWidth: 1000 } : undefined}
            noSubmit
        >
            <div style={{ height: "fit-content" }}>
                {isAudio ? (
                    <audio controls src={fileUrl}></audio>
                ) : preview ? (
                    <iframe
                        title={fileName}
                        width="100%"
                        height="100%"
                        style={{ minHeight: 450 }}
                        frameborder="0"
                        src={`data:${extension};base64,${b64}`}
                    ></iframe>
                ) : (
                    <img
                        src={getImagePreview({
                            extension,
                            imageUrl: fileUrl,
                        })}
                        className="img-fluid img-rounded mb-sm-30"
                        alt=""
                    />
                )}

                <div style={{ display: "flex" }}>
                    {isPreviewable && !isAudio && (
                        <CustomButton
                            className="btn-main inline white lead"
                            text={preview ? "Hide Preview" : "Preview"}
                            onClick={handlePreviewOrHide}
                            disabled={!fileUrl}
                            style={{ marginRight: 20 }}
                        />
                    )}
                    <CustomButton
                        text="Download"
                        onClick={handleDownload}
                        disabled={!fileUrl}
                    />
                </div>
            </div>
        </CustomGGLandDialog>
    );
};

export default ExtraFile;
