import { useNavigate } from "@reach/router";
import { memo, useContext } from "react";
import { ThemeContext } from "../../../../providers/Theme/ThemeProvider";
import Footer from "../../../../components/footer/Footer";
import HeaderWithImage from "../../../../components/header/HeaderWithImage";
import CustomButton from "../../../../components/button/CustomButton";
import ROUTES from "../../../../components/constants/routes";
import BlogsList from "../../../../components/blog/BlogsList";
import { AuthContext } from "../../../../providers/Auth/AuthProvider";

const Blogs = () => {
    const { isDark } = useContext(ThemeContext);
    const { isBlogAdmin } = useContext(AuthContext);
    const navigate = useNavigate();

    return (
        <div className={isDark ? "greyscheme" : ""}>
            <HeaderWithImage
                title={isBlogAdmin ? "Blogs Management" : "Blogs"}
                subtitle={isBlogAdmin && "Manage your blogs"}
            />

            <section className="container">
                {isBlogAdmin && (
                    <CustomButton
                        text="+ CREATE NEW BLOG"
                        onClick={() => navigate(ROUTES.createBlog)}
                        style={{
                            marginBottom: 25,
                        }}
                    />
                )}
                <BlogsList />
            </section>

            <Footer />
        </div>
    );
};
export default memo(Blogs);
