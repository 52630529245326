import { useQuery } from "@apollo/client";
import { memo, useContext, useState } from "react";
import CustomButton from "../../components/button/CustomButton";
import CustomLoader from "../../components/customLoader/CustomLoader";
import CustomGGLandDialog from "../../components/dialog/CustomGGLandDialog";
import Footer from "../../components/footer/Footer";
import CreateParagraphForm from "../../components/help/CreateParagraphForm";
import ParagraphItem from "../../components/help/ParagraphItem";
import COLORS from "../../constants/colors";
import { GET_SUB_HELP } from "../../graphql/help/query";
import { AuthContext } from "../../providers/Auth/AuthProvider";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";

const SubHelpDetail = ({ subHelpId }) => {
    const { isDark } = useContext(ThemeContext);
    const { isBlogAdmin } = useContext(AuthContext);
    const [openNewParagraphDialog, setOpenNewParagraphDialog] = useState(false);

    const { data, loading, refetch } = useQuery(GET_SUB_HELP, {
        variables: {
            id: Number(subHelpId),
        },
        fetchPolicy: "cache-and-network",
    });

    const subHelp = data?.getSubHelp;

    const onCloseNewParagraphDialog = () => {
        setOpenNewParagraphDialog(false);
    };

    return (
        <div className={isDark ? "greyscheme" : ""}>
            {loading && <CustomLoader />}

            <section
                className="jumbotron breadcumb no-bg"
                style={
                    !isDark
                        ? {
                              borderBottom: `1px solid ${COLORS.v2.greyText}`,
                          }
                        : undefined
                }
            >
                <div className="mainbreadcumb">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 offset-md-2 text-center">
                                <h1
                                    style={{
                                        color: !isDark && COLORS.v2.black_grey,
                                    }}
                                >
                                    {subHelp?.help?.title}
                                </h1>
                                <div className="spacer-20"></div>

                                <p
                                    className="mt-0"
                                    style={{
                                        color: !isDark && COLORS.v2.black_grey,
                                    }}
                                >
                                    {subHelp?.title}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container">
                {isBlogAdmin && (
                    <div style={{ display: "flex" }}>
                        <CustomButton
                            text="+ ADD NEW PARAGRAPH"
                            onClick={() => setOpenNewParagraphDialog(true)}
                            disabled={loading}
                            isBlack
                        />
                    </div>
                )}

                {subHelp?.content?.length ? (
                    <div style={{ marginTop: 40 }}>
                        {subHelp?.content?.map((paragraph) => (
                            <ParagraphItem
                                paragraph={paragraph}
                                refetchSubHelp={refetch}
                            />
                        ))}
                    </div>
                ) : (
                    <div style={{ marginTop: 20 }}>No paragraph avalaible.</div>
                )}
            </section>

            {openNewParagraphDialog && (
                <CustomGGLandDialog
                    title="Create a new paragraph for this sub help"
                    handleClose={onCloseNewParagraphDialog}
                >
                    <CreateParagraphForm
                        subHelpId={subHelpId}
                        handleCloseDialog={onCloseNewParagraphDialog}
                        refetchSubHelp={refetch}
                    />
                </CustomGGLandDialog>
            )}

            <Footer />
        </div>
    );
};
export default memo(SubHelpDetail);
