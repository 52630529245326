import { useMutation } from "@apollo/client";
import { memo, useContext, useState } from "react";
import CustomButton from "../../components/button/CustomButton";
import { CREATE_SUB_HELP } from "../../graphql/help/mutation";
import { BloggersClientContext } from "../../providers/Apollo/ApolloProvider";
import { SnackBarContext } from "../../providers/SnackBar/SnackBarProvider";
import CustomInput from "../input/CustomInput";

const CreateSubHelpForm = ({ helpId, handleCloseDialog, refetchHelp }) => {
    const { displaySnackBar } = useContext(SnackBarContext);
    const { bloggersClient } = useContext(BloggersClientContext);
    const [title, setTitle] = useState(undefined);
    const [priority, setPriority] = useState(undefined);

    const [createSubHelp, { loading }] = useMutation(CREATE_SUB_HELP, {
        client: bloggersClient,
        onCompleted: (data) => {
            if (data && data.createSubHelp) {
                displaySnackBar({
                    message: "Sub help created",
                    type: "success",
                });
                handleCloseDialog();
                refetchHelp();
            }
        },
        onError: (err) => {
            if (err.message) {
                displaySnackBar({
                    message: err.message,
                    type: "error",
                });
            }
        },
    });

    const onCreate = () => {
        if (helpId && title) {
            createSubHelp({
                variables: {
                    input: {
                        helpId: Number(helpId),
                        title,
                        priority: Number(priority),
                    },
                },
            });
        }
    };

    const handleChangeTitle = (value) => {
        setTitle(value);
    };

    const handleChangePriority = (value) => {
        setPriority(Number(value));
    };

    return (
        <div>
            <CustomInput
                label="Title"
                placeholder="Give a title to your sub help"
                value={title}
                handleChangeValue={handleChangeTitle}
                error={!title}
                errorText="This field is required"
            />

            <CustomInput
                label="Priority"
                placeholder="Priority of your sub help"
                value={priority}
                handleChangeValue={handleChangePriority}
                type="number"
            />
            <div style={{ display: "flex" }}>
                <CustomButton
                    text="CREATE"
                    onClick={onCreate}
                    disabled={loading || !title || !helpId}
                    style={{ marginRight: 20 }}
                />
                <CustomButton
                    text="CANCEL"
                    onClick={handleCloseDialog}
                    disabled={loading}
                    isWhite
                />
            </div>
        </div>
    );
};
export default memo(CreateSubHelpForm);
