import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { AuthContext } from "../../providers/Auth/AuthProvider";
import { SnackBarContext } from "../../providers/SnackBar/SnackBarProvider";
import { useNavigate } from "@reach/router";
import ROUTES from "../../components/constants/routes";
import { GET_NFT } from "../../graphql/nft/query";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";
import NFTAuthorPicture from "../../components/nftCard/authorPicture/NFTAuthorPicture";
import getImagePreview from "../../utils/nft/getImagePreview";
import LOCAL_STORAGE_KEYS from "../../constants/localStorage";
import axios from "axios";
import config from "../../../config/config";
import { ethers } from "ethers";
import Footer from "../../components/footer/Footer";
import CustomLoader from "../../components/customLoader/CustomLoader";
import { GET_USER } from "../../graphql/user/query";
import NFTOfferForm from "./form/NFTOfferForm";

const NFTOffer = ({ cid, shareHolder }) => {
    const { authContext } = useContext(AuthContext);
    const { myProfile } = authContext;
    const { displaySnackBar } = useContext(SnackBarContext);
    const { isDark } = useContext(ThemeContext);
    const [mimetype, setMimetype] = useState("");
    const [nftUrl, setNftUrl] = useState("");
    const navigate = useNavigate();

    const [infos, setInfos] = useState({
        cid,
        title: undefined,
        description: undefined,
        price: undefined,
        shareCount: undefined,
        royalties: undefined,
        externalLink: undefined,
        isSensitiveContent: false,
        properties: [],
    });

    const { data: dataUser, loading: loadingGetUser } = useQuery(GET_USER, {
        variables: { id: shareHolder },
        skip: !shareHolder,
    });

    const shareHolderUser = dataUser?.getUser;

    const { data, loading: loadingQuery } = useQuery(GET_NFT, {
        variables: { id: cid },
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
            if (data && data.getIp) {
                setMimetype(data.getIp.mimetype);
                const {
                    cid,
                    title,
                    description,
                    price,
                    shareCount,
                    royalties,
                    externalLink,
                    isSensitiveContent,
                    properties,
                } = data.getIp;
                setInfos({
                    cid,
                    title,
                    description,
                    price,
                    shareCount: shareCount
                        ? ethers.utils.formatEther(shareCount)
                        : undefined,
                    royalties,
                    externalLink,
                    isSensitiveContent,
                    properties,
                });
            }
        },
    });

    const Authorization = `Bearer ${localStorage.getItem(
        LOCAL_STORAGE_KEYS.TOKEN
    )}`;

    useEffect(() => {
        if (cid) {
            axios
                .get(`${config.nft.ipsUrl}/${cid}`, {
                    headers: {
                        Authorization,
                    },
                    responseType: "blob",
                })
                .then((res) => {
                    const blobURL = URL.createObjectURL(res.data);
                    setNftUrl(blobURL);
                });
        }
    }, [Authorization, cid]);

    useEffect(() => {
        const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
        if (!token) {
            displaySnackBar({
                message: "You must be logged in to make an offer on an NFT",
                type: "error",
            });
            navigate(ROUTES.wallets);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myProfile]);

    return (
        <div className={isDark ? "greyscheme" : ""}>
            {(loadingQuery || loadingGetUser) && <CustomLoader />}

            <section
                className="jumbotron breadcumb no-bg"
                style={{
                    backgroundImage: !isDark
                        ? `url(${"/img/background/subheader.jpg"})`
                        : `url(${"/img/background/8.jpg"})`,
                }}
            >
                <div className="mainbreadcumb">
                    <div className="container">
                        <div className="row m-10-hor">
                            <div className="col-12">
                                <h1 className="text-center">
                                    {shareHolderUser
                                        ? `Propose an offer to ${
                                              shareHolderUser?.userName ||
                                              shareHolderUser?.id
                                          }`
                                        : "Make an offer on this NFT"}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container">
                <div className="row">
                    <div className="col-lg-7 offset-lg-1 mb-5">
                        <form
                            id="form-create-item"
                            className="form-border"
                            action="#"
                        >
                            <NFTOfferForm cid={cid} shareHolder={shareHolder} />
                        </form>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-xs-12">
                        <h5>Preview item</h5>
                        <div className="nft__item m-0">
                            <NFTAuthorPicture
                                src={data?.getIp?.user?.profilePictureUri}
                            />
                            <div className="nft__item_wrap">
                                <span>
                                    <img
                                        src={getImagePreview({
                                            extension: mimetype,
                                            imageUrl: nftUrl,
                                        })}
                                        id="get_file_2"
                                        className="lazy nft__item_preview"
                                        alt=""
                                    />
                                </span>
                            </div>
                            <div className="nft__item_info">
                                <span>
                                    <h4>{infos.title}</h4>
                                </span>
                                <div className="nft__item_price">
                                    {infos.price ? (
                                        <div>{infos.price} ETH</div>
                                    ) : null}
                                    {infos.royalties ? (
                                        <div>{infos.royalties}%</div>
                                    ) : null}
                                </div>
                                {/* <div className="nft__item_action">
                                    <span>Place a bid</span>
                                </div> */}
                                <div className="nft__item_like">
                                    <i className="fa fa-heart"></i>
                                    <span>0</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
};

export default NFTOffer;
