import { gql } from "@apollo/client";

export const GET_TICKER_STAT = gql`
    query TickerStat {
        nfterc20S {
            id
            title
            dailyStats(where: { averagePrice_not: null, periodicity: DAILY }) {
                averagePrice
                delta
            }
        }
    }
`;
