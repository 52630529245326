import authentification from "./authentification";
import common from "./common";
import header from "./header";
import home from "./home";
import myProfile from "./myProfile";
import net from "./net";
import originsClub from "./originsClub";
import titles from "./titles";
import upload from "./upload";
import wallet from "./wallet";

const en = {
    ...common,
    ...home,
    ...header,
    ...authentification,
    ...myProfile,
    ...net,
    ...wallet,
    ...upload,
    ...titles,
    ...originsClub,
};

export default en;
