import React from "react";
import Particle from "../components/Particle";
import SliderMainParticle from "../components/SliderMainParticle";
import FeatureBox from "../components/FeatureBox";
import CarouselCollectionRedux from "../components/CarouselCollectionRedux";
import ColumnNewRedux from "../components/ColumnNewRedux";
import AuthorListRedux from "../components/AuthorListRedux";
import Footer from "../components/footer";
import { createGlobalStyle } from "styled-components";
import COLORS from "../constants/colors";

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: ${COLORS.light.color_2};
    border-bottom: solid 1px ${COLORS.light.color_2};
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: #fff;
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: ${COLORS.light.color_2};
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;

const homeone = () => (
    <div>
        <GlobalStyles />
        <section
            className="jumbotron no-bg"
            style={{ backgroundImage: `url(${"./img/background/2.jpg"})` }}
        >
            <Particle />
            <SliderMainParticle />
        </section>

        <section className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="text-center">
                        <h2>Popular Items</h2>
                        <div className="small-border"></div>
                    </div>
                </div>
            </div>

            <ColumnNewRedux />
        </section>

        <section className="container-fluid bg-gray">
            <div className="row">
                <div className="col-lg-12">
                    <div className="text-center">
                        <h2>Hot Collections</h2>
                        <div className="small-border"></div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <CarouselCollectionRedux />
                    </div>
                </div>
            </div>
        </section>

        <section className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="text-center">
                        <h2>Top Sellers</h2>
                        <div className="small-border"></div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <AuthorListRedux />
                </div>
            </div>
        </section>

        <section className="container-fluid bg-gray">
            <div className="row">
                <div className="col-lg-12">
                    <div className="text-center">
                        <h2>Create and sell your NFTs</h2>
                        <div className="small-border"></div>
                    </div>
                </div>
            </div>
            <div className="container">
                <FeatureBox />
            </div>
        </section>

        <Footer />
    </div>
);
export default homeone;
