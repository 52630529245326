import CustomSelect from "../../../components/select/CustomSelect";

const ActivityFilter = ({ value, handleChangeValue }) => {
    return (
        <div className="dropdownSelect one">
            <CustomSelect
                options={[
                    {
                        value: undefined,
                        label: "All",
                    },
                    {
                        value: "PAYMENT",
                        label: "Sales",
                    },
                    {
                        value: "TRANSFER",
                        label: "Mints",
                    },
                ]}
                value={value}
                onChange={handleChangeValue}
                isClearable={false}
            />
        </div>
    );
};

export default ActivityFilter;
