import React, { useContext } from "react";
import COLORS from "../../constants/colors";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";

const CustomSwitchProperty = ({
    title,
    subtitle,
    value,
    handleChangeValue,
    faIcon: faIconProp,
}) => {
    const { isDark } = useContext(ThemeContext);
    const faIcon = faIconProp || "fa-exclamation-triangle";

    return (
        <div className="switch-with-title">
            <h5>
                <i
                    className={`fa fa- ${faIcon} id-color-2 mr10`}
                    style={{
                        color: isDark ? COLORS.dark.color_2 : undefined,
                    }}
                ></i>
                {title}
            </h5>
            <div className="de-switch">
                <input
                    type="checkbox"
                    id="switch-unlock"
                    className="checkbox"
                    value={value}
                    checked={value}
                    style={{
                        backgroundColor: isDark
                            ? COLORS.dark.color_2
                            : undefined,
                    }}
                />
                <label
                    htmlFor="switch-unlock"
                    onClick={handleChangeValue}
                ></label>
            </div>
            <div className="clearfix"></div>
            <p className="p-info pb-3">{subtitle}</p>
        </div>
    );
};

export default CustomSwitchProperty;
