import { useQuery } from "@apollo/client";
import React, { useContext } from "react";
import CustomLoader from "../../../components/customLoader/CustomLoader";
import PermitCardList from "../../../components/permitCard/PermitCardList";
import { MY_PERMITS } from "../../../graphql/permit/query";
import { ThemeContext } from "../../../providers/Theme/ThemeProvider";

const MyPermits = () => {
    const { isDark } = useContext(ThemeContext);

    const { data, loading } = useQuery(MY_PERMITS, {
        fetchPolicy: "cache-and-network",
    });

    const myPermits = data ? data.myPermits : [];

    return (
        <div className={isDark ? "greyscheme" : ""}>
            {loading && <CustomLoader />}
            {myPermits.length ? (
                <PermitCardList permits={myPermits} />
            ) : (
                <div>No permit.</div>
            )}
        </div>
    );
};
export default MyPermits;
