import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { AuthContext } from "../../../providers/Auth/AuthProvider";
import CustomInput from "../../../components/input/CustomInput";
import { SnackBarContext } from "../../../providers/SnackBar/SnackBarProvider";
import { useNavigate } from "@reach/router";
import ROUTES from "../../../components/constants/routes";
import CustomButton from "../../../components/button/CustomButton";
import LOCAL_STORAGE_KEYS from "../../../constants/localStorage";
import {
    getIpId,
    fillSaleOrOffer,
    partialDomain,
    IDEAL_MARKETPLACE_TYPES,
} from "common";
import { ethers } from "ethers";
import contract from "../../../utils/blockchain/contract";
import { GET_SUBGRAPH_NFT } from "../../../subgraph/nft/query";
import { GraphNodeClientContext } from "../../../providers/Apollo/ApolloProvider";
import moment from "moment";
import { MAKE_SIGNED_OFFER } from "../../../graphql/offer/mutation";
import { defaultValidUntil } from "../../../constants/blockchain";
import CustomLoader from "../../../components/customLoader/CustomLoader";
import { GET_USER } from "../../../graphql/user/query";
import { GET_NFT } from "../../../graphql/nft/query";

const NFTOfferForm = ({ cid, shareHolder, handleClose, handleSwitchMenu }) => {
    const { authContext } = useContext(AuthContext);
    const { myProfile } = authContext;
    const { displaySnackBar } = useContext(SnackBarContext);
    const { graphNodeClient } = useContext(GraphNodeClientContext);
    const navigate = useNavigate();
    const [validUntil, setValidUntil] = useState("");
    const [offerPricePerShare, setofferPricePerShare] = useState(0);
    const [offerShares, setofferShares] = useState(0);
    const [totalShares, setTotalShares] = useState(0);
    const [loadingSignature, setLoadingSignature] = useState(false);

    const wallet = authContext.wallet;
    const signer = wallet?.signer;
    const provider = wallet?.provider;

    const { data: dataUser, loading: loadingGetUser } = useQuery(GET_USER, {
        variables: { id: shareHolder },
        skip: !shareHolder,
    });

    const shareHolderUser = dataUser?.getUser;

    const [createOffer, { loading: loadingCreateOffer }] = useMutation(
        MAKE_SIGNED_OFFER,
        {
            onCompleted: (data) => {
                if (data && data.makeSignedOffer) {
                    if (handleClose) {
                        handleClose();
                        if (handleSwitchMenu) handleSwitchMenu();
                    } else {
                        navigate(`${ROUTES.nftDetail}/${cid}`);
                    }

                    displaySnackBar({
                        message: "You made an offer",
                        type: "success",
                    });
                }
            },
            onError: (err) => {
                console.log(`err : `, err);
                setLoadingSignature(false);
                if (
                    err.message === "NFT owner cannot make offer on his own NFT"
                ) {
                    displaySnackBar({
                        message:
                            "You cannot make offer on this NFT because you are the owner",
                        type: "error",
                    });
                } else if (err.message === "Bad signature") {
                    displaySnackBar({
                        message: "Bad signature",
                        type: "error",
                    });
                } else {
                    displaySnackBar({
                        message: "Error on making offer",
                        type: "error",
                    });
                }
            },
            refetchQueries: [
                {
                    query: GET_NFT,
                    variables: { id: cid, saleOfferFilter: "active" },
                },
            ],
        }
    );

    const nftErc20Id = getIpId({
        IdealNFTAddress: contract?.contracts?.IdealNFT?.address,
        ip: {
            contentType: 1,
            content: ethers.utils.toUtf8Bytes(cid),
        },
        chainId: contract?.chainId,
    });

    const { data: mintedNFTData, loading: loadingGraphnode } = useQuery(
        GET_SUBGRAPH_NFT,
        {
            fetchPolicy: "cache-and-network",
            variables: { id: nftErc20Id },
            client: graphNodeClient,
            onCompleted: (data) => {
                if (data && data.nfterc20) {
                    const mintedNFT = data?.nfterc20;
                    const currentTotalShare = mintedNFT?.totalShares;

                    const shareHolderBalance = shareHolder
                        ? mintedNFT?.balances?.find(
                              (balance) =>
                                  balance?.owner?.address?.toLowerCase() ===
                                  shareHolder?.toLowerCase()
                          )
                        : null;

                    const shareHolderBalanceAmount = shareHolderBalance
                        ? Number(
                              ethers.utils.formatEther(
                                  shareHolderBalance?.amount || "0"
                              )
                          )
                        : null;

                    const formattedcurrentTotalShare = currentTotalShare
                        ? ethers.utils.formatEther(currentTotalShare)
                        : 0;

                    const sellerTotalShare =
                        shareHolderBalanceAmount ||
                        Number(formattedcurrentTotalShare);
                    setTotalShares(sellerTotalShare);
                    setofferShares(sellerTotalShare);

                    const balances = mintedNFT?.balances || [];

                    const userIsTheOnlyShareHolder =
                        balances?.length === 1 &&
                        balances[0]?.owner?.address?.toLowerCase() ===
                            myProfile?.id?.toLowerCase();

                    // Check if the user is the only share holder
                    if (userIsTheOnlyShareHolder) {
                        displaySnackBar({
                            message:
                                "You cannot make an offer on this NFT because you are the only share holder",
                            type: "error",
                        });
                        if (handleClose) {
                            handleClose();
                        } else {
                            navigate(`${ROUTES.nftDetail}/${cid}`);
                        }
                    }
                }
            },
            onError: (err) => {
                console.log("err : ", err);
            },
        }
    );

    const mintedNFT = mintedNFTData?.nfterc20;

    const handleChangePrice = (value) => {
        setofferPricePerShare(Number(value));
    };

    const handleChangeShareCount = (value) => {
        setofferShares(Number(value));
    };

    const handleExpirationDate = (value) => {
        setValidUntil(value);
    };

    const validUntilDate = moment(validUntil);
    const todayDate = moment();

    const invalidValidUntil = !!(
        validUntil &&
        validUntilDate &&
        validUntilDate.isBefore(todayDate)
    );

    useEffect(() => {
        const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
        if (!token) {
            displaySnackBar({
                message: "You must be logged in to make an offer on an NFT",
                type: "error",
            });
            navigate(ROUTES.wallets);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myProfile]);

    const missmingValues = !offerPricePerShare || !offerShares;

    const total = (offerPricePerShare * offerShares).toString();

    const handleMakeOffer = async () => {
        if (!mintedNFT) {
            displaySnackBar({
                message: "This NFT is not minted yet",
                type: "error",
            });
            return;
        }

        if (missmingValues) {
            displaySnackBar({
                message: "You must set a price per share and a share count",
                type: "error",
            });
            return;
        }

        try {
            setLoadingSignature(true);
            console.log("--------------- handleMakeOffer --------------------");
            const defaultOffer = fillSaleOrOffer({});
            console.log(`defaultOffer : `, defaultOffer);
            // ***********************************
            // A FAIRE : MAKE OFFER BY SHARE OWNER
            // ***********************************
            const offer = {
                ...defaultOffer,
                saleOrOffer: 2,
                item: {
                    tokenContract: mintedNFT?.address,
                    value: ethers.utils.parseEther(offerShares.toString()),
                },
                payment: {
                    ...defaultOffer.payment,
                    value: ethers.utils.parseEther(total),
                },
                owner: {
                    ...defaultOffer.owner,
                    tokenOwner: shareHolder || defaultOffer.owner.tokenOwner,
                },
                buyer: {
                    ...defaultOffer.owner,
                    tokenOwner: authContext.myProfile.id,
                },
                salt: ethers.utils.hexlify(ethers.utils.randomBytes(32)),
                feeReceiver: contract?.contracts?.PaymentSplitter?.address,
                validUntil: {
                    timeLimitType: 0,
                    blockNumberOrTimestamp: validUntil
                        ? Math.floor(new Date(validUntil).getTime() / 1000)
                        : defaultValidUntil,
                },
            };

            const chainId = provider?.provider.chainId;

            const domain = {
                ...partialDomain,
                chainId,
                verifyingContract:
                    contract.contracts?.IdealMarketplace?.address,
            };

            const signature = await signer._signTypedData(
                domain,
                IDEAL_MARKETPLACE_TYPES,
                offer
            );

            console.log(`signature : `, signature);

            setLoadingSignature(false);

            createOffer({
                variables: {
                    input: {
                        ipCid: cid,
                        offer,
                        signature,
                        validUntil: new Date(validUntil || defaultValidUntil),
                    },
                },
            });
        } catch (error) {
            console.log(`error : `, error);
            displaySnackBar({
                message:
                    error?.data?.message || "Error while signing your offer",
                type: "error",
            });
            setLoadingSignature(false);
        }
    };

    const maxShareoffer = totalShares ? Number(totalShares) : undefined;

    const maxShareError = offerShares > maxShareoffer;
    const maxShareErrorMessage = shareHolderUser
        ? `Share count cannot be more than ${
              shareHolderUser?.userName || shareHolderUser?.id
          } owns : ${maxShareoffer}`
        : `Share count cannot be more than the total shares : ${maxShareoffer}`;

    const requiredShareMessage = "Share count is required";
    const requiredPricePerShareMessage = "Price per share is required";

    return (
        <div>
            {(loadingGraphnode ||
                loadingCreateOffer ||
                loadingSignature ||
                loadingGetUser) && (
                <CustomLoader
                    text={
                        loadingCreateOffer
                            ? "Creating your offer..."
                            : loadingSignature
                            ? "Signing your offer..."
                            : undefined
                    }
                />
            )}
            <div className="field-set">
                <CustomInput
                    label="Shares"
                    placeholder="enter share count"
                    value={offerShares}
                    handleChangeValue={handleChangeShareCount}
                    type="number"
                    error={maxShareError || !offerShares}
                    errorText={
                        !offerShares
                            ? requiredShareMessage
                            : maxShareErrorMessage
                    }
                />

                <CustomInput
                    label="Price per share (ETH)"
                    placeholder="enter price per share (ETH)"
                    value={offerPricePerShare}
                    handleChangeValue={handleChangePrice}
                    type="number"
                    error={!offerPricePerShare}
                    errorText={requiredPricePerShareMessage}
                />

                <CustomInput
                    label="Total price (ETH)"
                    placeholder="enter the total price (ETH)"
                    value={total}
                    disabled
                />

                <CustomInput
                    label="Expiration date"
                    placeholder="enter the expiration date of your sale"
                    value={
                        validUntil
                            ? moment(validUntil).format("YYYY-MM-DD")
                            : undefined
                    }
                    handleChangeValue={handleExpirationDate}
                    type="date"
                    min={moment(new Date()).format("YYYY-MM-DD")}
                    error={invalidValidUntil}
                    errorText="Expiration date must be a future date"
                />

                <CustomButton
                    text="Make offer"
                    onClick={handleMakeOffer}
                    disabled={
                        loadingGraphnode ||
                        loadingCreateOffer ||
                        missmingValues ||
                        maxShareError ||
                        invalidValidUntil
                    }
                />
            </div>
        </div>
    );
};

export default NFTOfferForm;
