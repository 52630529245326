import { useMutation } from "@apollo/client";
import { Tooltip } from "@material-ui/core";
import { useContext } from "react";
import { LIKE_OR_DISLIKE_NFT } from "../../../graphql/nft/mutation";
import { SnackBarContext } from "../../../providers/SnackBar/SnackBarProvider";
import { ThemeContext } from "../../../providers/Theme/ThemeProvider";
import getLikeColor from "../../../utils/nft/getLikeColor";

const LikeButton = ({ cid, likedByMe, onCompleted, refetchQueries, style }) => {
    const { displaySnackBar } = useContext(SnackBarContext);
    const { isDark } = useContext(ThemeContext);

    const [likeOrDislikeNFT, { loading }] = useMutation(LIKE_OR_DISLIKE_NFT, {
        onCompleted,
        onError: (err) => {
            console.log("err : ", err.message);
            if (err.message === "Unauthenticated") {
                displaySnackBar({
                    message: "You must be authenticated to like an NFT.",
                    type: "error",
                });
            } else {
                displaySnackBar({
                    message: "Error occured while liking the NFT.",
                    type: "error",
                });
            }
        },
        refetchQueries,
    });

    const onLike = () => {
        if (loading) return;

        likeOrDislikeNFT({ variables: { cid: cid } });
    };

    return (
        <Tooltip title={likedByMe ? "Remove your like" : "Like this NFT"}>
            <i
                className="fa fa-heart"
                style={{
                    color: likedByMe ? getLikeColor({ isDark }) : undefined,
                    ...style,
                }}
                onClick={onLike}
            ></i>
        </Tooltip>
    );
};

export default LikeButton;
