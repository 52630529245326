import { createContext, useContext, useEffect, useState } from "react";
import {
    defaultEnv,
    getEnvFromChainId,
    getEnvFromHost,
} from "../../utils/network/network";
import { AuthContext } from "../Auth/AuthProvider";
import GlobalNetMismatchBanner from "./GlobalNetMismatchBanner/GlobalNetMismatchBanner";

export const NetworkContext = createContext({
    isNetworkMismatched: false,
    isUnsupportedNetwork: false,
});

const NetTesterProvider = ({ children }) => {
    const {
        authContext: { wallet, myProfile },
        setIsAdmin,
        setIsModerator,
        checkAdminAndModerator,
    } = useContext(AuthContext);

    const [showNetBanner, setShowNetBanner] = useState(false);
    const [networkEnv, setNetworkEnv] = useState("undefined");
    const [hostEnv, setHostEnv] = useState(undefined);

    const handleCloseBanner = () => {
        setShowNetBanner(false);
    };

    const checkEnvMismatch = (chainId) => {
        const currentHostname = window.location.hostname;
        const currentHostEnv = getEnvFromHost(currentHostname);
        const currentNetworkEnv = getEnvFromChainId(chainId);

        if (currentHostEnv) {
            setHostEnv(currentHostEnv);
        }

        if (currentNetworkEnv.chainId !== defaultEnv.chainId) {
            setNetworkEnv(currentNetworkEnv);
        } else {
            setNetworkEnv("unsupported");
        }

        if (currentHostEnv.chainId === currentNetworkEnv.chainId) {
            setShowNetBanner(false);
        } else {
            setShowNetBanner(true);
        }
    };

    useEffect(() => {
        // Check on page load
        const chainId0x = wallet?.provider?.provider.chainId;

        const currentChainId = Number(chainId0x);

        if (wallet && currentChainId) {
            checkEnvMismatch(currentChainId);
        }

        // Check on chainChanged (Listener)
        if (window.ethereum) {
            window.ethereum.on("chainChanged", (networkChainId) => {
                checkEnvMismatch(Number(networkChainId));
            });
        }
    }, [wallet]);

    const isNetworkMismatched =
        networkEnv?.chainId && hostEnv?.chainId !== networkEnv?.chainId;
    const isUnsupportedNetwork = networkEnv === "unsupported";

    useEffect(() => {
        if (isNetworkMismatched || isUnsupportedNetwork) {
            setIsAdmin(false);
            setIsModerator(false);
        } else {
            checkAdminAndModerator();
        }
    }, [isNetworkMismatched, isUnsupportedNetwork]);

    return (
        <NetworkContext.Provider
            value={{
                isNetworkMismatched,
                isUnsupportedNetwork,
            }}
        >
            {myProfile &&
                showNetBanner &&
                (isNetworkMismatched || isUnsupportedNetwork) && (
                    <GlobalNetMismatchBanner
                        handleCloseBanner={handleCloseBanner}
                        networkEnv={networkEnv}
                        hostEnv={hostEnv}
                        isUnsupportedNetwork={isUnsupportedNetwork}
                    />
                )}

            {children}
        </NetworkContext.Provider>
    );
};

export default NetTesterProvider;
