import React, { useContext, useRef } from "react";
import Footer from "../../components/footer/Footer";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";
import { useQuery } from "@apollo/client";
import { GET_HOME_STAT } from "../../graphql/statistic/query";
import CustomLoader from "../../components/customLoader/CustomLoader";
import { useEffect } from "react";
import { useLocation } from "@reach/router";
import { parse } from "query-string";
import SliderMainV2 from "./SliderMainParticles/SliderMainV2";
import FeatureboxV2 from "./FeatureBox/FeatureBoxV2";
import AdditionalInfo from "./AdditionalInfo/AdditionalInfo";
import PopularItemsV2 from "./PopularItems/PopularItemsV2";
import PromoMovie from "./PromoMovie/PromoMovie";

const Home = () => {
    const { isDark } = useContext(ThemeContext);
    const location = useLocation();
    const searchParams = parse(location.search);

    const popularItemsRef = useRef(null);
    const topSellersRef = useRef(null);

    const scrollToPupularItems = () =>
        popularItemsRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });

    const scrollToTopSellers = () =>
        topSellersRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });

    const { data, loading } = useQuery(GET_HOME_STAT, {
        fetchPolicy: "cache-and-network",
    });

    const creatorsCount = data?.getHomeStat?.creatorsCount || 0;
    const mintedNftsCount = data?.getHomeStat?.mintedNftsCount || 0;

    useEffect(() => {
        if (!loading && searchParams?.section === "popularItems") {
            scrollToPupularItems();
        }
    }, [popularItemsRef, loading, searchParams]);

    useEffect(() => {
        if (!loading && searchParams?.section === "topSellers") {
            scrollToTopSellers();
        }
    }, [topSellersRef, loading, searchParams]);

    return (
        <div className={isDark ? "greyscheme" : ""}>
            {loading && <CustomLoader />}

            <section
                className="jumbotron no-bg"
                style={{
                    background: `url(${"./img/v2/backgrounds/home_background1.png"}), #000`,
                    backgroundSize: "75% 100%, cover",
                    backgroundPosition: "right",
                    backgroundRepeat: "no-repeat",
                    overflow: "hidden",
                }}
            >
                <SliderMainV2 />
            </section>

            <PromoMovie />
            <FeatureboxV2 />
            <AdditionalInfo />

            <div ref={popularItemsRef}>
                <PopularItemsV2 />
            </div>

            <div ref={topSellersRef}>{/* <TopSellers /> */}</div>

            <Footer />
        </div>
    );
};
export default Home;
