import { Box, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
    createStyles({
        logosContainer: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            alignItems: "center",
            width: "100%",
        },
        logo: {
            margin: 20,
            width: "auto",
            height: 50,
        },
        copyrightLogo: {
            margin: 20,
            width: "auto",
            height: 100,
        },
    })
);

const TrustedPartnersLogos = () => {
    const classes = useStyles();

    return (
        <Box className={classes.logosContainer}>
            <img
                key="/img/v2/logos/brave-black.svg"
                src="/img/v2/logos/brave-black.svg"
                className={classes.logo}
                alt="#"
            />
            <img
                key="/img/v2/logos/etherium logo.png"
                src="/img/v2/logos/etherium logo.png"
                className={classes.logo}
                alt="#"
            />
            <img
                key="/img/v2/logos/pngkit_copyright-png_1190094.png"
                src="/img/v2/logos/pngkit_copyright-png_1190094.png"
                className={classes.copyrightLogo}
                alt="#"
            />
        </Box>
    );
};

export default TrustedPartnersLogos;
