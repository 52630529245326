import { memo, useContext, useState } from "react";
import COLORS from "../../constants/colors";
import { FONT_SIZES } from "../../constants/sizes";
import DeleteIcon from "@material-ui/icons/Delete";
import { AuthContext } from "../../providers/Auth/AuthProvider";
import EditIcon from "@material-ui/icons/Edit";
import { useMutation } from "@apollo/client";
import { DELETE_PARAGRAPH } from "../../graphql/help/mutation";
import { SnackBarContext } from "../../providers/SnackBar/SnackBarProvider";
import CustomGGLandDialog from "../dialog/CustomGGLandDialog";
import CustomButton from "../button/CustomButton";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";
import UpdateParagraphForm from "./UpdateParagraphForm";
import { BloggersClientContext } from "../../providers/Apollo/ApolloProvider";

const ParagraphItem = ({ paragraph, refetchSubHelp }) => {
    const { isBlogAdmin } = useContext(AuthContext);
    const { displaySnackBar } = useContext(SnackBarContext);
    const { bloggersClient } = useContext(BloggersClientContext);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const { isDark } = useContext(ThemeContext);
    const [openUpdateSubHelpDialog, setOpenUpdateSubHelpDialog] =
        useState(false);

    const [deleteParagraph, { loading: loadingDelete }] = useMutation(
        DELETE_PARAGRAPH,
        {
            client: bloggersClient,
            onCompleted: (data) => {
                if (data && data.deleteParagraph) {
                    displaySnackBar({
                        message: "Paragraph deleted",
                        type: "success",
                    });
                    onCloseDeleteDialog();
                    if (refetchSubHelp) {
                        refetchSubHelp();
                    }
                }
            },
            onError: (err) => {
                if (err.message) {
                    displaySnackBar({
                        message: err.message,
                        type: "error",
                    });
                }
            },
        }
    );

    const onCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const onCloseUpdateDialog = () => {
        setOpenUpdateSubHelpDialog(false);
    };

    const onDelete = () => {
        if (paragraph?.id) {
            deleteParagraph({
                variables: {
                    id: Number(paragraph?.id),
                },
            });
        }
    };

    return (
        <div style={{ display: "flex" }}>
            {paragraph?.text && (
                <div
                    style={{
                        width: "100%",
                        paddingTop: 15,
                        paddingBottom: 15,
                        fontSize: FONT_SIZES.common.large,
                        color: isDark ? COLORS.v2.white : COLORS.v2.black_grey,
                        cursor: "pointer",
                    }}
                >
                    {paragraph?.text}
                </div>
            )}

            {paragraph?.picture && (
                <div
                    className="col-lg-3 col-sm-6 col-xs-12"
                    style={{ marginTop: 20 }}
                >
                    <div className="bloglist item">
                        <div className="post-content">
                            <div className="post-image">
                                <img
                                    alt=""
                                    src={paragraph?.picture}
                                    className="lazy"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {isBlogAdmin && (
                <EditIcon
                    style={{
                        alignSelf: "center",
                        cursor: "pointer",
                        marginLeft: 20,
                    }}
                    onClick={() => setOpenUpdateSubHelpDialog(true)}
                />
            )}
            {isBlogAdmin && (
                <DeleteIcon
                    style={{
                        alignSelf: "center",
                        cursor: "pointer",
                        marginLeft: 20,
                    }}
                    onClick={() => setOpenDeleteDialog(true)}
                />
            )}

            {openDeleteDialog && (
                <CustomGGLandDialog
                    title="Do you really want to delete this paragraph ?"
                    handleClose={onCloseDeleteDialog}
                >
                    <div>
                        {paragraph?.text && `"${paragraph?.text}"`}
                        {paragraph?.picture && (
                            <div
                                className="col-lg-3 col-sm-6 col-xs-12"
                                style={{ marginTop: 20 }}
                            >
                                <div className="bloglist item">
                                    <div className="post-content">
                                        <div className="post-image">
                                            <img
                                                alt=""
                                                src={paragraph?.picture}
                                                className="lazy"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div style={{ display: "flex" }}>
                            <CustomButton
                                text="DELETE"
                                onClick={onDelete}
                                disabled={loadingDelete}
                                style={{ marginRight: 20 }}
                            />
                            <CustomButton
                                text="CANCEL"
                                onClick={onCloseDeleteDialog}
                                disabled={loadingDelete}
                                isWhite
                            />
                        </div>
                    </div>
                </CustomGGLandDialog>
            )}

            {openUpdateSubHelpDialog && (
                <CustomGGLandDialog
                    title="Update this paragraph"
                    handleClose={onCloseUpdateDialog}
                >
                    <UpdateParagraphForm
                        paragraph={paragraph}
                        handleCloseDialog={onCloseUpdateDialog}
                        refetchSubHelp={refetchSubHelp}
                    />
                </CustomGGLandDialog>
            )}
        </div>
    );
};
export default memo(ParagraphItem);
