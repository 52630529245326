import React from "react";
import Footer from "../components/footer";
import { createGlobalStyle } from "styled-components";
import COLORS from "../constants/colors";

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: ${COLORS.light.color_2};
    border-bottom: solid 1px ${COLORS.light.color_2};
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: ${COLORS.light.color_2};
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;

const serviceone = () => (
    <div>
        <GlobalStyles />

        <section
            className="jumbotron breadcumb no-bg"
            style={{
                backgroundImage: `url(${"./img/background/subheader.jpg"})`,
            }}
        >
            <div className="mainbreadcumb">
                <div className="container">
                    <div className="row m-10-hor">
                        <div className="col-12">
                            <h1 className="text-center">Elegant Icons</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="container">
            <div className="row">
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="arrow_up"></span>{" "}
                    &nbsp;arrow_up
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="arrow_down"></span>{" "}
                    &nbsp;arrow_down
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="arrow_left"></span>{" "}
                    &nbsp;arrow_left
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="arrow_right"></span>{" "}
                    &nbsp;arrow_right
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="arrow_left-up"></span>{" "}
                    &nbsp;arrow_left-up
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="arrow_right-up"></span>{" "}
                    &nbsp;arrow_right-up
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_right-down"
                    ></span>{" "}
                    &nbsp;arrow_right-down
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="arrow_left-down"></span>{" "}
                    &nbsp;arrow_left-down
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="arrow-up-down"></span>{" "}
                    &nbsp;arrow-up-down
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_up-down_alt"
                    ></span>{" "}
                    &nbsp;arrow_up-down_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_left-right_alt"
                    ></span>{" "}
                    &nbsp;arrow_left-right_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_left-right"
                    ></span>{" "}
                    &nbsp;arrow_left-right
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_expand_alt2"
                    ></span>{" "}
                    &nbsp;arrow_expand_alt2
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_expand_alt"
                    ></span>{" "}
                    &nbsp;arrow_expand_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="arrow_condense"></span>{" "}
                    &nbsp;arrow_condense
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="arrow_expand"></span>{" "}
                    &nbsp;arrow_expand
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="arrow_move"></span>{" "}
                    &nbsp;arrow_move
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="arrow_carrot-up"></span>{" "}
                    &nbsp;arrow_carrot-up
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_carrot-down"
                    ></span>{" "}
                    &nbsp;arrow_carrot-down
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_carrot-left"
                    ></span>{" "}
                    &nbsp;arrow_carrot-left
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_carrot-right"
                    ></span>{" "}
                    &nbsp;arrow_carrot-right
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_carrot-2up"
                    ></span>{" "}
                    &nbsp;arrow_carrot-2up
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_carrot-2down"
                    ></span>{" "}
                    &nbsp;arrow_carrot-2down
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_carrot-2left"
                    ></span>{" "}
                    &nbsp;arrow_carrot-2left
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_carrot-2right"
                    ></span>{" "}
                    &nbsp;arrow_carrot-2right
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_carrot-up_alt2"
                    ></span>{" "}
                    &nbsp;arrow_carrot-up_alt2
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_carrot-down_alt2"
                    ></span>{" "}
                    &nbsp;arrow_carrot-down_alt2
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_carrot-left_alt2"
                    ></span>{" "}
                    &nbsp;arrow_carrot-left_alt2
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_carrot-right_alt2"
                    ></span>{" "}
                    &nbsp;arrow_carrot-right_alt2
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_carrot-2up_alt2"
                    ></span>{" "}
                    &nbsp;arrow_carrot-2up_alt2
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_carrot-2down_alt2"
                    ></span>{" "}
                    &nbsp;arrow_carrot-2down_alt2
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_carrot-2left_alt2"
                    ></span>{" "}
                    &nbsp;arrow_carrot-2left_alt2
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_carrot-2right_alt2"
                    ></span>{" "}
                    &nbsp;arrow_carrot-2right_alt2
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_triangle-up"
                    ></span>{" "}
                    &nbsp;arrow_triangle-up
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_triangle-down"
                    ></span>{" "}
                    &nbsp;arrow_triangle-down
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_triangle-left"
                    ></span>{" "}
                    &nbsp;arrow_triangle-left
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_triangle-right"
                    ></span>{" "}
                    &nbsp;arrow_triangle-right
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_triangle-up_alt2"
                    ></span>{" "}
                    &nbsp;arrow_triangle-up_alt2
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_triangle-down_alt2"
                    ></span>{" "}
                    &nbsp;arrow_triangle-down_alt2
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_triangle-left_alt2"
                    ></span>{" "}
                    &nbsp;arrow_triangle-left_alt2
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_triangle-right_alt2"
                    ></span>{" "}
                    &nbsp;arrow_triangle-right_alt2
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="arrow_back"></span>{" "}
                    &nbsp;arrow_back
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_minus-06"></span>{" "}
                    &nbsp;icon_minus-06
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_plus"></span>{" "}
                    &nbsp;icon_plus
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_close"></span>{" "}
                    &nbsp;icon_close
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_check"></span>{" "}
                    &nbsp;icon_check
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_minus_alt2"></span>{" "}
                    &nbsp;icon_minus_alt2
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_plus_alt2"></span>{" "}
                    &nbsp;icon_plus_alt2
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_close_alt2"></span>{" "}
                    &nbsp;icon_close_alt2
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_check_alt2"></span>{" "}
                    &nbsp;icon_check_alt2
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_zoom-out_alt"
                    ></span>{" "}
                    &nbsp;icon_zoom-out_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_zoom-in_alt"
                    ></span>{" "}
                    &nbsp;icon_zoom-in_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_search"></span>{" "}
                    &nbsp;icon_search
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_box-empty"></span>{" "}
                    &nbsp;icon_box-empty
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_box-selected"
                    ></span>{" "}
                    &nbsp;icon_box-selected
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_minus-box"></span>{" "}
                    &nbsp;icon_minus-box
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_plus-box"></span>{" "}
                    &nbsp;icon_plus-box
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_box-checked"
                    ></span>{" "}
                    &nbsp;icon_box-checked
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_circle-empty"
                    ></span>{" "}
                    &nbsp;icon_circle-empty
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_circle-slelected"
                    ></span>{" "}
                    &nbsp;icon_circle-slelected
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_stop_alt2"></span>{" "}
                    &nbsp;icon_stop_alt2
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_stop"></span>{" "}
                    &nbsp;icon_stop
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_pause_alt2"></span>{" "}
                    &nbsp;icon_pause_alt2
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_pause"></span>{" "}
                    &nbsp;icon_pause
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_menu"></span>{" "}
                    &nbsp;icon_menu
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_menu-square_alt2"
                    ></span>{" "}
                    &nbsp;icon_menu-square_alt2
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_menu-circle_alt2"
                    ></span>{" "}
                    &nbsp;icon_menu-circle_alt2
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_ul"></span>{" "}
                    &nbsp;icon_ul
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_ol"></span>{" "}
                    &nbsp;icon_ol
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_adjust-horiz"
                    ></span>{" "}
                    &nbsp;icon_adjust-horiz
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_adjust-vert"
                    ></span>{" "}
                    &nbsp;icon_adjust-vert
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_document_alt"
                    ></span>{" "}
                    &nbsp;icon_document_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_documents_alt"
                    ></span>{" "}
                    &nbsp;icon_documents_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_pencil"></span>{" "}
                    &nbsp;icon_pencil
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_pencil-edit_alt"
                    ></span>{" "}
                    &nbsp;icon_pencil-edit_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_pencil-edit"
                    ></span>{" "}
                    &nbsp;icon_pencil-edit
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_folder-alt"></span>{" "}
                    &nbsp;icon_folder-alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_folder-open_alt"
                    ></span>{" "}
                    &nbsp;icon_folder-open_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_folder-add_alt"
                    ></span>{" "}
                    &nbsp;icon_folder-add_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_info_alt"></span>{" "}
                    &nbsp;icon_info_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_error-oct_alt"
                    ></span>{" "}
                    &nbsp;icon_error-oct_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_error-circle_alt"
                    ></span>{" "}
                    &nbsp;icon_error-circle_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_error-triangle_alt"
                    ></span>{" "}
                    &nbsp;icon_error-triangle_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_question_alt2"
                    ></span>{" "}
                    &nbsp;icon_question_alt2
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_question"></span>{" "}
                    &nbsp;icon_question
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_comment_alt"
                    ></span>{" "}
                    &nbsp;icon_comment_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_chat_alt"></span>{" "}
                    &nbsp;icon_chat_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_vol-mute_alt"
                    ></span>{" "}
                    &nbsp;icon_vol-mute_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_volume-low_alt"
                    ></span>{" "}
                    &nbsp;icon_volume-low_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_volume-high_alt"
                    ></span>{" "}
                    &nbsp;icon_volume-high_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_quotations"></span>{" "}
                    &nbsp;icon_quotations
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_quotations_alt2"
                    ></span>{" "}
                    &nbsp;icon_quotations_alt2
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_clock_alt"></span>{" "}
                    &nbsp;icon_clock_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_lock_alt"></span>{" "}
                    &nbsp;icon_lock_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_lock-open_alt"
                    ></span>{" "}
                    &nbsp;icon_lock-open_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_key_alt"></span>{" "}
                    &nbsp;icon_key_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_cloud_alt"></span>{" "}
                    &nbsp;icon_cloud_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_cloud-upload_alt"
                    ></span>{" "}
                    &nbsp;icon_cloud-upload_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_cloud-download_alt"
                    ></span>{" "}
                    &nbsp;icon_cloud-download_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_image"></span>{" "}
                    &nbsp;icon_image
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_images"></span>{" "}
                    &nbsp;icon_images
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_lightbulb_alt"
                    ></span>{" "}
                    &nbsp;icon_lightbulb_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_gift_alt"></span>{" "}
                    &nbsp;icon_gift_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_house_alt"></span>{" "}
                    &nbsp;icon_house_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_genius"></span>{" "}
                    &nbsp;icon_genius
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_mobile"></span>{" "}
                    &nbsp;icon_mobile
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_tablet"></span>{" "}
                    &nbsp;icon_tablet
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_laptop"></span>{" "}
                    &nbsp;icon_laptop
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_desktop"></span>{" "}
                    &nbsp;icon_desktop
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_camera_alt"></span>{" "}
                    &nbsp;icon_camera_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_mail_alt"></span>{" "}
                    &nbsp;icon_mail_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_cone_alt"></span>{" "}
                    &nbsp;icon_cone_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_ribbon_alt"></span>{" "}
                    &nbsp;icon_ribbon_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_bag_alt"></span>{" "}
                    &nbsp;icon_bag_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_creditcard"></span>{" "}
                    &nbsp;icon_creditcard
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_cart_alt"></span>{" "}
                    &nbsp;icon_cart_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_paperclip"></span>{" "}
                    &nbsp;icon_paperclip
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_tag_alt"></span>{" "}
                    &nbsp;icon_tag_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_tags_alt"></span>{" "}
                    &nbsp;icon_tags_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_trash_alt"></span>{" "}
                    &nbsp;icon_trash_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_cursor_alt"></span>{" "}
                    &nbsp;icon_cursor_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_mic_alt"></span>{" "}
                    &nbsp;icon_mic_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_compass_alt"
                    ></span>{" "}
                    &nbsp;icon_compass_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_pin_alt"></span>{" "}
                    &nbsp;icon_pin_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_pushpin_alt"
                    ></span>{" "}
                    &nbsp;icon_pushpin_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_map_alt"></span>{" "}
                    &nbsp;icon_map_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_drawer_alt"></span>{" "}
                    &nbsp;icon_drawer_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_toolbox_alt"
                    ></span>{" "}
                    &nbsp;icon_toolbox_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_book_alt"></span>{" "}
                    &nbsp;icon_book_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_calendar"></span>{" "}
                    &nbsp;icon_calendar
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_film"></span>{" "}
                    &nbsp;icon_film
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_table"></span>{" "}
                    &nbsp;icon_table
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_contacts_alt"
                    ></span>{" "}
                    &nbsp;icon_contacts_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_headphones"></span>{" "}
                    &nbsp;icon_headphones
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_lifesaver"></span>{" "}
                    &nbsp;icon_lifesaver
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_piechart"></span>{" "}
                    &nbsp;icon_piechart
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_refresh"></span>{" "}
                    &nbsp;icon_refresh
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_link_alt"></span>{" "}
                    &nbsp;icon_link_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_link"></span>{" "}
                    &nbsp;icon_link
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_loading"></span>{" "}
                    &nbsp;icon_loading
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_blocked"></span>{" "}
                    &nbsp;icon_blocked
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_archive_alt"
                    ></span>{" "}
                    &nbsp;icon_archive_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_heart_alt"></span>{" "}
                    &nbsp;icon_heart_alt
                </div>

                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_printer"></span>{" "}
                    &nbsp;icon_printer
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_calulator"></span>{" "}
                    &nbsp;icon_calulator
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_building"></span>{" "}
                    &nbsp;icon_building
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_floppy"></span>{" "}
                    &nbsp;icon_floppy
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_drive"></span>{" "}
                    &nbsp;icon_drive
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_search-2"></span>{" "}
                    &nbsp;icon_search-2
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_id"></span>{" "}
                    &nbsp;icon_id
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_id-2"></span>{" "}
                    &nbsp;icon_id-2
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_puzzle"></span>{" "}
                    &nbsp;icon_puzzle
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_like"></span>{" "}
                    &nbsp;icon_like
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_dislike"></span>{" "}
                    &nbsp;icon_dislike
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_mug"></span>{" "}
                    &nbsp;icon_mug
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_currency"></span>{" "}
                    &nbsp;icon_currency
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_wallet"></span>{" "}
                    &nbsp;icon_wallet
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_pens"></span>{" "}
                    &nbsp;icon_pens
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_easel"></span>{" "}
                    &nbsp;icon_easel
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_flowchart"></span>{" "}
                    &nbsp;icon_flowchart
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_datareport"></span>{" "}
                    &nbsp;icon_datareport
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_briefcase"></span>{" "}
                    &nbsp;icon_briefcase
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_shield"></span>{" "}
                    &nbsp;icon_shield
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_percent"></span>{" "}
                    &nbsp;icon_percent
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_globe"></span>{" "}
                    &nbsp;icon_globe
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_globe-2"></span>{" "}
                    &nbsp;icon_globe-2
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_target"></span>{" "}
                    &nbsp;icon_target
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_hourglass"></span>{" "}
                    &nbsp;icon_hourglass
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_balance"></span>{" "}
                    &nbsp;icon_balance
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_star_alt"></span>{" "}
                    &nbsp;icon_star_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_star-half_alt"
                    ></span>{" "}
                    &nbsp;icon_star-half_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_star"></span>{" "}
                    &nbsp;icon_star
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_star-half"></span>{" "}
                    &nbsp;icon_star-half
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_tools"></span>{" "}
                    &nbsp;icon_tools
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_tool"></span>{" "}
                    &nbsp;icon_tool
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_cog"></span>{" "}
                    &nbsp;icon_cog
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_cogs"></span>{" "}
                    &nbsp;icon_cogs
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="arrow_up_alt"></span>{" "}
                    &nbsp;arrow_up_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="arrow_down_alt"></span>{" "}
                    &nbsp;arrow_down_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="arrow_left_alt"></span>{" "}
                    &nbsp;arrow_left_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="arrow_right_alt"></span>{" "}
                    &nbsp;arrow_right_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_left-up_alt"
                    ></span>{" "}
                    &nbsp;arrow_left-up_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_right-up_alt"
                    ></span>{" "}
                    &nbsp;arrow_right-up_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_right-down_alt"
                    ></span>{" "}
                    &nbsp;arrow_right-down_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_left-down_alt"
                    ></span>{" "}
                    &nbsp;arrow_left-down_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_condense_alt"
                    ></span>{" "}
                    &nbsp;arrow_condense_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_expand_alt3"
                    ></span>{" "}
                    &nbsp;arrow_expand_alt3
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_carrot_up_alt"
                    ></span>{" "}
                    &nbsp;arrow_carrot_up_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_carrot-down_alt"
                    ></span>{" "}
                    &nbsp;arrow_carrot-down_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_carrot-left_alt"
                    ></span>{" "}
                    &nbsp;arrow_carrot-left_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_carrot-right_alt"
                    ></span>{" "}
                    &nbsp;arrow_carrot-right_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_carrot-2up_alt"
                    ></span>{" "}
                    &nbsp;arrow_carrot-2up_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_carrot-2dwnn_alt"
                    ></span>{" "}
                    &nbsp;arrow_carrot-2dwnn_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_carrot-2left_alt"
                    ></span>{" "}
                    &nbsp;arrow_carrot-2left_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_carrot-2right_alt"
                    ></span>{" "}
                    &nbsp;arrow_carrot-2right_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_triangle-up_alt"
                    ></span>{" "}
                    &nbsp;arrow_triangle-up_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_triangle-down_alt"
                    ></span>{" "}
                    &nbsp;arrow_triangle-down_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_triangle-left_alt"
                    ></span>{" "}
                    &nbsp;arrow_triangle-left_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="arrow_triangle-right_alt"
                    ></span>{" "}
                    &nbsp;arrow_triangle-right_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_minus_alt"></span>{" "}
                    &nbsp;icon_minus_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_plus_alt"></span>{" "}
                    &nbsp;icon_plus_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_close_alt"></span>{" "}
                    &nbsp;icon_close_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_check_alt"></span>{" "}
                    &nbsp;icon_check_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_zoom-out"></span>{" "}
                    &nbsp;icon_zoom-out
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_zoom-in"></span>{" "}
                    &nbsp;icon_zoom-in
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_stop_alt"></span>{" "}
                    &nbsp;icon_stop_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_menu-square_alt"
                    ></span>{" "}
                    &nbsp;icon_menu-square_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_menu-circle_alt"
                    ></span>{" "}
                    &nbsp;icon_menu-circle_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_document"></span>{" "}
                    &nbsp;icon_document
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_documents"></span>{" "}
                    &nbsp;icon_documents
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_pencil_alt"></span>{" "}
                    &nbsp;icon_pencil_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_folder"></span>{" "}
                    &nbsp;icon_folder
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_folder-open"
                    ></span>{" "}
                    &nbsp;icon_folder-open
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_folder-add"></span>{" "}
                    &nbsp;icon_folder-add
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_folder_upload"
                    ></span>{" "}
                    &nbsp;icon_folder_upload
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_folder_download"
                    ></span>{" "}
                    &nbsp;icon_folder_download
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_info"></span>{" "}
                    &nbsp;icon_info
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_error-circle"
                    ></span>{" "}
                    &nbsp;icon_error-circle
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_error-oct"></span>{" "}
                    &nbsp;icon_error-oct
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_error-triangle"
                    ></span>{" "}
                    &nbsp;icon_error-triangle
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_question_alt"
                    ></span>{" "}
                    &nbsp;icon_question_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_comment"></span>{" "}
                    &nbsp;icon_comment
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_chat"></span>{" "}
                    &nbsp;icon_chat
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_vol-mute"></span>{" "}
                    &nbsp;icon_vol-mute
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_volume-low"></span>{" "}
                    &nbsp;icon_volume-low
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_volume-high"
                    ></span>{" "}
                    &nbsp;icon_volume-high
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_quotations_alt"
                    ></span>{" "}
                    &nbsp;icon_quotations_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_clock"></span>{" "}
                    &nbsp;icon_clock
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_lock"></span>{" "}
                    &nbsp;icon_lock
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_lock-open"></span>{" "}
                    &nbsp;icon_lock-open
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_key"></span>{" "}
                    &nbsp;icon_key
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_cloud"></span>{" "}
                    &nbsp;icon_cloud
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_cloud-upload"
                    ></span>{" "}
                    &nbsp;icon_cloud-upload
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_cloud-download"
                    ></span>{" "}
                    &nbsp;icon_cloud-download
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_lightbulb"></span>{" "}
                    &nbsp;icon_lightbulb
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_gift"></span>{" "}
                    &nbsp;icon_gift
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_house"></span>{" "}
                    &nbsp;icon_house
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_camera"></span>{" "}
                    &nbsp;icon_camera
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_mail"></span>{" "}
                    &nbsp;icon_mail
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_cone"></span>{" "}
                    &nbsp;icon_cone
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_ribbon"></span>{" "}
                    &nbsp;icon_ribbon
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_bag"></span>{" "}
                    &nbsp;icon_bag
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_cart"></span>{" "}
                    &nbsp;icon_cart
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_tag"></span>{" "}
                    &nbsp;icon_tag
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_tags"></span>{" "}
                    &nbsp;icon_tags
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_trash"></span>{" "}
                    &nbsp;icon_trash
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_cursor"></span>{" "}
                    &nbsp;icon_cursor
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_mic"></span>{" "}
                    &nbsp;icon_mic
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_compass"></span>{" "}
                    &nbsp;icon_compass
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_pin"></span>{" "}
                    &nbsp;icon_pin
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_pushpin"></span>{" "}
                    &nbsp;icon_pushpin
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_map"></span>{" "}
                    &nbsp;icon_map
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_drawer"></span>{" "}
                    &nbsp;icon_drawer
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_toolbox"></span>{" "}
                    &nbsp;icon_toolbox
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_book"></span>{" "}
                    &nbsp;icon_book
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_contacts"></span>{" "}
                    &nbsp;icon_contacts
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_archive"></span>{" "}
                    &nbsp;icon_archive
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_heart"></span>{" "}
                    &nbsp;icon_heart
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_profile"></span>{" "}
                    &nbsp;icon_profile
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_group"></span>{" "}
                    &nbsp;icon_group
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_grid-2x2"></span>{" "}
                    &nbsp;icon_grid-2x2
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_grid-3x3"></span>{" "}
                    &nbsp;icon_grid-3x3
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_music"></span>{" "}
                    &nbsp;icon_music
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_pause_alt"></span>{" "}
                    &nbsp;icon_pause_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_phone"></span>{" "}
                    &nbsp;icon_phone
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_upload"></span>{" "}
                    &nbsp;icon_upload
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_download"></span>{" "}
                    &nbsp;icon_download
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_rook"></span>{" "}
                    &nbsp;icon_rook
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_printer-alt"
                    ></span>{" "}
                    &nbsp;icon_printer-alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_calculator_alt"
                    ></span>{" "}
                    &nbsp;icon_calculator_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_building_alt"
                    ></span>{" "}
                    &nbsp;icon_building_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_floppy_alt"></span>{" "}
                    &nbsp;icon_floppy_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_drive_alt"></span>{" "}
                    &nbsp;icon_drive_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_search_alt"></span>{" "}
                    &nbsp;icon_search_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_id_alt"></span>{" "}
                    &nbsp;icon_id_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_id-2_alt"></span>{" "}
                    &nbsp;icon_id-2_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_puzzle_alt"></span>{" "}
                    &nbsp;icon_puzzle_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_like_alt"></span>{" "}
                    &nbsp;icon_like_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_dislike_alt"
                    ></span>{" "}
                    &nbsp;icon_dislike_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_mug_alt"></span>{" "}
                    &nbsp;icon_mug_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_currency_alt"
                    ></span>{" "}
                    &nbsp;icon_currency_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_wallet_alt"></span>{" "}
                    &nbsp;icon_wallet_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_pens_alt"></span>{" "}
                    &nbsp;icon_pens_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_easel_alt"></span>{" "}
                    &nbsp;icon_easel_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_flowchart_alt"
                    ></span>{" "}
                    &nbsp;icon_flowchart_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_datareport_alt"
                    ></span>{" "}
                    &nbsp;icon_datareport_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_briefcase_alt"
                    ></span>{" "}
                    &nbsp;icon_briefcase_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_shield_alt"></span>{" "}
                    &nbsp;icon_shield_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="icon_percent_alt"
                    ></span>{" "}
                    &nbsp;icon_percent_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_globe_alt"></span>{" "}
                    &nbsp;icon_globe_alt
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="icon_clipboard"></span>{" "}
                    &nbsp;icon_clipboard
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="social_facebook"></span>{" "}
                    &nbsp;social_facebook
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="social_twitter"></span>{" "}
                    &nbsp;social_twitter
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_pinterest"
                    ></span>{" "}
                    &nbsp;social_pinterest
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_googleplus"
                    ></span>{" "}
                    &nbsp;social_googleplus
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="social_tumblr"></span>{" "}
                    &nbsp;social_tumblr
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_tumbleupon"
                    ></span>{" "}
                    &nbsp;social_tumbleupon
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_wordpress"
                    ></span>{" "}
                    &nbsp;social_wordpress
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_instagram"
                    ></span>{" "}
                    &nbsp;social_instagram
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="social_dribbble"></span>{" "}
                    &nbsp;social_dribbble
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="social_vimeo"></span>{" "}
                    &nbsp;social_vimeo
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="social_linkedin"></span>{" "}
                    &nbsp;social_linkedin
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="social_rss"></span>{" "}
                    &nbsp;social_rss
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_deviantart"
                    ></span>{" "}
                    &nbsp;social_deviantart
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="social_share"></span>{" "}
                    &nbsp;social_share
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="social_myspace"></span>{" "}
                    &nbsp;social_myspace
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="social_skype"></span>{" "}
                    &nbsp;social_skype
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="social_youtube"></span>{" "}
                    &nbsp;social_youtube
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="social_picassa"></span>{" "}
                    &nbsp;social_picassa
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_googledrive"
                    ></span>{" "}
                    &nbsp;social_googledrive
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="social_flickr"></span>{" "}
                    &nbsp;social_flickr
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="social_blogger"></span>{" "}
                    &nbsp;social_blogger
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span aria-hidden="true" className="social_spotify"></span>{" "}
                    &nbsp;social_spotify
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_delicious"
                    ></span>{" "}
                    &nbsp;social_delicious
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_facebook_circle"
                    ></span>{" "}
                    &nbsp;social_facebook_circle
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_twitter_circle"
                    ></span>{" "}
                    &nbsp;social_twitter_circle
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_pinterest_circle"
                    ></span>{" "}
                    &nbsp;social_pinterest_circle
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_googleplus_circle"
                    ></span>{" "}
                    &nbsp;social_googleplus_circle
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_tumblr_circle"
                    ></span>{" "}
                    &nbsp;social_tumblr_circle
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_stumbleupon_circle"
                    ></span>{" "}
                    &nbsp;social_stumbleupon_circle
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_wordpress_circle"
                    ></span>{" "}
                    &nbsp;social_wordpress_circle
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_instagram_circle"
                    ></span>{" "}
                    &nbsp;social_instagram_circle
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_dribbble_circle"
                    ></span>{" "}
                    &nbsp;social_dribbble_circle
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_vimeo_circle"
                    ></span>{" "}
                    &nbsp;social_vimeo_circle
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_linkedin_circle"
                    ></span>{" "}
                    &nbsp;social_linkedin_circle
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_rss_circle"
                    ></span>{" "}
                    &nbsp;social_rss_circle
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_deviantart_circle"
                    ></span>{" "}
                    &nbsp;social_deviantart_circle
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_share_circle"
                    ></span>{" "}
                    &nbsp;social_share_circle
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_myspace_circle"
                    ></span>{" "}
                    &nbsp;social_myspace_circle
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_skype_circle"
                    ></span>{" "}
                    &nbsp;social_skype_circle
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_youtube_circle"
                    ></span>{" "}
                    &nbsp;social_youtube_circle
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_picassa_circle"
                    ></span>{" "}
                    &nbsp;social_picassa_circle
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_googledrive_alt2"
                    ></span>{" "}
                    &nbsp;social_googledrive_alt2
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_flickr_circle"
                    ></span>{" "}
                    &nbsp;social_flickr_circle
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_blogger_circle"
                    ></span>{" "}
                    &nbsp;social_blogger_circle
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_spotify_circle"
                    ></span>{" "}
                    &nbsp;social_spotify_circle
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_delicious_circle"
                    ></span>{" "}
                    &nbsp;social_delicious_circle
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_facebook_square"
                    ></span>{" "}
                    &nbsp;social_facebook_square
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_twitter_square"
                    ></span>{" "}
                    &nbsp;social_twitter_square
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_pinterest_square"
                    ></span>{" "}
                    &nbsp;social_pinterest_square
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_googleplus_square"
                    ></span>{" "}
                    &nbsp;social_googleplus_square
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_tumblr_square"
                    ></span>{" "}
                    &nbsp;social_tumblr_square
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_stumbleupon_square"
                    ></span>{" "}
                    &nbsp;social_stumbleupon_square
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_wordpress_square"
                    ></span>{" "}
                    &nbsp;social_wordpress_square
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_instagram_square"
                    ></span>{" "}
                    &nbsp;social_instagram_square
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_dribbble_square"
                    ></span>{" "}
                    &nbsp;social_dribbble_square
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_vimeo_square"
                    ></span>{" "}
                    &nbsp;social_vimeo_square
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_linkedin_square"
                    ></span>{" "}
                    &nbsp;social_linkedin_square
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_rss_square"
                    ></span>{" "}
                    &nbsp;social_rss_square
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_deviantart_square"
                    ></span>{" "}
                    &nbsp;social_deviantart_square
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_share_square"
                    ></span>{" "}
                    &nbsp;social_share_square
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_myspace_square"
                    ></span>{" "}
                    &nbsp;social_myspace_square
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_skype_square"
                    ></span>{" "}
                    &nbsp;social_skype_square
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_youtube_square"
                    ></span>{" "}
                    &nbsp;social_youtube_square
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_picassa_square"
                    ></span>{" "}
                    &nbsp;social_picassa_square
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_googledrive_square"
                    ></span>{" "}
                    &nbsp;social_googledrive_square
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_flickr_square"
                    ></span>{" "}
                    &nbsp;social_flickr_square
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_blogger_square"
                    ></span>{" "}
                    &nbsp;social_blogger_square
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_spotify_square"
                    ></span>{" "}
                    &nbsp;social_spotify_square
                </div>
                <div className="col-lg-4 col-md-6 demo-icon-wrap-s2">
                    <span
                        aria-hidden="true"
                        className="social_delicious_square"
                    ></span>{" "}
                    &nbsp;social_delicious_square
                </div>
            </div>
        </section>

        <Footer />
    </div>
);
export default serviceone;
