import { createStyles, makeStyles } from "@material-ui/core";
import { useContext } from "react";
import { useIntl } from "react-intl";
import AppLogoOnly from "../../../components/logo/AppLogoOnly";
import SectionSubtitle from "../../../components/section/SectionSubtitle";
import IdealSlogan from "../../../components/slogan/IdealSlogan";
import COLORS from "../../../constants/colors";
import { ThemeContext } from "../../../providers/Theme/ThemeProvider";

const OriginsClubAdditionalInfo = () => {
    const { isDark } = useContext(ThemeContext);

    const useStyles = makeStyles((theme) =>
        createStyles({
            container: {
                display: "flex",
                flexDirection: "column",
                color: !isDark ? COLORS.v2.black_grey : COLORS.v2.white,
                backgroundColor: isDark
                    ? COLORS.v2.black_grey
                    : COLORS.v2.white,
                alignItems: "center",
            },
        })
    );

    const classes = useStyles();
    const { formatMessage } = useIntl();

    return (
        <section className={classes.container}>
            <IdealSlogan />
            <h2 style={{ marginTop: 50 }}>
                {formatMessage({
                    id: "originsClub.big.title1",
                })}
            </h2>
            <SectionSubtitle text="FEATURED DAO’S" />

            <p style={{ maxWidth: "50%", textAlign: "center", marginTop: 20 }}>
                {formatMessage({
                    id: "originsClub.additional.info.text",
                })}
            </p>

            <AppLogoOnly style={{ marginTop: 30 }} />
        </section>
    );
};

export default OriginsClubAdditionalInfo;
