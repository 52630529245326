import { useIntl } from "react-intl";

const HeaderWithImage = ({ title, subtitle }) => {
    const { formatMessage } = useIntl();

    return (
        <section
            className="jumbotron breadcumb no-bg"
            style={{
                backgroundImage: `url(${"./img/v2/backgrounds/breadcumb_background.png"})`,
            }}
        >
            <div className="mainbreadcumb">
                <div className="container">
                    <div className="row m-10-hor">
                        <div className="col-12 text-center">
                            <h1 className="text-center">
                                {formatMessage({
                                    id: title,
                                })}
                            </h1>
                            {subtitle && (
                                <p>
                                    {formatMessage({
                                        id: subtitle,
                                    })}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HeaderWithImage;
