import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../../config/config";
import LOCAL_STORAGE_KEYS from "../../constants/localStorage";

const useNftUrl = (cid) => {
    const [nftUrl, setNftUrl] = useState(undefined);

    const Authorization = `Bearer ${localStorage.getItem(
        LOCAL_STORAGE_KEYS.TOKEN
    )}`;

    useEffect(() => {
        if (cid) {
            axios
                .get(`${config.nft.ipsUrl}/${cid}`, {
                    headers: {
                        Authorization,
                    },
                    responseType: "blob",
                })
                .then((res) => {
                    const blobURL = URL.createObjectURL(res.data);
                    setNftUrl(blobURL);
                });
        }
    }, [Authorization, cid]);

    return nftUrl;
};

export default useNftUrl;
