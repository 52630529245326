import { useQuery } from "@apollo/client";
import { useNavigate } from "@reach/router";
import React, { useContext } from "react";
import { useIntl } from "react-intl";
import CustomButton from "../../../components/button/CustomButton";
import ROUTES from "../../../components/constants/routes";
import CustomLoader from "../../../components/customLoader/CustomLoader";
import IDXLogo from "../../../components/logo/IDXLogo";
import NFTCardList from "../../../components/nftCard/NFTCardList";
import COLORS from "../../../constants/colors";
import { GET_POPULAR_NFTS } from "../../../graphql/nft/query";
import { ThemeContext } from "../../../providers/Theme/ThemeProvider";

const PopularItemsV2 = () => {
    const { isDark } = useContext(ThemeContext);
    const navigate = useNavigate();

    const { data, loading } = useQuery(GET_POPULAR_NFTS, {
        fetchPolicy: "cache-and-network",
    });
    const { formatMessage } = useIntl();

    const popularNFTs = data ? data.getPopularIps : [];

    const onViewAllProjects = () => {
        navigate(ROUTES.explore);
    };

    if (!popularNFTs || !popularNFTs.length) return null;

    return (
        <div
            style={{
                backgroundColor: isDark
                    ? COLORS.v2.grey_background
                    : COLORS.v2.grey_button,
            }}
        >
            <section
                className="container"
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {loading && <CustomLoader />}
                <div className="row">
                    <div className="col-lg-12">
                        <div className="text-center">
                            <IDXLogo isGrey={!isDark} isGreen={isDark} />
                            <h4
                                style={{
                                    marginTop: 25,
                                    textTransform: "uppercase",
                                    marginBottom: 100,
                                }}
                            >
                                {formatMessage({
                                    id: "title.catalog.subtitle.popular.items",
                                })}
                            </h4>
                        </div>
                    </div>
                </div>

                {popularNFTs.length ? (
                    <NFTCardList nfts={popularNFTs} />
                ) : (
                    <div>No item.</div>
                )}

                {popularNFTs.length && (
                    <CustomButton
                        text="View All Projects"
                        onClick={onViewAllProjects}
                        isBlack={!isDark}
                        style={{ alignSelf: "center", marginTop: 75 }}
                    />
                )}
            </section>
        </div>
    );
};
export default PopularItemsV2;
