import { gql } from "@apollo/client";
import { DENY_FRAGMENT } from "./fragment";

export const MY_DENIES = gql`
    query GetMyDenies {
        myDenies {
            ...DenyFields
            moderator {
                id
                userName
                profilePictureUri
            }
            user {
                id
                userName
                profilePictureUri
            }
            ip {
                cid
                title
                mimetype
                likesCount
                likedByMe
                isPermitted
                privacyStatus
                isDenied
            }
        }
    }
    ${DENY_FRAGMENT}
`;
