import { useMutation } from "@apollo/client";
import React, { useContext, useState } from "react";
import CustomButton from "../../components/button/CustomButton";
import Footer from "../../components/footer/Footer";
import CustomInput from "../../components/input/CustomInput";
import { SIGNIN_AS_BLOGGER } from "../../graphql/blogger/mutation";
import { BloggersClientContext } from "../../providers/Apollo/ApolloProvider";
import { BloggerAuthContext } from "../../providers/BloggerAuth/BloggerAuthProvider";
import { SnackBarContext } from "../../providers/SnackBar/SnackBarProvider";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";

const BloggerLogin = () => {
    const { loginAsBlogger } = useContext(BloggerAuthContext);
    const { bloggersClient } = useContext(BloggersClientContext);
    const { displaySnackBar } = useContext(SnackBarContext);
    const { isDark } = useContext(ThemeContext);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [signinAsBlogger] = useMutation(SIGNIN_AS_BLOGGER, {
        client: bloggersClient,
        onCompleted: (data) => {
            if (data && data.signInForBlog && data.signInForBlog.access) {
                displaySnackBar({
                    message: "Blogger successfully authenticated",
                    type: "success",
                });
                loginAsBlogger(data.signInForBlog.access);
            }
        },
        onError: (err) => {
            if (err.message) {
                displaySnackBar({
                    message: err.message,
                    type: "error",
                });
            }
        },
    });

    const handleChangeUsername = (value) => {
        setUsername(value);
    };

    const handleChangePassword = (value) => {
        setPassword(value);
    };

    const handleSignin = () => {
        signinAsBlogger({
            variables: {
                input: {
                    username,
                    password,
                },
            },
        });
    };

    return (
        <div className={isDark ? "greyscheme" : ""}>
            <section
                className="jumbotron breadcumb no-bg"
                style={{
                    backgroundImage: isDark
                        ? `url(${"./img/background/8.jpg"})`
                        : `url(${"./img/background/2.jpg"})`,
                }}
            >
                <div className="mainbreadcumb">
                    <div className="container">
                        <div className="row align-items-center">
                            <div
                                className="col-lg-5 text-light wow fadeInRight"
                                data-wow-delay=".5s"
                            >
                                <div className="spacer-10"></div>
                                <h1>Login as blogger</h1>
                                <p className="lead">
                                    A blogger is an user who can manage all the
                                    blogs and help documentation of IDEAL.
                                </p>
                            </div>
                            <div
                                className="col-lg-4 offset-lg-2 wow fadeIn"
                                data-wow-delay=".5s"
                            >
                                <div className="box-login">
                                    <h3 className="mb10">Login</h3>
                                    <p>
                                        Enter your username and your password as
                                        a blogger to manage IDEAL blogs.
                                    </p>
                                    <form
                                        name="contactForm"
                                        id="contact_form"
                                        className="form-border"
                                        action="#"
                                    >
                                        <div className="field-set">
                                            <CustomInput
                                                label="Username"
                                                placeholder="Enter your username"
                                                value={username}
                                                handleChangeValue={
                                                    handleChangeUsername
                                                }
                                                error={!username}
                                                errorText="Username is required"
                                            />
                                        </div>

                                        <div className="field-set">
                                            <CustomInput
                                                label="Password"
                                                placeholder="Enter your password"
                                                value={password}
                                                handleChangeValue={
                                                    handleChangePassword
                                                }
                                                error={!password}
                                                errorText="Password is required"
                                                type="password"
                                            />
                                        </div>

                                        <div className="field-set">
                                            <CustomButton
                                                text="LOGIN"
                                                disabled={
                                                    !username || !password
                                                }
                                                onClick={handleSignin}
                                                style={{ marginRight: 20 }}
                                            />
                                        </div>

                                        <div className="clearfix"></div>

                                        <div className="spacer-single"></div>
                                        {/* <ul className="list s3">
                                            <li>Login with:</li>
                                            <li>
                                                <span>Facebook</span>
                                            </li>
                                            <li>
                                                <span>Google</span>
                                            </li>
                                        </ul> */}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
};
export default BloggerLogin;
