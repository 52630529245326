import React, { useContext } from "react";
import { Router, Location, Redirect } from "@reach/router";
// import Home from "./../pages/home";
import HomeGrey from "../pages/homeGrey";
import Home1 from "../pages/home1";
import Home1grey from "../pages/home1Grey";
import Home2 from "../pages/home2";
import Home2grey from "../pages/home2Grey";
import Home3 from "../pages/home3";
import Explore from "../pages/explore/Explore";
import Exploregrey from "../pages/exploreGrey";
import Explore2 from "../pages/explore2";
import Explore2grey from "../pages/explore2Grey";
// import Rangking from './pages/rangking';
import RankingRedux from "../pages/RankingRedux";
import RankingReduxgrey from "../pages/RankingReduxGrey";
import Auction from "../pages/Auction";
import Auctiongrey from "../pages/AuctionGrey";
import Helpcenter from "../pages/helpcenter";
import Helpcentergrey from "../pages/helpcenterGrey";
import Colection from "../pages/colection";
import Colectiongrey from "../pages/colectionGrey";
// import ItemDetail from './pages/ItemDetail';
import ItemDetailRedux from "../pages/ItemDetailRedux";
import ItemDetailReduxgrey from "../pages/ItemDetailReduxGrey";
import Author from "../pages/Author";
import AuthorGrey from "../pages/AuthorGrey";
// import Wallet from "./../pages/wallet";
import Login from "../pages/login";
import Logingrey from "../pages/loginGrey";
import LoginTwo from "../pages/loginTwo";
import LoginTwogrey from "../pages/loginTwoGrey";
import Register from "../pages/register";
import Registergrey from "../pages/registerGrey";
import Price from "../pages/price";
import Works from "../pages/works";
import News from "../pages/news";
import NewsGrey from "../pages/newsGrey";
import NewsSingle from "../pages/newsSingle";
import NewsSingleGrey from "../pages/newsSingleGrey";
// import Create from "../pages/create";
// import Creategrey from "../pages/createGrey";
import Create2 from "../pages/create2";
import Create3 from "../pages/create3";
import Createoption from "../pages/createOptions";
// import Activity from "../pages/activity";
import Activitygrey from "../pages/activityGrey";
import Contact from "../pages/contact";
import Contactgrey from "../pages/contactGrey";
import ElegantIcons from "../pages/elegantIcons";
import EtlineIcons from "../pages/etlineIcons";
import FontAwesomeIcons from "../pages/fontAwesomeIcons";
import Accordion from "../pages/accordion";
import Alerts from "../pages/alerts";
import Progressbar from "../pages/progressbar";
import Tabs from "../pages/tabs";
import Minter from "../pages/Minter";
import Mintergrey from "../pages/MinterGrey";

// Integrated screens
import ROUTES from "../components/constants/routes";
import Home from "../pages/home/Home";
import Wallets from "../pages/wallets/Wallets";
import MyProfile from "../pages/myProfile/MyProfile";
import MyProfileSettings from "../pages/myProfile/MyProfileSettings";
import CreateNFT from "../pages/create/CreateNFT";
import NFTDetail from "../pages/nftDetail/NFTDetail";
import UpdateNFT from "../pages/nftUpdate/UpdateNFT";
import CustomRoute from "./CustomRoute";

// debug
import Debug from "../pages/debug";
import { AuthContext } from "../providers/Auth/AuthProvider";
import CustomLoader from "../components/customLoader/CustomLoader";
import NFTSell from "../pages/nftSell/NFTSell";
import NFTOffer from "../pages/nftOffer/NFTOffer";
import Deposit from "../pages/deposit/Deposit";
import Activity from "../pages/activity/Activity";
import AdminHome from "../pages/admin/AdminHome";
import ModeratorHome from "../pages/moderator/ModeratorHome";
import NFTPermit from "../pages/nftPermit/NFTPermit";
import Notification from "../pages/notification/Notification";
import OriginsClub from "../pages/originsClub/OriginsClub";
import Newsletter from "../pages/newsletter/Newsletter";
import CreateBlog from "../pages/admin/communityManagement/blogs/create/CreateBlog";
import BlogDetail from "../pages/blogDetail/BlogDetail";
import Blogs from "../pages/admin/communityManagement/blogs/Blogs";
import UpdateBlog from "../pages/admin/communityManagement/blogs/update/UpdateBlog";
import HelpCenter from "../pages/admin/communityManagement/helpCenter/HelpCenter";
import CreateHelp from "../pages/admin/communityManagement/helpCenter/create/CreateHelp";
import HelpDetail from "../pages/helpDetail/HelpDetail";
import UpdateHelp from "../pages/admin/communityManagement/helpCenter/update/UpdateHelp";
import SubHelpDetail from "../pages/subHelpDetail/SubHelpDetail";
import { BloggerAuthContext } from "../providers/BloggerAuth/BloggerAuthProvider";
import BloggerHome from "../pages/bloggerHome/BloggerHome";
import BloggerLogin from "../pages/bloggerLogin/BloggerLogin";

export const ScrollTop = ({ children, location }) => {
    React.useEffect(() => window.scrollTo(0, 0), [location]);
    return children;
};

export const PosedRouter = ({ children }) => (
    <Location>
        {({ location }) => (
            <div id="routerhang">
                <div key={location.key}>
                    <Router location={location}>{children}</Router>
                </div>
            </div>
        )}
    </Location>
);

const Routes = () => {
    const { loadingAuth } = useContext(AuthContext);
    const { loadingAuth: loadingAuthBlogger } = useContext(BloggerAuthContext);
    if (loadingAuth || loadingAuthBlogger) return <CustomLoader />;

    return (
        <PosedRouter>
            <ScrollTop path="/">
                <Home exact path="/">
                    <Redirect to={ROUTES.home} />
                </Home>
                <CustomRoute Component={Wallets} path={ROUTES.wallets} />
                <CustomRoute
                    Component={MyProfile}
                    path={ROUTES.myProfile}
                    authRequired
                />
                <CustomRoute
                    Component={MyProfileSettings}
                    path={ROUTES.myProfileSettings}
                    authRequired
                />
                <CustomRoute
                    Component={CreateNFT}
                    path={ROUTES.createNFT}
                    authErrorMessage="You must be logged in to create NFT"
                    authRequired
                />
                <NFTDetail path={`${ROUTES.nftDetail}/:cid`} />
                <UpdateNFT path={`${ROUTES.nftUpdate}/:cid`} />
                <NFTSell path={`${ROUTES.nftSell}/:cid`} />
                <NFTOffer path={`${ROUTES.nftOffer}/:cid`} />
                <NFTOffer path={`${ROUTES.nftOffer}/:cid/:shareHolder`} />
                <CustomRoute Component={Explore} path={ROUTES.explore} />
                <CustomRoute
                    Component={Deposit}
                    path={ROUTES.deposit}
                    authErrorMessage="You must be logged in to make a deposit"
                    authRequired
                />
                <CustomRoute Component={Activity} path={ROUTES.activity} />
                <CustomRoute Component={AdminHome} path={ROUTES.adminHome} />
                <CustomRoute
                    Component={BloggerHome}
                    path={ROUTES.bloggerHome}
                    bloggerAuthRequired
                />
                <CustomRoute Component={Blogs} path={ROUTES.blogs} />
                <CustomRoute Component={HelpCenter} path={ROUTES.helpCenter} />
                <CustomRoute
                    Component={CreateBlog}
                    path={ROUTES.createBlog}
                    bloggerAuthRequired
                />
                <BlogDetail path={`${ROUTES.blogDetail}/:blogId`} />
                <UpdateBlog path={`${ROUTES.updateBlog}/:blogId`} />
                <CustomRoute
                    Component={CreateHelp}
                    path={ROUTES.createHelp}
                    bloggerAuthRequired
                />
                <HelpDetail path={`${ROUTES.helpDetail}/:helpId`} />
                <UpdateHelp path={`${ROUTES.updateHelp}/:helpId`} />
                <SubHelpDetail path={`${ROUTES.subHelpDetail}/:subHelpId`} />

                <CustomRoute
                    Component={BloggerLogin}
                    path={ROUTES.bloggerLogin}
                />

                <CustomRoute
                    Component={ModeratorHome}
                    path={ROUTES.moderatorHome}
                />
                <NFTPermit path={`${ROUTES.nftPermit}/:cid`} />
                <CustomRoute
                    Component={Notification}
                    path={ROUTES.notifications}
                    authErrorMessage="You must be logged in to view notifications"
                    authRequired
                />
                <CustomRoute
                    Component={OriginsClub}
                    path={ROUTES.originsClub}
                />
                <CustomRoute Component={Newsletter} path={ROUTES.newsletter} />

                <HomeGrey path="/homeGrey" />
                <Home1 path="/home1" />
                <Home1grey path="/home1Grey" />
                <Home2 path="/home2" />
                <Home2grey path="/home2Grey" />
                <Home3 path="/home3" />

                <Exploregrey path="/exploreGrey" />
                <Explore2 path="/explore2" />
                <Explore2grey path="/explore2Grey" />
                <RankingRedux path="/rangking" />
                <RankingReduxgrey path="/rangkingGrey" />
                <Auction path="/Auction" />
                <Auctiongrey path="/AuctionGrey" />
                <Helpcenter path="/helpcenter" />
                <Helpcentergrey path="/helpcenterGrey" />
                <Colection path="/colection/:collectionId" />
                <Colectiongrey path="/colectionGrey/:collectionId" />
                <ItemDetailRedux path="/ItemDetail/:nftId" />
                <ItemDetailReduxgrey path="/ItemDetailGrey/:nftId" />
                <Author path="/Author/:authorId" />
                <AuthorGrey path="/AuthorGrey/:authorId" />

                <Login path="/login" />
                <Logingrey path="/loginGrey" />
                <LoginTwo path="/loginTwo" />
                <LoginTwogrey path="/loginTwoGrey" />
                <Register path="/register" />
                <Registergrey path="/registerGrey" />
                <Price path="/price" />
                <Works path="/works" />
                <News path="/news" />
                <NewsGrey path="/newsGrey" />
                <NewsSingle path="/news/:postId" />
                <NewsSingleGrey path="/newsGrey/:postId" />
                <Createoption path="/createOptions" />
                <Create2 path="/create2" />
                <Create3 path="/create3" />
                {/* <Activity path="/activity" /> */}
                <Activitygrey path="/activityGrey" />
                <Contact path="/contact" />
                <Contactgrey path="/contactGrey" />
                <ElegantIcons path="/elegantIcons" />
                <EtlineIcons path="/etlineIcons" />
                <FontAwesomeIcons path="/fontAwesomeIcons" />
                <Accordion path="/accordion" />
                <Alerts path="/alerts" />
                <Progressbar path="/progressbar" />
                <Tabs path="/tabs" />
                <Minter path="/mint" />
                <Mintergrey path="/minter" />
                {process.env.REACT_APP_DEBUG &&
                    process.env.REACT_APP_DEBUG === "true" && (
                        <Debug path="/__debug" />
                    )}
            </ScrollTop>
        </PosedRouter>
    );
};
export default Routes;
