import { useContext } from "react";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";

const CustomButton = ({
    text,
    onClick,
    disabled,
    style,
    isWhite,
    isBlack: isBlackProps,
    isGreen,
}) => {
    const { isDark } = useContext(ThemeContext);
    const isBlack = isBlackProps || !isDark;

    return (
        <input
            type="button"
            className={
                isGreen
                    ? "btn-main"
                    : isWhite
                    ? "btn-main inline white lead"
                    : isBlack
                    ? "btn-main inline black lead"
                    : "btn-main"
            }
            value={text}
            onClick={onClick}
            disabled={disabled}
            style={style}
        />
    );
};

export default CustomButton;
