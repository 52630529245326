import { memo } from "react";
import { useQuery } from "@apollo/client";
import CustomLoader from "../customLoader/CustomLoader";
import { GET_HELPS } from "../../graphql/help/query";
import HelpItem from "./HelpItem";

const HelpList = ({ filter }) => {
    const { data, loading } = useQuery(GET_HELPS, {
        fetchPolicy: "cache-and-network",
        variables: {
            filter: filter || undefined,
        },
    });

    const helps = data?.getHelps || [];

    return (
        <div className="row">
            {loading && <CustomLoader />}
            {helps &&
                helps.map((help, index) => (
                    <HelpItem help={help} key={index} />
                ))}
            {!helps?.length && <div>No help documentation avalaible.</div>}
        </div>
    );
};
export default memo(HelpList);
