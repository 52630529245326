import { gql } from "@apollo/client";
import { JWT_FRAGMENT } from "./fragment";

export const OBTAIN_JWT = gql`
    mutation JWT($signature: String!, $message: String!) {
        jwt(message: $message, signature: $signature) {
            ...JwtFields
        }
    }
    ${JWT_FRAGMENT}
`;
