import { useQuery } from "@apollo/client";
import { Link } from "@reach/router";
import { ethers } from "ethers";
import moment from "moment";
import { useContext } from "react";
import CustomAddress from "../../../components/address/CustomAddress";
import ROUTES from "../../../components/constants/routes";
import { GET_NFT_EXTENSION } from "../../../graphql/nft/query";
import { GET_USER } from "../../../graphql/user/query";
import useNftUrl from "../../../hooks/useNftUrl/useNftUrl";
import { AuthContext } from "../../../providers/Auth/AuthProvider";
import getImagePreview from "../../../utils/nft/getImagePreview";
import { formatNumber } from "../../../utils/string/formatNumber";

const ActivityItem = ({ activity }) => {
    const { authContext } = useContext(AuthContext);

    const isPayment = activity?.activity === "PAYMENT";
    const paymentBuyerAddress = activity?.buyer?.address?.toLowerCase() || "";
    const paymentOwnerAddress = activity?.owner?.address?.toLowerCase() || "";

    const isFromAddressExist =
        activity?.from?.address?.toLowerCase() !==
        "0x0000000000000000000000000000000000000000";
    const transferFromAddress = activity?.from?.address?.toLowerCase() || "";
    const transferToAddress = activity?.to?.address?.toLowerCase() || "";

    const itemCID = activity?.itemContract?.content
        ? ethers.utils.toUtf8String(activity?.itemContract?.content)
        : "";

    const { data: nft } = useQuery(GET_NFT_EXTENSION, {
        fetchPolicy: "cache-first",
        variables: { id: itemCID },
        skip: !itemCID,
    });

    const extension = nft?.getIp?.mimetype || "";

    const nftUrl = useNftUrl(itemCID);
    const itemTitle = activity?.itemContract?.title || "";
    const itemId = activity?.itemContract?.id || "";

    const { data: buyerUserData } = useQuery(GET_USER, {
        fetchPolicy: "cache-first",
        variables: { id: paymentBuyerAddress },
        skip: !isPayment || !paymentBuyerAddress,
    });

    const buyerUser = buyerUserData?.getUser;

    const { data: sellerUserData } = useQuery(GET_USER, {
        fetchPolicy: "cache-first",
        variables: { id: paymentOwnerAddress },
        skip: !isPayment || !paymentOwnerAddress,
    });

    const sellerUser = sellerUserData?.getUser;

    const { data: fromUserData } = useQuery(GET_USER, {
        fetchPolicy: "cache-first",
        variables: { id: transferFromAddress },
        skip: isPayment || !transferFromAddress || !isFromAddressExist,
    });

    const fromUser = fromUserData?.getUser;

    const { data: toUserData } = useQuery(GET_USER, {
        fetchPolicy: "cache-first",
        variables: { id: transferToAddress },
        skip: isPayment || !transferToAddress,
    });

    const toUser = toUserData?.getUser;

    const paymentAmount = activity?.value
        ? Number(ethers.utils.formatEther(activity?.value))
        : "";

    const paymentShares = activity?.shares
        ? Number(ethers.utils.formatEther(activity?.shares))
        : "";

    const transferAmount = activity?.amount
        ? Number(ethers.utils.formatEther(activity?.amount))
        : "";

    const date = moment(new Date(activity?.timestamp * 1000)).format(
        "DD/MM/YYYY, hh:mm A"
    );

    if (isPayment)
        return (
            <li className="act_sale">
                <img
                    className="lazy"
                    src={getImagePreview({
                        extension,
                        imageUrl: nftUrl,
                    })}
                    alt=""
                />
                <div className="act_list_text">
                    <h4>
                        <Link
                            to={`${ROUTES.nftDetail}/${itemCID}`}
                            className="name_link"
                        >
                            {itemTitle || itemId}
                        </Link>
                    </h4>
                    {formatNumber(paymentShares)} shares purchased by{" "}
                    <span className="color">
                        {authContext?.myProfile?.id === buyerUser?.id
                            ? "You"
                            : buyerUser?.userName}
                        <CustomAddress address={buyerUser?.id} />
                    </span>
                    from{" "}
                    <span className="color">
                        {authContext?.myProfile?.id === sellerUser?.id
                            ? "You"
                            : sellerUser?.userName}
                        <CustomAddress address={sellerUser?.id} />
                    </span>
                    for {paymentAmount} ETH
                    <span className="act_list_date">{date}</span>
                </div>
            </li>
        );

    if (!isFromAddressExist)
        return (
            <li className="act_mint">
                <img
                    className="lazy"
                    src={getImagePreview({
                        extension,
                        imageUrl: nftUrl,
                    })}
                    alt=""
                />
                <div className="act_list_text">
                    <h4>
                        <Link
                            to={`${ROUTES.nftDetail}/${itemCID}`}
                            className="name_link"
                        >
                            {itemTitle || itemId}
                        </Link>
                    </h4>
                    Mint of {formatNumber(transferAmount)} shares by{" "}
                    <span className="color">
                        {authContext?.myProfile?.id === toUser?.id
                            ? "You"
                            : toUser?.userName}
                        <CustomAddress address={toUser?.id} />
                    </span>
                    <span className="act_list_date">{date}</span>
                </div>
            </li>
        );

    return (
        <li className="act_follow">
            <img className="lazy" src={fromUser?.profilePictureUri} alt="" />
            <div className="act_list_text">
                <h4>
                    {authContext?.myProfile?.id === fromUser?.id
                        ? "You"
                        : fromUser?.userName}
                </h4>
                transfered {transferAmount} ETH to{" "}
                <span className="color">
                    {authContext?.myProfile?.id === toUser?.id
                        ? "You"
                        : toUser?.userName}
                </span>
                <span className="act_list_date">{date}</span>
            </div>
        </li>
    );
};

export default ActivityItem;
