import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "@reach/router";
import ROUTES from "../../../components/constants/routes";
import CustomButton from "../../../components/button/CustomButton";
import IDXLogo from "../../../components/logo/IDXLogo";
import SectionSubtitle from "../../../components/section/SectionSubtitle";
import COLORS from "../../../constants/colors";
import { createStyles, makeStyles } from "@material-ui/core";
import { WHATS_UP_MENUS } from "./menu/whatsHotMenu";
import WhatsHotTable from "./table/WhatsHotTable";
import { useQuery } from "@apollo/client";
import { GET_FEATURED_NFTS } from "../../../graphql/nft/query";
import CustomSelect from "../../../components/select/CustomSelect";

const useStyles = makeStyles((theme) =>
    createStyles({
        headerContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            width: "100%",
            padding: "0px 90px 0px 90px",
        },
        menu: {
            display: "flex",
            alignItems: "center",
            marginBottom: 40,
            flexWrap: "wrap",
        },
        menuItem: {
            padding: "0px 5px 0px 5px",
            margin: 10,
            color: COLORS.v2.white,
            cursor: "pointer",
            height: "fit-content",
            fontSize: 15,
            fontWeight: "bold",
        },
        menuItemSelected: {
            padding: "0px 5px 0px 5px",
            margin: 10,
            backgroundColor: COLORS.v2.green,
            color: COLORS.v2.grey_background,
            cursor: "pointer",
            borderBlockColor: COLORS.v2.green,
            borderRadius: 13,
            height: "fit-content",
            fontSize: 15,
            fontWeight: "bold",
        },
    })
);

const WhatsHot = () => {
    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const [selectedMenu, setSelectedMenu] = useState("Origins Club");
    const [filter, setFilter] = useState({
        value: "dailyStats",
        label: "Last 24h",
    });

    const { data } = useQuery(GET_FEATURED_NFTS, {
        fetchPolicy: "cache-and-network",
    });

    const faturedItems = data?.getFeaturedIps || [];

    const classes = useStyles();

    const navigateToExplore = () => {
        navigate(ROUTES.explore);
    };

    const onSelectMenu = (menu) => {
        setSelectedMenu(menu);
    };

    const options = [
        {
            value: "dailyStats",
            label: "Last 24h",
        },
        {
            value: "weeklyStats",
            label: "Last 7 days",
        },
        {
            value: "monthlyStats",
            label: "Last 30 days",
        },
    ];

    const handleChangeFilter = (option) => {
        setFilter(option);
    };

    return (
        <section className="whats_hot_container">
            <div className={classes.headerContainer}>
                <div className={classes.menu}>
                    <IDXLogo
                        isGreen
                        style={{
                            marginRight: 50,
                            marginBottom: 0,
                        }}
                    />
                    {WHATS_UP_MENUS.map((menu) => (
                        <div
                            className={
                                selectedMenu === menu
                                    ? classes.menuItemSelected
                                    : classes.menuItem
                            }
                            onClick={() => onSelectMenu(menu)}
                        >
                            {menu}
                        </div>
                    ))}
                    <CustomSelect
                        placeholder="Filter by date"
                        options={options}
                        value={filter}
                        onChange={handleChangeFilter}
                        noMaxWidth
                        containerStyle={{
                            width: 200,
                            paddingTop: 20,
                            marginLeft: 20,
                        }}
                    />
                </div>
                <SectionSubtitle
                    text="WHAT’S HOT"
                    style={{ marginBottom: 20 }}
                />
            </div>
            <WhatsHotTable isLeft items={faturedItems} filter={filter?.value} />
            <CustomButton
                text={formatMessage({
                    id: "See All",
                })}
                style={{ marginTop: 70, alignSelf: "center" }}
                onClick={navigateToExplore}
                isBlack
            />
        </section>
    );
};
export default WhatsHot;
