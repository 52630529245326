const originsClub = {
    "originsClub.big.title1": "Origins Club",
    "originsClub.big.title2": "CLUB",
    "originsClub.subtitle":
        "Don't just support the next big franchise, own a part of it. Sell, invest, and trade the hottest creative works of today and the future.",
    "originsClub.btn.main": "Whitelisted",
    "originsClub.additional.info.text":
        "In just a few clicks, you’ll be able to see all the traits of a collection and which NFTs are rare - all before anyone else can.",
};

export default originsClub;
