import React, { useContext, useState } from "react";
import config from "../../../config/config";
import CustomButton from "../button/CustomButton";
import Files from "react-files";
import FilePreview from "../filePreview/FilePreview";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";
import COLORS from "../../constants/colors";
import { fileToBase64 } from "../../utils/file/getBase64";

const CustomExtraFilesInput = ({
    title,
    extraFiles,
    setExtraFiles,
    onExtraFilesChange,
    onRemoveExtraFiles,
    onError,
}) => {
    const { isDark } = useContext(ThemeContext);
    const [previewedFile, setPreviewedFile] = useState(undefined);
    const [b64, setB64] = useState("");

    const onExtraFilesChangeDefault = (browsedFiles) => {
        if (setExtraFiles) {
            const filtredFiles = browsedFiles.filter(
                (file) =>
                    !extraFiles.some(
                        (extraFile) =>
                            extraFile?.size === file.size &&
                            extraFile?.name === file?.name
                    )
            );

            setExtraFiles([...extraFiles, ...filtredFiles]);
        }
    };

    const onRemoveExtraFilesDefault = () => {
        if (setExtraFiles) setExtraFiles([]);
    };

    return (
        <div className="">
            <h5>
                <i
                    className={`fa fa- fa-file id-color-2 mr10`}
                    style={{
                        color: isDark
                            ? COLORS.dark.color_2
                            : COLORS.light.color,
                        marginRight: 10,
                    }}
                ></i>
                {title || "Extra files"}
            </h5>
            <p>Import files you want to attach to your NFT.</p>
            <div
                className="d-create-file"
                style={{
                    marginBottom: 20,
                    textAlign: "-webkit-center",
                }}
            >
                <p id="file_name">
                    PNG, JPG, GIF, WEBP or MP4. Max {config.nft.fileSizeLimit}
                    mb.
                </p>

                {extraFiles
                    ? extraFiles.map((x) => (
                          <b
                              key={x?.id}
                              style={{
                                  display: "flex",
                                  justifyContent: "center",
                              }}
                          >
                              <p
                                  style={{
                                      cursor: "pointer",
                                  }}
                                  onClick={() => {
                                      setPreviewedFile(x);
                                      if (x.file) {
                                          fileToBase64(x.file, (result) => {
                                              setB64(result);
                                          });
                                      } else {
                                          fileToBase64(x, (result) => {
                                              setB64(result);
                                          });
                                      }
                                  }}
                              >
                                  {x.name}
                              </p>
                              <p
                                  style={{
                                      marginLeft: 20,
                                      cursor: "pointer",
                                      fontWeight: "bolder",
                                  }}
                                  onClick={() => {
                                      if (setExtraFiles)
                                          setExtraFiles(
                                              (currentExtraFiles) =>
                                                  currentExtraFiles?.filter(
                                                      (file) =>
                                                          file?.id !== x?.id
                                                  ) || []
                                          );
                                  }}
                              >
                                  x
                              </p>
                          </b>
                      ))
                    : null}

                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Files
                        className="upload_file"
                        onChange={
                            onExtraFilesChange || onExtraFilesChangeDefault
                        }
                        onError={onError}
                        accepts={[
                            "image/*",
                            ".pdf",
                            "audio/*",
                            "video/*",
                            ".txt",
                            ".docx",
                        ]}
                        multiple={false}
                        maxFiles={100}
                        maxFileSize={config.nft.fileSizeLimit * 1000000}
                        minFileSize={0}
                    >
                        <CustomButton text="Browse" />
                    </Files>
                    <CustomButton
                        text="Remove all"
                        onClick={
                            onRemoveExtraFiles || onRemoveExtraFilesDefault
                        }
                        style={{ marginLeft: 30 }}
                        disabled={!extraFiles || !extraFiles?.length}
                        isWhite
                    />
                </div>
            </div>
            {previewedFile && (
                <FilePreview
                    fileUrl={previewedFile?.preview?.url || b64}
                    fileBlob={previewedFile?.preview?.blob}
                    fileName={previewedFile?.name}
                    fileType={previewedFile?.type}
                    handleClose={() => setPreviewedFile(undefined)}
                />
            )}
        </div>
    );
};

export default CustomExtraFilesInput;
