import config from "../../../config/config";

const { main, test, dev, local } = config.network;

export const NETWORKS = [main, test, dev, local];

export const defaultEnv = {
    chainId: 0,
    name: "",
    host: "",
    link: "",
    rpcUrl: "",
};

export const getEnvFromHost = (hostName) => {
    const env = NETWORKS.find((net) => net.host === hostName);
    return env || defaultEnv;
};

export const getEnvFromChainId = (chainId) => {
    const env = NETWORKS.find((net) => net.chainId === chainId);
    return env || defaultEnv;
};
