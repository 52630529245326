import { gql } from "@apollo/client";
import { NFT_FRAGMENT } from "./fragment";

export const GET_MINIMAL_NFT = gql`
    query GetMinimalIp($id: String!) {
        getIp(id: $id) {
            cid
            title
        }
    }
`;

export const GET_NFT = gql`
    query GetIp($id: String!, $saleOfferFilter: IPFilter) {
        getIp(id: $id) {
            ...NFTFields
            properties {
                id
                name
                value
            }
            user {
                id
                userName
                profilePictureUri
            }
            sales(filter: $saleOfferFilter) {
                id
                sale
                validUntil
                isExpired
                createdAt
                signature
                seller {
                    id
                    userName
                    profilePictureUri
                }
            }
            offers(filter: $saleOfferFilter) {
                id
                offer
                validUntil
                isExpired
                createdAt
                signature
                user {
                    id
                    userName
                    profilePictureUri
                }
            }
            extraFiles {
                cid
                filename
                mimetype
            }
            categories {
                id
                code
                name
            }
            isPermitted
            privacyStatus
            permit {
                id
                moderator {
                    id
                    userName
                    profilePictureUri
                }
                user {
                    id
                }
                blockHeight
                validUntil
                deadline
                isExpired
                salt
                signature
            }
            isDenied
            deny {
                id
                moderator {
                    id
                    userName
                    profilePictureUri
                }
                internalReason
                publicReason
                createdAt
            }
        }
    }
    ${NFT_FRAGMENT}
`;

export const GET_NFT_TO_PERMIT = gql`
    query GetIpToPermit($id: String!) {
        getIp(id: $id) {
            ...NFTFields
            properties {
                id
                name
                value
            }
            user {
                id
                userName
                profilePictureUri
            }
            extraFiles {
                cid
                filename
                mimetype
            }
            categories {
                id
                code
                name
            }
            permit {
                id
                moderator {
                    id
                    userName
                    profilePictureUri
                }
                validUntil
                deadline
                isExpired
                createdAt
            }
            isPermitted
            isDenied
            privacyStatus
            deny {
                id
                moderator {
                    id
                    userName
                    profilePictureUri
                }
                internalReason
                publicReason
                createdAt
            }
        }
    }
    ${NFT_FRAGMENT}
`;

export const GET_NFT_EXTENSION = gql`
    query GetIp($id: String!) {
        getIp(id: $id) {
            cid
            mimetype
        }
    }
`;

export const GET_MY_CREATED_NFTS = gql`
    query MyCreatedIps {
        myCreatedIps {
            ...NFTFields
            user {
                id
                userName
                profilePictureUri
            }
            sales {
                id
                sale
                validUntil
            }
        }
    }
    ${NFT_FRAGMENT}
`;

export const GET_EXPLORE_NFTS = gql`
    query GetExploreNfts($input: GetMintedIpsInput) {
        getMintedIps(input: $input) {
            ...NFTFields
            user {
                id
                userName
                profilePictureUri
            }
            sales {
                id
                sale
                validUntil
            }
        }
    }
    ${NFT_FRAGMENT}
`;

export const GET_PENDING_NFTS = gql`
    query GetPendingNfts {
        getPendingIps {
            ...NFTFields
            user {
                id
                userName
                profilePictureUri
            }
            permit {
                id
                moderator {
                    id
                    userName
                    profilePictureUri
                }
                validUntil
                deadline
                isExpired
            }
            deny {
                id
                moderator {
                    id
                    userName
                    profilePictureUri
                }
                internalReason
                publicReason
                createdAt
            }
            isPermitted
            isDenied
        }
    }
    ${NFT_FRAGMENT}
`;

export const MINTED_NFTS_SEARCH = gql`
    query MintedNFTsSearch($input: GetMintedIpsInput) {
        getMintedIps(input: $input) {
            cid
            title
            user {
                id
                userName
                profilePictureUri
            }
        }
    }
`;

export const GET_POPULAR_NFTS = gql`
    query GetPopularIps {
        getPopularIps {
            ...NFTFields
            user {
                id
                userName
                profilePictureUri
            }
            sales {
                id
                sale
                validUntil
            }
        }
    }
    ${NFT_FRAGMENT}
`;

export const GET_NFT_LIKES = gql`
    query GetIpLikes($id: String!) {
        getIp(id: $id) {
            cid
            likes {
                user {
                    id
                    userName
                    profilePictureUri
                }
                createdAt
            }
        }
    }
`;

export const GET_FEATURED_NFTS = gql`
    query GetFeaturedIps($skip: Int, $take: Int) {
        getFeaturedIps(skip: $skip, take: $take) {
            cid
            title
            user {
                id
                userName
                profilePictureUri
            }
        }
    }
`;
