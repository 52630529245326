const authentification = {
    "auth.title": "Connectez votre portefeuille.",
    "auth.subtitle":
        "Connectez-vous avec l'un de nos fournisseurs d'informations de portefeuille disponibles.",
    "auth.metamask.signin.message.success":
        "Authentification via Metamask réussié !",
    "auth.metamask.signin.message.error":
        "Erreur ou annulation d'authentification via Metamask.",
    "auth.metamask.signin.locked.message.error":
        "Veuillez déverouiller votre portefeuille.",
    "auth.metamask.unsupported.wallet.error":
        "Type de portefeuille non pris en charge.",
    "auth.signout.message.success": "Déconnecté avec succès !",
};

export default authentification;
