import { ApolloProvider } from "@apollo/client";
import { createContext, useState } from "react";
import createApolloClient from "../../../services/apolloClient";
import createBloggersApolloClient from "../../../services/bloggersApolloClient";
import createGraphNodeApolloClient from "../../../services/graphNodeApolloClient";
import LOCAL_STORAGE_KEYS from "../../constants/localStorage";

const bloggersToken = localStorage.getItem(LOCAL_STORAGE_KEYS.BLOGGERS_TOKEN);
const _bloggersClient = createBloggersApolloClient(bloggersToken);
const graphNodeClient = createGraphNodeApolloClient();

export const GraphNodeClientContext = createContext({
    graphNodeClient,
});

export const BloggersClientContext = createContext({
    bloggersClient: _bloggersClient,
    changeBloggersClient: (token) => {},
});

const ApolloAppProvider = ({ children }) => {
    const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
    const client = createApolloClient(token);
    const [bloggersClient, setBloggersClient] = useState(_bloggersClient);

    const changeBloggersClient = (token) => {
        const newClient = createBloggersApolloClient(token);
        setBloggersClient(newClient);
    };

    return (
        <BloggersClientContext.Provider
            value={{ bloggersClient, changeBloggersClient }}
        >
            <GraphNodeClientContext.Provider value={{ graphNodeClient }}>
                <ApolloProvider client={client}>{children}</ApolloProvider>
            </GraphNodeClientContext.Provider>
        </BloggersClientContext.Provider>
    );
};

export default ApolloAppProvider;
