import React, { memo, useContext, useState } from "react";
import CustomAddress from "../../../../../components/address/CustomAddress";
import CustomButton from "../../../../../components/button/CustomButton";
import USER_ROLES from "../../../../../components/constants/userRoles";
import CustomLoader from "../../../../../components/customLoader/CustomLoader";
import NFTAuthorPicture from "../../../../../components/nftCard/authorPicture/NFTAuthorPicture";
import useIsUserRole from "../../../../../hooks/useIsUserRole/useIsUserRole";
import { AuthContext } from "../../../../../providers/Auth/AuthProvider";
import { SnackBarContext } from "../../../../../providers/SnackBar/SnackBarProvider";

const UserModeratorItem = ({ user, contractInstance }) => {
    const { authContext } = useContext(AuthContext);
    const { displaySnackBar } = useContext(SnackBarContext);
    const [loadingTx, setLoadingTx] = useState(false);
    const isYou = authContext?.myProfile?.id === user?.id;
    const userRole = useIsUserRole(user?.id, contractInstance);

    const isModerator = userRole === USER_ROLES.moderator;

    const wallet = authContext?.wallet;
    const provider = wallet?.provider;

    const handleGiveOrRemoveModeratorRole = async () => {
        if (contractInstance && user?.id) {
            try {
                const setModeratorTx = await contractInstance.setModerator(
                    user?.id,
                    !isModerator
                );

                await setModeratorTx.wait();

                const txReceipt = await provider.getTransactionReceipt(
                    setModeratorTx.hash
                );

                console.log(`SetModerator receipt ===== `, txReceipt);

                if (txReceipt) {
                    console.log("********** Role updated ************");
                    setTimeout(() => {
                        setLoadingTx(false);
                    }, 3000);

                    displaySnackBar({
                        message: "User's role changed successfully",
                        type: "success",
                    });
                }
            } catch (error) {
                setLoadingTx(false);
                displaySnackBar({
                    message: error?.message
                        ? error?.message
                        : "Error occured while changing the user's role",
                    type: "error",
                });
            }
        }
    };

    return (
        <div className="p_list" style={{ display: "flex" }}>
            {loadingTx && <CustomLoader text="Changing user's role..." />}
            <NFTAuthorPicture
                address={user?.id}
                src={user?.profilePictureUri}
                noAnimation
            />
            <div className="p_list_info">
                <span>
                    <b>{isYou ? "You" : user?.userName}</b>
                    <CustomAddress address={user?.id} />
                </span>
                <span>{userRole}</span>
            </div>

            {!isYou && (
                <div>
                    <CustomButton
                        text={
                            isModerator
                                ? "Remove moderator role"
                                : "Give moderator role"
                        }
                        onClick={handleGiveOrRemoveModeratorRole}
                        isWhite={isModerator}
                        style={{
                            marginLeft: 25,
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default memo(UserModeratorItem);
