import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import React from "react";
import COLORS from "../../constants/colors";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: COLORS.light.white,
    },
}));

const CustomLoader = ({ text }) => {
    const classes = useStyles();

    return (
        <Backdrop className={classes.backdrop} open={true}>
            <div style={{ position: "fixed", marginTop: 100 }}>
                {text || "Loading..."}
            </div>
            <CircularProgress color={COLORS.light.black_col} />
        </Backdrop>
    );
};

export default CustomLoader;
