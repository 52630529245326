import { getMainDefinition } from "@apollo/client/utilities";
import { ApolloClient, InMemoryCache, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createClient } from "graphql-ws";
import "bootstrap/dist/css/bootstrap.min.css";
import { ApolloLink, Observable } from "@apollo/client/core";
import { print } from "graphql";
import { createUploadLink } from "apollo-upload-client";

const createApolloClient = (token) => {
    class WebSocketLink extends ApolloLink {
        constructor(options) {
            super();
            this.client = createClient(options);
        }

        request(operation) {
            return new Observable((sink) => {
                return this.client.subscribe(
                    { ...operation, query: print(operation.query) },
                    {
                        next: sink.next.bind(sink),
                        complete: sink.complete.bind(sink),
                        error: (err) => {
                            if (err instanceof Error) {
                                return sink.error(err);
                            }

                            if (err instanceof CloseEvent) {
                                return sink.error(
                                    // reason will be available on clean closes
                                    new Error(
                                        `Socket closed with event ${err.code} ${
                                            err.reason || ""
                                        }`
                                    )
                                );
                            }

                            return sink.error(
                                new Error(
                                    err.map(({ message }) => message).join(", ")
                                )
                            );
                        },
                    }
                );
            });
        }
    }

    const httpLink = createUploadLink({
        uri: `${process.env.REACT_APP_DAPP_URL}`,
    });

    const authLink = setContext((_, { headers }) => {
        if (!token) {
            return headers;
        }

        return {
            headers: {
                ...headers,
                Authorization: token ? `Bearer ${token}` : "",
            },
        };
    });

    const wsLink = new WebSocketLink({
        url: () => {
            if (token)
                return `${process.env.REACT_APP_DAPP_WS_URL}?token=${token}`;

            return `${process.env.REACT_APP_DAPP_WS_URL}`;
        },
        lazy: true,
    });

    const splitLink = split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === "OperationDefinition" &&
                definition.operation === "subscription"
            );
        },
        wsLink,
        authLink.concat(httpLink)
    );

    const client = new ApolloClient({
        link: splitLink, // authLink.concat(httpLink),
        cache: new InMemoryCache(),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: "cache-and-network",
            },
        },
    });

    return client;
};

export default createApolloClient;
