import { gql } from "@apollo/client";

export const GET_MY_BLOGGER_INFO = gql`
    query MyBloggerInfo {
        myBloggerInfo {
            id
            username
        }
    }
`;
