const PropertyItem = (props) => {
    const { property } = props;

    return (
        <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="nft_attr">
                <h5>{property.name}</h5>
                <h4>{property.value}</h4>
                {/* <span>85% have this trait</span> */}
            </div>
        </div>
    );
};

export default PropertyItem;
