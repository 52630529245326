import { useMutation } from "@apollo/client";
import { memo, useContext, useState } from "react";
import CustomButton from "../../components/button/CustomButton";
import { UPDATE_SUB_HELP } from "../../graphql/help/mutation";
import { BloggersClientContext } from "../../providers/Apollo/ApolloProvider";
import { SnackBarContext } from "../../providers/SnackBar/SnackBarProvider";
import CustomInput from "../input/CustomInput";

const UpdateSubHelpForm = ({ subHelp, handleCloseDialog, refetchHelp }) => {
    const { displaySnackBar } = useContext(SnackBarContext);
    const { bloggersClient } = useContext(BloggersClientContext);
    const [title, setTitle] = useState(subHelp?.title);
    const [priority, setPriority] = useState(
        subHelp?.priority ? Number(subHelp?.priority) : undefined
    );

    const [updateSubHelp, { loading }] = useMutation(UPDATE_SUB_HELP, {
        client: bloggersClient,
        onCompleted: (data) => {
            if (data && data.updateSubHelp) {
                displaySnackBar({
                    message: "Sub help edited",
                    type: "success",
                });
                handleCloseDialog();
                if (refetchHelp) {
                    refetchHelp();
                }
            }
        },
        onError: (err) => {
            if (err.message) {
                displaySnackBar({
                    message: err.message,
                    type: "error",
                });
            }
        },
    });

    const onUpdate = () => {
        if (title) {
            updateSubHelp({
                variables: {
                    input: {
                        id: Number(subHelp?.id),
                        title,
                        priority: Number(priority),
                    },
                },
            });
        }
    };

    const handleChangeTitle = (value) => {
        setTitle(value);
    };

    const handleChangePriority = (value) => {
        setPriority(Number(value));
    };

    return (
        <div>
            <CustomInput
                label="Title"
                placeholder="Give a title to your sub help"
                value={title}
                handleChangeValue={handleChangeTitle}
                error={!title}
                errorText="This field is required"
            />

            <CustomInput
                label="Priority"
                placeholder="Priority of your sub help"
                value={priority}
                handleChangeValue={handleChangePriority}
                type="number"
            />
            <div style={{ display: "flex" }}>
                <CustomButton
                    text="UPDATE"
                    onClick={onUpdate}
                    disabled={loading || !title}
                    style={{ marginRight: 20 }}
                />
                <CustomButton
                    text="CANCEL"
                    onClick={handleCloseDialog}
                    disabled={loading}
                    isWhite
                />
            </div>
        </div>
    );
};
export default memo(UpdateSubHelpForm);
