import { gql } from "@apollo/client";
import { SALE_FRAGMENT } from "./fragment";

export const MAKE_SIGNED_SALE = gql`
    mutation MakeSignedSale($input: MakeSignedSaleInput!) {
        makeSignedSale(input: $input) {
            ...SaleFields
        }
    }
    ${SALE_FRAGMENT}
`;
