import { useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "@reach/router";
import { memo, useContext, useState } from "react";
import CustomButton from "../../components/button/CustomButton";
import ROUTES from "../../components/constants/routes";
import CustomLoader from "../../components/customLoader/CustomLoader";
import CustomGGLandDialog from "../../components/dialog/CustomGGLandDialog";
import Footer from "../../components/footer/Footer";
import CreateSubHelpForm from "../../components/help/CreateSubHelpForm";
import HelpItem from "../../components/help/HelpItem";
import SubHelpItem from "../../components/help/SubHelpItem";
import COLORS from "../../constants/colors";
import { DELETE_HELP } from "../../graphql/help/mutation";
import { GET_HELP } from "../../graphql/help/query";
import { BloggersClientContext } from "../../providers/Apollo/ApolloProvider";
import { AuthContext } from "../../providers/Auth/AuthProvider";
import { SnackBarContext } from "../../providers/SnackBar/SnackBarProvider";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";

const HelpDetail = ({ helpId }) => {
    const { isDark } = useContext(ThemeContext);
    const { isBlogAdmin } = useContext(AuthContext);
    const { displaySnackBar } = useContext(SnackBarContext);
    const { bloggersClient } = useContext(BloggersClientContext);
    const navigate = useNavigate();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openNewSubHelpDialog, setOpenNewSubHelpDialog] = useState(false);

    const { data, loading, refetch } = useQuery(GET_HELP, {
        variables: {
            id: Number(helpId),
        },
        fetchPolicy: "cache-and-network",
    });

    const help = data?.getHelp;

    const [deleteHelp, { loading: loadingDelete }] = useMutation(DELETE_HELP, {
        client: bloggersClient,
        onCompleted: (data) => {
            if (data && data.deleteHelp) {
                displaySnackBar({
                    message: "Help deleted",
                    type: "success",
                });
                navigate(`${ROUTES.helpCenter}`);
            }
        },
        onError: (err) => {
            if (err.message) {
                displaySnackBar({
                    message: err.message,
                    type: "error",
                });
            }
        },
    });

    const onDeleteHelp = () => {
        deleteHelp({
            variables: {
                id: Number(helpId),
            },
        });
    };

    const onCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const onCloseNewSubHelpDialog = () => {
        setOpenNewSubHelpDialog(false);
    };

    return (
        <div className={isDark ? "greyscheme" : ""}>
            {(loading || loadingDelete) && <CustomLoader />}

            <section
                className="jumbotron breadcumb no-bg"
                style={
                    !isDark
                        ? {
                              borderBottom: `1px solid ${COLORS.v2.greyText}`,
                          }
                        : undefined
                }
            >
                <div className="mainbreadcumb">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 offset-md-2 text-center">
                                <h1
                                    style={{
                                        color: !isDark && COLORS.v2.black_grey,
                                    }}
                                >
                                    {help?.title}
                                </h1>
                                <div className="spacer-20"></div>

                                <p
                                    className="mt-0"
                                    style={{
                                        color: !isDark && COLORS.v2.black_grey,
                                    }}
                                >
                                    {help?.description}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container">
                {isBlogAdmin && (
                    <div style={{ display: "flex" }}>
                        <CustomButton
                            text="UPDATE HELP"
                            onClick={() =>
                                navigate(`${ROUTES.updateHelp}/${helpId}`)
                            }
                            disabled={loading || loadingDelete}
                            style={{ marginRight: 20 }}
                            isGreen
                        />
                        <CustomButton
                            text="DELETE HELP"
                            onClick={() => setOpenDeleteDialog(true)}
                            disabled={loading || loadingDelete}
                            isWhite
                        />

                        <CustomButton
                            text="+ ADD NEW SUB HELP"
                            onClick={() => setOpenNewSubHelpDialog(true)}
                            disabled={loading || loadingDelete}
                            isBlack
                        />
                    </div>
                )}

                {help?.subHelps?.length ? (
                    <div style={{ marginTop: 40 }}>
                        {help?.subHelps?.map((subhelp) => (
                            <SubHelpItem
                                subHelp={subhelp}
                                refetchHelp={refetch}
                            />
                        ))}
                    </div>
                ) : (
                    <div style={{ marginTop: 20 }}>
                        No sub help documentation avalaible.
                    </div>
                )}
            </section>

            {openDeleteDialog && (
                <CustomGGLandDialog
                    title="Do you really want to delete this help documentation ?"
                    handleClose={onCloseDeleteDialog}
                >
                    <div>
                        <HelpItem help={help} isPreviewMode />
                        <div style={{ display: "flex" }}>
                            <CustomButton
                                text="DELETE"
                                onClick={onDeleteHelp}
                                disabled={loading}
                                style={{ marginRight: 20 }}
                            />
                            <CustomButton
                                text="CANCEL"
                                onClick={onCloseDeleteDialog}
                                disabled={loading}
                                isWhite
                            />
                        </div>
                    </div>
                </CustomGGLandDialog>
            )}

            {openNewSubHelpDialog && (
                <CustomGGLandDialog
                    title="Create a new sub help documentation"
                    handleClose={onCloseNewSubHelpDialog}
                >
                    <CreateSubHelpForm
                        helpId={helpId}
                        handleCloseDialog={onCloseNewSubHelpDialog}
                        refetchHelp={refetch}
                    />
                </CustomGGLandDialog>
            )}

            <Footer />
        </div>
    );
};
export default memo(HelpDetail);
