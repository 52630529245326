import { ethers } from "ethers";
import React, { memo, useContext } from "react";
import { AuthContext } from "../../../../providers/Auth/AuthProvider";
import contract from "../../../../utils/blockchain/contract";
import UserModeratorItem from "./item/UserModeratorItem";

const UsersModeratorList = ({ users, loadMore, showLoadMore }) => {
    const { authContext } = useContext(AuthContext);

    const contractAddress = contract?.contracts?.IdealNFT?.address;
    const contractAbi = contract?.contracts?.IdealNFT?.abi;
    let contractInstance = new ethers.Contract(contractAddress, contractAbi);

    contractInstance = authContext?.wallet?.signer
        ? contractInstance.connect(authContext?.wallet?.signer)
        : null;

    return (
        <div className="row">
            {users &&
                users.map((user, index) => (
                    <UserModeratorItem
                        key={index}
                        user={user}
                        contractInstance={contractInstance}
                    />
                ))}
            {showLoadMore && users.length && (
                <div className="col-lg-12">
                    <div className="spacer-single"></div>
                    <span onClick={loadMore} className="btn-main lead m-auto">
                        Load More
                    </span>
                </div>
            )}
        </div>
    );
};

export default memo(UsersModeratorList);
