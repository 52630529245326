import { gql } from "@apollo/client";
import { OFFER_FRAGMENT } from "./fragment";

export const MAKE_SIGNED_OFFER = gql`
    mutation MakeSignedOffer($input: MakeSignedOfferInput!) {
        makeSignedOffer(input: $input) {
            ...OfferFields
        }
    }
    ${OFFER_FRAGMENT}
`;
