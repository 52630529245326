import { useEffect, useState } from "react";
import USER_ROLES from "../../components/constants/userRoles";

const useIsUserRole = (userId, contractInstance) => {
    const [userRole, setUserRole] = useState(USER_ROLES.normalUser);

    useEffect(() => {
        if (userId && contractInstance) {
            contractInstance?.admin().then((admin) => {
                if (userId?.toLowerCase() === admin?.toLowerCase()) {
                    setUserRole(USER_ROLES.admin);
                }
            });

            contractInstance?.moderators(userId).then((isModeratorRes) => {
                if (isModeratorRes === true) {
                    setUserRole(USER_ROLES.moderator);
                }
            });
        }
    }, [contractInstance, userId]);

    return userRole;
};

export default useIsUserRole;
