import { fillSaleOrOffer } from "common";

export const canUserAcceptOffer = ({
    currentUser,
    offerAuthor,
    shareHolders,
    offerOwnerTokenOwner,
}) => {
    const defaultOffer = fillSaleOrOffer({});

    // in the case of the current user = offer author
    if (offerAuthor?.toLowerCase() === currentUser?.toLowerCase()) {
        return false;
    }

    // offer to all share holders
    if (
        defaultOffer.owner.tokenOwner.toLowerCase() ===
        offerOwnerTokenOwner?.toLowerCase()
    ) {
        return shareHolders?.some(
            (sh) => sh?.toLowerCase() === currentUser?.toLowerCase()
        );
    }

    // offer to a spacific share holders
    return currentUser?.toLowerCase() === offerOwnerTokenOwner?.toLowerCase();
};
