const myProfile = {
    "myProfile.title": "Mon profil",
    "myProfile.joined": "A rejoins le",
    "myProfile.preview": "Aperçu",
    "myProfile.update": "Mettre à jour le profil",
    "myProfile.wallet.address.copy": "Copier l'adresse du portefeuille",
    "myProfile.settings.title": "Paramètres de profil",
    "myProfile.settings.username": "Nom d'utilisateur",
    "myProfile.settings.username.placeholder": "Entrer le nom d'utilisateur",
    "myProfile.settings.bio": "Bio",
    "myProfile.settings.bio.placeholder": "Racontez au monde votre histoire !",
    "myProfile.settings.email": "Adresse email",
    "myProfile.settings.email.format.error": "Format de l'email invalide.",
    "myProfile.settings.email.placeholder": "Entrer l'adresse email",
    "myProfile.settings.wallet": "Adresse du Portefeuille",
    "myProfile.settings.wallet.placeholder": "L'adresse de votre Portefeuille",
    "myProfile.settings.profile.picture": "Photo de profil",
    "myProfile.settings.profile.picture.tooltip": "Votre photo de profil",
    "myProfile.settings.profile.picture.edit.tooltip":
        "Modifier votre photo de profil",
    "myProfile.settings.update.success":
        "Profil d'utilisateur modifié avec succès !",
    "myProfile.settings.update.error":
        "Une erreur est survenue lors de la modification du profil.",
    "myProfile.settings.uploading.picture.unknown.error":
        "Une erreur inconnue est survenue lors du chargement de votre fichier.",
    "myProfile.settings.uploading.picture.size.error":
        "Fichier trop grande. La taille du fichier est limitée à",
};

export default myProfile;
