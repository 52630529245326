import { useNavigate } from "@reach/router";
import { memo, useContext, useState } from "react";
import { ThemeContext } from "../../../../providers/Theme/ThemeProvider";
import Footer from "../../../../components/footer/Footer";
import CustomButton from "../../../../components/button/CustomButton";
import ROUTES from "../../../../components/constants/routes";
import { AuthContext } from "../../../../providers/Auth/AuthProvider";
import HelpList from "../../../../components/help/HelpList";
import useDebounce from "../../../../hooks/useDebounce/useDebounce";
import COLORS from "../../../../constants/colors";

const HelpCenter = () => {
    const { isDark } = useContext(ThemeContext);
    const { isBlogAdmin } = useContext(AuthContext);
    const navigate = useNavigate();
    const [filter, setFilter] = useState("");

    const debouncedFilter = useDebounce(filter);

    const handleChange = (event) => {
        setFilter(event?.target?.value);
    };

    return (
        <div className={isDark ? "greyscheme" : ""}>
            <section
                className="jumbotron breadcumb no-bg"
                style={
                    !isDark
                        ? {
                              borderBottom: `1px solid ${COLORS.v2.greyText}`,
                          }
                        : undefined
                }
            >
                <div className="mainbreadcumb">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 offset-md-2 text-center">
                                <h1
                                    style={{
                                        color: !isDark && COLORS.v2.black_grey,
                                    }}
                                >
                                    Help Center
                                </h1>
                                <div className="spacer-20"></div>
                                <form
                                    className="row"
                                    id="form_sb"
                                    name="myForm"
                                >
                                    <div className="col text-center">
                                        <input
                                            className="form-control"
                                            id="name_1"
                                            name="name_1"
                                            placeholder="type your question here"
                                            type="text"
                                            value={filter}
                                            onChange={handleChange}
                                            style={{
                                                background:
                                                    !isDark &&
                                                    COLORS.v2
                                                        .grey_light_backgroung,
                                            }}
                                        />{" "}
                                        <button id="btn-submit">
                                            <i className="arrow_right"></i>
                                        </button>
                                    </div>
                                </form>
                                <div className="spacer-20"></div>

                                <p
                                    className="mt-0"
                                    style={{
                                        color: !isDark && COLORS.v2.black_grey,
                                    }}
                                >
                                    eg. create item, create wallet.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container">
                {isBlogAdmin && (
                    <CustomButton
                        text="+ CREATE NEW HELP"
                        onClick={() => navigate(ROUTES.createHelp)}
                        style={{
                            marginBottom: 25,
                        }}
                    />
                )}

                <HelpList filter={debouncedFilter} />
            </section>

            <Footer />
        </div>
    );
};
export default memo(HelpCenter);
