import { gql } from "@apollo/client";

export const USER_FRAGMENT = gql`
    fragment UserFields on User {
        id
        encryptionPublicKey
        userName
        bio
        email
        profilePictureUri
        createdAt
    }
`;
