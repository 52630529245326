import { useQuery } from "@apollo/client";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useNavigate } from "@reach/router";
import React, { useState } from "react";
import { MINTED_NFTS_SEARCH } from "../../graphql/nft/query";
import useDebounce from "../../hooks/useDebounce/useDebounce";
import ROUTES from "../constants/routes";

const NFTSearch = () => {
    const navigate = useNavigate();
    const [titleFilter, setTitleFilter] = useState("");
    const debouncedTitleFilter = useDebounce(titleFilter);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        if (titleFilter.length > 0) {
            setOpen(true);
        }
    };
    const handleInputChange = (event, newInputValue) => {
        setTitleFilter(newInputValue);
        if (newInputValue.length > 0) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    };

    const { data, loading } = useQuery(MINTED_NFTS_SEARCH, {
        variables: {
            input: {
                title: debouncedTitleFilter,
                isAutocomplete: true,
            },
        },
        fetchPolicy: "cache-and-network",
    });

    const searchResult = data?.getMintedIps || [];

    const onSelect = (event, value) => {
        if (value?.cid) {
            navigate(`${ROUTES.nftDetail}/${value?.cid}`);
        }
    };

    return (
        <div className="search">
            <Autocomplete
                id="custom-input-demo"
                options={searchResult}
                getOptionLabel={(option) => option?.title}
                noOptionsText="No item found"
                loadingText="Searching..."
                loading={loading}
                openOnFocus={false}
                inputValue={titleFilter}
                onChange={onSelect}
                open={open}
                onOpen={handleOpen}
                onClose={() => setOpen(false)}
                onInputChange={handleInputChange}
                renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                        <input
                            {...params.inputProps}
                            id="quick_search"
                            className="xs-hide"
                            name="quick_search"
                            placeholder="Search artworks, creators and collectors..."
                            type="text"
                        />
                    </div>
                )}
            />
        </div>
    );
};

export default NFTSearch;
