import { NOT_FOUND_NFT_IMAGE } from "../../constants/file";

const getImagePreview = ({ extension, imageUrl }) => {
    const pdfIllustration = "/img/items/pdf-image.png";
    const txtIllustration = "/img/items/txt-image.png";
    const videoIllustration = "/img/items/video-file.png";
    const audioIllustration = "/img/items/audio-file.png";

    if (extension?.includes("image")) {
        return imageUrl || NOT_FOUND_NFT_IMAGE;
    } else if (
        extension?.includes("docx") ||
        extension?.includes("txt") ||
        extension?.includes("text")
    ) {
        return txtIllustration;
    } else if (extension?.includes("pdf")) {
        return pdfIllustration;
    } else if (extension?.includes("video")) {
        return videoIllustration;
    } else if (extension?.includes("audio")) {
        return audioIllustration;
    }

    return undefined;
};

export default getImagePreview;
