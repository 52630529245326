const myProfile = {
    "myProfile.title": "My Profile",
    "myProfile.joined": "Joined",
    "myProfile.preview": "Preview",
    "myProfile.update": "Update profile",
    "myProfile.wallet.address.copy": "Copy wallet address",
    "myProfile.settings.title": "Profile Settings",
    "myProfile.settings.username": "Username",
    "myProfile.settings.username.placeholder": "Enter username",
    "myProfile.settings.bio": "Bio",
    "myProfile.settings.bio.placeholder": "Tell the world your story !",
    "myProfile.settings.email": "Email address",
    "myProfile.settings.email.placeholder": "Enter email",
    "myProfile.settings.email.format.error":
        "Entered value does not match email format.",
    "myProfile.settings.wallet": "Wallet Address",
    "myProfile.settings.wallet.placeholder": "Your wallet address",
    "myProfile.settings.profile.picture": "Profile Image",
    "myProfile.settings.profile.picture.tooltip": "Your profile picture",
    "myProfile.settings.profile.picture.edit.tooltip":
        "Edit your profile picture",
    "myProfile.settings.update.success": "User profile successfuly modified !",
    "myProfile.settings.update.error": "Error occured while setting profile.",
    "myProfile.settings.uploading.picture.unknown.error":
        "Unknown error occured while uploading your file.",
    "myProfile.settings.uploading.picture.size.error":
        "File too large. File size limit is",
};

export default myProfile;
