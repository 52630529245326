import { gql } from "@apollo/client";
import { USER_FRAGMENT } from "./fragment";

export const GET_ME = gql`
    query GetMe {
        me {
            ...UserFields
        }
    }
    ${USER_FRAGMENT}
`;

export const GET_USER = gql`
    query GetUser($id: ID!) {
        getUser(id: $id) {
            ...UserFields
        }
    }
    ${USER_FRAGMENT}
`;

export const GET_MINIMAL_USER = gql`
    query GetMinimalUser($id: ID!) {
        getUser(id: $id) {
            id
            userName
            profilePictureUri
        }
    }
`;

export const GET_USERS = gql`
    query GetUsers($input: UsersInput) {
        users(input: $input) {
            ...UserFields
        }
    }
    ${USER_FRAGMENT}
`;
