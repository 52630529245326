import { createStyles, makeStyles } from "@material-ui/core";
import React from "react";
import COLORS from "../../constants/colors";

const IDXLogo = ({ isGrey, isGreen, style, beforeText, afterText }) => {
    const useStyles = makeStyles((theme) =>
        createStyles({
            h1: {
                display: "flex",
                justifyContent: "center",
            },
        })
    );

    const classes = useStyles();

    if (isGrey)
        return (
            <h1
                className={classes.h1}
                style={{
                    color: COLORS.v2.black_grey,
                    marginBottom: 40,
                    ...style,
                }}
            >
                {beforeText ? `${beforeText} ` : ""}(ID
                <div style={{ color: COLORS.v2.greyText }}>X</div>)
                {afterText ? ` ${afterText}` : ""}
            </h1>
        );

    if (isGreen)
        return (
            <h1
                className={classes.h1}
                style={{
                    color: COLORS.v2.white,
                    marginBottom: 40,
                    ...style,
                }}
            >
                {beforeText ? `${beforeText} ` : ""}(ID
                <div style={{ color: COLORS.v2.green }}>X</div>)
                {afterText ? ` ${afterText}` : ""}
            </h1>
        );

    return (
        <h1
            className={classes.h1}
            style={{
                color: COLORS.v2.white,
                marginBottom: 40,
                ...style,
            }}
        >
            {beforeText ? `${beforeText} ` : ""}(
            <div style={{ color: COLORS.v2.green }}>IDX</div>)
            {afterText ? ` ${afterText}` : ""}
        </h1>
    );
};
export default IDXLogo;
