import React, { createContext, useContext, useEffect, useState } from "react";
import { SnackBarContext } from "../SnackBar/SnackBarProvider";
import { useIntl } from "react-intl";
import LOCAL_STORAGE_KEYS from "../../constants/localStorage";
import ROUTES from "../../components/constants/routes";
import { useQuery } from "@apollo/client";
import { GET_MY_BLOGGER_INFO } from "../../graphql/blogger/query";
import { BloggersClientContext } from "../Apollo/ApolloProvider";
import { useLocation, useNavigate } from "@reach/router";
import createBloggersApolloClient from "../../../services/bloggersApolloClient";

const defaultBlogger = {
    id: 0,
    username: "",
};

export const BloggerAuthContext = createContext({
    meAsblogger: defaultBlogger,
    loadingAuth: false,
    isBloggerConnected: false,
    setMeAsBlogger: (partial) => {},
    logoutAsBlogger: (isError) => {},
    loginAsBlogger: (token) => {},
});

const token = localStorage.getItem(LOCAL_STORAGE_KEYS.BLOGGERS_TOKEN);

const BloggerAuthProvider = ({ children }) => {
    const { displaySnackBar } = useContext(SnackBarContext);
    const { bloggersClient, changeBloggersClient } = useContext(
        BloggersClientContext
    );
    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const location = useLocation();

    const [meAsblogger, setMeAsBlogger] = useState(defaultBlogger);

    const logoutAsBlogger = (isError) => {
        navigate(ROUTES.bloggerLogin);

        localStorage.removeItem(LOCAL_STORAGE_KEYS.BLOGGERS_TOKEN);
        setMeAsBlogger(null);

        if (!isError) {
            displaySnackBar({
                message: formatMessage({
                    id: "auth.signout.message.success",
                }),
                type: "success",
            });
        }
    };

    const { loading: loadingGetMeAsBlogger } = useQuery(GET_MY_BLOGGER_INFO, {
        client: bloggersClient,
        skip: !token,
        // Check every 1 hour
        pollInterval: 3600 * 1 * 1000,
        onCompleted: (data) => {
            if (data && data.myBloggerInfo) {
                setMeAsBlogger(data.myBloggerInfo);
                if (location?.pathname === ROUTES.bloggerLogin) {
                    navigate(ROUTES.bloggerHome);
                }
            } else {
                logoutAsBlogger(true);
            }
        },
        onError: (error) => {
            if (error && token) {
                logoutAsBlogger(true);
                displaySnackBar({
                    message: "Your token has expired, please reconnect.",
                    type: "error",
                });
            }
        },
    });

    const loginAsBlogger = (t) => {
        if (t) {
            localStorage.setItem(LOCAL_STORAGE_KEYS.BLOGGERS_TOKEN, t);
            const tempClient = createBloggersApolloClient(t);

            changeBloggersClient(t);

            tempClient.query({ query: GET_MY_BLOGGER_INFO }).then((res) => {
                if (res && res.data && res.data.myBloggerInfo) {
                    setMeAsBlogger(res.data.myBloggerInfo);
                    navigate(ROUTES.bloggerHome);
                } else {
                    logoutAsBlogger(true);
                }
            });
        }
    };

    useEffect(() => {
        if (!token) setMeAsBlogger(null);
    }, []);

    return (
        <BloggerAuthContext.Provider
            value={{
                loadingAuth: loadingGetMeAsBlogger,
                loginAsBlogger,
                logoutAsBlogger,
                meAsblogger,
                setMeAsBlogger,
                isBloggerConnected: !!meAsblogger,
            }}
        >
            {children}
        </BloggerAuthContext.Provider>
    );
};

export default BloggerAuthProvider;
