import { useQuery } from "@apollo/client";
import { fillSaleOrOffer } from "common";
import { ethers } from "ethers";
import moment from "moment";
import { useState } from "react";
import { useContext } from "react";
import { useIntl } from "react-intl";
import { defaultValidUntil } from "../../constants/blockchain";
import { GET_USER } from "../../graphql/user/query";
import { AuthContext } from "../../providers/Auth/AuthProvider";
import { NetworkContext } from "../../providers/NetTester/NetTesterProvider";
import { SnackBarContext } from "../../providers/SnackBar/SnackBarProvider";
import { canUserAcceptOffer } from "../../utils/blockchain/offer";
import { formatNumber } from "../../utils/string/formatNumber";
import CustomAddress from "../address/CustomAddress";
import CustomButton from "../button/CustomButton";
import Clock from "../Clock";
import CustomLoader from "../customLoader/CustomLoader";
import NFTAuthorPicture from "../nftCard/authorPicture/NFTAuthorPicture";

const SaleOrOfferItemList = ({
    user,
    saleOrOffer,
    saleOrOfferJSON,
    contractInstance,
    shareHolders,
    refetch,
}) => {
    const { formatMessage } = useIntl();
    const { authContext, isUserConnected } = useContext(AuthContext);
    const { isNetworkMismatched, isUnsupportedNetwork } =
        useContext(NetworkContext);
    const { displaySnackBar } = useContext(SnackBarContext);
    const [loading, setLoading] = useState(false);
    const defaultOffer = fillSaleOrOffer({});

    const isSale = saleOrOfferJSON?.saleOrOffer === 1 ? true : false;

    const { data: dataUser } = useQuery(GET_USER, {
        variables: { id: saleOrOfferJSON?.owner?.tokenOwner },
        skip:
            isSale ||
            defaultOffer.owner.tokenOwner?.toLowerCase() ===
                saleOrOfferJSON?.owner?.tokenOwner?.toLowerCase(),
    });

    const targetOfferUser = dataUser?.getUser;

    const userName = user?.userName;
    const userProfilePictureUri = user?.profilePictureUri;

    const sharesCount = Number(
        ethers.utils.formatEther(saleOrOfferJSON?.item?.value)
    );

    const totalPrice = Number(
        ethers.utils.formatEther(saleOrOfferJSON?.payment?.value)
    );

    const pricePerShare = totalPrice / sharesCount;

    const handleExecuteSaleOrOffer = async () => {
        if (isNetworkMismatched || isUnsupportedNetwork) {
            displaySnackBar({
                message: formatMessage({
                    id: "net.issue.block.transaction",
                }),
                type: "error",
            });
            return;
        }

        console.log("********** handleExecuteSaleOrOffer ********");
        console.log("Signature ==== ", saleOrOffer?.signature);

        if (isSale) {
            console.log(`saleJSON : `, saleOrOfferJSON);
        } else {
            console.log(`offerfferJSON : `, saleOrOfferJSON);
        }

        try {
            console.log("********** Executing starts ********");
            setLoading(true);
            if (isSale) {
                await contractInstance.execute(
                    saleOrOfferJSON,
                    saleOrOffer?.signature,
                    { value: saleOrOfferJSON?.payment?.value }
                );
            } else {
                await contractInstance.execute(
                    saleOrOfferJSON,
                    saleOrOffer?.signature
                    // { value: saleOrOfferJSON?.payment?.value }
                );
            }
            setTimeout(() => {
                refetch();
                setLoading(false);
            }, 3000);
            displaySnackBar({
                message: isSale
                    ? "You have successfully accepted the sale"
                    : "You have successfully accepted the sale",
                type: "success",
            });

            console.log("********** Executing ends ********");
        } catch (error) {
            setLoading(false);
            displaySnackBar({
                message:
                    error?.data?.message ||
                    error?.message ||
                    "Error occured when exectuting",
                type: "error",
            });
        }
    };

    const canExecuteOffer = canUserAcceptOffer({
        currentUser: authContext?.myProfile?.id,
        offerAuthor: user?.id,
        shareHolders,
        offerOwnerTokenOwner: saleOrOfferJSON?.owner?.tokenOwner,
    });

    return (
        <div className="p_list" style={{ display: "flex" }}>
            {loading && (
                <CustomLoader
                    text={isSale ? "Executing sale..." : "Executing offer..."}
                />
            )}
            <NFTAuthorPicture
                address={user?.id}
                src={userProfilePictureUri}
                noAnimation
            />
            <div className="p_list_info">
                {isSale ? "Sale " : "Offer"} <b>{pricePerShare} ETH / share</b>
                <span>
                    <b>{formatNumber(sharesCount)}</b> shares
                </span>
                <span>
                    by{" "}
                    <b>
                        {authContext?.myProfile?.id === user?.id
                            ? "You"
                            : userName}
                    </b>{" "}
                    <CustomAddress address={user?.id} />
                </span>
                <span>
                    at{" "}
                    {moment(saleOrOffer?.createdAt).format(
                        "DD/MM/YYYY, hh:mm A"
                    )}
                </span>
                {!isSale && (
                    <span>
                        to{" "}
                        {targetOfferUser ? (
                            <b>
                                {targetOfferUser?.userName}{" "}
                                <CustomAddress address={targetOfferUser?.id} />
                            </b>
                        ) : (
                            <b>all share holders</b>
                        )}
                    </span>
                )}
                {saleOrOfferJSON?.validUntil?.blockNumberOrTimestamp &&
                saleOrOfferJSON?.validUntil?.blockNumberOrTimestamp <
                    defaultValidUntil ? (
                    <span>
                        {!saleOrOffer?.isExpired ? (
                            <span>
                                Ends in
                                <div className="de_countdown">
                                    <Clock
                                        deadline={
                                            new Date(
                                                saleOrOfferJSON?.validUntil
                                                    ?.blockNumberOrTimestamp *
                                                    1000
                                            )
                                        }
                                    />
                                </div>
                            </span>
                        ) : (
                            <span>
                                <b>Expired </b>
                                on{" "}
                                {moment(
                                    saleOrOfferJSON?.validUntil
                                        ?.blockNumberOrTimestamp * 1000
                                ).format("DD/MM/YYYY")}
                            </span>
                        )}
                    </span>
                ) : null}
            </div>
            {isUserConnected && !saleOrOffer?.isExpired && (
                <div>
                    {isSale &&
                        authContext?.myProfile?.id !==
                            saleOrOfferJSON?.owner?.tokenOwner && (
                            <CustomButton
                                text="Execute"
                                onClick={handleExecuteSaleOrOffer}
                                isWhite
                                style={{
                                    marginLeft: 25,
                                }}
                            />
                        )}
                    {!isSale && canExecuteOffer && (
                        <CustomButton
                            text="Execute"
                            onClick={handleExecuteSaleOrOffer}
                            isWhite
                            style={{
                                marginLeft: 25,
                            }}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default SaleOrOfferItemList;
