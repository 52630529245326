import React, { useContext, useEffect, useState } from "react";
import Breakpoint, {
    BreakpointProvider,
    setDefaultBreakpoints,
} from "react-socks";
import { Link, useNavigate, useLocation } from "@reach/router";
import useOnclickOutside from "react-cool-onclickoutside";
import HeaderAuth from "./HeaderAuth";
import AppLogo from "../components/logo/AppLogo";
import ROUTES, { BLOGGER_ROUTES } from "../components/constants/routes";
import HeaderMenuXL from "./menus/HeaderMenuXL";
import {
    CATALOG_ITEMS,
    COMMUNITY_ITEMS,
    MY_PORTFOLIO_ITEMS,
} from "./subMenus/subMenus";
import HeaderMenuL from "./menus/HeaderMenuL";
import NFTSearch from "../components/nftSearch/NFTSearch";
import { useIntl } from "react-intl";
import HeaderNoSubmenu from "./menus/HeaderNoSubmenu";
import { AuthContext } from "../providers/Auth/AuthProvider";
import { Fade } from "@material-ui/core";
import { ThemeContext } from "../providers/Theme/ThemeProvider";
import COLORS from "../constants/colors";
import { BloggerAuthContext } from "../providers/BloggerAuth/BloggerAuthProvider";
import BloggerHeader from "./bloggerHeader/BloggerHeader";

setDefaultBreakpoints([{ xs: 0 }, { l: 1199 }, { xl: 1200 }]);

const NavLink = (props) => (
    <Link
        {...props}
        getProps={({ isCurrent }) => {
            // the object returned here is passed to the
            // anchor element's props
            return {
                className: isCurrent ? "active" : "non-active",
            };
        }}
    />
);

const Header = () => {
    const { isAdmin, isModerator } = useContext(AuthContext);
    const { isBloggerConnected } = useContext(BloggerAuthContext);
    const { isDark } = useContext(ThemeContext);
    const [openMenu, setOpenMenu] = React.useState(false);
    const [openCatalogMenu, setOpenCatalogMenu] = React.useState(false);
    const [openMyCollectionMenu, setOpenMyCollectionMenu] =
        React.useState(false);
    const [openActivityMenu, setOpenActivityMenu] = React.useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { formatMessage } = useIntl();

    const handleNavigateToHome = () => {
        navigate(ROUTES.home);
        btn_icon(false);
    };

    const handleNavigatoAdminInterface = () => {
        navigate(ROUTES.adminHome);
        btn_icon(false);
    };

    const handleNavigateToOrigisClub = () => {
        navigate(ROUTES.originsClub);
        btn_icon(false);
    };

    const handleNavigateToModeratorInterface = () => {
        navigate(ROUTES.moderatorHome);
        btn_icon(false);
    };

    const handleOpenHomeMenu = () => {
        setOpenMenu(!openMenu);
    };

    const handleCloseHomeMenu = () => {
        setOpenMenu(!openMenu);
        btn_icon(false);
    };

    const handleOpenCatalogMenu = () => {
        setOpenCatalogMenu(!openCatalogMenu);
    };
    const handleOpenMyCollectionMenu = () => {
        setOpenMyCollectionMenu(!openMyCollectionMenu);
    };
    const handleOpenActivityMenu = () => {
        setOpenActivityMenu(!openActivityMenu);
    };
    const closeMenu = () => {
        setOpenMenu(false);
    };
    const handleCloseCatalogMenu = () => {
        setOpenCatalogMenu(false);
        btn_icon(false);
    };
    const handleCloseMyCollectionMenu = () => {
        setOpenMyCollectionMenu(false);
        btn_icon(false);
    };
    const handleCloseActivityMenu = () => {
        setOpenActivityMenu(false);
        btn_icon(false);
    };
    const ref = useOnclickOutside(() => {
        closeMenu();
    });
    const ref1 = useOnclickOutside(() => {
        handleCloseCatalogMenu();
    });
    const ref2 = useOnclickOutside(() => {
        handleCloseMyCollectionMenu();
    });
    const ref3 = useOnclickOutside(() => {
        handleCloseActivityMenu();
    });

    const [showmenu, btn_icon] = useState(false);
    useEffect(() => {
        const header = document.getElementById("myHeader");
        const totop = document.getElementById("scroll-to-top");
        const sticky = header?.offsetTop;
        const scrollCallBack = window?.addEventListener("scroll", () => {
            btn_icon(false);
            if (window.pageYOffset > sticky) {
                header?.classList.add("sticky");
                totop?.classList.add("show");
            } else {
                header?.classList.remove("sticky");
                totop?.classList.remove("show");
            }
            if (window.pageYOffset > sticky) {
                closeMenu();
            }
        });
        return () => {
            window.removeEventListener("scroll", scrollCallBack);
        };
    }, []);

    const isDebug =
        process.env.REACT_APP_DEBUG && process.env.REACT_APP_DEBUG === "true";

    if (location?.pathname === ROUTES.bloggerLogin) return null;
    if (
        isBloggerConnected &&
        !!BLOGGER_ROUTES.some((route) => location?.pathname?.includes(route))
    )
        return <BloggerHeader />;

    return (
        <header
            id="myHeader"
            className={"navbar white"}
            style={{
                backgroundColor: isDark && COLORS.v2.black_grey,
                borderBottom: isDark && `solid 1px ${COLORS.v2.black_grey}`,
            }}
        >
            <div className="container">
                <div className="row w-100-nav">
                    <div className="logo px-0">
                        <div className="navbar-title navbar-item">
                            <AppLogo />
                        </div>
                    </div>

                    <NFTSearch />

                    <BreakpointProvider>
                        <Breakpoint l down>
                            {showmenu && (
                                <div className="menu">
                                    {/* <HeaderNoSubmenu
                                        label="IDEAL EXCHANGE"
                                        handleClick={handleNavigateToHome}
                                    /> */}

                                    <div className="navbar-item">
                                        <div ref={ref}>
                                            <div
                                                className="dropdown-custom dropdown-toggle btn"
                                                style={{
                                                    color:
                                                        isDark &&
                                                        COLORS.v2.white,
                                                }}
                                                onClick={
                                                    isDebug
                                                        ? handleOpenHomeMenu
                                                        : handleNavigateToHome
                                                }
                                            >
                                                {formatMessage({
                                                    id: "IDEAL EXCHANGE",
                                                })}
                                            </div>
                                            {openMenu && (
                                                <div
                                                    className={
                                                        isDark
                                                            ? "item-dropdown dark"
                                                            : "item-dropdown"
                                                    }
                                                >
                                                    <div
                                                        className="dropdown"
                                                        onClick={
                                                            handleCloseHomeMenu
                                                        }
                                                    >
                                                        <NavLink
                                                            to={ROUTES.home}
                                                        >
                                                            {formatMessage({
                                                                id: "title.home",
                                                            })}
                                                        </NavLink>
                                                        <NavLink to="/__debug">
                                                            Home Debug
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {isAdmin && (
                                        <HeaderNoSubmenu
                                            label="Admin Interface"
                                            handleClick={
                                                handleNavigatoAdminInterface
                                            }
                                        />
                                    )}

                                    <HeaderNoSubmenu
                                        label="Origins Club"
                                        handleClick={handleNavigateToOrigisClub}
                                    />

                                    {isModerator && (
                                        <HeaderNoSubmenu
                                            label="Moderator Interface"
                                            handleClick={
                                                handleNavigateToModeratorInterface
                                            }
                                        />
                                    )}

                                    <HeaderMenuL
                                        ref={ref1}
                                        label="title.catalog"
                                        openSubMenu={openCatalogMenu}
                                        handleOpenMenu={handleOpenCatalogMenu}
                                        handleCloseMenu={handleCloseCatalogMenu}
                                        subMenus={CATALOG_ITEMS}
                                    />

                                    <HeaderMenuL
                                        ref={ref2}
                                        label="title.my.portfolio"
                                        openSubMenu={openMyCollectionMenu}
                                        handleOpenMenu={
                                            handleOpenMyCollectionMenu
                                        }
                                        handleCloseMenu={
                                            handleCloseMyCollectionMenu
                                        }
                                        subMenus={MY_PORTFOLIO_ITEMS}
                                    />

                                    <HeaderMenuL
                                        ref={ref3}
                                        label="title.community"
                                        openSubMenu={openActivityMenu}
                                        handleOpenMenu={handleOpenActivityMenu}
                                        handleCloseMenu={
                                            handleCloseActivityMenu
                                        }
                                        subMenus={COMMUNITY_ITEMS}
                                    />
                                </div>
                            )}
                        </Breakpoint>

                        <Breakpoint xl>
                            <Fade in timeout={5000}>
                                <div className="menu">
                                    <img
                                        key={
                                            isDark
                                                ? "/img/v2/logos/ideal-only-logo-dark.png"
                                                : "/img/v2/logos/Icon_BLK@3x.png"
                                        }
                                        src={
                                            isDark
                                                ? "/img/v2/logos/ideal-only-logo-dark.png"
                                                : "/img/v2/logos/Icon_BLK@3x.png"
                                        }
                                        style={{
                                            height: 30,
                                            marginTop: 20,
                                            marginRight: 10,
                                        }}
                                        alt="#"
                                    />

                                    {/* <HeaderNoSubmenu
                                        label="IDEAL EXCHANGE"
                                        handleClick={handleNavigateToHome}
                                        isXL
                                    /> */}

                                    <div className="navbar-item">
                                        <div ref={ref}>
                                            <div
                                                className="dropdown-custom dropdown-toggle btn"
                                                style={{
                                                    color:
                                                        isDark &&
                                                        COLORS.v2.white,
                                                }}
                                                onClick={
                                                    !isDebug
                                                        ? handleNavigateToHome
                                                        : undefined
                                                }
                                                onMouseEnter={
                                                    isDebug
                                                        ? handleOpenHomeMenu
                                                        : undefined
                                                }
                                                onMouseLeave={
                                                    isDebug
                                                        ? closeMenu
                                                        : undefined
                                                }
                                            >
                                                {formatMessage({
                                                    id: "IDEAL EXCHANGE",
                                                })}
                                                <span className="lines"></span>
                                                {openMenu && isDebug && (
                                                    <div
                                                        className={
                                                            isDark
                                                                ? "item-dropdown dark"
                                                                : "item-dropdown"
                                                        }
                                                    >
                                                        <div
                                                            className="dropdown"
                                                            onClick={closeMenu}
                                                        >
                                                            <NavLink
                                                                to={ROUTES.home}
                                                            >
                                                                {formatMessage({
                                                                    id: "title.home",
                                                                })}
                                                            </NavLink>
                                                            <NavLink to="/__debug">
                                                                Home Debug
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <HeaderNoSubmenu
                                        label="Origins Club"
                                        handleClick={handleNavigateToOrigisClub}
                                        isXL
                                    />

                                    {isAdmin && (
                                        <HeaderNoSubmenu
                                            label="Admin Interface"
                                            handleClick={
                                                handleNavigatoAdminInterface
                                            }
                                            isXL
                                        />
                                    )}

                                    {isModerator && (
                                        <HeaderNoSubmenu
                                            label="Moderator Interface"
                                            handleClick={
                                                handleNavigateToModeratorInterface
                                            }
                                            isXL
                                        />
                                    )}

                                    <HeaderMenuXL
                                        ref={ref1}
                                        label="title.catalog"
                                        openSubMenu={openCatalogMenu}
                                        handleOpenMenu={handleOpenCatalogMenu}
                                        handleCloseMenu={handleCloseCatalogMenu}
                                        subMenus={CATALOG_ITEMS}
                                    />

                                    <HeaderMenuXL
                                        ref={ref2}
                                        label="title.my.portfolio"
                                        openSubMenu={openMyCollectionMenu}
                                        handleOpenMenu={
                                            handleOpenMyCollectionMenu
                                        }
                                        handleCloseMenu={
                                            handleCloseMyCollectionMenu
                                        }
                                        subMenus={MY_PORTFOLIO_ITEMS}
                                    />

                                    <HeaderMenuXL
                                        ref={ref3}
                                        label="title.community"
                                        openSubMenu={openActivityMenu}
                                        handleOpenMenu={handleOpenActivityMenu}
                                        handleCloseMenu={
                                            handleCloseActivityMenu
                                        }
                                        subMenus={COMMUNITY_ITEMS}
                                    />
                                </div>
                            </Fade>
                        </Breakpoint>
                    </BreakpointProvider>

                    <HeaderAuth />
                </div>

                <button
                    className="nav-icon"
                    onClick={() => btn_icon(!showmenu)}
                >
                    <div
                        className="menu-line white"
                        style={{ backgroundColor: isDark && COLORS.v2.white }}
                    ></div>
                    <div
                        className="menu-line1 white"
                        style={{ backgroundColor: isDark && COLORS.v2.white }}
                    ></div>
                    <div
                        className="menu-line2 white"
                        style={{ backgroundColor: isDark && COLORS.v2.white }}
                    ></div>
                </button>
            </div>
        </header>
    );
};
export default Header;
