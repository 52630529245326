const header = {
    "header.home": "Accueil",
    "header.about": "A propos",
    "header.my.profile": "Mon Profile",
    "header.signin": "Se connecter",
    "header.choose.wallet": "Choisir wallet",
    "header.signout": "Se déconnecter",
};

export default header;
