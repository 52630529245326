import moment from "moment";
import { useContext } from "react";
import { AuthContext } from "../../../providers/Auth/AuthProvider";
import { ThemeContext } from "../../../providers/Theme/ThemeProvider";
import getLikeColor from "../../../utils/nft/getLikeColor";
import CustomAddress from "../../address/CustomAddress";
import NFTAuthorPicture from "../../nftCard/authorPicture/NFTAuthorPicture";

const NFTLikeItem = ({ like }) => {
    const { authContext } = useContext(AuthContext);
    const { isDark } = useContext(ThemeContext);

    return (
        <div className="p_list" style={{ display: "flex" }}>
            <NFTAuthorPicture
                address={like?.user?.id}
                src={like?.user?.profilePictureUri}
                iconClassName="fa fa-heart"
                iconStyle={{
                    color: "white",
                    background: getLikeColor({ isDark }),
                }}
                noAnimation
            />
            <div className="p_list_info">
                <span>
                    <b>
                        {authContext?.myProfile?.id?.toLowerCase() !==
                        like?.user?.id?.toLowerCase()
                            ? like?.user?.userName
                            : "You"}
                    </b>{" "}
                    <CustomAddress address={like?.user?.id} />
                </span>
                <span>
                    at {moment(like?.createdAt).format("DD/MM/YYYY, hh:mm A")}
                </span>
            </div>
        </div>
    );
};

export default NFTLikeItem;
