import { gql } from "@apollo/client";

export const OFFER_FRAGMENT = gql`
    fragment OfferFields on SignedOffer {
        id
        version
        offer
        signature
        validUntil
        IP {
            cid
        }
        user {
            id
        }
    }
`;
