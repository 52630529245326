import { gql } from "@apollo/client";

export const GET_SUBGRAPH_NFT = gql`
    query Nfterc20($id: String!, $activityWhere: Activity_filter) {
        nfterc20(id: $id) {
            id
            address
            totalShares
            title
            description
            royalties
            externalLink
            balances(where: { amount_not: "0" }) {
                id
                owner {
                    address
                }
                amount
            }
            activities(
                orderBy: timestamp
                orderDirection: desc
                where: $activityWhere
            ) {
                id
                activity
                timestamp
                ... on Payment {
                    id
                    owner {
                        address
                    }
                    buyer {
                        address
                    }
                    shares
                    value
                }
                ... on Transfer {
                    id
                    from {
                        address
                    }
                    to {
                        address
                    }
                    amount
                }
            }
        }
    }
`;

export const GET_NFT_STAT = gql`
    query Nfterc20($id: String!) {
        nfterc20(id: $id) {
            id
            address
            totalShares
            title
            dailyStats(where: { averagePrice_not: null, periodicity: DAILY }) {
                averagePrice
                delta
                volume
            }
            weeklyStats(
                where: { averagePrice_not: null, periodicity: WEEKLY }
            ) {
                averagePrice
                delta
                volume
            }
            monthlyStats(
                where: { averagePrice_not: null, periodicity: MONTHLY }
            ) {
                averagePrice
                delta
                volume
            }
        }
    }
`;

export const GET_SUBGRAPH_NFTS = gql`
    query Nfterc20S {
        nfterc20S {
            id
            address
            totalShares
            balances(where: { amount_not: "0" }) {
                id
                owner {
                    address
                }
                amount
            }
            content
        }
    }
`;

export const GET_USER_BALANCES = gql`
    query Nfterc20S($address: String!) {
        balances(where: { owner: $address, amount_not: "0" }) {
            amount
            contract {
                ... on NFTERC20 {
                    balances(where: { amount_not: "0" }) {
                        owner {
                            address
                        }
                        amount
                    }
                    content
                }
            }
        }
    }
`;
