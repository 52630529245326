const authentification = {
    "auth.title": "Connect your wallet.",
    "auth.subtitle": "Connect with one of our available wallet info providers.",
    "auth.metamask.signin.message.success":
        "Authentication via Metamask successful !",
    "auth.metamask.signin.message.error":
        "Error or cancellation of authentication via Metamask.",
    "auth.metamask.signin.locked.message.error": "Please unlock your wallet.",
    "auth.metamask.unsupported.wallet.error": "Unsupported wallet type.",
    "auth.signout.message.success": "Successfully logged out !",
};

export default authentification;
