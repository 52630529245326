import Select from "react-select";

const CustomSelect = ({
    title,
    placeholder,
    options,
    onChange,
    defaultValue,
    value,
    noMaxWidth,
    noOptionsMessage,
    isLoading,
    isClearable,
    containerStyle,
}) => {
    const customStyles = {
        option: (base, state) => ({
            ...base,
            background: "#fff",
            color: "#333",
            borderRadius: state.isFocused ? "0" : 0,
            "&:hover": {
                background: "#eee",
            },
        }),
        menu: (base) => ({
            ...base,
            borderRadius: 0,
            marginTop: 0,
            maxWidth: noMaxWidth ? undefined : 300,
        }),
        menuList: (base) => ({
            ...base,
            padding: 0,
        }),
        control: (base, state) => ({
            ...base,
            padding: 2,
            maxWidth: noMaxWidth ? undefined : 300,
            marginBottom: 20,
        }),
    };

    return (
        <div className="dropdownSelect one" style={containerStyle}>
            {title && <h5>{title}</h5>}
            <Select
                placeholder={placeholder || "Select..."}
                styles={customStyles}
                menuContainerStyle={{ zIndex: 999 }}
                options={options}
                onChange={onChange}
                defaultValue={defaultValue}
                value={value}
                isClearable={
                    isClearable === undefined || isClearable === null
                        ? true
                        : isClearable
                }
                noOptionsMessage={() => noOptionsMessage || "No option..."}
                isLoading={isLoading}
            />
        </div>
    );
};

export default CustomSelect;
