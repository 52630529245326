import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import CustomInput from "../../components/input/CustomInput";
import { AuthContext } from "../../providers/Auth/AuthProvider";
import CustomLoader from "../../components/customLoader/CustomLoader";
import { isValidEmail } from "../../utils/string/isValidEmail";
import ProfilePicture from "../../components/profilePicture/ProfilePicture";
import { SnackBarContext } from "../../providers/SnackBar/SnackBarProvider";
import { UPDATE_MY_PROFILE } from "../../graphql/user/mutation";
import { useMutation } from "@apollo/client";
import CustomButton from "../../components/button/CustomButton";
import Footer from "../../components/footer/Footer";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";
import HeaderWithImage from "../../components/header/HeaderWithImage";

const MyProfileSettings = () => {
    const { formatMessage } = useIntl();
    const { authContext, setAuthContext } = useContext(AuthContext);
    const { myProfile } = authContext;
    const { displaySnackBar } = useContext(SnackBarContext);
    const [me, setMe] = useState(myProfile);
    const { isDark } = useContext(ThemeContext);

    const onError = () => {
        displaySnackBar({
            message: formatMessage({ id: "myProfile.settings.update.error" }),
            type: "error",
        });
    };

    const [updateMyProfile, { loading }] = useMutation(UPDATE_MY_PROFILE, {
        onCompleted: (data) => {
            if (data && data.updateMyProfile) {
                setAuthContext({ myProfile: data.updateMyProfile });
                displaySnackBar({
                    message: formatMessage({
                        id: "myProfile.settings.update.success",
                    }),
                    type: "success",
                });
            } else {
                onError();
            }
        },
        onError,
    });

    useEffect(() => {
        setMe(authContext.myProfile);
    }, [authContext.myProfile]);

    const handleChangeUsername = (value) => {
        setMe({
            ...me,
            userName: value,
        });
    };

    const handleChangeBio = (value) => {
        setMe({
            ...me,
            bio: value,
        });
    };

    const handleChangeEmail = (value) => {
        setMe({
            ...me,
            email: value,
        });
    };

    const handleEditProfilePic = (profilePicUri) => {
        setMe({ ...me, profilePictureUri: profilePicUri });
    };

    const onSave = () => {
        updateMyProfile({
            variables: {
                input: {
                    bio: me?.bio?.trim(),
                    email: me?.email?.trim(),
                    profilePictureUri: me?.profilePictureUri,
                    userName: me?.userName?.trim(),
                },
            },
        });
    };

    const emailError = !me?.email || isValidEmail(me?.email) ? false : true;

    const noChanges =
        me?.userName?.trim() === authContext.myProfile?.userName &&
        me?.bio?.trim() === authContext?.myProfile?.bio &&
        me?.email?.trim() === authContext?.myProfile?.email &&
        me?.profilePictureUri?.trim() ===
            authContext?.myProfile?.profilePictureUri;

    return (
        <div className={isDark ? "greyscheme" : ""}>
            {loading && <CustomLoader text="Applying your changes..." />}

            <HeaderWithImage title={"myProfile.settings.title"} />

            <section className="container">
                <div className="row">
                    <div className="col-lg-7 offset-lg-1 mb-5">
                        <form
                            id="form-create-item"
                            className="form-border"
                            action="#"
                        >
                            <div className="field-set">
                                <CustomInput
                                    label={formatMessage({
                                        id: "myProfile.settings.username",
                                    })}
                                    placeholder={formatMessage({
                                        id: "myProfile.settings.username.placeholder",
                                    })}
                                    value={me?.userName}
                                    handleChangeValue={handleChangeUsername}
                                />

                                <CustomInput
                                    label={formatMessage({
                                        id: "myProfile.settings.bio",
                                    })}
                                    value={me?.bio}
                                    handleChangeValue={handleChangeBio}
                                    placeholder={formatMessage({
                                        id: "myProfile.settings.bio.placeholder",
                                    })}
                                    muiltiline
                                />
                                <CustomInput
                                    label={formatMessage({
                                        id: "myProfile.settings.email",
                                    })}
                                    placeholder={formatMessage({
                                        id: "myProfile.settings.email.placeholder",
                                    })}
                                    value={me?.email}
                                    handleChangeValue={handleChangeEmail}
                                    error={emailError}
                                    errorText={formatMessage({
                                        id: "myProfile.settings.email.format.error",
                                    })}
                                />
                            </div>
                        </form>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-xs-12">
                        <h5>
                            {formatMessage({
                                id: "myProfile.settings.profile.picture",
                            })}
                        </h5>
                        <ProfilePicture
                            src={me?.profilePictureUri}
                            handleEdit={handleEditProfilePic}
                            isEditable
                        />
                    </div>
                </div>
                <div className="update-profile-save">
                    <CustomButton
                        text={formatMessage({
                            id: "common.save",
                        })}
                        onClick={onSave}
                        disabled={noChanges || loading || emailError}
                    />
                </div>
            </section>

            <Footer />
        </div>
    );
};

export default MyProfileSettings;
