import { useContext, useState, useEffect } from 'react';
import { ethers } from "ethers";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";

import { localhost_deployments, devnet_deployments, partialDomain, partialDomainNFT, getIpId, PERMIT_TYPE } from "common";
import config from "../../config/config";

import { AuthContext } from "../providers/Auth/AuthProvider";
// import { GraphNodeClientContext } from "../providers/Apollo/ApolloProvider";
import { useQuery, useMutation, gql, useSubscription } from "@apollo/client";
// import { SnackbarContext } from  "../providers/SnackBar/SnackBarProvider";

const UPLOAD_SINGLE_MUTATION = gql`
  mutation uploadSingle($uploadInput: UploadInput!) {
    uploadSingle(input: $uploadInput) {
      cid
    }
  }
`;

const IPS_QUERY = gql`
	query getIps {
		myCreatedIps {
			cid
			user {
				id
			}
		}
	}
`

const USERS_QUERY = gql`
	query getUsers {
		users {
			id
		}
	}
`

const SIGN_PERMIT_MUTATION = gql`
	mutation SignPermit($permit: Permit!, $signature: String!) {
		signPermit(permit: $permit, signature: $signature)
	}
`

const PERMITS_QUERY = gql`
	query GetPermits {
		myPermits {
			id
			user {
				id
			}
		}
	}
`

const PENDING_IPS_QUERY = gql`
	query {
		getPendingIps {
			cid
			user {
				id
			}
			permit {
				id
				moderator {
					id
				}
			}
		}
	}
`

const USER_CONNECTED_SUBSCRIPTION = gql`
	subscription OnUserConnected {
		userConnected {
			id
		}
	}
`

const PERMIT_STATUS_SUBSCRIPTION = gql`
	subscription OnMyPermitStatus {
		onMyPermitStatus {
			id
			variables
			createdAt
		}
	}
`

const NOTIFICATION_RESULT_QUERY = gql`
	query GetMyNotificationResult($skip: Int!, $take: Int!) {
		myNotifications(skip: $skip, take: $take) {
			notifications {
				id
				variables
			}
			totalCount
			unreadCount
		}
	}
`

// const PERMIT_QUERY = gql`

// `

function IP({cid, jwt, chainId, users, permitMutation }) {
	const [ blob, setBlob ] = useState();
	const [ id, setId ] = useState()
	// const [ blockchainStatus, setBlockchainStatus ] = useState("pending");
	const [ shares, setShares ] = useState();
	const [ myShares, setMyShares ] = useState();
	const { authContext: { wallet } } = useContext(AuthContext);
	const [ user, setUser ] = useState(users.users[0].id);
	const [ bleh, setBleh ] = useState();
	useEffect(() => {
		(async () => {
			// console.log(ethers.BigNumber);
			if ( wallet && wallet.provider && chainId !== "-" && cid) {
				const input = {
					IdealNFTAddress: localhost_deployments.contracts.IdealNFT.address,
					chainId,
					ip: {
						contentType: 1,
						content: ethers.utils.toUtf8Bytes(cid)
					} 
				}
				const id = getIpId(input);
				setId(id);
			}
		})()
	}, [wallet, chainId, cid])

	useEffect(() => {
		(async () => {
			if ( id && wallet.provider ) {
				let contract = new ethers.Contract(id, localhost_deployments.contracts.Fungible.abi)
				contract = contract.connect(wallet.provider);
				try {
					console.log(wallet.address);
					const _myShares = await contract.balanceOf(wallet.address);
					const _totalShares = await contract.totalSupply();
					setMyShares(_myShares.toString());
					setShares(_totalShares.toString());
				} catch(err ) {
					console.error(err.message);
					return;
				}
			}
			
		})()
	}, [id, wallet])

	useEffect(() => {
		(async () => {
			if ( cid && jwt ) {
				const _blob = await fetch(
					`${config.nft.ipsUrl}/${cid}`,
					{
						headers: { 
							Authorization: `Bearer ${jwt}`,
							// "Content-Type": "image/gif"
						}
					}
				)
					.then(res => res.blob());
				console.log("_blob", _blob);
				setBlob(URL.createObjectURL(_blob));
			}
		})();
	}, [cid, jwt]);

	// useEffect(() => {
	// 	(async )
	// }, [])

	return <Card>
		<Card.Title>{cid}<br/>{shares && <b>MINTED</b>}</Card.Title>
		<Card.Body>
			{
				!blob
				?
				<Spinner animation="border" />
				:
				<img src={blob} alt='' />
			}
			{
				id
				&&
				<p>id: {id}</p>
			}
			{
				shares
				&&
				<p>total shares (via blockchain): {shares}</p>
			}
			{
				myShares
				&&
				<p>my shares (via blockchain): {myShares} </p>
			}
			<select value={user} onChange={({target: { value }}) => setUser(value)}>
				{ users.users.map(({id}) => <option key={id}>{id}</option>)}
			</select>
			<button onClick={async () => {
				const verifyingContract = localhost_deployments.contracts.IdealNFT.address;
				const domain = { ...partialDomainNFT, chainId, verifyingContract};
				const types = { Permit: PERMIT_TYPE };
				const tokenId = getIpId({
					IdealNFTAddress: verifyingContract,
					ip: {contentType: 1, content: ethers.utils.toUtf8Bytes(cid)},
					chainId
				});
				const moderator = wallet.address;
				const value = {
					moderator,
					user,
					tokenId,
					blockHeight: "0x00",
					deadline: Math.floor((new Date().getTime()) / 1000) + 3600 * 24,
					salt: ethers.utils.hexlify(ethers.utils.randomBytes(32)),
				}

				const signature = await wallet.signer._signTypedData(
					domain,
					types,
					value
				);

				permitMutation({variables: {
					permit: {
						...value,
						ip: cid
					},
					signature
				}});
			}} > Permit </button>
			
		</Card.Body>
	</Card>
}



function Debug() {
	const { /*name, version,*/ contracts: { IdealMarketplace, /*Fungible,*/ IdealNFT } }  = devnet_deployments
	const [ domain, setDomain ] = useState({
		...partialDomain,
		chainId: "-",
		verifyingContract: IdealMarketplace.address
	});
	const [ blockNumber, setBlockNumber ] = useState("-")
	// const [ fire, setFire ] = useState(0);
	const [ target, setTarget ] = useState()
	const [ ips, setIPs ] = useState([]);
	// const [ wallet, setWallet ] = useState(localStorage.getItem("token"));
	const [ uploadSingleMutation ] = useMutation(UPLOAD_SINGLE_MUTATION);

	const { authContext: { wallet, jwt } } = useContext(AuthContext);
	const { data: ipData, /*loading, error,*/ refetch: refetchIps } = useQuery(IPS_QUERY);
	const { data: users } = useQuery(USERS_QUERY);
	const [ permitMutation, { data: permitData } ] = useMutation(SIGN_PERMIT_MUTATION);
	const { data: permitsData } = useQuery(PERMITS_QUERY);
	const { data: notificationData } = useQuery(NOTIFICATION_RESULT_QUERY, { variables: { skip: 0, take: 10}});
	// const { graphNodeClient } = useContext(GraphNodeClientContext);
	// console.log(graphNodeClient);

	useEffect(() => {
		(async () => {
			if ( wallet ) {
				let chainId = await wallet.provider.send("eth_chainId", [])
				setDomain(prev => ({...prev, chainId}));
			}
			
		})();
	}, [wallet]);



	useEffect(() => {
		( async () => {
			let interval;
			if ( wallet ) {
				let blockNumber = await wallet.provider.getBlockNumber();
				setBlockNumber(blockNumber.toString());
				interval = setInterval(
					async () => setBlockNumber((await wallet.provider.getBlockNumber()).toString())
					, 15000
				);
			} else {
				if ( interval ) interval.clear()
			}
		})()
	}, [wallet])

	useEffect(() => {
		if ( ipData && wallet && wallet.address && jwt ) {
			let _ips = ipData.myCreatedIps.filter(({user: { id }}) => id === wallet.address )
			setIPs(_ips);
			// Promise.all(
			// 	_ips
			// 		.map(({ cid }) => 
			// 			fetch(
			// 				`http://localhost:4000/ips/${cid}`,
			// 				{
			// 					headers: {
			// 						Authorization: `Bearer ${jwt}`
			// 					}
			// 				}
			// 			)
			// 				.then(res => res.blob())
			// 				.then(b => console.log(b))
			// 		)

			// )
		}
	}, [wallet, ipData, jwt])

	const { data: subscriptionData, error: subscriptionError, loading: loadingSubscription } = useSubscription(USER_CONNECTED_SUBSCRIPTION);
	const { data: permitSubscriptionData, error: permitSubscriptionError, loading: permitSubscriptionLoading} = useSubscription(PERMIT_STATUS_SUBSCRIPTION);

	if ( subscriptionError ) {
		console.log("subscriptionError", subscriptionError);
	}

	if ( subscriptionData ) {
		console.log("subscriptionData", subscriptionData);
	}

	if ( loadingSubscription ) {
		console.log("loadingSubscription", loadingSubscription)
	}

	const handleUpload = () => {
		if ( target &&  target.validity.valid ) {
			uploadSingleMutation({variables: { uploadInput: { infos: { title: "whatever"} , file: target.file }}})
				.then(() => refetchIps())
		}
	}

	if ( permitSubscriptionData ) {
		console.log("permitSubscriptionData", permitSubscriptionData);
	}

	if ( notificationData ) {
		console.log(notificationData);
	}
	return <Container>
		<h4>Blockchain</h4>
		<Row>
			<Col>block # : <br/><b>{blockNumber}</b></Col>
			<Col>chain id : <br/><b>{domain.chainId}</b></Col>
			<Col>user address : <br /><b>{wallet ? wallet.address : "-"}</b></Col>
		</Row>
		<h4>Contracts</h4>
		<Row>
			<Col>Ideal marketplace : <br/><b>{IdealMarketplace.address}</b></Col>
			<Col>NFT : <br/><b>{IdealNFT.address}</b></Col>
		</Row>
		<h4>Ideal NFT Marketplace for IPs</h4>
		<h5>Upload</h5>
		<Row>
			<Col>
				<input type="file" onChange={({target: {validity, files: [file]}}) => setTarget({ validity, file})}/>
			</Col>
			<Col><button onClick={handleUpload} disabled={!target || !target.validity.valid}>Upload</button></Col>
		</Row>
		<h5>My uploaded IPs</h5>
		<Row>
			{
				ips.length === 0 || !jwt || !users
				?
				<Col>No data found</Col>
				:
				<>
					{
						ips
							.map(({cid}) => (
								<Col>
									<IP 
										cid={cid}
										jwt={jwt}
										chainId={domain.chainId}
										users={users}
										permitMutation={permitMutation}
										permitData={permitData}
									/>
								</Col>
							))
					}
				</>
			}
		</Row>
		<h5>Permits</h5>
		<Row>
			{permitsData && permitsData.myPermits.map(p => <Col>
				{JSON.stringify(p, null, 2)}
			</Col>)}
		</Row>
	</Container>
}

let exported;
if ( process.env.REACT_APP_DEBUG ) {
	exported = Debug;
} else {
	exported = () => <div></div>
}

export default exported;