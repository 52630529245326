import { gql } from "@apollo/client";
import { USER_FRAGMENT } from "./fragment";

export const UPDATE_MY_PROFILE = gql`
    mutation UpdateMyProfile($input: UpdateMyProfileInput!) {
        updateMyProfile(input: $input) {
            ...UserFields
        }
    }
    ${USER_FRAGMENT}
`;
