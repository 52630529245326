import React, { memo, useState } from "react";
import NFTCardCid from "./NFTCardCid";

const NFTCardListCid = ({ cids, loadMore, showLoadMore }) => {
    const [height, setHeight] = useState(0);

    const onImgLoad = ({ target: img }) => {
        let currentHeight = height;
        if (currentHeight < img.offsetHeight) {
            setHeight(img.offsetHeight);
        }
    };

    if (!cids?.length)
        return (
            <div className="row">
                <div>No item.</div>
            </div>
        );

    return (
        <div className="row">
            {cids &&
                cids.map((cid, index) => (
                    <NFTCardCid
                        cid={cid}
                        key={index}
                        onImgLoad={onImgLoad}
                        height={height}
                    />
                ))}
            {showLoadMore && cids.length && (
                <div className="col-lg-12">
                    <div className="spacer-single"></div>
                    <span onClick={loadMore} className="btn-main lead m-auto">
                        Load More
                    </span>
                </div>
            )}
        </div>
    );
};

export default memo(NFTCardListCid);
