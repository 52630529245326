const net = {
    "net.switch.error": "Error while tring to switch to {name} network.",
    "net.switch.success": "Network successfully switched to {name} network !",
    "net.unknown.message":
        "Your wallet is connected to an unknown network. Please switch to {linkSwitchToMainNet} network.",
    "net.wrong.net.message":
        "Your wallet is connected to the {name} network. To use Ideal on {name}, please switch to {linkText}.",
    "net.main": "Main",
    "net.switch.host.suggestion":
        "If you want to connect to {name}, then please visit this {link}.",
    "net.switch.net.suggestion":
        "If you want to connect to {name}, then press this {link} or change the network in your wallet manually.",
    "net.unsupported.net.suggestion":
        "Three networks are avalaible, please choose one : {mainnet}, {testnet} and {devnet}",
    "net.issue.block.transaction":
        "You have to resolve your network issue to send transaction",
};

export default net;
