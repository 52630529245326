import { Box, createStyles, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import CustomButton from "../button/CustomButton";
import CustomInput from "../input/CustomInput";
import CloseIcon from "@material-ui/icons/Close";
import COLORS from "../../constants/colors";
import { ThemeContext } from "../../providers/Theme/ThemeProvider";
import CustomGGLandDialog from "../dialog/CustomGGLandDialog";

const useStyles = makeStyles((theme) =>
    createStyles({
        addIcon: {
            alignSelf: "center",
            cursor: "pointer",
            marginRight: 10,
        },
        flexContainer: {
            display: "flex",
        },
    })
);

const AddProperties = (props) => {
    const classes = useStyles();
    const { properties, handleChangeProperties } = props;
    const [openDialog, setOpenDialog] = useState(false);
    const { isDark } = useContext(ThemeContext);

    const initial =
        properties && properties.length
            ? properties.map((prop, i) => ({ index: i, ...prop }))
            : [
                  {
                      index: 0,
                      name: undefined,
                      value: undefined,
                  },
              ];

    const [newProperties, setNewProperties] = useState(initial);

    const handleChangeNewPropertyName = (value, name) => {
        const newPropertiesEdited = newProperties.map((prop) => {
            if (`newprop-name-${prop.index}` === name) {
                return {
                    ...prop,
                    name: value,
                };
            }

            return prop;
        });

        setNewProperties(newPropertiesEdited);
    };

    const handleChangeNewPropertyValue = (value, name) => {
        const newPropertiesEdited = newProperties.map((prop) => {
            if (`newprop-value-${prop.index}` === name) {
                return {
                    ...prop,
                    value: value,
                };
            }

            return prop;
        });

        setNewProperties(newPropertiesEdited);
    };

    const addMore = () => {
        const lastElement = newProperties[newProperties.length - 1];

        const addedNewProperties = [
            ...newProperties,
            {
                index: lastElement
                    ? lastElement.index + 1
                    : newProperties.length,
                name: undefined,
                value: undefined,
            },
        ];
        setNewProperties(addedNewProperties);
    };

    const removeProp = (index) => {
        const removedProperties = newProperties
            .filter((prop) => prop.index !== index)
            .map((prop, i) => {
                return { index: i, ...prop };
            });
        setNewProperties(removedProperties);
    };

    const handleOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const onSave = () => {
        const newPropertiesFiltred = newProperties.filter(
            (prop) => !!prop.name && !!prop.value
        );
        handleChangeProperties(newPropertiesFiltred);
        setOpenDialog(false);
    };

    useEffect(() => {
        const initial =
            properties && properties.length
                ? properties.map((prop, i) => ({ index: i, ...prop }))
                : [
                      {
                          index: 0,
                          name: undefined,
                          value: undefined,
                      },
                  ];

        setNewProperties(initial);
    }, [properties]);

    return (
        <div className="switch-with-title">
            <h5>
                <i className="fa fa- fa-list id-color-2 mr10"></i>
                Properties
            </h5>
            <div className="de-switch">
                <CustomButton
                    text="+"
                    className="btn-main inline white lead"
                    onClick={handleOpen}
                    style={{
                        marginRight: 0,
                        borderStyle: !isDark ? "solid" : undefined,
                        borderWidth: !isDark ? 1 : undefined,
                        borderColor: !isDark ? COLORS.light.color : undefined,
                    }}
                />
            </div>
            <div className="clearfix"></div>
            <p className="p-info pb-3">
                Properties show up underneath your item, are clickable, and can
                be filtered in your collection's sidebar.
            </p>

            {openDialog && (
                <CustomGGLandDialog
                    title="Add Properties"
                    handleClose={handleClose}
                    submitText="Save"
                    handleSubmit={onSave}
                    cancelText="+"
                    handleCancel={addMore}
                >
                    <Box className={isDark ? "greyscheme" : ""}>
                        {newProperties &&
                            newProperties.length &&
                            newProperties.map((prop, i) => (
                                <Box style={{ display: "flex" }}>
                                    <CloseIcon
                                        className={classes.addIcon}
                                        onClick={() => removeProp(i)}
                                    />

                                    <CustomInput
                                        label="Name"
                                        placeholder="e.g. 'Gender'"
                                        name={`newprop-name-${prop.index}`}
                                        value={prop.name}
                                        handleChangeValue={
                                            handleChangeNewPropertyName
                                        }
                                        containerStyle={{ marginRight: 20 }}
                                    />
                                    <CustomInput
                                        label="Value"
                                        placeholder="e.g. 'Male'"
                                        value={prop.value}
                                        name={`newprop-value-${prop.index}`}
                                        handleChangeValue={
                                            handleChangeNewPropertyValue
                                        }
                                        containerStyle={{ marginRight: 20 }}
                                    />
                                </Box>
                            ))}
                    </Box>
                </CustomGGLandDialog>
            )}
        </div>
    );
};

export default AddProperties;
