import React, { useContext, useRef, useState } from "react";
import { Link, useNavigate } from "@reach/router";
import ProfilePicture from "../components/profilePicture/ProfilePicture";
import { AuthContext } from "../providers/Auth/AuthProvider";
import { useIntl } from "react-intl";
import { SnackBarContext } from "../providers/SnackBar/SnackBarProvider";
import { ThemeContext } from "../providers/Theme/ThemeProvider";
import {
    Box,
    Button,
    createStyles,
    makeStyles,
    Popover,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import COLORS from "../constants/colors";
import Brightness2Icon from "@material-ui/icons/Brightness2";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import THEMES from "../constants/theme";
import CustomSwitch from "../components/switch/CustomSwitch";
import LOCAL_STORAGE_KEYS from "../constants/localStorage";
import CustomLoader from "../components/customLoader/CustomLoader";
import NotificationIcon from "./notification/NotificationIcon";
import ROUTES from "../components/constants/routes";

const HeaderAuth = () => {
    const { authContext, logout } = useContext(AuthContext);
    const { myProfile, wallet, jwt } = authContext;
    const { formatMessage } = useIntl();
    const navigate = useNavigate();

    const [openedPopover, setOpenedPopover] = useState(false);
    const popoverAnchor = useRef(null);
    const { displaySnackBar } = useContext(SnackBarContext);
    const { isDark, changeTheme, switchTheme } = useContext(ThemeContext);

    const useStyles = makeStyles((theme) =>
        createStyles({
            popover: {
                pointerEvents: "none",
                opacity: 0.9,
            },
            popoverPaper: {
                marginTop: 10,
                borderRadius: 7,
                display: "flex",
                flexDirection: "column",
                pointerEvents: "auto",
                backgroundColor: isDark
                    ? COLORS.dark.background_grey
                    : COLORS.light.white,
            },
            logoutButton: {
                paddingTop: 5,
                paddingBottom: 5,
                textTransform: "none",
                color: isDark ? COLORS.light.white : COLORS.light.black_soft,
                backgroundColor: isDark
                    ? COLORS.v2.black_grey
                    : COLORS.light.white,

                justifyContent: "flex-start",
                fontSize: 14,
                borderRadius: 0,
                "&:hover": {
                    backgroundColor: COLORS.v2.green,
                    color: COLORS.v2.black_grey,
                },
            },
            logoutText: {
                marginLeft: 10,
            },
            separator: {
                height: 1,
                width: "100%",
                backgroundColor: isDark
                    ? COLORS.light.black_soft
                    : COLORS.light.white,
            },
        })
    );

    const classes = useStyles();

    const popoverEnter = () => {
        setOpenedPopover(true);
    };

    const popoverLeave = () => {
        setOpenedPopover(false);
    };

    const _logout = () => {
        logout();
        popoverLeave();
        displaySnackBar({
            message: formatMessage({ id: "auth.signout.message.success" }),
            type: "success",
        });
    };

    const handleChangeTheme = (event) => {
        const isChecked = event.target.checked;
        const theme = isChecked ? THEMES.DARK : THEMES.LIGHT;
        changeTheme(theme);
    };

    const navigateToWallets = () => {
        navigate(ROUTES.wallets);
    };

    const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);

    const loading = token && (!jwt || !wallet);

    if (loading) return <CustomLoader />;

    return myProfile ? (
        <div className="mainside connected" style={{ display: "flex" }}>
            <NotificationIcon />
            <span
                ref={popoverAnchor}
                aria-owns="mouse-over-popover"
                aria-haspopup="true"
                onMouseEnter={popoverEnter}
                onMouseLeave={popoverLeave}
            >
                <ProfilePicture src={myProfile?.profilePictureUri} isSmall />
            </span>

            <Popover
                id="mouse-over-popover"
                open={openedPopover}
                anchorEl={popoverAnchor.current}
                disableScrollLock
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                className={classes.popover}
                classes={{ paper: classes.popoverPaper }}
                PaperProps={{
                    onMouseEnter: popoverEnter,
                    onMouseLeave: popoverLeave,
                }}
                placement="bottom-end"
            >
                <Button
                    className={classes.logoutButton}
                    component={Link}
                    onClick={popoverLeave}
                    to={ROUTES.myProfile}
                >
                    <AccountCircleIcon />
                    <Box className={classes.logoutText}>
                        {formatMessage({ id: "header.my.profile" })}
                    </Box>
                </Button>
                <Box className={classes.separator} />
                <Button className={classes.logoutButton} onClick={switchTheme}>
                    <Brightness2Icon />
                    <Box className={classes.logoutText}>Dark Mode</Box>
                    <CustomSwitch
                        checked={isDark}
                        handleChange={handleChangeTheme}
                    />
                </Button>
                <Box className={classes.separator} />
                <Button className={classes.logoutButton} onClick={_logout}>
                    <ExitToAppIcon />
                    <Box className={classes.logoutText}>
                        {formatMessage({ id: "header.signout" })}
                    </Box>
                </Button>
            </Popover>
        </div>
    ) : (
        <div className="mainside">
            <span
                ref={popoverAnchor}
                aria-owns="mouse-over-popover"
                aria-haspopup="true"
                onMouseEnter={popoverEnter}
                onMouseLeave={popoverLeave}
            >
                <Button
                    onClick={navigateToWallets}
                    style={{
                        fontWeight: "normal",
                        borderRadius: 60,
                        backgroundColor: "black",
                        color: COLORS.v2.green,
                        textTransform: "none",
                        paddingLeft: 20,
                        paddingRight: 20,
                    }}
                >
                    {formatMessage({ id: "wallet.connect" })}
                </Button>
                <Popover
                    id="mouse-over-popover"
                    open={openedPopover}
                    anchorEl={popoverAnchor.current}
                    disableScrollLock
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    className={classes.popover}
                    classes={{ paper: classes.popoverPaper }}
                    PaperProps={{
                        onMouseEnter: popoverEnter,
                        onMouseLeave: popoverLeave,
                    }}
                    placement="bottom-end"
                >
                    <Button
                        className={classes.logoutButton}
                        onClick={switchTheme}
                    >
                        <Brightness2Icon />
                        <Box className={classes.logoutText}>Dark Mode</Box>
                        <CustomSwitch
                            checked={isDark}
                            handleChange={handleChangeTheme}
                        />
                    </Button>
                </Popover>
            </span>
        </div>
    );
};
export default HeaderAuth;
