import { keyframes } from "@emotion/react";

export const fadeInUp = keyframes`
0% {
  opacity: 0;
  -webkit-transform: translateY(40px);
  transform: translateY(40px);
}
100% {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
`;

export const inline = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
.d-inline{
  display: inline-block;
 }
`;
