import { gql } from "@apollo/client";

export const HELP_FRAGMENT = gql`
    fragment HelpFields on Help {
        id
        title
        description
        priority
    }
`;

export const SUB_HELP_FRAGMENT = gql`
    fragment SubHelpFields on SubHelp {
        id
        title
        priority
    }
`;

export const PARAGRAPH_FRAGMENT = gql`
    fragment ParagraphFields on Paragraph {
        id
        text
        picture
        priority
    }
`;
