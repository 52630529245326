import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import COLORS from "../../../../constants/colors";
import WhatsHotTableItem from "./item/WhatsHotTableItem";

const useStyles = makeStyles({
    tableHead: {
        backgroundColor: COLORS.v2.grey_background,
        color: COLORS.v2.greyText,
    },
    noBorder: {
        borderBottom: "none",
        borderTop: "none",
    },
});

const WhatsHotTable = ({ items, filter }) => {
    const classes = useStyles();

    const CustomTableCellHeader = ({ children, style }) => (
        <TableCell
            className={classes.tableHead}
            align="left"
            classes={{ root: classes.noBorder }}
            width="14,28%"
            style={style}
        >
            {children}
        </TableCell>
    );

    return (
        <TableContainer>
            <Table aria-label="simple table">
                <TableHead className={classes.tableHead}>
                    <TableRow>
                        <CustomTableCellHeader
                            style={{
                                color: COLORS.v2.greyText,
                                paddingLeft: 90,
                            }}
                        >
                            User
                        </CustomTableCellHeader>
                        <CustomTableCellHeader>IDX Name</CustomTableCellHeader>
                        <CustomTableCellHeader>Volume</CustomTableCellHeader>
                        <CustomTableCellHeader>Shares</CustomTableCellHeader>
                        <CustomTableCellHeader>
                            Average Price
                        </CustomTableCellHeader>
                        <CustomTableCellHeader>24h %</CustomTableCellHeader>
                        <CustomTableCellHeader>1 week %</CustomTableCellHeader>
                        <CustomTableCellHeader
                            style={{
                                color: COLORS.v2.greyText,
                                paddingRight: 90,
                            }}
                        >
                            1 month %
                        </CustomTableCellHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item, index) => (
                        <WhatsHotTableItem
                            item={item}
                            index={index}
                            filter={filter}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default WhatsHotTable;
