const EtherLogo = ({ style }) => {
    return (
        <img
            key="/img/v2/logos/ethers-logo.png"
            src="/img/v2/logos/ethers-logo.png"
            alt="#"
            style={{ height: 15, ...style }}
        />
    );
};

export default EtherLogo;
